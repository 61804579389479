import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Cookies from "js-cookie";
import { getAccessControl, put_AccessControl } from "../../../../../services/acessControl";

type Props = {
  roleId: number;
  searchValue: string;
  getExpandGroupsDetails: (expandList: any[]) => void;
  setPleaseWait: (wait: boolean) => void;
  isChange: number;
  updateToZeroChange: () => void;
};

export default function AllRoles({
  roleId,
  searchValue,
  getExpandGroupsDetails,
  setPleaseWait,
  isChange,
  updateToZeroChange
}: Props) {
  const token = Cookies.get("token-jj-rental-ui");
  const [roles, setRoles] = useState<any[]>([]);
  const [filterRoles, setFilterRoles] = useState<any[]>([]);
  const [isChangeAll, setIsChangeAll] = useState<boolean>(false);

  useEffect(() => {
    setPleaseWait(true);
    getAccessControl("accessControl", {roleId}, token!.toString())
      .then((response) => {
        const grouped = response.reduce(
          (result: any[], item: any) => {
            if (!result[item.groupName]) {
              result[item.groupName] = [];
            }
            result[item.groupName].push(item);
            return result;
          },
          {}
        );

        setRoles(() =>
          Object.keys(grouped).map((key) => {
            return {
              groupName: key,
              isExpand: false,
              sections: grouped[key],
            };
          })
        );

        setFilterRoles(() =>
          Object.keys(grouped).map((key) => {
            return {
              groupName: key,
              isExpand: false,
              sections: grouped[key],
            };
          })
        );
        setPleaseWait(false);
      })
      .catch((error) => {
        console.error(error);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId]);

  useEffect(() => {
    if (isChange !== 0) {
      setFilterRoles(() =>
        filterRoles.map((item: any) => {
          if (item.isExpand) {
            return {
              ...item,
              sections: item.sections.map((data: any) => {
                return {
                  ...data,
                  allowDenyStatusId: isChange,
                };
              }),
            };
          }
          return item;
        })
      );

      setRoles(() =>
        roles.map((item: any) => {
          if (filterRoles.find((filterData: any) => filterData.groupName === item.groupName) ? filterRoles.find((filterData: any) => filterData.groupName === item.groupName).isExpand : item.isExpand) {
            return {
              ...item,
              sections: item.sections.map((data: any) => {
                return {
                  ...data,
                  allowDenyStatusId: isChange,
                };
              }),
            };
          }
          return item;
        })
      );

      setIsChangeAll(true);
      setPleaseWait(false);
      updateToZeroChange();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChange]);

  useEffect(() => {
    if (!isChangeAll) {
      if (roles)
        setFilterRoles(() =>
          roles
            .filter(
              (item: any) =>
                item.groupName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                item.sections?.find(
                  (value: any) =>
                    value.section &&
                    value.section
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                ) ||
                item.sections?.find(
                  (value: any) =>
                    value.action &&
                    value.action
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                )
            )
            .map((data: any) => {
              return {
                ...data,
                isExpand: filterRoles.find(
                  (filterData: any) => filterData.groupName === data.groupName
                )?.isExpand,
                sections: data.sections?.filter(
                  (filterData: any) =>
                    (filterData.section &&
                      filterData.section
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())) ||
                    (filterData.action &&
                      filterData.action
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()))
                ),
              };
            })
        );
    } else {
      setIsChangeAll(false);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, roles]);

  useEffect(() => {
    getExpandGroupsDetails(filterRoles.filter((item: any) => item.isExpand));
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRoles]);

  const updatedSingleSelection = async (
    id: number,
    allowDenyStatusId: number
  ) => {
    setPleaseWait(true);
    put_AccessControl(id, allowDenyStatusId, token!.toString())
      .then(() => {
        setFilterRoles(() =>
          filterRoles.map((item: any) => {
            if (item.sections.find((value: any) => value.id === id)) {
              return {
                ...item,
                sections: item.sections.map((data: any) => {
                  if (data.id === id) {
                    return {
                      ...data,
                      allowDenyStatusId: allowDenyStatusId,
                    };
                  }
                  return data;
                }),
              };
            }
            return item;
          })
        );

        setRoles(() =>
          roles.map((item: any) => {
            if (item.sections.find((value: any) => value.id === id)) {
              return {
                ...item,
                sections: item.sections.map((data: any) => {
                  if (data.id === id) {
                    return {
                      ...data,
                      allowDenyStatusId: allowDenyStatusId,
                    };
                  }
                  return data;
                }),
              };
            }
            return item;
          })
        );

        setPleaseWait(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div style={{ padding: "24px" }}>
      {filterRoles.map((header: any, index: number) => {
        return (
          <div
            key={index}
            style={{
              border: "1px solid gray",
              borderRadius: "6px",
              marginBottom: "8px",
              cursor: "pointer",
            }}
          >
            {/* Dropdown */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#eceff1",
                color: "#454545",
                padding: "6px",
                borderRadius: header.isExpand ? "6px 6px 0px 0px" : "6px",
              }}
              onClick={() =>
                setFilterRoles((prevRoles) => {
                  const result = [...prevRoles];
                  result[index] = {
                    ...result[index],
                    isExpand: !header.isExpand,
                  };
                  return result;
                })
              }
            >
              <p
                style={{
                  marginBlock: "0px",
                  fontSize: "14px",
                  WebkitUserSelect: "none" /* Safari */,
                  msUserSelect: "none" /* IE 10 and IE 11 */,
                  userSelect: "none" /* Standard syntax */,
                }}
              >
                {header.groupName}
              </p>

              {header.isExpand ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}
            </div>

            {/* ExpandList */}
            <div
              style={{
                display: header.isExpand ? "block" : "none",
                cursor: "default",
              }}
            >
              {/* Header */}
              <hr style={{ marginTop: "0px" }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0px 8px 2px 8px",
                }}
              >
                <h5
                  style={{
                    width: "33.33%",
                    fontWeight: "normal",
                    marginBlock: "0px",
                  }}
                >
                  Section
                </h5>
                <h5
                  style={{
                    width: "33.33%",
                    fontWeight: "normal",
                    marginBlock: "0px",
                  }}
                >
                  Action
                </h5>
                <div
                  style={{
                    width: "33.33%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                    opacity: "0.2",
                  }}
                >
                  <button
                    style={{
                      cursor: "default",
                      backgroundColor: "gray",
                      color: "white",
                      borderRadius: "2px 0px 0px 2px",
                      textTransform: "capitalize",
                      fontSize: "10px",
                      fontWeight: "bold",
                      maxHeight: "20px",
                      minHeight: "20px",
                      border: "none",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Not Set
                  </button>

                  <button
                    style={{
                      cursor: "default",
                      backgroundColor: "#66BB6A",
                      color: "white",
                      borderRadius: "0px",
                      textTransform: "capitalize",
                      fontSize: "10px",
                      fontWeight: "bold",
                      maxHeight: "20px",
                      minHeight: "20px",
                      border: "none",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Allow
                  </button>

                  <button
                    style={{
                      cursor: "default",
                      backgroundColor: "#ff0000",
                      color: "white",
                      borderRadius: "0px 2px 2px 0px",
                      textTransform: "capitalize",
                      fontSize: "10px",
                      fontWeight: "bold",
                      maxHeight: "20px",
                      minHeight: "20px",
                      border: "none",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Deny
                  </button>
                </div>
              </div>
              <hr style={{ marginBlock: "0px" }} />

              {header.sections.map((item: any, index: number, arr: any[]) => {
                return (
                  <React.Fragment key={index}>
                    {/* List */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        verticalAlign: "center",
                        padding: "8px 8px",
                      }}
                    >
                      <h5
                        style={{
                          width: "33.33%",
                          fontWeight: "normal",
                          fontSize: "10px",
                          marginBlock: "0px",
                        }}
                      >
                        {item.section}
                      </h5>
                      <h5
                        style={{
                          width: "33.33%",
                          fontWeight: "normal",
                          fontSize: "10px",
                          marginBlock: "0px",
                        }}
                      >
                        {item.action}
                      </h5>
                      <div
                        style={{
                          width: "33.33%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <button
                          style={{
                            opacity: item.allowDenyStatusId === 1 ? "1" : "0.2",
                            backgroundColor: "gray",
                            color: "white",
                            borderRadius: "2px 0px 0px 2px",
                            textTransform: "capitalize",
                            fontSize: "10px",
                            fontWeight: "bold",
                            maxHeight: "20px",
                            minHeight: "20px",
                            border: "none",
                            cursor:
                              item.allowDenyStatusId === 1
                                ? "default"
                                : "pointer",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                          onClick={() => {
                            if (item.allowDenyStatusId !== 1) {
                              updatedSingleSelection(item.id, 1);
                            }
                          }}
                        >
                          Not Set
                        </button>

                        <button
                          style={{
                            opacity: item.allowDenyStatusId === 2 ? "1" : "0.2",
                            backgroundColor: "#66BB6A",
                            color: "white",
                            borderRadius: "0px",
                            textTransform: "capitalize",
                            fontSize: "10px",
                            fontWeight: "bold",
                            maxHeight: "20px",
                            minHeight: "20px",
                            border: "none",
                            cursor:
                              item.allowDenyStatusId === 2
                                ? "default"
                                : "pointer",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                          onClick={() => {
                            if (item.allowDenyStatusId !== 2) {
                              updatedSingleSelection(item.id, 2);
                            }
                          }}
                        >
                          Allow
                        </button>

                        <button
                          style={{
                            opacity: item.allowDenyStatusId === 3 ? "1" : "0.2",
                            backgroundColor: "#ff0000",
                            color: "white",
                            borderRadius: "0px 2px 2px 0px",
                            textTransform: "capitalize",
                            fontSize: "10px",
                            fontWeight: "bold",
                            maxHeight: "20px",
                            minHeight: "20px",
                            border: "none",
                            cursor:
                              item.allowDenyStatusId === 3
                                ? "default"
                                : "pointer",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                          onClick={() => {
                            if (item.allowDenyStatusId !== 3) {
                              updatedSingleSelection(item.id, 3);
                            }
                          }}
                        >
                          Deny
                        </button>
                      </div>
                    </div>
                    {arr.length - 1 !== index && (
                      <hr
                        style={{ marginBlock: "0px", background: "#e0e0e0" }}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
