import { Dialog, DialogContent } from '@mui/material';
import React from 'react'

type Props = {
  pleaseWaitPopup: boolean;
}

export default function PleaseWaitPopup({ pleaseWaitPopup }: Props) {
  return (
    <>
      <Dialog
        open={pleaseWaitPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "32%",
          },
        }}
      >
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
              backgroundColor: "white",
              color: "black",
              textAlign: "center",
            }}
          >
            Please Wait...
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
