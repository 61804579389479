import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TenTabLayout from "./TenTabLayout";
import { CurrentType } from "../../../enum/CurrentType";
import { useDispatch } from "react-redux";
import { setAppState } from "../../../redux/features/appStateSlice";

export default function EditReservation() {
  const [currentType, setCurrentType] = useState<CurrentType>(CurrentType.EDIT)
  const primaryKey = useLocation().state;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppState("carrental.reservations"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TenTabLayout
      currentType={currentType}
      setCurrentType={(value) => setCurrentType(value)}
      _primaryKey={primaryKey}
      data={{}} />
  );
}
