import React from "react";
import UtilizationGraph from "./UtilizationGraph";
import QuickPickup from "./QuickPickup";
import QuickLinks from "./QuickLinks";
import AllTodaysPickups from "./AllTodaysPickups";
import AllReturns from "./AllReturns";
import OverDue from "./OverDue";
import RepairsIn from "./RepairsIn";
import RepairsOut from "./RepairsOut";
import PendingMaintenance from "./PendingMaintenance";

type Props = {
  date: Date;
};

export default function DailyManifest({ date }: Props) {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", padding: "14px" }}>
        <div style={{ width: "50%" }}>
          <UtilizationGraph date={date} />
        </div>

        <div style={{ width: "50%" }}>
          <QuickPickup />
        
          <QuickLinks />
        </div>
      </div>

        <div style={{ padding: "14px" }}>
          <AllTodaysPickups date={date} />

          <AllReturns date={date} />

          <OverDue date={date} />

          <RepairsIn date={date}/>
          
          <RepairsOut date={date}/>

          <PendingMaintenance date={date}/>
        </div>
    </>
  );
}
