import { Box, Button, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import NightlightOutlinedIcon from "@mui/icons-material/NightlightOutlined";
import colorConfigs from "../../../../configs/colorConfigs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../../configs/api";

const accountingAccountOptions: any = [];
for (let i = 1; i <= 8; i++) {
  accountingAccountOptions.push({ value: i, label: i });
}

export const AddSeasons = () => {
  const [name, setName] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const [dateStart, setDateStart] = useState<string>("");
  const [dateEnd, setDateEnd] = useState<string>("");
  const [minimalRentalPeriodDays, setMinimalRentalPeriodDays] =
    useState<string>("");
  const [maxRentalPeriodDays, setMaxRentalPeriodDays] = useState<string>("");
  const [minimumRentlHours, setMinimumRentlHours] = useState<string>("");
  const [maximumRentalHours, setMaximumRentalHours] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [accountingAccount, setAccountingAccount] = useState<string>("");

  const [branches, setBranches] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then((response) => {
        setBranches(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.branchName };
          })
        );

        setBranchId(response.data[0].id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}season`,
        JSON.stringify({
          name,
          branchId,
          dateStart,
          dateEnd,
          minimalRentalPeriodDays,
          maxRentalPeriodDays,
          minimumRentlHours,
          maximumRentalHours,
          accountingAccount,
          isActive: active,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <NightlightOutlinedIcon
            sx={{ fontSize: "24px", marginRight: "5px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Seasons / Add Season
          </Typography>
        </Box>
      </Toolbar>
      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            Season Overview
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="branchId"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Branch
                    <br />
                    <select
                      name="branchId"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={branchId}
                      onChange={(e) => setBranchId(e.target.value)}
                      required
                    >
                      {branches.map((item: any) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <div
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "50%", marginRight: "3%" }}>
                      <label
                        htmlFor="year"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Date Start <br />
                        <input
                          type="date"
                          name="year"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={dateStart}
                          onChange={(e) => setDateStart(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="year"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Date End <br />
                        <input
                          type="date"
                          name="year"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={dateEnd}
                          onChange={(e) => setDateEnd(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="minimumRentlHours"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Minimum Rental Hours
                      <br />
                      <input
                        type="text"
                        name="minimumRentlHours"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={minimumRentlHours}
                        onChange={(e) => setMinimumRentlHours(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="accountingAccount"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Accounting Account
                      <br />
                      <Select
                        styles={{
                          menu: (base) => ({ ...base, fontSize: "12px" }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={false}
                        name="accountingAccount"
                        value={accountingAccountOptions.find(
                          (item: any) => item.value === accountingAccount
                        )}
                        onChange={(accountingAccount: any) =>
                          setAccountingAccount(
                            accountingAccount ? accountingAccount.value : ""
                          )
                        }
                        options={accountingAccountOptions}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "10px", fontWeight: "bold" }}>
                      Active?
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="enabled"
                        style={{ fontSize: "12px" }}
                        checked={active ? true : false}
                        onChange={(e) =>
                          setActive(e.target.value === "Yes" ? true : false)
                        }
                      />
                      Yes
                    </label>
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="enabled"
                        style={{ fontSize: "12px" }}
                        checked={active ? false : true}
                        onChange={(e) =>
                          setActive(e.target.value === "Yes" ? true : false)
                        }
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="websiteLink"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Name
                    <br />
                    <input
                      type="text"
                      name="websiteLink"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "50%", marginRight: "3%" }}>
                    <label
                      htmlFor="maxRentalPeriodDays"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Minimal Rental Period (Days)
                      <br />
                      <input
                        type="text"
                        name="maxRentalPeriodDays"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={minimalRentalPeriodDays}
                        onChange={(e) =>
                          setMinimalRentalPeriodDays(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>
                  <div style={{ width: "50%" }}>
                    <label
                      htmlFor="year"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Max Rental Period (Days)
                      <br />
                      <input
                        type="text"
                        name="maxRentalPeriodDays"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={maxRentalPeriodDays}
                        onChange={(e) => setMaxRentalPeriodDays(e.target.value)}
                        required
                      />
                    </label>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="maximumRentalHours"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Maximum Rental Hours
                    <br />
                    <input
                      type="text"
                      name="maximumRentalHours"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={maximumRentalHours}
                      onChange={(e) => setMaximumRentalHours(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
