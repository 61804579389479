import { Box, Button, Stack } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import baseURL from "../../../../configs/api";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  width: "100%",
  paddingBottom: "0px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  paddingLeft: `calc(1em + ${theme.spacing(2)})`,
  paddingBottom: "0px",
}));

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 90,
    editable: false,
    sortable: false,
  },
  {
    field: "lastName",
    headerName: "Full Name",
    width: 150,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <Link to="/admin/usermanagement/users/edituser" state={params.id}>
          {params.value}
        </Link>
      );
    },
  },
  {
    field: "userName",
    headerName: "Username",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "emailAddress",
    headerName: "Email",
    type: "number",
    width: 110,
    editable: false,
    sortable: false,
  },
  {
    field: "mobilPhoneNumber",
    headerName: "Mobile Number",
    editable: false,
    sortable: false,
    width: 160,
  },
  {
    field: "role",
    headerName: "Roles",
    width: 110,
    editable: false,
    sortable: false,
  },
  {
    field: "enabled",
    headerName: "Enabled",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "lastActivity",
    headerName: "Last Activity",
    type: "number",
    width: 110,
    editable: false,
    sortable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    type: "number",
    width: 110,
    editable: false,
    sortable: false,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    type: "number",
    width: 110,
    editable: false,
    sortable: false,
  },
];


export const EnabledUsers = () => {
  const isEnabled = true;
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [oldPage, setOldPage] = useState<number>(-1);
  const [searchTableData, setSearchTableData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [usersFilterValue, setUsersFilterValue] = useState(0);
  const [operatorFilter, setOpertorFilter] = useState(0);
  const [totalLength, setTotalLength] = useState<number>(0);
  const [selectedFilter, setSelectedFilter] = useState<string>("Full Name");
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  useEffect(() => {
    if (searchValue === "" && !isFirstTime) {
      const newPaginationModel = {
        page: 0,
        pageSize: paginationModel.pageSize,
      };

      setPaginationModel(newPaginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setIsFirstTime(false);
      emptySearchApiCall(paginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])



  const emptySearchApiCall = (newPaginationModel: any) => {
    setIsDataLoaded(true);
    fetch(`${baseURL}users/GetUserReportPagingandIsEnabled/${newPaginationModel.page},${newPaginationModel.pageSize},${isEnabled}`)
      .then((res) => res.json())
      .then((data) => {

        const result = data.userReportsDto.map((item: any) => {
          return {
            ...item,
            lastName: item.firstName + " " + item.lastName,
            enabled: item.enabled ? "Yes" : "No",
          };
        })

        let newArray = [];
        if (paginationModel.page === 0) {
          newArray = result;
        } else if (paginationModel.page > oldPage) {
          newArray = searchTableData.concat(result);
        } else {
          newArray = searchTableData;
        }

        setSearchTableData(newArray);
        setTotalLength(data.count);
        setIsDataLoaded(false);

        if (paginationModel.page === 0) {
          setOldPage(-1);
        } else {
          setOldPage(() => paginationModel.page - 1);
        }
      });
  }


  useEffect(() => {

    let timeOutId: NodeJS.Timeout;

    if (searchValue !== "") {
      timeOutId = setTimeout(() => {
        const newPaginationModel = {
          page: 0,
          pageSize: paginationModel.pageSize,
        };

        setPaginationModel(newPaginationModel);

      }, 1000);

    }

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue !== "") {
      apiCall(paginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const apiCall = (newPaginationModel: any) => {
    let filterData: any[] = [];
    setIsDataLoaded(true);
    switch (usersFilterValue) {
      case 0:
        fetch(`${baseURL}users/GetUserReporttSearchFullNameAndIsEnabled/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize},${isEnabled}`)
          .then((res) => res.json())
          .then((data) => {

            filterData = data.userReportsDto.map((item: any) => {
              return {
                ...item,
                lastName: item.firstName + " " + item.lastName,
                enabled: item.enabled ? "Yes" : "No",
              };
            })

            let newArray = [];
            if (paginationModel.page === 0) {
              newArray = filterData;
            } else if (paginationModel.page > oldPage) {
              newArray = searchTableData.concat(filterData);
            } else {
              newArray = searchTableData;
            }

            setSearchTableData(newArray);
            setTotalLength(data.count);
            setIsDataLoaded(false);
          });
        break;
      case 1:
        fetch(`${baseURL}users/GetUserReportSearchUserNameAndIsEnabled/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize},${isEnabled}`)
          .then((res) => res.json())
          .then((data) => {

            filterData = data.userReportsDto.map((item: any) => {
              return {
                ...item,
                lastName: item.firstName + " " + item.lastName,
                enabled: item.enabled ? "Yes" : "No",
              };
            })

            let newArray = [];
            if (paginationModel.page === 0) {
              newArray = filterData;
            } else if (paginationModel.page > oldPage) {
              newArray = searchTableData.concat(filterData);
            } else {
              newArray = searchTableData;
            }

            setSearchTableData(newArray);
            setTotalLength(data.count);
            setIsDataLoaded(false);
            if (paginationModel.page === 0) {
              setOldPage(-1);
            } else {
              setOldPage(() => paginationModel.page - 1);
            }
          });
        break;
      case 2:
        fetch(`${baseURL}users/GetUserReportSearchEmailAndIsEnabled/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize},${isEnabled}`)
          .then((res) => res.json())
          .then((data) => {

            filterData = data.userReportsDto.map((item: any) => {
              return {
                ...item,
                lastName: item.firstName + " " + item.lastName,
                enabled: item.enabled ? "Yes" : "No",
              };
            })

            let newArray = [];
            if (paginationModel.page === 0) {
              newArray = filterData;
            } else if (paginationModel.page > oldPage) {
              newArray = searchTableData.concat(filterData);
            } else {
              newArray = searchTableData;
            }

            setSearchTableData(newArray);
            setTotalLength(data.count);
            setIsDataLoaded(false);

            if (paginationModel.page === 0) {
              setOldPage(-1);
            } else {
              setOldPage(() => paginationModel.page - 1);
            }
          });
        break;
      case 3:
        fetch(`${baseURL}users/GetUserReportSearchRoleAndIsEnabled/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize},${isEnabled}`)
          .then((res) => res.json())
          .then((data) => {

            filterData = data.userReportsDto.map((item: any) => {
              return {
                ...item,
                lastName: item.firstName + " " + item.lastName,
                enabled: item.enabled ? "Yes" : "No",

              };
            })

            let newArray = [];
            if (paginationModel.page === 0) {
              newArray = filterData;
            } else if (paginationModel.page > oldPage) {
              newArray = searchTableData.concat(filterData);
            } else {
              newArray = searchTableData;
            }

            setSearchTableData(newArray);
            setTotalLength(data.count);
            setIsDataLoaded(false);

            if (paginationModel.page === 0) {
              setOldPage(-1);
            } else {
              setOldPage(() => paginationModel.page - 1);
            }
          });
        break;

    }
  }





  const applyFilter = () => {
    setSelectedFilter(() =>
      usersFilterValue === 0 ? "Full Name" : usersFilterValue === 1 ? "UserName" : usersFilterValue === 2 ? "Email" : "Role"
    );
    setSearchValue("");
    setDisplayFilter(false);
  };

  const clearFilter = () => {
    setSearchValue("");
    setDisplayFilter(false);
  };


  return (
    <>
      <Box sx={{ padding: "24px" }}>
        <Stack
          direction="row"
          spacing={3}
          sx={{ border: "1px solid black", alignItems: "center" }}
        >
          <div style={{ position: "relative", width: "150px" }}>
            <Button
              variant="contained"
              endIcon={<ArrowDropDownIcon sx={{ color: "#bdbdbd" }} />}
              sx={{
                color: "black",
                backgroundColor: "#e0dee0",
                textTransform: "unset",
                fontSize: "12px",
                border: "none",
                borderRadius: "0px",

                "&: hover": {
                  backgroundColor: "white",
                },
              }}
              onClick={() => setDisplayFilter(() => !displayFilter)}
            >
              {selectedFilter}
            </Button>

            <div
              style={{
                display: displayFilter ? "block" : "none",
                width: "170px",
                position: "absolute",
                zIndex: "100",
                top: "-100%",
                left: "100%",
                background: "white",
                border: "1px solid black",
                padding: "8px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="fields"
                    style={{ fontSize: "10px", fontWeight: "bold" }}
                  >
                    Fields
                    <br />
                  </label>

                  <CloseIcon
                    sx={{
                      width: "18px",
                      fontSize: "10px",
                      cursor: "pointer",
                      background: "#e0e0e0",
                    }}
                    onClick={() => setDisplayFilter(false)}
                  />
                </div>

                <select
                  name="fields"
                  style={{
                    width: "100%",
                    fontSize: "10px",
                    paddingBlock: "3px",
                  }}
                  value={usersFilterValue}
                  onChange={(e) =>
                    setUsersFilterValue(parseInt(e.target.value))
                  }
                >
                  <option value="0">Full Name</option>
                  <option value="1">Username</option>
                  <option value="2">Email</option>
                  <option value="3">Role</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="operator"
                  style={{ fontSize: "10px", fontWeight: "bold" }}
                >
                  Operator
                  <br />
                  <select
                    name="operator"
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      paddingBlock: "3px",
                    }}
                    value={operatorFilter}
                    onChange={(e) => setOpertorFilter(parseInt(e.target.value))}
                  >
                    <option value="0">In a list</option>
                  </select>
                </label>
              </div>


              <div
                style={{
                  display: "flex",

                  marginTop: "7px",
                  justifyContent: "space-between",
                  whiteSpace: "nowrap",
                }}
              >
                <button
                  className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                  onClick={() => applyFilter()}
                >
                  Apply Filter
                </button>

                <button
                  className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                  onClick={() => clearFilter()}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);

              }}
            />
          </Search>
        </Stack>

        <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
          {!isDataLoaded ? searchTableData.length > 0 ? (
            <DataGrid
              rows={searchTableData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
              rowCount={totalLength}
              paginationModel={paginationModel}
              onPaginationModelChange={(model: GridPaginationModel) => {
                if (model.pageSize !== paginationModel.pageSize) {
                  setPaginationModel({
                    page: 0,
                    pageSize: model.pageSize,
                  });
                  return;
                }

                setPaginationModel({
                  page: model.page,
                  pageSize: model.pageSize,
                });
              }}
              disableRowSelectionOnClick
              disableColumnMenu
              checkboxSelection
              showCellVerticalBorder={false}
              sx={{
                border: "none",
                fontSize: "12px",
                wordWrap: "break-word",
                overflow: "visible",
              }}
            />
          ) : (
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <p>No Data Found</p>
            </div>
          ) : (
            <div className="loading">
              <p>Please wait</p>
              <span>
                <i></i>
                <i></i>
              </span>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};
