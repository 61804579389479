import React, { useEffect, useState } from "react";
import { format } from "date-fns";

type Props = {
  branches: any;
  reservation: any;
  changeRates: (
    hourlyRate: string,
    dailyRate: string,
    weeklyRate: string,
    yearlyRate: string
  ) => void;
};

export default function RackRateDetails({
  branches,
  reservation,
  changeRates,
}: Props) {
  const [hourlyRate, setHourlyRate] = useState<string>("");
  const [dailyRate, setDailyRate] = useState<string>("");
  const [weeklyRate, setWeeklyRate] = useState<string>("");
  const [yearlyRate, setYearlyRate] = useState<string>("");
  const [isChange, setIsChange] = useState<boolean>(false);

  useEffect(() => {
    if (reservation) {
      setHourlyRate(reservation?.hourlyRate);
      setDailyRate(reservation?.dailyRate);
      setWeeklyRate(reservation?.weeklyRate);
      setYearlyRate(reservation?.yearlyRate);
    }
  }, [reservation]);

  useEffect(() => {
    if (isChange) {
      changeRates(hourlyRate, dailyRate, weeklyRate, yearlyRate);
      setIsChange(false);
    }
  }, [isChange]);

  return (
    <div
      style={{
        width: "100%",
        padding: "24px",
      }}
    >
      <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
        Rack rates details
      </h5>
      <hr />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "20px",
          gap: "8px",
        }}
      >
        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          #
        </div>
        <div
          style={{
            width: "18%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Season
        </div>
        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Hours
        </div>
        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Days
        </div>
        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Weeks
        </div>
        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Months
        </div>
        <div
          style={{
            width: "15%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Base Hourly Price
        </div>
        <div
          style={{
            width: "15%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Base Daily Price
        </div>
        <div
          style={{
            width: "15%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Base Weekly Price
        </div>
        <div
          style={{
            width: "15%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Base Monthly Price
        </div>
        <div
          style={{
            width: "15%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Dates
        </div>
        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Was Manually Changed
        </div>
      </div>
      <hr style={{ marginBottom: "0px" }} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          {reservation?.id}
        </div>

        <div
          style={{
            width: "18%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          {
            branches.find(
              (item: any) => item.id === parseInt(reservation?.branchId)
            )?.branchName
          }
        </div>

        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          {reservation?.totalHours}
        </div>

        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          {reservation?.totalDays}
        </div>

        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          {reservation?.totalWeeks}
        </div>

        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          {reservation?.totalYears}
        </div>

        <div
          style={{
            width: "15%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          <input
            style={{ width: "100%" }}
            type="text"
            value={hourlyRate}
            onChange={(e) => {
              setIsChange(true);
              setHourlyRate(e.target.value);
            }}
          />
        </div>

        <hr style={{ marginBlock: "0px" }} />

        <div
          style={{
            width: "15%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          <input
            style={{ width: "100%" }}
            type="text"
            value={dailyRate}
            onChange={(e) => {
              setIsChange(true);
              setDailyRate(e.target.value);
            }}
          />
        </div>

        <hr style={{ marginBlock: "0px" }} />

        <div
          style={{
            width: "15%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          <input
            style={{ width: "100%" }}
            type="text"
            value={weeklyRate}
            onChange={(e) => {
              setIsChange(true);
              setWeeklyRate(e.target.value);
            }}
          />
        </div>

        <hr style={{ marginBlock: "0px" }} />

        <div
          style={{
            width: "15%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          <input
            style={{ width: "100%" }}
            type="text"
            value={yearlyRate}
            onChange={(e) => {
              setIsChange(true);
              setYearlyRate(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            width: "20%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          {format(
            new Date(reservation ? reservation?.pickupDate : new Date()),
            "MM/dd/yyyy KK:mm a"
          )}
          <pre />
          {format(
            new Date(reservation ? reservation?.returenDate : new Date()),
            "MM/dd/yyyy KK:mm a"
          )}
        </div>

        <div
          style={{
            width: "8%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            marginBlock: "4px",
          }}
        >
          {reservation?.wasRateManuallyChanged ? "Yes" : "No"}
        </div>
      </div>

      <hr style={{ marginBlock: "0px" }} />

      <button
        style={{
          marginTop: "16px",
          paddingBlock: "8px",
          backgroundColor: "#f0ad4e",
          color: "white",
          border: "none",
          cursor: "pointer",
          fontSize: "12px",
        }}
      >
        Delete & Retrieve Rates from Settings
      </button>
    </div>
  );
}
