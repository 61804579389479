import axios from "axios"
import baseURL from "../configs/api"

export const post_CommonFile = (data: any) => {
    return axios({
        url: `${baseURL}commonFile/upload`,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const commonFileUrl = `${baseURL}commonFile/Get?name=`;