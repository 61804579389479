import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import React, { useState } from 'react'
import baseURL from '../../../configs/api';
import colorConfigs from '../../../configs/colorConfigs';
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import { useNavigate } from 'react-router';

type Props = {
  particularQuotes: any;
  showPopup: boolean;
  hidePopup: () => void;
  deleteQuote: (id: number) => void;
}

const QuotesDetailsPopup = ({ particularQuotes, showPopup, hidePopup, deleteQuote }: Props) => {
  const [showCopyPublicLink, setShowCopuPublicLink] = useState<boolean>(false);

  const copyPublicLink = () => {
    const text = `Reservation Type

    ${particularQuotes?.reservationType}
    
    Tags
    
    ${particularQuotes?.tag}
    
    Customer Name
    
    ${particularQuotes?.customerName}
    
    Customer Email
    
    ${particularQuotes?.customerEmail}
    
    Pickup Date
    
    ${particularQuotes?.pickupDate}
    
    Return Date
    
    ${particularQuotes?.returnDate}
    
    Pickup Location
    
    ${particularQuotes?.pickupLocation}
    
    Return Location
    
    ${particularQuotes?.returnLocation}
    
    Branch
    
    ${particularQuotes?.branchName}
    
    Vehicle Class
    
    ${particularQuotes?.vehicleClassName}
    
    Forced Security Deposit

    ${particularQuotes?.securityDepositAmount}
    
    Manual Discount
    
     ${particularQuotes?.discount}
    
    Phone Number
    
    ${particularQuotes?.phoneNumber}
    
    Rack Price
    
    ${particularQuotes?.rackPrice}
    
    Total Price
    
    ${particularQuotes?.totalPrice}`;

    navigator.clipboard.writeText(text);

    setShowCopuPublicLink(true);
  }

  const handleDelete = async () => {
    if (
      window.confirm("Are you sure you want to delete this Quote?") === true
    ) {
      await axios
        .delete(`${baseURL}quote?id=${particularQuotes.id}`)
        .then((response) => {
          deleteQuote(particularQuotes.id);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const navigate = useNavigate();
  const createNewReservation = () => {
    const data = {
      ...particularQuotes,
      totalPrice: particularQuotes?.totalPrice.replaceAll("$", ""),
      rackPrice: particularQuotes?.rackPrice.replaceAll("$", ""),
      securityDepositAmount: particularQuotes?.securityDepositAmount.replaceAll("$", ""),
      discount: particularQuotes?.discount.replaceAll("$", ""),
    }

    navigate("/carrental/reservations/addreservation", { state: data });
  }


  return (
    <>
      <Dialog
        open={showPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "35%",
            display: "flex",
            position: "absolute",
            top: "30px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>Quote Details</p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => hidePopup()}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
              marginBottom: "10px"
            }}
          >

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                marginTop: "10px"
              }}
            >
              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Reservation Type</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.reservationType}</p>
              </div>

              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Tags</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.tag}</p>
              </div>

            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                marginTop: "5px"
              }}
            >
              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Customer Name</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.customerName}</p>
              </div>

              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Customer Email</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.customerEmail}</p>
              </div>

            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                marginTop: "5px"
              }}
            >
              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Pickup Date</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.pickupDate}</p>
              </div>

              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Return Date</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.returnDate}</p>
              </div>

            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                marginTop: "5px"
              }}
            >
              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Pickup Location</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.pickupLocation}</p>
              </div>

              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Return Location</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.returnLocation}</p>
              </div>

            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                marginTop: "5px"
              }}
            >
              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Branch</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.branchName}</p>
              </div>

              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Vehicle Class</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.vehicleClassName}</p>
              </div>

            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                marginTop: "5px"
              }}
            >
              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Forced Security Deposit</p>
                <p style={{ fontSize: "12px", marginBlock: "0px", textAlign: "right" }}>{particularQuotes?.securityDepositAmount}</p>
              </div>

            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                marginTop: "5px"
              }}
            >
              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Manual Discount</p>
                <p style={{ fontSize: "12px", marginBlock: "0px", textAlign: "right" }}>{particularQuotes?.discount}</p>
              </div>

              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Phone Number</p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularQuotes?.phoneNumber}</p>
              </div>

            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                marginTop: "5px"
              }}
            >
              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Rack Price</p>
                <p style={{ fontSize: "12px", marginBlock: "0px", textAlign: "right" }}>{particularQuotes?.rackPrice}</p>
              </div>

              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Total Price</p>
                <p style={{ fontSize: "12px", marginBlock: "0px", textAlign: "right" }}>{particularQuotes?.totalPrice}</p>
              </div>

            </div>

          </div>

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "25%" }} >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => hidePopup()}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "75%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "red",
                    color: "white",
                  },
                }}
                onClick={() => handleDelete()}
              >
                Delete
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#81D4FA",
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#81D4FA",
                    color: "white",
                  },
                }}
                onClick={() => copyPublicLink()}
              >
                Copy Public Link
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => createNewReservation()}
              >
                Create Reservation
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showCopyPublicLink}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "35%",
            display: "flex",
            position: "absolute",
            top: "30px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>JJ Rentals</p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => setShowCopuPublicLink(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
              marginBlock: "10px"
            }}
          >
            Data copied to clipboard
          </div>

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                borderRadius: "0",
                border: "none",
                textTransform: "capitalize",
                fontSize: "12px",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              onClick={() => setShowCopuPublicLink(false)}
            >
              Ok
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default QuotesDetailsPopup