import { RouteType } from "./config";
import HomeIcon from "@mui/icons-material/Home";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RestoreIcon from "@mui/icons-material/Restore";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Dashboard from "../pages/dashboard/Dashboard";
import Reservations from "../pages/carrental/reservations/Reservations";
import CarRental from "../pages/carrental/CarRental";
import ReservationAttempts from "../pages/carrental/ReservationAttempts";
import Quotes from "../pages/carrental/quotes/Quotes";
import Payments from "../pages/carrental/Payments";
import Calendar from "../pages/carrental/calendar/Calendar";
import Fleet from "../pages/fleet/Fleet";
import Vehicles from "../pages/fleet/vehicles/Vehicles";
import Maintenance from "../pages/fleet/maintenance/Maintenance";
import RepairOrders from "../pages/fleet/repairOrder/RepairOrders";
import Contacts from "../pages/contacts/Contacts";
import Customers from "../pages/contacts/customers/Customers";
import CustomersEnquiry from "../pages/contacts/customersEnquiry/CustomersEnquiry";
import Fires from "../pages/forms/Fires";
import Checklist from "../pages/checklist/Checklist";
import Forms from "../pages/forms/Forms";
import Reports from "../pages/reports/Reports";


export const appRoutes: RouteType[] = [
  {
    index: true,
    path: "/",
    element: <Dashboard />,
    state: "dashboard",
    section: "Dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <HomeIcon sx={{ fontSize: "14px" }} />,
    },
  },
  {
    path: "/carrental",
    element: <CarRental />,
    state: "carrental",
    sidebarProps: {
      displayText: "Car Rental",
      icon: <EventAvailableIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/carrental/reservations",
        element: <Reservations />,
        state: "carrental.reservations",
        section: "dashboardreservations",
        sidebarProps: {
          displayText: "Reservations",
          icon: <EventAvailableIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/carrental/reservationattempts",
        element: <ReservationAttempts />,
        state: "carrental.reservationsattempts",
        section: "dashboardreservationattempts",
        sidebarProps: {
          displayText: "Reservation Attempts",
          icon: <RestoreIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/carrental/quotes",
        element: <Quotes />,
        state: "carrental.quotes",
        section: "dashboardquotes",
        sidebarProps: {
          displayText: "Quotes",
          icon: <InsertDriveFileOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/carrental/payments",
        element: <Payments />,
        state: "carrental.payments",
        section: "dashboardpayments",
        sidebarProps: {
          displayText: "Payments",
          icon: <PaymentsOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/carrental/calendar",
        element: <Calendar />,
        state: "carrental.calendar",
        section: "dashboardcalendar",
        sidebarProps: {
          displayText: "Calendar",
          icon: <CalendarMonthIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/fleet",
    element: <Fleet />,
    state: "fleet",
    sidebarProps: {
      displayText: "Fleet",
      icon: <DirectionsCarIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/fleet/vehicle",
        element: <Vehicles />,
        state: "fleet.vehicles",
        section: "dashboardvehicles",
        sidebarProps: {
          displayText: "Vehicles",
          icon: <DirectionsCarIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/fleet/maintenance",
        element: <Maintenance />,
        state: "fleet.maintenance",
        section: "dashboardmaintenance",
        sidebarProps: {
          displayText: "Maintenance",
          icon: <LocalShippingIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/fleet/repairorders",
        element: <RepairOrders />,
        state: "fleet.repairorders",
        section: "dashboardrepairorders",
        sidebarProps: {
          displayText: "Repair Orders",
          icon: <BusinessIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/contacts",
    element: <Contacts />,
    state: "contacts",
    sidebarProps: {
      displayText: "Contacts",
      icon: <PeopleAltIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/contacts/customers",
        element: <Customers />,
        state: "contacts.Customers",
        section: "dashboardcustomers",
        sidebarProps: {
          displayText: "Customers",
          icon: <PeopleAltIcon sx={{ fontSize: "14px" }} />,
        },
      },
      {
        path: "/contacts/CustomersEnquiry",
        element: <CustomersEnquiry />,
        state: "contacts.CustomersEnquiry",
        section: "dashboardcustomersenquiry",
        sidebarProps: {
          displayText: "Customers Enquiry",
          icon: <SupportAgentIcon sx={{ fontSize: "14px" }} />,
        },
      }
    ],
  },
  {
    path: "/checklist/checklist",
    element: <Checklist />,
    state: "checklist.checklist",
    section: "dashboardchecklist",
    sidebarProps: {
      displayText: "Checklist",
      icon: <CheckBoxIcon sx={{ fontSize: "14px" }} />,
    },
  },
  {
    path: "/forms",
    element: <Forms />,
    state: "forms",
    sidebarProps: {
      displayText: "Forms",
      icon: <DescriptionOutlinedIcon sx={{ fontSize: "14px" }} />,
    },
    child: [
      {
        path: "/forms/fires",
        element: <Fires />,
        state: "forms.fires",
        section: "dashboardfires",
        sidebarProps: {
          displayText: "Fires",
          icon: <BackHandOutlinedIcon sx={{ fontSize: "14px" }} />,
        },
      },
    ],
  },
  {
    path: "/reports",
    element: <Reports />,
    state: "reports",
    section: "dashboardreports",
    sidebarProps: {
      displayText: "Reports",
      icon: <InsertDriveFileOutlinedIcon sx={{ fontSize: "14px" }} />,
    },
  },
];

export default appRoutes;