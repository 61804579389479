import {
  CalendarMonth,
  LocalPrintshopOutlined,
  FilterAlt,
  DateRange  
} from "@mui/icons-material";
import { Box, Toolbar, Typography, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { dateToPosition, Views } from "./utlis";
import moment from "moment";
import colorConfigs from "../../../configs/colorConfigs";
import DateRangePicker from "../../../components/daterangepicker/DateRangePicker";

type Props = {
  setTimeLineHeight: (value: number) => void;
  time: Views;
  setTime: (time: Views) => void;
  setResourcesDataIsLoaded: (value: boolean) => void;
  onPrint: () => void;
  setStartDate: (value: string) => void;
  setEndDate: (value: string) => void;
};

const date = new Date();

export default function CalendarHeader({
  setTimeLineHeight,
  time,
  setTime,
  setResourcesDataIsLoaded,
  onPrint,
  setStartDate,
  setEndDate
}: Props) {
  const [showDateRangePicker, setShowDateRangePicker] = useState<boolean>(false);

  const [currentDateFormat, setCurrentDateFormat] = useState(
    moment(date).format('MMMM - ') +
    moment(date).clone().add(2, 'month').format('MMMM YYYY')
  );

  const topPosition = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleTimeLineHeight = () => {
      if (topPosition.current) {
        setTimeLineHeight((topPosition.current.offsetTop + topPosition.current?.offsetHeight) || 367);
      }
    }

    handleTimeLineHeight();
    window.addEventListener("resize", handleTimeLineHeight);

    return () => {
      window.removeEventListener("resize", handleTimeLineHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <Box ref={topPosition}>
        <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack spacing={0.5} direction="row">
              <CalendarMonth sx={{ fontSize: "24px", marginRight: "5px", color: colorConfigs.topbar.bg }} />
              <Typography variant="body1" sx={{ fontSize: "16px", color: colorConfigs.topbar.bg }}>
                {`Calendar -> ${currentDateFormat}`}
              </Typography>
            </Stack>

            <Stack spacing={0.5} direction="row">
              <Stack spacing={0} direction="row" sx={{ marginRight: "8px" }}>
                <button
                  style={{
                    backgroundColor:
                      time === Views.TimeSlotToday ? "#ECEFF1" : "#ffffff",
                    color: colorConfigs.topbar.bg,
                    borderRadius: "3px 0px 0px 3px",
                    border: "2px solid #ddd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    cursor: "pointer",
                    height: "26px",
                  }}
                  onClick={() => {
                    setTime(Views.TimeSlotToday);
                    setCurrentDateFormat(
                      moment(date).format('MMM D, YYYY')
                    );
                    setResourcesDataIsLoaded(false);
                  }}
                >
                  Today
                </button>

                <button
                  style={{
                    backgroundColor:
                      time === Views.TimeSlotWEEK ? "#ECEFF1" : "#ffffff",
                    color: colorConfigs.topbar.bg,
                    borderRadius: "0",
                    borderStyle: "solid solid solid none",
                    borderWidth: "2px",
                    borderColor: "#ddd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    cursor: "pointer",
                    height: "26px",
                  }}
                  onClick={() => {
                    setTime(Views.TimeSlotWEEK);
                    setCurrentDateFormat(
                      moment(date).startOf('week').format('MMM D - ') +
                      moment(date).endOf('week').format('D, YYYY')
                    );
                    setResourcesDataIsLoaded(false);
                  }}
                >
                  Week
                </button>

                <button
                  style={{
                    backgroundColor:
                      time === Views.TimeSlotMONTH ? "#ECEFF1" : "#ffffff",
                    color: colorConfigs.topbar.bg,
                    borderRadius: "0",
                    borderStyle: "solid solid solid none",
                    borderWidth: "2px",
                    borderColor: "#ddd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    cursor: "pointer",
                    height: "26px",
                  }}
                  onClick={() => {
                    setTime(Views.TimeSlotMONTH);
                    setCurrentDateFormat(moment(date).format('MMMM - YYYY'));
                    setResourcesDataIsLoaded(false);
                  }}
                >
                  Month
                </button>

                <button
                  style={{
                    backgroundColor:
                      time === Views.TimeSlot3MONTH ? "#ECEFF1" : "#ffffff",
                    color: colorConfigs.topbar.bg,
                    borderRadius: "0",
                    borderStyle: "solid solid solid none",
                    borderWidth: "2px",
                    borderColor: "#ddd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    cursor: "pointer",
                    height: "26px",
                  }}
                  onClick={() => {
                    setTime(Views.TimeSlot3MONTH);
                    setCurrentDateFormat(
                      moment(date).format('MMMM - ') +
                      moment(date).clone().add(2, 'month').format('MMMM YYYY')
                    );
                    setResourcesDataIsLoaded(false);
                  }}
                >
                  3 Months
                </button>

                <button
                  style={{
                    backgroundColor:
                      time === Views.TimeSlot6MONTH ? "#ECEFF1" : "#ffffff",
                    color: colorConfigs.topbar.bg,
                    borderRadius: "0",
                    borderStyle: "solid none",
                    borderWidth: "2px",
                    borderColor: "#ddd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    cursor: "pointer",
                    height: "26px",
                  }}
                  onClick={() => {
                    setTime(Views.TimeSlot6MONTH);
                    setCurrentDateFormat(
                      moment(date).format('MMMM - ') +
                      moment(date).clone().add(5, 'month').format('MMMM YYYY')
                    );
                    setResourcesDataIsLoaded(false);
                  }}
                >
                  6 Months
                </button>

                <button
                  style={{
                    backgroundColor:
                      time === Views.TimeSlotYear ? "#ECEFF1" : "#ffffff",
                    color: colorConfigs.topbar.bg,
                    borderRadius: "0px 3px 3px 0px",
                    border: "2px solid #ddd",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    cursor: "pointer",
                    height: "26px",
                  }}
                  onClick={() => {
                    setTime(Views.TimeSlotYear);
                    setCurrentDateFormat(moment(date).format('YYYY'));
                    setResourcesDataIsLoaded(false);
                  }}
                >
                  Year
                </button>
              </Stack>

              <div
                style={{
                  backgroundColor: time === Views.TimeSlotCustom ? "#ECEFF1" : "#ffffff",
                  color: colorConfigs.topbar.bg,
                  borderRadius: "0",
                  border: "2px solid #ddd",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "5px",
                }}
                onClick={() => setShowDateRangePicker(!showDateRangePicker)}
              >
                <DateRange />
              </div>

              <div
                style={{
                  backgroundColor: "#ffffff",
                  color: colorConfigs.topbar.bg,
                  borderRadius: "0",
                  border: "2px solid #ddd",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "5px",
                }}
                onClick={onPrint}
              >
                <LocalPrintshopOutlined />
              </div>

              <div
                style={{
                  backgroundColor: "#ffffff",
                  color: colorConfigs.topbar.bg,
                  borderRadius: "0",
                  border: "2px solid #ddd",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "5px",
                }}
              >
                <SaveOutlinedIcon />
              </div>

              <div
                style={{
                  backgroundColor: "#ffffff",
                  color: colorConfigs.topbar.bg,
                  borderRadius: "0",
                  border: "2px solid #ddd",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "5px",
                }}
              >
                <FilterAlt />
              </div>
            </Stack>
          </Box>
        </Toolbar>
      </Box>

      <DateRangePicker
        showDateRangePicker={showDateRangePicker}
        setShowDateRangePicker={setShowDateRangePicker}
        setDateRange={(startDate, endDate) => {
          setStartDate(startDate);
          setEndDate(endDate);
          const differentInDays = dateToPosition(moment(startDate), moment(endDate));
          if (differentInDays <= 0) {
            setCurrentDateFormat(
              moment(startDate).format('MMM D, YYYY')
            );
          } else {
            setCurrentDateFormat(
              moment(startDate).format('MMM D, YYYY') +
              moment(endDate).format(' - MMM D, YYYY')
            );
          }

          setTime(Views.TimeSlotCustom);
        }}
      />
    </>
  );
}
