import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import colorConfigs from "../../../configs/colorConfigs";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import baseURL from "../../../configs/api";

type Props = {
  openSecurityDepositDialog: boolean;
  setOpenSecurityDepositDialog: () => void;
  reservation: any;
  securityDepositAmountToPaid: string
  updateSecurityDepositAmountToPaid: (updateAmount: string) => void;
};

export default function SecuityDeposit({
  openSecurityDepositDialog,
  setOpenSecurityDepositDialog,
  reservation,
  securityDepositAmountToPaid,
  updateSecurityDepositAmountToPaid
}: Props) {
  const [securityDepositAmount, setSecurityDepositAmount] =
    useState<string>("");

    useEffect(() => {
      setSecurityDepositAmount(securityDepositAmountToPaid);
    }, [securityDepositAmountToPaid])

  const updateReservationSecurityDeposit = async () => {
    if (reservation) {
    await axios
      .put(
        `${baseURL}reservation`,
        {
          ...reservation,
          securityDepositAmount: securityDepositAmount,
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        updateSecurityDepositAmountToPaid(securityDepositAmount)
        setOpenSecurityDepositDialog();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    } else {
      updateSecurityDepositAmountToPaid(securityDepositAmount)
      setOpenSecurityDepositDialog();
    }
  };

  return (
    <Dialog
      open={openSecurityDepositDialog}
      maxWidth="md"
      fullScreen={false}
      PaperProps={{
        style: {
          width: "24%",
          display: "flex",
          position: "absolute",
          top: "30px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", width: "100%" }}>
        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 16px 8px 16px",
          }}
        >
          <p style={{ fontSize: "14px", marginLeft: "5px" }}>
            Edit Security Deposit
          </p>
          <CloseIcon
            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
            onClick={() => setOpenSecurityDepositDialog()}
          />
        </div>
        <hr style={{ marginBlock: "0px" }} />
      </DialogTitle>
      <DialogContent sx={{ padding: "0px" }}>
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              paddingLeft: "8px",
              paddingRight: "8px",
              marginBlock: "8px",
            }}
          >
            <label
              htmlFor="securityDeposit"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              Security Deposit
              <br />
              <input
                type="text"
                name="securityDeposit"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  paddingBlock: "5px",
                }}
                value={securityDepositAmount}
                onChange={(e) => setSecurityDepositAmount(e.target.value)}
              />
            </label>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBlock: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
            backgroundColor: "#eeeeee",
            marginTop: "8px",
          }}
        >
          <div style={{ width: "50%" }} />

          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#e0dee0",
                color: colorConfigs.sidebar.color,
                borderRadius: "0",
                border: "none",
                textTransform: "capitalize",
                fontSize: "12px",
                "&: hover": {
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                },
              }}
              onClick={() => setOpenSecurityDepositDialog()}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                borderRadius: "0",
                border: "none",
                textTransform: "capitalize",
                fontSize: "12px",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              onClick={() => updateReservationSecurityDeposit()}
            >
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
