import { useEffect, useState } from "react";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Box, Button, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../../configs/api";
import { useNavigate } from "react-router-dom";
import axois from "../../../../configs/axois";
import UsernameValidatedComponent from "../../../../components/UsernameValidatedComponent";
import EmailAlreadyExistChecking from "../../../../components/EmailAlreadyExistChecking";
import { getEmailChecking } from "../../../../services/EmailChecking";
import PasswordValidation from "../../../../components/PasswordValidation";


export const AddUser = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [mobilPhoneNumber, setMobilePhoneNumber] = useState<string>("");
  const [forcePasswordChange, setForcePasswordChange] = useState<string>("Yes");
  const [enabled, setEnabled] = useState<string>("Yes");
  const [language, setLanguage] = useState<string>("English");
  const [agentCompany, setAgentCategory] = useState<string>("JVCRM");
  const [roleId, setRoleId] = useState<string>("");
  const [dateFormat, setDateFormat] = useState<string>("MM/dd/yyyy KK:mm a");
  const [roleOptions, setRoleOptions] = useState([]);
  const [usernameTouched, setUsernameTouched] = useState<boolean>(false);
  const [emailTouched, setEmailTouched] = useState<boolean>(false);

  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [passwordTouched, setPasswordTouched] = useState<boolean>(false);

  useEffect(() => {
    axois.get(`${baseURL}roles`)
      .then((response) => {
        setRoleOptions(response.data.map((item: any) => {
          return { value: item.id, label: item.roleName }
        }))

        setRoleId(response.data[0].id)
      }).catch(error => {
        console.error(error);
      });
  }, [])

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}users`,
        JSON.stringify({
          firstName,
          lastName,
          userName,
          emailAddress,
          password,
          mobilPhoneNumber,
          language,
          agentCompany,
          forcePasswordChange: forcePasswordChange === "Yes" ? true : false,
          enabled: enabled === "Yes" ? true : false,
          roleId,
          dateFormat
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <form
      style={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "5px",
      }}
      onSubmit={(e) => handleSubmit(e)}
    >
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <PeopleAltIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Users - Add user
          </Typography>
        </Box>
      </Toolbar>
      <hr />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "16px 24px 5px 24px ",
        }}
      >
        <div style={{ width: "50%", marginRight: "16px" }}>
          <div style={{ marginBottom: "5px" }}>
            <label
              htmlFor="firstName"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              First Name
              <br />
              <input
                type="text"
                name="firstName"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  paddingBlock: "5px",
                }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </label>
          </div>

          <UsernameValidatedComponent
            userName={userName}
            setUserName={setUserName}
            touched={usernameTouched}
            setTouched={setUsernameTouched}
          />

          <div style={{ marginBottom: "5px" }}>
            <PasswordValidation
              password={password}
              setPassword={setPassword}
              isValid={isValidPassword}
              setIsValid={setIsValidPassword}
              touched={passwordTouched}
              setTouched={setPasswordTouched}
            />
          </div>
          
          <div style={{ marginBottom: "5px" }}>
            <label
              htmlFor="mobileNumber"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              Mobile Number
              <br />
              <input
                type="tel"
                name="mobileNumber"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  paddingBlock: "5px",
                }}
                value={mobilPhoneNumber}
                onChange={(e) => setMobilePhoneNumber(e.target.value)}
                required
              />
            </label>
          </div>

          <div style={{ marginBottom: "5px" }}>
            <label
              htmlFor="language"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              Language
              <br />
              <select
                name="language"
                id="cars"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  paddingBlock: "5px",
                }}
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="English">English</option>
              </select>
            </label>
          </div>
          <label
            htmlFor="agentCategory"
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            RA Agent Category
            <br />
            <select
              name="agentCategory"
              style={{
                width: "100%",
                fontSize: "14px",
                paddingBlock: "5px",
              }}
              value={agentCompany}
              onChange={(e) => setAgentCategory(e.target.value)}
            >
              <option value="JVCRM">JVCRM</option>
            </select>
          </label>
        </div>

        <div style={{ width: "50%", marginLeft: "16px" }}>
          <div style={{ marginBottom: "5px" }}>
            <label
              htmlFor="lastName"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              Last Name
              <br />
              <input
                type="text"
                name="lastName"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  paddingBlock: "5px",
                }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </label>
          </div>
          <EmailAlreadyExistChecking
            emailAddress={emailAddress}
            setEmailAddress={setEmailAddress}
            getEmailChecking={getEmailChecking}
            touched={emailTouched}
            setTouched={setEmailTouched}
          />

          <div style={{ marginTop: "12px" }}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              Force password change on next login
            </label>{" "}
            <br />
            <label style={{ fontSize: "12px" }}>
              <input
                type="radio"
                value="Yes"
                name="forcePassword"
                style={{ fontSize: "12px" }}
                checked={forcePasswordChange === "Yes"}
                onChange={(e) =>
                  setForcePasswordChange(e.target.checked ? "Yes" : "No")
                }
              />
              Yes
            </label>
            <label style={{ fontSize: "12px" }}>
              <input
                type="radio"
                value="No"
                name="forcePassword"
                style={{ fontSize: "12px" }}
                checked={forcePasswordChange === "No"}
                onChange={(e) =>
                  setForcePasswordChange(e.target.checked ? "No" : "Yes")
                }
              />
              No
            </label>
          </div>

          <div style={{ marginTop: "10px" }}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              Enabled
            </label>{" "}
            <br />
            <label style={{ fontSize: "12px" }}>
              <input
                type="radio"
                value="Yes"
                name="enabled"
                style={{ fontSize: "12px" }}
                checked={enabled === "Yes"}
                onChange={(e) => setEnabled(e.target.checked ? "Yes" : "No")}
              />
              Yes
            </label>
            <label style={{ fontSize: "12px" }}>
              <input
                type="radio"
                value="No"
                name="enabled"
                style={{ fontSize: "12px" }}
                checked={enabled === "No"}
                onChange={(e) => setEnabled(e.target.checked ? "No" : "Yes")}
              />
              No
            </label>
          </div>

          <div style={{ marginTop: "5px" }}>
            <label
              htmlFor="dateFormat"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              Date Format
              <br />
              <select
                name="dateFormat"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  paddingBlock: "5px",
                }}
                value={dateFormat}
                onChange={(e) => setDateFormat(e.target.value)}
              >
                <option value="MM/dd/yyyy KK:mm a">Use System Default</option>
                <option value="yyyy-MM-dd kk:mm">2023-06-16 7:37 ()</option>
                <option value="yyyy-MM-dd kk:mm">2023-06-16 7:37 (iso)</option>
                <option value="dd-MM-yyyy kk:mm">16-06-2023 7:37 (eu)</option>
                <option value="MM/dd/yyyy KK:mm a">06/16/2023 7:37 AM (us)</option>
                <option value="dd/MM/yyyy KK:mm a">16/06/2023 7:37 AM (ve)</option>
                <option value="dd.MM.yyyy KK:mm a">16.06.2023 7:37 AM (int)</option>
                <option value="dd-MMMM-yyyy KK:mm a">16-Jun-2023 7:37 AM (id)</option>
                <option value="dd.MM.yyyy kk:mm">16.06.2023 7:37 (ch)</option>
                <option value="MM/dd/yyyy KK:mm a">Use System Default</option>
                <option value="yyyy-MM-dd kk:mm">2023-06-16 7:37 ()</option>
                <option value="yyyy-MM-dd kk:mm">2023-06-16 7:37 (iso)</option>
                <option value="dd-MM-yyyy kk:mm">16-06-2023 7:37 (eu)</option>
                <option value="MM/dd/yyyy KK:mm a">06/16/2023 7:37 AM (us)</option>
                <option value="dd/MM/yyyy KK:mm a">16/06/2023 7:37 AM (ve)</option>
                <option value="dd.MM.yyyy KK:mm a">16.06.2023 7:37 AM (int)</option>
                <option value="dd-MMMM-yyyy KK:mm a">16-Jun-2023 7:37 AM (id)</option>
                <option value="dd.MM.yyyy kk:mm">16.06.2023 7:37 (ch)</option>
              </select>
            </label>
          </div>

        </div>

      </div>

      <div style={{ padding: "0px 24px 8px 24px" }}>
        <label htmlFor="roles" style={{ fontSize: "12px", fontWeight: "bold" }}>
          Roles
          <br />
          <select
            name="roles"
            style={{
              width: "100%",
              fontSize: "14px",
              paddingBlock: "5px",
            }}
            value={roleId}
            onChange={(e) => setRoleId(e.target.value)}
          >
            {roleOptions.map((role: any) => {
              return (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              );
            })}

          </select>
        </label>
      </div>

      <div
        style={{
          height: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "8px",
          alignItems: "center",
          borderBottomRightRadius: "5px",
          borderBottomLeftRadius: "5px",
          padding: "10px 24px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: colorConfigs.topbar.bg,
            borderRadius: "0",
            textTransform: "capitalize",
            "&: hover": {
              backgroundColor: colorConfigs.topbar.bg,
            },
          }}
          type="submit"
        >
          Save
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
            borderRadius: "0",
            textTransform: "capitalize",
            marginRight: "8px",
            "&: hover": {
              backgroundColor: colorConfigs.sidebar.bg,
              color: colorConfigs.sidebar.color,
            },
          }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </div>

    </form>
  );
};
