import {
  Box,
  Toolbar,
  Typography,
  Stack,
  Button,
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import colorConfigs from "../../../../configs/colorConfigs";
import MenuIcon from "@mui/icons-material/Menu";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AllCategories from "./AllCategories";
import AddAddOnCategory from "./AddAddOnCategory";
import EditAddOnCategory from "./EditAddOnCategory";
import baseURL from "../../../../configs/api";

const pages = ["All"];

export const AddOnCategories = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [firstTime, setFirstTime] = React.useState<String>(pages[0]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const [addPopup, setAddPopup] = useState<boolean>(false);
  const [editPopup, setEditPopup] = useState<boolean>(false);
  const [id, setId] = useState("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [oldPage, setOldPage] = useState<number>(-1);
  const [searchTableData, setSearchTableData] = useState<any[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [totalLength, setTotalLength] = useState<number>(0);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  useEffect(() => {
    if (searchValue === "" && !isFirstTime) {

      const newPaginationModel = {
        page: 0,
        pageSize: paginationModel.pageSize,
      };

      setPaginationModel(newPaginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setIsFirstTime(false);
      apiCall(paginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const apiCall = (newPaginationModel: any) => {
    setIsDataLoaded(true);
    fetch(`${baseURL}addOnCategory/GetAddOnCategoryReportPaging/${newPaginationModel.page},${newPaginationModel.pageSize}`)
      .then((res) => res.json())
      .then((data) => {

        const result = data.addOnCategoryReportDto.map((item: any) => {
          return {
            ...item,
          };
        })

        let newArray = [];
        if (paginationModel.page === 0) {
          newArray = result;
        } else if (paginationModel.page > oldPage) {
          newArray = searchTableData.concat(result);
        } else {
          newArray = searchTableData;
        }

        setSearchTableData(newArray);
        setTotalLength(data.count);
        setIsDataLoaded(false);

        if (paginationModel.page === 0) {
          setOldPage(-1);
        } else {
          setOldPage(() => paginationModel.page - 1);
        }
      });
  }


  return (
    <Box>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <AddBusinessIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Add-On-Categories
          </Typography>
        </Box>

        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs.topbar.bg,
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => setAddPopup(true)}
          >
            Add Add-On-Category
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#e0dee0",
              color: "black",
              borderRadius: "0",
              border: "none",
            }}
          >
            <MenuIcon />
          </Button>
        </Stack>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>

        </Container>
      </AppBar>

      <div style={{ display: firstTime === pages[0] ? "block" : "none" }}>
        <AllCategories
          handleOnEditClick={(id) => {
            setId(id);
            setEditPopup(true);
          }}

          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          totalLength={totalLength}
          setTotalLength={setTotalLength}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setSearchTableData={setSearchTableData}
          oldPage={oldPage}
          setOldPage={setOldPage}
          searchTableData={searchTableData}
          isDataLoaded={isDataLoaded}
          setIsDataLoaded={setIsDataLoaded}
        />
      </div>

      {
        addPopup && (
          <AddAddOnCategory
            popup={addPopup}
            hidePopup={() => setAddPopup(false)}
            isSaved={() => {
              setAddPopup(false);
            }}
          />
        )
      }

      {
        editPopup && (
          <EditAddOnCategory
            id={id}
            popup={editPopup}
            hidePopup={() => setEditPopup(false)}
            isSaved={() => {
              setEditPopup(false);
            }}
          />
        )
      }


    </Box>
  );
};
