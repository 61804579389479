import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../../configs/api";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { format } from "date-fns";

type Props = {
    customerId: string;
    addNotePopup: boolean;
    setAddNotePopup: () => void;
}

export default function AddNote({ customerId, addNotePopup, setAddNotePopup }: Props) {
    const [notes, setNotes] = useState<string>("");

    const resetForm = () => {
        setNotes("");
    };

    useEffect(() => {
        if (addNotePopup) {
            resetForm();
        }
    }, [addNotePopup]);

    const handleNotes = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const currentDate = new Date();

        axios
            .post(
                `${baseURL}comment`,
                JSON.stringify({
                    attributeName: "Customer",
                    notes,
                    createdDate: `${format(currentDate, 'MM/dd/yyyy')} ${format(currentDate, 'hh:mm:ss a')}`,
                    attributeId: parseInt(customerId),
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then((response) => {
                setAddNotePopup();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    useEffect(() => {
        setNotes(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }, [editorState]);

    return (
        <Dialog
            open={addNotePopup}
            maxWidth="md"
            fullScreen={false}
            PaperProps={{
                style: {
                    width: "40%",
                    display: "flex",
                    position: "absolute",
                    top: "30px",
                },
            }}
        >
            <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                <div
                    style={{
                        height: "50px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px 16px 8px 16px",
                    }}
                >
                    <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                        Add Note
                    </p>
                    <CloseIcon
                        sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
                        onClick={() => {
                            setAddNotePopup();
                        }}
                    />
                </div>
                <hr style={{ marginBlock: "0px" }} />
            </DialogTitle>
            <DialogContent sx={{ padding: "0px" }}>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <form onSubmit={(e) => handleNotes(e)} style={{}}>
                        <div style={{ padding: "2px 2px 0px 2px" }}>

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "0px 16px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        marginRight: "3%",
                                    }}
                                >
                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="notes"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Notes
                                        </label>
                                        <br />

                                        <Editor
                                            editorState={editorState}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            onEditorStateChange={setEditorState}
                                            toolbar={{
                                                inline: { inDropdown: true },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                history: { inDropdown: true },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    marginBottom: "20px",
                                }}
                            >

                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "50px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                paddingBlock: "8px",
                                paddingLeft: "16px",
                                paddingRight: "16px",
                                backgroundColor: "#eeeeee",
                            }}
                        >
                            <div style={{ width: "50%" }} />

                            <div
                                style={{
                                    width: "50%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        color: "black",
                                        borderRadius: "0",
                                        border: "1px solid black",
                                        marginRight: "5px",
                                    }}
                                >
                                    <LocalPrintshopOutlinedIcon />
                                </Button>

                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                        borderRadius: "0",
                                        border: "none",
                                        textTransform: "capitalize",
                                        fontSize: "12px",
                                        "&: hover": {
                                            backgroundColor: "#e0dee0",
                                            color: colorConfigs.sidebar.color,
                                        },
                                    }}
                                    onClick={() => setAddNotePopup()}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "white",
                                        borderRadius: "0",
                                        border: "none",
                                        textTransform: "capitalize",
                                        fontSize: "12px",
                                        "&: hover": {
                                            backgroundColor: colorConfigs.topbar.bg,
                                            color: "white",
                                        },
                                    }}
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
}
