import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../../configs/api";

type Props = {
    customerName: string;
    editPopup: boolean;
    hidePopup: () => void;
    deleteCreditCard: () => void;
    particularCreditDetails: any;
}

export default function DeleteCreditCard({ customerName, editPopup, hidePopup, deleteCreditCard, particularCreditDetails }: Props) {

    const deleteTheCreditCard = async () => {
        if (
            window.confirm(
                "Are you sure you want to delete this Credit Card?"
            ) === true
        ) {
            await axios
                .delete(`${baseURL}creditCard?id=${particularCreditDetails?.id}`)
                .then((response) => {
                    deleteCreditCard();
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    return (
        <Dialog
            open={editPopup}
            maxWidth="md"
            fullScreen={false}
            PaperProps={{
                style: {
                    width: "40%",
                    display: "flex",
                    position: "absolute",
                    top: "30px",
                },
            }}
        >
            <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                <div
                    style={{
                        height: "50px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px 16px 8px 16px",
                    }}
                >
                    <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                        Credit Card
                    </p>
                    <CloseIcon
                        sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
                        onClick={() => hidePopup()}
                    />
                </div>
                <hr style={{ marginBlock: "0px" }} />
            </DialogTitle>
            <DialogContent sx={{ padding: "0px" }}>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        marginBottom: "10px"
                    }}
                >

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Last 4 digits</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>....{particularCreditDetails?.creditCardNumber}</p>
                        </div>

                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Expiration Date</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularCreditDetails?.expirationDate}</p>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "5px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Contact</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{customerName}</p>
                        </div>

                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Cardholder's Name</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularCreditDetails?.cardholderName}</p>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "5px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Brand</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularCreditDetails?.brand}</p>
                        </div>

                    </div>

                </div>


                <div
                    style={{
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBlock: "8px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        backgroundColor: "#eeeeee",
                    }}
                >
                    <div style={{ width: "50%" }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "red",
                                color: "white",
                                borderRadius: "0",
                                border: "none",
                                textTransform: "capitalize",
                                fontSize: "12px",
                                "&: hover": {
                                    backgroundColor: "red",
                                    color: "white",
                                },
                            }}
                            onClick={() => deleteTheCreditCard()}
                        >
                            Delete
                        </Button>
                    </div>

                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#e0dee0",
                                color: colorConfigs.sidebar.color,
                                borderRadius: "0",
                                border: "none",
                                textTransform: "capitalize",
                                fontSize: "12px",
                                "&: hover": {
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                },
                            }}
                            onClick={() => hidePopup()}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
