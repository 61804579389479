import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import MenuIcon from "@mui/icons-material/Menu";
import NightlightOutlinedIcon from "@mui/icons-material/NightlightOutlined";
import colorConfigs from "../../../../configs/colorConfigs";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../../configs/api";
import axois from "../../../../configs/axois";

const pages = [
  "Edit"
];

const accountingAccountOptions: any = [];
for (let i = 1; i <= 8; i++) {
  accountingAccountOptions.push({ value: i, label: i });
}

export const EditSeasons = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const [name, setName] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const [dateStart, setDateStart] = useState<string>("");
  const [dateEnd, setDateEnd] = useState<string>("");
  const [minimalRentalPeriodDays, setMinimalRentalPeriodDays] =
    useState<string>("");
  const [maxRentalPeriodDays, setMaxRentalPeriodDays] = useState<string>("");
  const [minimumRentlHours, setMinimumRentlHours] = useState<string>("");
  const [maximumRentalHours, setMaximumRentalHours] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [accountingAccount, setAccountingAccount] = useState<string>("");

  const [firstTime, setFirstTime] = React.useState<String>(pages[0]);

  const [branches, setBranches] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then((response) => {
        setBranches(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.branchName };
          })
        );

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const idNumber = useLocation();
  useEffect(() => {
    axois
      .get(`${baseURL}season/${idNumber.state}`)
      .then((response) => {
        setName(response.data.name);
        setBranchId(response.data.branchId);
        setDateStart((response.data.dateStart).split("T")[0]);
        setDateEnd((response.data.dateEnd).split("T")[0]);
        setMinimalRentalPeriodDays(response.data.minimalRentalPeriodDays);
        setMaxRentalPeriodDays(response.data.maxRentalPeriodDays);
        setMinimumRentlHours(response.data.minimumRentlHours);
        setMaximumRentalHours(response.data.maximumRentalHours);
        setAccountingAccount(response.data.accountingAccount);
        setActive(response.data.isActive);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .put(
        `${baseURL}season`,
        JSON.stringify({
          id: idNumber.state,
          name,
          branchId,
          dateStart,
          dateEnd,
          minimalRentalPeriodDays,
          maxRentalPeriodDays,
          minimumRentlHours,
          maximumRentalHours,
          accountingAccount,
          isActive: active,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteTheBranch = async () => {
    if (
      window.confirm("Are you sure you want to delete this season?") === true
    ) {
      await axios
        .delete(`${baseURL}season?id=${idNumber.state}`)
        .then((response) => {
          console.log(response);
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <NightlightOutlinedIcon
            sx={{ fontSize: "24px", marginRight: "5px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Seasons / Edit Season
          </Typography>
        </Box>
        <div>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => deleteTheBranch()}
          >
            Delete
          </Button>
        </div>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>

      <div
        style={{
          width: "100%",
          display: firstTime === pages[0] ? "flex" : "none",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            Season Overview
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="branchId"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Branch
                    <br />
                    <select
                      name="branchId"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={branchId}
                      onChange={(e) => setBranchId(e.target.value)}
                      required
                    >
                      {branches.map((item: any) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <div
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "50%", marginRight: "3%" }}>
                      <label
                        htmlFor="year"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Date Start <br />
                        <input
                          type="date"
                          name="year"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={dateStart}
                          onChange={(e) => setDateStart(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="year"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Date End <br />
                        <input
                          type="date"
                          name="year"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={dateEnd}
                          onChange={(e) => setDateEnd(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="minimumRentlHours"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Minimum Rental Hours
                      <br />
                      <input
                        type="text"
                        name="minimumRentlHours"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={minimumRentlHours}
                        onChange={(e) => setMinimumRentlHours(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="accountingAccount"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Accounting Account
                      <br />
                      <Select
                        styles={{
                          menu: (base) => ({ ...base, fontSize: "12px" }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={false}
                        name="accountingAccount"
                        value={accountingAccountOptions.find(
                          (item: any) => item.value === parseInt(accountingAccount)
                        )}
                        onChange={(accountingAccount: any) =>
                          setAccountingAccount(
                            accountingAccount ? accountingAccount.value : ""
                          )
                        }
                        options={accountingAccountOptions}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "10px", fontWeight: "bold" }}>
                      Active?
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="enabled"
                        style={{ fontSize: "12px" }}
                        checked={active ? true : false}
                        onChange={(e) =>
                          setActive(e.target.value === "Yes" ? true : false)
                        }
                      />
                      Yes
                    </label>
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="enabled"
                        style={{ fontSize: "12px" }}
                        checked={active ? false : true}
                        onChange={(e) =>
                          setActive(e.target.value === "Yes" ? true : false)
                        }
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="websiteLink"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Name
                    <br />
                    <input
                      type="text"
                      name="websiteLink"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "50%", marginRight: "3%" }}>
                    <label
                      htmlFor="maxRentalPeriodDays"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Minimal Rental Period (Days)
                      <br />
                      <input
                        type="text"
                        name="maxRentalPeriodDays"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={minimalRentalPeriodDays}
                        onChange={(e) =>
                          setMinimalRentalPeriodDays(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>
                  <div style={{ width: "50%" }}>
                    <label
                      htmlFor="year"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Max Rental Period (Days)
                      <br />
                      <input
                        type="text"
                        name="maxRentalPeriodDays"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={maxRentalPeriodDays}
                        onChange={(e) => setMaxRentalPeriodDays(e.target.value)}
                        required
                      />
                    </label>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="maximumRentalHours"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Maximum Rental Hours
                    <br />
                    <input
                      type="text"
                      name="maximumRentalHours"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={maximumRentalHours}
                      onChange={(e) => setMaximumRentalHours(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
