import { EventAvailable, ExpandMore, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import Agreement from './Agreement'
import Cars from './Cars'
import Changes from './Changes'
import Checkin from './Checkin'
import Checkout from './Checkout'
import Confirm from './Confirm'
import Customer from './Customer'
import Dates from './Dates'
import Extras from './Extras'
import Payments from './Payments'
import axios from 'axios'
import { Link } from 'react-router-dom'
import baseURL from '../../../configs/api'
import axois from '../../../configs/axois'
import { Box, Button, ListItemButton, Toolbar, Typography } from '@mui/material'
import colorConfigs from '../../../configs/colorConfigs'
import AddComments from '../../AddComments'
import PleaseWaitPopup from '../../PleaseWaitPopup'
import DateAndTimeChange from './DateAndTimeChange'
import Summary from './Summary'
import { getBranches } from '../../../services/branch'
import { CurrentType } from '../../../enum/CurrentType'
import { getLocations } from '../../../services/location'
import { getReservation } from '../../../services/reservation'
import { getAddOnCategories } from '../../../services/addOnCategory'
import { getRoles } from '../../../services/roles'
import { getAdditionalCharges } from '../../../services/additionalCharge'
import { getDiscounts } from '../../../services/discount'
import { getCustomers } from '../../../services/customer'
import useAuth from '../../../components/hooks/useAuth'
import { format } from 'date-fns'
import { delete_comment, getCommentsOfAttribute, post_comment } from '../../../services/comment'


type CommentType = {
    id: number;
    attributeName: string;
    attributeId: string;
    notes: string;
    userId: string;
    createdDate: string;
};

type Props = {
    currentType: CurrentType;
    setCurrentType: (value: CurrentType) => void
    _primaryKey: number;
    data: any;
}

const TenTabLayout = ({
    currentType,
    setCurrentType,
    _primaryKey,
    data
}: Props) => {
    const { auth }: any = useAuth();
    const [pages, setPages] = useState<string[]>([
        "Dates",
        "Cars",
        "Extras",
        "Customer",
        "Confirm",
    ]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<string>(pages[0]);
    const [pagesIsAdded, setPagesIsAdded] = useState<boolean>(false);

    //Block Tab Condition
    const [isdateText, setIsDateText] = useState<boolean>(false)
    const [isVehicleSelected, setIsVehicleSelected] = useState<boolean>(false);
    const [isCustomerSelected, setIsCustomerSelected] = useState<boolean>(false);
    const [isCheckout, setIsCheckout] = useState<boolean>(false);

    //Date
    const currentDate = new Date();
    const [branches, setBranches] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);
    const [branchId, setBranchId] = useState<string>("");
    const [pickupDate, setPickupDate] = useState<string>(currentDate.toISOString().split("T")[0]);
    const [pickupTime, setPickupTime] = useState<string>("");
    const [returnDate, setReturnDate] = useState<string>(new Date(currentDate.setDate(currentDate.getDate() + 1)).toISOString().split("T")[0]);
    const [returnTime, setReturnTime] = useState<string>("");
    const [pickupLocationId, setPickupLocationId] = useState<string>("");
    const [returnLocationId, setReturnLocationId] = useState<string>("");
    const [walkInCustomer, setWalkInCustomer] = useState<boolean>(true);

    const [dateAndTimeBox, setDateAndTimeBox] = useState<boolean>(false);
    const [dateAndTimeChange, setDateAndTimeChange] = useState<boolean>(false);

    //Cars
    const [vehicleClassId, setVehicleClassId] = useState<string>("");
    const [selectedVehicleClass, setSelectedVehiclesClass] = useState<any>();
    const [isFirstTimeOfReservationSelected, setIsFirstTimeOfReservationSelected] = useState<boolean>(true);
    const [status, setStatus] = useState<string>("Quotes");
    const [totalOfSelectedVehicleClass, setTotalOfSelectedVehicleClass] = useState<number>(0.0);
    const [totalHours, setTotalHours] = useState<number>(0);
    const [hourlyRate, setHourlyRate] = useState<number>(0.0);
    const [totalDays, setTotalDays] = useState(0);
    const [dailyRate, setDailyRate] = useState<number>(0.0);
    const [totalWeeks, setTotalWeeks] = useState(0);
    const [weeklyRate, setWeeklyRate] = useState<number>(0.0);
    const [totalMonths, setTotalMonths] = useState(0);
    const [monthilyRate, setMonthilyRate] = useState<number>(0.0);

    //Extras
    const [extraValue, setExtraValue] = useState<any[]>([]);
    const [totalAmountOfAdditionalCharge, setTotalAmountOfAdditionalCharges] = useState<number>(0.0);
    const [checkExtraValue, setCheckExtraValue] = useState<any[]>([]);
    const [reservationAdditionalCharge, setReservationAdditionalCharge] = useState<any[]>([]);
    const [addOnCategories, setAddOnCategories] = useState<any[]>([]);
    const [isAddOnCategories, setIsAddOnCategories] = useState<boolean>(false);
    const [roles, setRoles] = useState<any[]>([]);
    const [isAddRoles, setIsAddRoles] = useState<boolean>(false);
    const [additionalCharges, setAdditionalCharges] = useState<any[]>([]);
    const [isAdditionalCharegesAdded, setIsAdditionalCharegesAdded] = useState<boolean>(false);
    const [isExtraValueIsAdded, setIsExtraValueIsAdded] = useState<boolean>(false);
    //Discount
    const [discountOptions, setDiscountOptions] = useState<any[]>([]);
    const [discountList, setDiscountList] = useState<any[]>([]);
    const [discountTotalAmount, setDiscountTotalAmount] = useState<number>(0.0);
    const [isDiscontList, setIsDiscountList] = useState<boolean>(true);

    const [totalAmount, setTotalAmount] = useState<number>(0.0);
    const [outstandingBalance, setOutstandingBalance] = useState<number>(0.0);

    //Customer
    const [localAddress, setLocalAddress] = useState<string>("");
    const [customerId, setCustomerId] = useState<string>("");
    const [customers, setCustomers] = useState<any[]>([]);
    const [customersUpdate, setCustomersUpdate] = useState<boolean>(true);
    const [hideToolbar, setHideToolbar] = useState<boolean>(false);

    //Confirm
    const [primaryKey, setPrimaryKey] = useState<string>("0");
    const [reservation, setReservation] = useState<any>();
    const [reservationUpdate, setReservationUpdate] = useState<boolean>(true);

    //Comments
    const [isComments, setIsComments] = useState<boolean>(true);
    const [isComment, setIsComment] = useState(0);
    const [saveCommentsValue, setSaveCommentsValue] = useState([]);
    const [comments, setComments] = useState<string>("");

    //Payments
    const [paymentList, setPaymentList] = useState([]);
    const [paymentUpdate, setPaymentUpdate] = useState<boolean>(true);
    const [refundList, setRefundList] = useState([]);
    const [refundUpdate, setRefundUpdate] = useState<boolean>(true);

    //Chechout
    const [signatureURL, setSignatureURL] = useState<any | null>();
    const [initialsURL, setInitialsURL] = useState<any | null>();
    const [signatureChange, setSignatureChange] = useState<boolean>(true);

    //Security Deposit
    const [securityDepositAmountToPaid, setSecurityDepositAmountToPaid] =
        useState<string>("");
    const [securityDepositList, setSecurityDepositList] = useState<any[]>([]);

    const [isFirstTime, setIsFirstTime] = useState<boolean>(false);
    const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

    useEffect(() => {
        if (currentType === CurrentType.EDIT) {
            setPages(["Dates", "Cars", "Extras", "Customer", "Confirm", "Payments", "Check Out", "Agreement", "Check in", "Changes",])
        }
    }, [currentType])

    useEffect(() => {
        getBranches()
        .then(data => {
            setBranches(data);
            if (currentType === CurrentType.ADD && data?.length > 0) {
                setBranchId(data[0].id || "0");
            } else {
                setBranchId("0");
            }
        })
            .catch((error) => {
                console.error(error);
            });
             // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getLocations().then(data => setLocations(data)).catch(error => console.error(error));
    }, []);

    useEffect(() => {
        getAddOnCategories()
            .then(data => {
                setAddOnCategories(
                    data.map((item: any) => {
                        return {
                            id: item.id,
                            categoryName: item.name,
                            roleId: item.roleId,
                        };
                    })
                );
                setIsAddOnCategories(true);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        getRoles()
            .then(data => {
                setRoles(
                    data.map((item: any) => {
                        return {
                            id: item.id,
                            roleName: item.roleName,
                        };
                    })
                );

                setIsAddRoles(true);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (isAddOnCategories && isAddRoles) {
            getAdditionalCharges()
                .then(data => {
                    setAdditionalCharges(
                        data.filter((value: any) => !value.hideFromReservations)
                            .map((item: any) => {
                                return {
                                    id: item.id,
                                    addOnCategoryId: item.addOnCategoryId,
                                    categoryName: addOnCategories.find(
                                        (category: any) => category.id === item.addOnCategoryId
                                    )?.categoryName,
                                    roleId: addOnCategories.find(
                                        (category: any) => category.id === item.addOnCategoryId
                                    )?.roleId,
                                    roleName: roles.find(
                                        (role: any) =>
                                            role.id ===
                                            parseInt(
                                                addOnCategories.find(
                                                    (category: any) =>
                                                        category.id === item.addOnCategoryId
                                                )?.roleId
                                            )
                                    )?.roleName,
                                    name: item.name,
                                    selectionType: item.selectionType,
                                    chargeType: item.chargeType,
                                    icon: item.icon,
                                    recommended: item.recommended,
                                    additionalChargeByBranches: item.additionalChargeByBranches,
                                };
                            })
                    );

                    setIsAddOnCategories(false);
                    setIsAddRoles(false);
                    setIsAdditionalCharegesAdded(true);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddOnCategories, isAddRoles]);

    useEffect(() => {
        if (branchId && isAdditionalCharegesAdded) {
            setExtraValue(
                additionalCharges.map((item: any) => {
                    return {
                        ...item,
                        name:
                            item.chargeType === "Amount"
                                ? item.name
                                : item.name +
                                " (" +
                                item.additionalChargeByBranches.find(
                                    (charges: any) => charges.branchId === parseInt(branchId)
                                )?.anountOrPercentage +
                                "%)",
                        amountOrPercentage: item.additionalChargeByBranches.find(
                            (charges: any) => charges.branchId === parseInt(branchId)
                        )?.anountOrPercentage,
                        mandatory: item.additionalChargeByBranches.find(
                            (charges: any) => charges.branchId === parseInt(branchId)
                        )?.mandatory,
                        isChecked: item.additionalChargeByBranches.find(
                            (charges: any) => charges.branchId === parseInt(branchId)
                        )?.mandatory,
                        mandatoryOnPublicReservations: item.additionalChargeByBranches.find(
                            (charges: any) => charges.branchId === parseInt(branchId)
                        )?.mandatoryOnPublicReservations,
                        mandatoryOnInternalReservations:
                            item.additionalChargeByBranches.find(
                                (charges: any) => charges.branchId === parseInt(branchId)
                            )?.mandatoryOnInternalReservations,
                        reservationAdditionalId: 0,
                    };
                })
            );

            setIsExtraValueIsAdded(true);
        }

         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branchId, isAdditionalCharegesAdded]);

    useEffect(() => {
        if (branchId) {
            getDiscounts()
                .then(data => setDiscountOptions(data.filter((item: any) => item.branchId === parseInt(branchId))))
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [branchId]);

    useEffect(() => {
        if (customersUpdate) {
            getCustomers()
                .then(data => {
                    setCustomers(
                        data.map((item: any) => {
                            return {
                                value: item.id,
                                label: item.firstName + " " + item.lastName,
                                dob: item.birthday,
                            };
                        })
                    );

                    setCustomersUpdate(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [customersUpdate]);

    useEffect(() => {
        setPrimaryKey(_primaryKey.toString());
    }, [_primaryKey])

    useEffect(() => {
        if (parseInt(primaryKey) !== 0 && reservationUpdate) {
            setPleaseWaitPopup(true);
            getReservation(primaryKey).then(data => {
                setIsFirstTime(true);
                setReservation(data);
                setStatus(data.status);
                setPickupDate(data.pickupDate?.split("T")[0]);
                setReturnDate(data.returenDate?.split("T")[0]);
                setPickupTime(data.pickupDate?.split("T")[1]);
                setReturnTime(data.returenDate?.split("T")[1]);
                setBranchId(data.branchId);
                setPickupLocationId(data.pickupLoationId);
                setReturnLocationId(data.returenLoationId);
                setVehicleClassId(data.vehicleClassId);
                setWalkInCustomer(data.walkInCustomer);
                setLocalAddress(data.localAddress);
                setCustomerId(data.customerId);
                setTotalAmount(data.totalPrice);
                setOutstandingBalance(data.outstandingBalance);
                setTotalHours(data.totalHours);
                setHourlyRate(data.hourlyRate);
                setTotalDays(data.totalDays);
                setDailyRate(data.dailyRate);
                setTotalWeeks(data.totalWeeks);
                setWeeklyRate(data.weeklyRate);
                setTotalMonths(data.totalYears);
                setMonthilyRate(data.yearlyRate);
                setTotalOfSelectedVehicleClass(
                    data.totalHours * data.hourlyRate
                    + data.totalDays * data.dailyRate
                    + data.totalWeeks * data.weeklyRate
                    + data.totalYears * data.yearlyRate
                )
                setSecurityDepositAmountToPaid(data.securityDepositAmount);
                setPleaseWaitPopup(false);
                setReservationUpdate(false);
                setCurrentType(CurrentType.EDIT);
            })
                .catch((error) => {
                    console.error(error);
                });
        }

         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryKey, reservationUpdate]);

    useEffect(() => {
        if (reservation && paymentUpdate) {
            axios
                .get(`${baseURL}payment/PaymentsByReservation/${reservation?.id}`)
                .then((response) => {
                    setPaymentList(response.data.filter((item: any) => item.paymentType === "Payment" || item.paymentType === "Authorize"));
                    setSecurityDepositList(response.data.filter((item: any) => item.paymentType === "Security Deposit"))
                })
                .catch((error) => {
                    console.error(error);
                });
            setPaymentUpdate(false);
        }
    }, [paymentUpdate, reservation]);

    useEffect(() => {
        if (reservation && refundUpdate) {
            axios
                .get(`${baseURL}refund/RefundsByReservation/${reservation?.id}`)
                .then((response) => {
                    setRefundList(() => response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
            setRefundUpdate(false);
        }
    }, [refundUpdate, reservation]);

    useEffect(() => {
        if (reservation && isDiscontList) {
            axios
                .get(
                    `${baseURL}/reservationDiscount/ReservationDiscountsByReservation/${reservation?.id}`
                )
                .then((response) => {
                    setDiscountList(() => response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
            setIsDiscountList(false);
        }
    }, [isDiscontList, reservation]);

    useEffect(() => {
        if (reservation && isExtraValueIsAdded) {
            axois
                .get(
                    `${baseURL}reservationAdditionalCharge?reservationId=${reservation?.id}`
                )
                .then((response) => {
                    setReservationAdditionalCharge(response.data.filter((item: any) => item.reservatinId === reservation?.id));

                    setCheckExtraValue(
                        response.data.filter((data: any) => data.reservatinId === reservation?.id)
                            .map((item: any) => {
                                return {
                                    id: item.additionalChargeByBranchId,
                                    categoryName: extraValue.find(
                                        (value: any) => value.id === item.additionalChargeByBranchId
                                    )?.categoryName,
                                    name: extraValue.find(
                                        (value: any) => value.id === item.additionalChargeByBranchId
                                    )?.name,
                                    amountOrPercentage:
                                        parseFloat(item.quantity) * parseFloat(item.basePrice),
                                };
                            })
                    );

                    setExtraValue(
                        extraValue.map((item: any) => {
                            return {
                                ...item,
                                isChecked: response.data
                                    .filter((data: any) => data.reservatinId === reservation?.id)
                                    .find(
                                        (data: any) => data.additionalChargeByBranchId === item.id
                                    ),
                                reservationAdditionalId: response.data
                                    .filter((data: any) => data.reservatinId === reservation?.id)
                                    .find(
                                        (data: any) => data.additionalChargeByBranchId === item.id
                                    )
                                    ? response.data
                                        .filter(
                                            (data: any) => data.reservatinId === reservation?.id
                                        )
                                        .find(
                                            (data: any) =>
                                                data.additionalChargeByBranchId === item.id
                                        )?.id
                                    : 0,
                            };
                        })
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservation, isExtraValueIsAdded]);

    useEffect(() => {
        if (parseInt(primaryKey) !== 0) {
            getCommentsOfAttribute("Reservation", primaryKey)
                .then(data => setSaveCommentsValue(data))
                .catch(error => console.error(error));
        }
    }, [isComment, primaryKey]);

    useEffect(() => {
        if (reservation && signatureChange) {
            axios
                .get(`${baseURL}fileUploadData/Reservation/${reservation?.id}`)
                .then((response) => {
                    setSignatureURL(() =>
                        response.data.find((item: any) => item.tabName === "Signature")
                    );
                    setInitialsURL(() =>
                        response.data.find((item: any) => item.tabName === "Initials")
                    );
                    setSignatureChange(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [reservation, signatureChange]);

    useEffect(() => {
        if (branchId && !isFirstTime) {
            axios
                .get(`${baseURL}securityDeposit`)
                .then((response) => {
                    setSecurityDepositAmountToPaid(
                        response.data.find(
                            (item: any) => item.branchId === parseInt(branchId)
                        )?.depositAmount
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branchId]);

    useEffect(() => {
        if (data && currentType === CurrentType.ADD_FROM_QUOTES) {
            setIsFirstTime(true);
            setBranchId(data.branchId);
            setVehicleClassId(data.vehicleClassId);
            setPickupLocationId(data.pickupLoationId);
            setReturnLocationId(data.returenLoationId);
            setPickupDate(data.pickupDatee.split("T")[0]);
            setPickupTime(data.pickupDatee.split("T")[1].slice(0, 5));
            setReturnDate(data.returenDate.split("T")[0]);
            setReturnTime(data.returenDate.split("T")[1].slice(0, 5));
            setCustomerId(data.customerId);
            setIsFirstTimeOfReservationSelected(false);
            setSecurityDepositAmountToPaid(data.securityDepositAmount);
            if (parseFloat(data.discount) > 0) {
                setDiscountList(oldDiscountList => [...oldDiscountList, { discountDefinition: "Manual", discountAmount: data.discount }])
            }
        }
    }, [data, currentType])

    const goToNextStep = (index: number) => {
        if (index === 5) {
            if (!pagesIsAdded) {
                const newPages: string[] = [
                    "Dates",
                    "Cars",
                    "Extras",
                    "Customer",
                    "Confirm",
                    "Payments",
                    "Check Out",
                    "Agreement",
                    "Check in",
                    "Changes",
                ];
                setPages(newPages);
                setPagesIsAdded(true);
            }
        }
        navigateToNextPage(index, true);
    };

    useEffect(() => {
        if (pagesIsAdded && pages) {
            setCurrentPage(pages[5]);
            setCurrentIndex(5);
        }
    }, [pagesIsAdded, pages]);

    const navigateToNextPage = (index: number, isSelected: boolean) => {
        switch (index) {
            case 0:
                setCurrentPage(pages[index]);
                setCurrentIndex(index);
                setIsVehicleSelected(false);
                setIsCustomerSelected(false);
                setIsCheckout(false);
                break;
            case 1:
                if ((branchId && pickupDate && returnDate && pickupTime && returnTime && pickupLocationId && returnLocationId) || isSelected) {
                    setCurrentPage(pages[index]);
                    setCurrentIndex(index);
                    setIsDateText(false);
                } else {
                    setIsDateText(true);
                }
                setIsVehicleSelected(false);
                setIsCustomerSelected(false);
                setIsCheckout(false);
                break;
            case 2:
                setIsFirstTimeOfReservationSelected(false);
                if (selectedVehicleClass || isSelected) {
                    setCurrentPage(pages[index]);
                    setCurrentIndex(index);
                    setIsDateText(false);
                    setIsVehicleSelected(false);
                } else {
                    setIsVehicleSelected(true);
                }
                setIsCustomerSelected(false);
                setIsCheckout(false);
                break;
            case 3:
                setCurrentPage(pages[index]);
                setCurrentIndex(index);
                setIsCustomerSelected(false);
                setIsCheckout(false);
                break;
            case 4:
                if (customerId || isSelected) {
                    setCurrentPage(pages[index]);
                    setCurrentIndex(index);
                    setIsCustomerSelected(false);
                } else {
                    setIsCustomerSelected(true);
                }
                setIsCheckout(false);
                break;
            case 5:
                setCurrentPage(pages[index]);
                setCurrentIndex(index);
                setIsCheckout(false);
                break;
            case 6:
                setCurrentPage(pages[index]);
                setCurrentIndex(index);
                setIsCheckout(false);
                break;
            case 7:
                if (Number(securityDepositAmountToPaid) === 0) {
                    setCurrentPage(pages[index]);
                    setCurrentIndex(index);
                    setIsCheckout(false);
                } else {
                    setIsCheckout(true);
                }
                break;
            case 8:
                if (Number(securityDepositAmountToPaid) === 0) {
                    setCurrentPage(pages[index]);
                    setCurrentIndex(index);
                    setIsCheckout(false);
                } else {
                    setIsCheckout(true);
                }
                break;
            case 9:
                if (Number(securityDepositAmountToPaid) === 0) {
                    setCurrentPage(pages[index]);
                    setCurrentIndex(index);
                    setIsCheckout(false);
                } else {
                    setIsCheckout(true);
                }
                break;
        }
    }

    const getDataFromDates = (
        pickupDate: string,
        pickupTime: string,
        returnDate: string,
        returnTime: string,
        branchId: string,
        pickupLocation: string,
        returnLocation: string,
        walkInCustomer: boolean
    ) => {
        setPickupDate(pickupDate);
        setPickupTime(pickupTime);
        setReturnDate(returnDate);
        setReturnTime(returnTime);
        setBranchId(branchId);
        setPickupLocationId(pickupLocation);
        setReturnLocationId(returnLocation);
        setWalkInCustomer(walkInCustomer);
    };

    const getDataFromCars = (
        vehicleClassId: string,
        selectedVehicleClass: any
    ) => {
        setVehicleClassId(vehicleClassId);
        setSelectedVehiclesClass(selectedVehicleClass);
    };

    useEffect(() => {
        if (selectedVehicleClass && !isFirstTimeOfReservationSelected) {
            setTotalOfSelectedVehicleClass(0.0);
            setHourlyRate(selectedVehicleClass?.hourlyRate);
            setDailyRate(selectedVehicleClass?.dailyRate);
            setWeeklyRate(selectedVehicleClass?.weeklyRate);
            setMonthilyRate(selectedVehicleClass?.monthilyRate);
            setTotalHours(parseInt(selectedVehicleClass?.hours));
            if (selectedVehicleClass?.days >= 30) {
                setTotalOfSelectedVehicleClass(
                    () =>
                        parseFloat(reservation?.totalPaid ? reservation?.totalPaid : 0) -
                        parseFloat(
                            reservation?.totalRefunded ? reservation?.totalRefunded : 0
                        ) +
                        parseInt((parseInt(selectedVehicleClass.days) / 30).toFixed(0)) *
                        Number(selectedVehicleClass.monthilyRate) +
                        parseInt(
                            parseInt((parseInt(selectedVehicleClass.days) % 30) + "") / 7 + ""
                        ) *
                        Number(selectedVehicleClass.weeklyRate) +
                        parseInt(
                            ((parseInt(selectedVehicleClass.days) % 30) % 7).toFixed(0)
                        ) *
                        Number(selectedVehicleClass.dailyRate) +
                        parseFloat(selectedVehicleClass.hours) *
                        Number(selectedVehicleClass.hourlyRate)
                );

                setTotalMonths(parseInt(selectedVehicleClass?.days / 30 + ""));
                setTotalWeeks(
                    parseInt(parseInt((selectedVehicleClass?.days % 30) + "") / 7 + "")
                );
                setTotalDays(
                    parseInt((parseInt((selectedVehicleClass?.days % 30) + "") % 7) + "")
                );
            } else if (selectedVehicleClass?.days >= 7) {
                setTotalOfSelectedVehicleClass(
                    () =>
                        parseFloat(reservation?.totalPaid ? reservation?.totalPaid : 0) -
                        parseFloat(
                            reservation?.totalRefunded ? reservation?.totalRefunded : 0
                        ) +
                        parseInt((parseInt(selectedVehicleClass.days) / 7).toFixed(0)) *
                        Number(selectedVehicleClass.weeklyRate) +
                        parseInt((parseInt(selectedVehicleClass.days) % 7).toFixed(0)) *
                        Number(selectedVehicleClass.dailyRate) +
                        parseFloat(selectedVehicleClass.hours) *
                        Number(selectedVehicleClass.hourlyRate)
                );

                setTotalMonths(0);
                setTotalWeeks(parseInt(selectedVehicleClass?.days / 7 + ""));
                setTotalDays(parseInt((selectedVehicleClass?.days % 7) + ""));
            } else {
                setTotalOfSelectedVehicleClass(
                    () =>
                        parseFloat(reservation?.totalPaid ? reservation?.totalPaid : 0) -
                        parseFloat(
                            reservation?.totalRefunded ? reservation?.totalRefunded : 0
                        ) +
                        parseFloat(selectedVehicleClass?.days) *
                        Number(selectedVehicleClass?.dailyRate) +
                        parseFloat(selectedVehicleClass?.hours) *
                        Number(selectedVehicleClass?.hourlyRate)
                );

                setTotalMonths(0);
                setTotalWeeks(0);
                setTotalDays(parseInt(selectedVehicleClass?.days));
            }
            setIsFirstTimeOfReservationSelected(false);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVehicleClass]);

    const getDataFromExtras = (total: number, extraValue: any[]) => {
        setTotalAmountOfAdditionalCharges(total);
        setCheckExtraValue(
            extraValue.map((item: any) => {
                return {
                    id: item.id,
                    categoryName: item.categoryName,
                    name: item.name,
                    amountOrPercentage: item.amountOrPercentage,
                };
            })
        );
    };

    useEffect(() => {
        if (currentType === CurrentType.ADD || currentType === CurrentType.ADD_FROM_QUOTES || !isFirstTime) {
            setTotalAmount(totalOfSelectedVehicleClass + totalAmountOfAdditionalCharge);
            setIsFirstTime(false);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalOfSelectedVehicleClass, totalAmountOfAdditionalCharge])


    const changeOnDisountList = (discountDefinition: string, discountAmount: number) => {
        setDiscountList(oldDiscountList => [...oldDiscountList, { discountDefinition, discountAmount }])
    }

    useEffect(() => {
        if (discountList.length !== 0) {
            let discountAmount = 0.0;
            discountList.forEach((item: any) => {
                discountAmount += parseFloat(item.discountAmount);
            });

            setDiscountTotalAmount(() => discountAmount);
        } else {
            setDiscountTotalAmount(0);
        }
    }, [discountList]);

    useEffect(() => {
        if (!reservation)
            setTotalAmount(totalAmount - discountTotalAmount);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalOfSelectedVehicleClass, discountTotalAmount])

    useEffect(() => {
        if (!reservation) setOutstandingBalance(totalAmount);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalAmount])

    const getDataFromCustomer = (customerId: string, localAddress: string) => {
        setLocalAddress(localAddress);
        setCustomerId(customerId);
    };

    const saveComment = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        post_comment(
            JSON.stringify({
                attributeName: "Reservation",
                attributeId: primaryKey,
                notes: comments,
                userId: auth?.id,
                createdDate:
                    format(new Date(), "yyyy-MM-dd") +
                    "T" +
                    format(new Date(), "KK:mm:ss"),
            })).then(data => {
                setComments("");
                setIsComment(isComment + 1);
            })
            .catch(error => console.error("Error:", error));
    };

    const deleteTheComment = async (id: number) => {
        if (
            window.confirm(
                "Are you sure you want to delete this comment?"
            )
        ) {
            setPleaseWaitPopup(true);
            delete_comment(id)
                .then(data => {
                    setPleaseWaitPopup(false);
                    setSaveCommentsValue(saveCommentsValue.filter((item: any) => item.id !== id));
                })
                .catch(error => console.error("Error:", error));
        }
    }


    return (
        <>
            <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <EventAvailable sx={{ fontSize: "24px", marginRight: "5px" }} />
                    {currentType === CurrentType.ADD || currentType === CurrentType.ADD_FROM_QUOTES ? (
                        <Typography variant="body1" sx={{ fontSize: "16px" }}>
                            New Reservation
                        </Typography>
                    ) : (
                        <>
                            <Link
                                to="/contacts/customers/editcustomer"
                                state={
                                    customers.find(
                                        (item: any) =>
                                            item.value === parseInt(customerId)
                                    )?.value
                                }
                            >
                                <Typography
                                    variant="body1"
                                    sx={{ fontSize: "16px" }}
                                    noWrap
                                >
                                    {reservation &&
                                        !customersUpdate &&
                                        customers.find(
                                            (item: any) =>
                                                item.value === parseInt(reservation?.customerId)
                                        )?.label}
                                </Typography>
                            </Link>

                            <Typography variant="body1" sx={{ fontSize: "16px" }} noWrap>
                                {reservation && " - " + reservation?.bookingNumber}
                            </Typography>
                        </>
                    )}
                </Box>
            </Toolbar>

            <hr
                style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }}
            />

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "8px",
                    marginLeft: "8px",
                    marginRight: "8px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center",
                    }}
                >
                    {pages.map((page: string, index: number) => {
                        if (currentPage === page) {
                            return (
                                <p
                                    key={index}
                                    style={{
                                        marginBlock: "0px",
                                        fontSize: "12px",
                                        backgroundColor: "#eeeeee",
                                        paddingBlock: "3px",
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                        borderRight:
                                            index !== pages.length - 1
                                                ? "1px solid gray"
                                                : "none",
                                    }}
                                >
                                    <strong
                                        style={{
                                            backgroundColor: colorConfigs.topbar.bg,
                                            color: "white",
                                            paddingLeft: "3px",
                                            paddingRight: "3px",
                                            fontSize: "10px",
                                        }}
                                    >
                                        {index + 1}
                                    </strong>{" "}
                                    {page}
                                </p>
                            );
                        } else {
                            return (
                                <p
                                    key={index}
                                    style={{
                                        marginBlock: "0px",
                                        fontSize: "12px",
                                        backgroundColor: "#eeeeee",
                                        paddingBlock: "3px",
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                        opacity: "0.5",
                                        cursor: "pointer",
                                        borderRight:
                                            index !== pages.length - 1
                                                ? "1px solid gray"
                                                : "none",
                                    }}
                                    onClick={() => navigateToNextPage(index, false)}
                                >
                                    <strong
                                        style={{
                                            backgroundColor: colorConfigs.topbar.bg,
                                            color: "white",
                                            paddingLeft: "3px",
                                            paddingRight: "3px",
                                            fontSize: "10px",
                                        }}
                                    >
                                        {index + 1}
                                    </strong>{" "}
                                    {page}
                                </p>
                            );
                        }
                    })}
                </div>

                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                    <ListItemButton
                        sx={{
                            display: currentPage === pages[0] ? "none" : "flex",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            paddingBlock: "5px",
                            backgroundColor: colorConfigs.topbar.bg,
                            color: "white",
                            "&: hover": {
                                backgroundColor: colorConfigs.topbar.bg,
                                color: "#eeeeee",
                            },
                        }}
                        onClick={() => navigateToNextPage(currentIndex - 1, false)}
                    >
                        <KeyboardArrowLeft style={{ fontSize: "16px" }} />
                    </ListItemButton>

                    <ListItemButton
                        sx={{
                            display:
                                currentPage === pages[pages.length - 1] ? "none" : "flex",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            paddingBlock: "5px",
                            backgroundColor: colorConfigs.topbar.bg,
                            color: "white",
                            "&: hover": {
                                backgroundColor: colorConfigs.topbar.bg,
                                color: "#eeeeee",
                            },
                        }}
                        onClick={() => navigateToNextPage(currentIndex + 1, false)}
                    >
                        <KeyboardArrowRight style={{ fontSize: "16px" }} />
                    </ListItemButton>
                </div>
            </div>

            <hr style={{ marginBottom: "0px" }} />

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div style={{ width: "75%" }}>

                    {isdateText &&
                        <p
                            style={{
                                fontSize: "10px",
                                backgroundColor: "#f1dedf",
                                color: "#bb6a68",
                                textAlign: "start",
                                borderRadius: "2px",
                                paddingBlock: "8px",
                                paddingLeft: "24px",
                                paddingRight: "24px",
                                marginLeft: "24px",
                                marginRight: "24px"
                            }}
                        >
                            Please Fill Every details
                        </p>
                    }

                    {isVehicleSelected &&
                        <p
                            style={{
                                fontSize: "10px",
                                backgroundColor: "#f1dedf",
                                color: "#bb6a68",
                                textAlign: "start",
                                borderRadius: "2px",
                                paddingBlock: "8px",
                                paddingLeft: "24px",
                                paddingRight: "24px",
                                marginLeft: "24px",
                                marginRight: "24px"
                            }}
                        >
                            You have to selected vehicle
                        </p>
                    }

                    {isCustomerSelected &&
                        <p
                            style={{
                                fontSize: "10px",
                                backgroundColor: "#f1dedf",
                                color: "#bb6a68",
                                textAlign: "start",
                                borderRadius: "2px",
                                paddingBlock: "8px",
                                paddingLeft: "24px",
                                paddingRight: "24px",
                                marginLeft: "24px",
                                marginRight: "24px"
                            }}
                        >
                            Please select a customer
                        </p>
                    }

                    {isCheckout &&
                        <p
                            style={{
                                fontSize: "10px",
                                backgroundColor: "#f1dedf",
                                color: "#bb6a68",
                                textAlign: "start",
                                borderRadius: "2px",
                                paddingBlock: "8px",
                                paddingLeft: "24px",
                                paddingRight: "24px",
                                marginLeft: "24px",
                                marginRight: "24px"
                            }}
                        >
                            Please clear the security deposit amount
                        </p>
                    }

                    <div style={{ display: currentPage === pages[0] ? "block" : "none" }}>
                        <Dates
                            goToNextPage={(page) => goToNextStep(page)}
                            getDataFromDate={(
                                pickupDate,
                                pickupTime,
                                returnDate,
                                returnTime,
                                branchId,
                                pickupLocation,
                                returnLocation,
                                walkInCustomer
                            ) =>
                                getDataFromDates(
                                    pickupDate,
                                    pickupTime,
                                    returnDate,
                                    returnTime,
                                    branchId,
                                    pickupLocation,
                                    returnLocation,
                                    walkInCustomer
                                )
                            }
                            branches={branches}
                            locations={locations}
                            popUpPickupDate={pickupDate}
                            popUpReturnDate={returnDate}
                            popUpPickupTime={pickupTime}
                            popUpReturnTime={returnTime}
                            selectBranchId={branchId}
                            currentType={currentType}
                            _pickupLocationId={pickupLocationId}
                            _returnLocationId={returnLocationId}
                            _walkInCustomer={walkInCustomer}
                        />
                    </div>

                    <div style={{ display: currentPage === pages[1] ? "block" : "none" }}>
                        <Cars
                            goToNextPage={(page) => goToNextStep(page)}
                            getDataFromCars={(vehicleClassIdString, selectedVehicleClass) =>
                                getDataFromCars(vehicleClassIdString, selectedVehicleClass)
                            }
                            branchId={branchId}
                            pickupDate={pickupDate}
                            pickupTime={pickupTime}
                            returnDate={returnDate}
                            returnTime={returnTime}
                            status={status}
                            vehicleClassId={vehicleClassId}
                            dateAndTimeChange={dateAndTimeChange}
                            setDateAndTimeChange={() => setDateAndTimeChange(false)}
                            totalOfSelectedVehicleClass={totalOfSelectedVehicleClass}
                        />
                    </div>

                    <div style={{ display: currentPage === pages[2] ? "block" : "none" }}>
                        <Extras
                            goToNextPage={(page) => goToNextStep(page)}
                            getDataFromExtras={(totalAmount, extraValue) =>
                                getDataFromExtras(totalAmount, extraValue)
                            }
                            reservation={reservation}
                            reservationUpdate={() => setReservationUpdate(true)}
                            extraValueData={extraValue}
                            selectedVehicleClass={selectedVehicleClass}
                            discountUpdate={() => setIsDiscountList(true)}
                            branchId={branchId}
                            vehicleClassId={vehicleClassId}
                            pickupDate={pickupDate}
                            pickupTime={pickupTime}
                            returnDate={returnDate}
                            returnTime={returnTime}
                            pickupLocation={pickupLocationId}
                            returnLocation={returnLocationId}
                            totalAmountAfterDiscount={totalAmount}
                            totalOfSelectedVehicleClass={totalOfSelectedVehicleClass}
                            discountOptions={discountOptions}
                            discountTotalAmount={discountTotalAmount}
                            setDiscountList={(discountDefinition: string, discountAmount: number) => changeOnDisountList(discountDefinition, discountAmount)}
                            securityDepositAmountToPaid={securityDepositAmountToPaid}
                        />
                    </div>

                    <div style={{ display: currentPage === pages[3] ? "block" : "none" }}>
                        <Customer
                            goToNextPage={(page) => goToNextStep(page)}
                            getDataFromCustomer={(customerId, localAddress) =>
                                getDataFromCustomer(customerId, localAddress)
                            }
                            vehicleClassId={vehicleClassId}
                            branchId={branchId}
                            pickupDate={pickupDate}
                            pickupTime={pickupTime}
                            returnDate={returnDate}
                            returnTime={returnTime}
                            outstandingBalance={outstandingBalance}
                            hideToolbar={hideToolbar}
                            setHideToolbar={(hideToolbar) => setHideToolbar(hideToolbar)}
                            customerPrimaryKey={customerId}
                            reservation={reservation}
                        />
                    </div>

                    <div style={{ display: currentPage === pages[4] ? "block" : "none" }}>
                        <Confirm
                            goToNextPage={(page) => goToNextStep(page)}
                            vehicleClassId={vehicleClassId}
                            outstandingBalance={outstandingBalance}
                            branchId={branchId}
                            pickupDate={pickupDate}
                            pickupTime={pickupTime}
                            returnDate={returnDate}
                            returnTime={returnTime}
                            pickupLocation={pickupLocationId}
                            returnLocation={returnLocationId}
                            locations={locations}
                            customerId={customerId}
                            localAddress={localAddress}
                            walkInCustomer={walkInCustomer}
                            currentPage={currentPage}
                            setPrimaryKey={(id) => setPrimaryKey(id)}
                            primaryKey={primaryKey}
                            branches={branches}
                            selectedVehicleClass={selectedVehicleClass}
                            reservation={reservation}
                            checkExtraValue={checkExtraValue}
                            reservationUpdate={() => setReservationUpdate(true)}
                            customersUpdate={() => setCustomersUpdate(true)}
                            securityDepositAmountToPaid={securityDepositAmountToPaid}
                            discountList={discountList}
                        />
                    </div>

                    {currentIndex >= 5 &&
                        <>
                            <div style={{ display: currentPage === pages[5] ? "block" : "none" }}>
                                <Payments
                                    reservation={reservation}
                                    reservationUpdate={() => setReservationUpdate(true)}
                                    paymentList={paymentList}
                                    paymentUpdate={() => setPaymentUpdate(true)}
                                    refundList={refundList}
                                    refundUpdate={() => setRefundUpdate(true)}
                                    selectedVehicleClass={selectedVehicleClass}
                                    discountList={discountList}
                                    discountUpdate={() => setIsDiscountList(true)}
                                    securityDepositAmountToPaid={securityDepositAmountToPaid}
                                    extraValue={extraValue}
                                />
                            </div>

                            <div style={{ display: currentPage === pages[6] ? "block" : "none" }}>
                                <Checkout
                                    goToNextPage={(page) => goToNextStep(page)}
                                    reservation={reservation}
                                    reservationUpdate={() => setReservationUpdate(true)}
                                    signatureURL={signatureURL}
                                    setSignatureURL={(value) => setSignatureURL(value)}
                                    initialsURL={initialsURL}
                                    setInitialsURL={(value) => setInitialsURL(value)}
                                    signatureChange={signatureChange}
                                    setSignatureChange={(value) => setSignatureChange(value)}
                                    securityDepositAmountToPaid={securityDepositAmountToPaid}
                                />
                            </div>

                            <div style={{ display: currentPage === pages[7] ? "block" : "none" }}>
                                <Agreement
                                    goToNextPage={(page) => goToNextStep(page)}
                                    reservation={reservation}
                                    signatureURL={signatureURL}
                                    setSignatureURL={(value) => setSignatureURL(value)}
                                    initialsURL={initialsURL}
                                    setInitialsURL={(value) => setInitialsURL(value)}
                                    setSignatureChange={(value) => setSignatureChange(value)}
                                />
                            </div>

                            <div style={{ display: currentPage === pages[8] ? "block" : "none" }}>
                                <Checkin
                                    goToNextPage={(page) => goToNextStep(page)}
                                    paymentList={paymentList}
                                    reservation={reservation}
                                    reservationUpdate={() => setReservationUpdate(true)}
                                />
                            </div>

                            <div style={{ display: currentPage === pages[9] ? "block" : "none" }}>
                                <Changes
                                    branches={branches}
                                    reservation={reservation}
                                    reservationAdditionalCharge={reservationAdditionalCharge}
                                    updateReservation={() => setReservationUpdate(true)}
                                />
                            </div>
                        </>
                    }

                    {(reservation && currentPage !== pages[0]) &&
                        <div
                            style={{
                                display: primaryKey ? "block" : "none",
                                padding: "24px",
                                width: "100%",
                            }}
                        >
                            <h5
                                style={{
                                    paddingBottom: "0px",
                                    marginBlock: "0px",
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    WebkitUserSelect: "none" /* Safari */,
                                    msUserSelect: "none" /* IE 10 and IE 11 */,
                                    userSelect: "none" /* Standard syntax */,
                                }}
                                onClick={() => setIsComments(!isComments)}
                            >
                                {isComments ? (
                                    <ExpandMore sx={{ fontSize: "20px" }} />
                                ) : (
                                    <KeyboardArrowRight sx={{ fontSize: "20px" }} />
                                )}{" "}
                                Comments
                            </h5>
                            <hr />

                            {isComments &&
                                saveCommentsValue.map((item: CommentType) => {
                                    return (
                                        <AddComments
                                            key={item.id}
                                            id={item.id}
                                            userId={item.userId}
                                            createdDate={item.createdDate}
                                            notes={item.notes}
                                            deleteTheComment={(id) => deleteTheComment(id)}
                                        />
                                    );
                                })}

                            {isComments && (
                                <form onSubmit={(e) => saveComment(e)}>
                                    <textarea
                                        style={{ width: "100%", height: "100px" }}
                                        value={comments}
                                        onChange={(e) => setComments(e.target.value)}
                                        required
                                    />

                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: colorConfigs.topbar.bg,
                                            color: "white",
                                            borderRadius: "0",
                                            border: "none",
                                            textTransform: "capitalize",
                                            marginTop: "16px",
                                            fontSize: "12px",
                                        }}
                                        type="submit"
                                    >
                                        Save Comment
                                    </Button>
                                </form>
                            )}
                        </div>
                    }
                </div>

                <div
                    style={{
                        width: "25%",
                    }}
                >
                    <Summary
                        currentPage={currentPage}
                        currentType={currentType}
                        showDateAndTimeBox={() => setDateAndTimeBox(true)}
                        outstandingBalance={outstandingBalance}
                        totalAmount={totalAmount}
                        status={status}
                        branches={branches}
                        branchId={branchId}
                        pickupDate={pickupDate}
                        pickupTime={pickupTime}
                        returnDate={returnDate}
                        returnTime={returnTime}
                        pickupLocation={pickupLocationId}
                        returnLocation={returnLocationId}
                        locations={locations}
                        selectedVehicleClass={selectedVehicleClass}
                        totalOfSelectedVehicleClass={totalOfSelectedVehicleClass}
                        totalHours={totalHours}
                        hourlyRate={hourlyRate}
                        totalDays={totalDays}
                        dailyRate={dailyRate}
                        totalWeeks={totalWeeks}
                        weeklyRate={weeklyRate}
                        totalMonths={totalMonths}
                        monthilyRate={monthilyRate}
                        checkExtraValue={checkExtraValue}
                        reservation={reservation}
                        paymentList={paymentList}
                        refundList={refundList}
                        securityDepositList={securityDepositList}
                        discountTotalAmount={discountTotalAmount}
                        discountList={discountList}
                        securityDepositAmountToPaid={securityDepositAmountToPaid}
                        updateSecurityDepositAmountToPaid={(updateAmount: string) => setSecurityDepositAmountToPaid(updateAmount)}
                    />
                </div>
            </div>

            <DateAndTimeChange
                dateAndTimeBox={dateAndTimeBox}
                setDateAndTimeBox={(value) => setDateAndTimeBox(value)}
                pickupDate={pickupDate}
                setPickupDate={(value) => setPickupDate(value)}
                pickupTime={pickupTime}
                setPickupTime={(value) => setPickupTime(value)}
                returnDate={returnDate}
                setReturnDate={(value) => setReturnDate(value)}
                returnTime={returnTime}
                setReturnTime={(value) => setReturnTime(value)}
                setDateAndTimeChange={() => setDateAndTimeChange(true)}
            />

            <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
        </>
    )
}

export default TenTabLayout