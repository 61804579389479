import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import baseURL from "../../../../../configs/api";
import colorConfigs from "../../../../../configs/colorConfigs";

type Props = {
  popup: boolean;
  hidePopup: () => void;
  isSaved: () => void;
};

export default function AddMaintenanceTypes({
  popup,
  hidePopup,
  isSaved,
}: Props) {
  const [maintenanceName, setMaintenanceName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isRecurring, setIsRecurring] = useState<boolean>(true);
  const [defaultTotalInParts, setDefaultTotalInParts] =
    useState<string>("0.00");
  const [defaultTotalInLabor, setDefaultTotalInLabor] =
    useState<string>("0.00");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios
      .post(
        `${baseURL}maintenanceType`,
        JSON.stringify({
          maintenanceName,
          isActive,
          isRecurring,
          defaultTotalInParts,
          defaultTotalInLabor,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        isSaved();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Dialog
      open={popup}
      maxWidth="md"
      PaperProps={{
        style: {
          width: "40%",
          marginTop: "-150px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", width: "100%" }}>
        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 16px 8px 16px",
          }}
        >
          <p style={{ fontSize: "14px", marginLeft: "5px" }}>
            Add Maintenance Type
          </p>
          <CloseIcon
            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
            onClick={() => hidePopup()}
          />
        </div>
        <hr style={{ marginBlock: "0px" }} />
      </DialogTitle>

      <DialogContent sx={{ padding: "0px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <form onSubmit={(e) => handleSubmit(e)} style={{}}>
            <div style={{ padding: "16px 16px 0px 16px" }}>
              <h5 style={{ marginBlock: "0px" }}>General</h5>
              <hr style={{ marginBlock: "0px" }} />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  padding: "0px 16px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="label"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Label
                      <br />
                      <input
                        type="text"
                        name="maintenanceName"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={maintenanceName}
                        onChange={(e) => setMaintenanceName(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Active?
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="isActive"
                        style={{ fontSize: "12px" }}
                        checked={isActive ? true : false}
                        onChange={(e) =>
                          setIsActive(e.target.value === "Yes" ? true : false)
                        }
                      />
                      Yes
                    </label>
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="isActive"
                        style={{ fontSize: "12px" }}
                        checked={isActive ? false : true}
                        onChange={(e) =>
                          setIsActive(e.target.value === "Yes" ? true : false)
                        }
                      />
                      No
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Is Recurring?
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="isRecurring"
                        style={{ fontSize: "12px" }}
                        checked={isRecurring ? true : false}
                        onChange={(e) =>
                          setIsRecurring(e.target.value === "Yes" ? true : false)
                        }
                      />
                      Yes
                    </label>
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="isRecurring"
                        style={{ fontSize: "12px" }}
                        checked={isRecurring ? false : true}
                        onChange={(e) =>
                          setIsRecurring(e.target.value === "Yes" ? true : false)
                        }
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>

              <h5 style={{ marginBottom: "0px" }}>Default Settings</h5>
              <hr style={{ marginBlock: "0px" }} />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                    paddingLeft: "16px",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="defaultTotalInParts"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Default Total in Parts
                      <br />
                      <input
                        type="number"
                        name="defaultTotalInParts"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={defaultTotalInParts}
                        onChange={(e) => setDefaultTotalInParts(e.target.value)}
                        required
                      />
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px", paddingRight: "16px" }}>
                    <label
                      htmlFor="defaultTotalInLabor"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Default Total in Labor
                      <br />
                      <input
                        type="number"
                        name="defaultTotalInLabor"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={defaultTotalInLabor}
                        onChange={(e) => setDefaultTotalInLabor(e.target.value)}
                        required
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBlock: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                backgroundColor: "#eeeeee",
              }}
            >
              <div style={{ width: "50%" }} />

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: "#e0dee0",
                      color: colorConfigs.sidebar.color,
                    },
                  }}
                  onClick={() => hidePopup()}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                    },
                  }}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
