import React, { Fragment, useEffect, useRef, useState } from 'react'
import colorConfigs from '../../../configs/colorConfigs';
import { convertWeek2DigitFormat, dateTimeToPosition, dateToPosition, formatTime, generateDateRangeCalendar, generateHourlyTimestamps, generateMonthlyCalendar, generateMonthNames, generateMonthNamesForDateRange, generateWeeklyCalendar, isCurrentHour, isCurrentMonth, isToday, isWeekend, parseDate, parseDateTime, TimelineType, Views } from './utlis';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './Calendar.css'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


type ResizeEvent = MouseEvent | TouchEvent;

type EventType = {
    id: number,
    resourceId: number;
    title: string,
    start: string,
    end: string,
    bgColor: string;
    borderColor: string;
}

type Track = EventType[];

type ChildType = {
    id: number;
    groupId: number;
    title: string;
    events: Track[] | [];
    isChildHidden: boolean;
}

type ResourceGroupType = {
    id: number;
    title: string;
    events: Track[] | [];
    children: ChildType[] | [];
    isChildHidden: boolean;
}

type GroupType = {
    id: number;
    title: string;
}

type TimelineProps = {
    startDate?: moment.Moment;
    customStartDate: string;
    customEndDate: string;
    timeLineHeight: number
    groups: GroupType[];
    groupsIsLoaded: boolean;
    resources: ChildType[];
    resourcesIsLoaded: boolean;
    events: EventType[];
    eventsIsLoaded: boolean;
    resourcesDataIsLoaded: boolean;
    setResourcesDataIsLoaded: (value: boolean) => void;
    rowHeight?: number;
    views?: Views;
    onItemClick: (id: number) => void;
    print: React.Dispatch<React.SetStateAction<(() => void)>>;
}

const Timeliner = ({
    startDate = moment(),
    customStartDate,
    customEndDate,
    timeLineHeight,
    groups,
    groupsIsLoaded,
    resources,
    resourcesIsLoaded,
    events,
    eventsIsLoaded,
    resourcesDataIsLoaded,
    setResourcesDataIsLoaded,
    rowHeight = 33,
    views = Views.TimeSlot3MONTH,
    onItemClick,
    print
}: TimelineProps) => {
    const [timelineType, setTimelineType] = useState<TimelineType>(TimelineType.TimelineDaySlotType);
    const topbarRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const resourcesRef = useRef<HTMLDivElement | null>(null);

    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [startX, setStartX] = useState<number>(0);
    const [scrollLeft, setScrollLeft] = useState<number>(0);

    const [startY, setStartY] = useState<number>(0);
    const [scrollTop, setScrollTop] = useState<number>(0);

    useEffect(() => {
        const handleScroll = () => {
            if (topbarRef.current && contentRef.current) {
                topbarRef.current.scrollLeft = contentRef.current.scrollLeft;
            }

            if (resourcesRef.current && contentRef.current) {
                resourcesRef.current.scrollTop = contentRef.current.scrollTop;
            }
        };

        const handleMouseDown = (e: MouseEvent) => {
            if (contentRef.current) {
                setIsDragging(true);
                setStartX(e.pageX - contentRef.current.offsetLeft);
                setScrollLeft(contentRef.current.scrollLeft);
                contentRef.current.style.cursor = "grabbing"; // Change cursor on drag start
            }
        };

        const handleMouseMove = (e: MouseEvent) => {
            if (isDragging && contentRef.current) {
                const x = e.pageX - contentRef.current.offsetLeft;
                const walk = (x - startX) * 2; // Scroll speed
                contentRef.current.scrollLeft = scrollLeft - walk;
            }
        };

        const handleMouseUp = () => {
            if (contentRef.current) {
                setIsDragging(false);
                contentRef.current.style.cursor = "move"; // Revert cursor to 'move' when drag ends
            }
        };

        const handleMouseLeave = () => {
            if (isDragging) {
                setIsDragging(false);
                if (contentRef.current) {
                    contentRef.current.style.cursor = "move"; // Revert cursor if mouse leaves
                }
            }
        };

        const handleTouchStart = (e: TouchEvent) => {
            if (contentRef.current) {
                setIsDragging(true);
                setStartX(e.touches[0].pageX - contentRef.current.offsetLeft);
                setScrollLeft(contentRef.current.scrollLeft);
                contentRef.current.style.cursor = "grabbing"; // Change cursor on drag start
            }
        };

        const handleTouchMove = (e: TouchEvent) => {
            if (isDragging && contentRef.current) {
                const x = e.touches[0].pageX - contentRef.current.offsetLeft;
                const walk = (x - startX) * 2; // Scroll speed
                contentRef.current.scrollLeft = scrollLeft - walk;
            }
        };

        const handleTouchEnd = () => {
            if (contentRef.current) {
                setIsDragging(false);
                contentRef.current.style.cursor = "move"; // Revert cursor when drag ends
            }
        };

        const handleResourceWheel = (e: WheelEvent) => {
            if (resourcesRef.current && contentRef.current) {
                contentRef.current.scrollBy({
                    top: e.deltaY * 4, // Wheel speed
                    behavior: "smooth",
                });

                resourcesRef.current.scrollBy({
                    top: e.deltaY * 4,
                    behavior: "smooth",
                });
            }
        };

        const handleResourceMouseDown = (e: MouseEvent) => {
            if (resourcesRef.current) {
                setIsDragging(true);
                setStartY(e.pageY - resourcesRef.current.offsetTop);
                setScrollTop(resourcesRef.current.scrollTop);
                resourcesRef.current.style.cursor = "grabbing"; // Change cursor on drag start
            }
        };

        const handleResourceMouseMove = (e: MouseEvent) => {
            if (isDragging && resourcesRef.current && contentRef.current) {
                const y = e.pageY - resourcesRef.current.offsetTop;
                const walk = (y - startY) * 2; // Scroll speed
                resourcesRef.current.scrollTop = scrollTop - walk;
                contentRef.current.scrollTop = scrollTop - walk;
            }
        };

        const handleResourceMouseUp = () => {
            if (resourcesRef.current) {
                setIsDragging(false);
                resourcesRef.current.style.cursor = "move"; // Revert cursor to 'move' when drag ends
            }
        };

        const handleResourceMouseLeave = () => {
            if (isDragging) {
                setIsDragging(false);
                if (resourcesRef.current) {
                    resourcesRef.current.style.cursor = "move"; // Revert cursor if mouse leaves
                }
            }
        };

        const handleResourceTouchStart = (e: TouchEvent) => {
            if (resourcesRef.current) {
                setIsDragging(true);
                setStartY(e.touches[0].pageY - resourcesRef.current.offsetTop);
                setScrollTop(resourcesRef.current.scrollTop);
                resourcesRef.current.style.cursor = "grabbing"; // Change cursor on drag start
            }
        };

        const handleResourceTouchMove = (e: TouchEvent) => {
            if (isDragging && resourcesRef.current && contentRef.current) {
                const y = e.touches[0].pageY - resourcesRef.current.offsetTop;
                const walk = (y - startY) * 2; // Scroll speed
                resourcesRef.current.scrollTop = scrollTop - walk;
                contentRef.current.scrollTop = scrollTop - walk;
            }
        };

        const handleResourceTouchEnd = () => {
            if (resourcesRef.current) {
                setIsDragging(false);
                resourcesRef.current.style.cursor = "move"; // Revert cursor when drag ends
            }
        };

        const contentElement = contentRef.current;
        const resourceElement = resourcesRef.current;

        if (contentElement) {
            contentElement.addEventListener("scroll", handleScroll);
            contentElement.addEventListener("mousedown", handleMouseDown);
            contentElement.addEventListener("mousemove", handleMouseMove);
            contentElement.addEventListener("mouseup", handleMouseUp);
            contentElement.addEventListener("mouseleave", handleMouseLeave);
            contentElement.addEventListener("touchstart", handleTouchStart);
            contentElement.addEventListener("touchmove", handleTouchMove);
            contentElement.addEventListener("touchend", handleTouchEnd);
        }

        if (resourceElement) {
            resourceElement.addEventListener("wheel", handleResourceWheel);
            resourceElement.addEventListener("mousedown", handleResourceMouseDown);
            resourceElement.addEventListener("mousemove", handleResourceMouseMove);
            resourceElement.addEventListener("mouseup", handleResourceMouseUp);
            resourceElement.addEventListener("mouseleave", handleResourceMouseLeave);
            resourceElement.addEventListener("touchstart", handleResourceTouchStart);
            resourceElement.addEventListener("touchmove", handleResourceTouchMove);
            resourceElement.addEventListener("touchend", handleResourceTouchEnd);
        }

        return () => {
            if (contentElement) {
                contentElement.removeEventListener("scroll", handleScroll);
                contentElement.removeEventListener("mousedown", handleMouseDown);
                contentElement.removeEventListener("mousemove", handleMouseMove);
                contentElement.removeEventListener("mouseup", handleMouseUp);
                contentElement.removeEventListener("mouseleave", handleMouseLeave);
                contentElement.removeEventListener("touchstart", handleTouchStart);
                contentElement.removeEventListener("touchmove", handleTouchMove);
                contentElement.removeEventListener("touchend", handleTouchEnd);
            }

            if (resourceElement) {
                resourceElement.removeEventListener("wheel", handleResourceWheel);
                resourceElement.removeEventListener(
                    "mousedown",
                    handleResourceMouseDown
                );
                resourceElement.removeEventListener(
                    "mousemove",
                    handleResourceMouseMove
                );
                resourceElement.removeEventListener("mouseup", handleResourceMouseUp);
                resourceElement.removeEventListener(
                    "mouseleave",
                    handleResourceMouseLeave
                );
                resourceElement.removeEventListener(
                    "touchstart",
                    handleResourceTouchStart
                );
                resourceElement.removeEventListener(
                    "touchmove",
                    handleResourceTouchMove
                );
                resourceElement.removeEventListener("touchend", handleResourceTouchEnd);
            }
        };
    }, [isDragging, startX, scrollLeft, startY, scrollTop, groupsIsLoaded, resourcesIsLoaded]);


    //resize
    const tableRef = useRef<HTMLTableElement | null>(null);

    const [startCleintX, setStartClientX] = useState<number>(0);
    const [startWidth, setStartWidth] = useState<number>(0);
    const [colIndex, setColIndex] = useState<number>(-1);
    const [newWidth, setNewWidth] = useState<number>(300);

    const getClientX = (e: ResizeEvent): number => {
        if (e.type === "touchmove" || e.type === "touchstart") {
            return (e as TouchEvent).touches[0].clientX;
        }
        return (e as MouseEvent).clientX;
    };

    const startResizing = (e: ResizeEvent) => {
        e.preventDefault();

        const col = e.currentTarget as HTMLTableCellElement;
        const index = parseInt(col.dataset.index || "0", 10);

        if (tableRef.current) {
            const cols = tableRef.current.querySelectorAll("col");
            if (index >= 0 && index < cols.length) {
                const colToResize = cols[index] as HTMLTableColElement;
                setColIndex(index);

                setStartClientX(getClientX(e));

                setStartWidth(colToResize.getBoundingClientRect().width);

                document.addEventListener("mousemove", handleResize);
                document.addEventListener("mouseup", stopResizing);
                document.addEventListener("touchmove", handleResize);
                document.addEventListener("touchend", stopResizing);
            }
        }
    };

    const handleResize = (e: ResizeEvent): void => {
        e.preventDefault();

        if (tableRef.current) {
            const clientX = getClientX(e);
            const widthChange = clientX - startCleintX;

            const newWidth = startWidth + widthChange;

            const cols = tableRef.current.querySelectorAll("col");

            if (colIndex >= 0 && colIndex < cols.length) {
                const colToResize = cols[colIndex] as HTMLTableColElement;

                colToResize.style.width = `${newWidth}px`;
                setNewWidth(newWidth);
            }
        }
    };

    const stopResizing = () => {
        document.removeEventListener("mousemove", handleResize);
        document.removeEventListener("mouseup", stopResizing);
        document.removeEventListener("touchmove", handleResize);
        document.removeEventListener("touchend", stopResizing);
    };

    useEffect(() => {
        return () => {
            document.removeEventListener("mousemove", handleResize);
            document.removeEventListener("mouseup", stopResizing);
            document.removeEventListener("touchmove", handleResize);
            document.removeEventListener("touchend", stopResizing);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //Width listener the schedule
    const [timeSlotContainerWidth, setTimeSlotContanierWidth] = useState<number>(664);

    useEffect(() => {
        const calculateTableWidth = (screenWidth: number) => {
            const maxScreenWidth = 980;
            const containerWidth = 664;
            const containerMaxWidth = 748;
            const pattern = [1, 2, 1, 2, 0, 2];
            const patternLength = pattern.length;

            // If screen width is below or equal to maxScreenWidth, table width is containerWidth
            if (screenWidth <= maxScreenWidth) {
                setTimeSlotContanierWidth(containerWidth);
                return;
            }

            if (screenWidth >= containerMaxWidth) {
                setTimeSlotContanierWidth(containerMaxWidth);
                return;
            }

            // Calculate excess width
            const excessWidth = screenWidth - maxScreenWidth;

            // Determine pattern index based on excess width
            const patternIndex = (excessWidth - 1) % patternLength;

            // Calculate the increment based on the pattern
            const increment = pattern[patternIndex];

            setTimeSlotContanierWidth((prevTimeSlotContainerWidth) => prevTimeSlotContainerWidth + increment);
        }

        calculateTableWidth(window.innerWidth);

        const handleResize = () => calculateTableWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [])

    const [dateSlot, setDateSlot] = useState<{ monthName: string, days: number, date: moment.Moment }[]>([]);
    const [timeSlot, setTimeSlot] = useState<{ noOfColumn: number, slot: string[] }>({ noOfColumn: 1, slot: [] });
    const [minWidthOfColumn, setMinWidthOfColumn] = useState<number>(48);
    const [resourcesData, setResourcesData] = useState<ResourceGroupType[]>([]);
    const [filterResourcesData, setFilterResourcesData] = useState<ResourceGroupType[]>([]);

    const [searchValue, setSearchValue] = useState<string>("");

    const columnHeaderRef = useRef<HTMLDivElement | null>(null);
    const dateSlotRef = useRef<HTMLTableRowElement | null>(null);
    const timelineContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let newDateSlot: { monthName: string, days: number, date: moment.Moment }[] = [];
        let newTimeSlot: string[] = [];
        let newNoOfColumn: number = 0;
        let newMinWidthOfColumn = 0;

        if (!dateSlotRef.current || !columnHeaderRef.current) {
            return;
        }

        switch (views) {
            case Views.TimeSlotToday:
                dateSlotRef.current.hidden = true;
                columnHeaderRef.current.style.height = "26px";
                newDateSlot = [];
                newTimeSlot = generateHourlyTimestamps(startDate.startOf('day'));
                newNoOfColumn = 48;
                newMinWidthOfColumn = 48;
                setTimelineType(TimelineType.TimelineTimeSlotType);
                break;
            case Views.TimeSlotWEEK:
                dateSlotRef.current.hidden = true;
                columnHeaderRef.current.style.height = "26px";
                newDateSlot = [];
                newTimeSlot = generateWeeklyCalendar(startDate.startOf('week'));
                newNoOfColumn = 7;
                newMinWidthOfColumn = 100 / 7;
                setTimelineType(TimelineType.TimelineDaySlotType);
                break;
            case Views.TimeSlotMONTH:
                dateSlotRef.current.hidden = true;
                columnHeaderRef.current.style.height = "26px";
                const endDate1 = moment(startDate).add(1, 'month').startOf('month');
                newDateSlot = [];
                newTimeSlot = generateMonthlyCalendar(startDate.startOf('month'), endDate1);
                newNoOfColumn = newTimeSlot?.length;
                newMinWidthOfColumn = 48;
                setTimelineType(TimelineType.TimelineDaySlotType);
                break;
            case Views.TimeSlot3MONTH:
                dateSlotRef.current.hidden = false;
                columnHeaderRef.current.style.height = "51px";
                const endDate2 = moment(startDate).add(3, 'month')
                newDateSlot = generateMonthNames(startDate, endDate2);
                newTimeSlot = generateMonthlyCalendar(startDate.startOf('month'), endDate2.startOf('month'));
                newNoOfColumn = newTimeSlot?.length;
                newMinWidthOfColumn = 43;
                setTimelineType(TimelineType.TimelineDaySlotType);
                break;
            case Views.TimeSlot6MONTH:
                dateSlotRef.current.hidden = false;
                columnHeaderRef.current.style.height = "51px";
                const endDate3 = moment(startDate).add(6, 'month')
                newDateSlot = generateMonthNames(startDate, endDate3);
                newTimeSlot = generateMonthlyCalendar(startDate.startOf('month'), endDate3.startOf('month'));
                newNoOfColumn = newTimeSlot?.length;
                newMinWidthOfColumn = 43;
                setTimelineType(TimelineType.TimelineDaySlotType);
                break;
            case Views.TimeSlotYear:
                dateSlotRef.current.hidden = false;
                columnHeaderRef.current.style.height = "51px";
                const endDate4 = moment(startDate).startOf("year").add(12, 'month')
                newDateSlot = generateMonthNames(startDate.startOf('year'), endDate4);
                newTimeSlot = generateMonthlyCalendar(startDate.startOf('year'), endDate4);
                newNoOfColumn = newTimeSlot?.length;
                newMinWidthOfColumn = 43;
                setTimelineType(TimelineType.TimelineDaySlotType);
                break;
            case Views.TimeSlotCustom:
                const differentInDays = dateToPosition(moment(customStartDate), moment(customEndDate));
                newDateSlot = [];
                newDateSlot = [];

                if (differentInDays === 0) {
                    newTimeSlot = generateHourlyTimestamps(moment(customStartDate));
                    newNoOfColumn = 48;
                    newMinWidthOfColumn = 48;
                    setTimelineType(TimelineType.TimelineTimeSlotType);
                } else if (differentInDays < 7) {
                    newTimeSlot = generateDateRangeCalendar(moment(customStartDate).startOf('day'), moment(customEndDate).startOf('day'));
                    newNoOfColumn = differentInDays;
                    newMinWidthOfColumn = 100 / differentInDays;
                } else if (differentInDays < 30) {
                    newTimeSlot = generateDateRangeCalendar(moment(customStartDate).startOf('day'), moment(customEndDate).startOf('day'));
                    newNoOfColumn = newTimeSlot?.length;
                    newMinWidthOfColumn = 96;
                } else {
                    newTimeSlot = generateDateRangeCalendar(moment(customStartDate).startOf('day'), moment(customEndDate).startOf('day'));
                    newNoOfColumn = newTimeSlot?.length;
                    newMinWidthOfColumn = 48;
                }

                if (moment(customStartDate).isSame(moment(customEndDate), 'month')) {
                    dateSlotRef.current.hidden = true;
                    columnHeaderRef.current.style.height = "26px";
                } else {
                    dateSlotRef.current.hidden = false;
                    newDateSlot = generateMonthNamesForDateRange(moment(customStartDate), moment(customEndDate));
                    columnHeaderRef.current.style.height = "51px";
                }

                if (differentInDays > 0) {
                    setTimelineType(TimelineType.TimelineDaySlotType);
                }

                break;
            default:
                dateSlotRef.current.hidden = false;
                columnHeaderRef.current.style.height = "51px";
                const endDate5 = moment(startDate).add(3, 'month')
                newDateSlot = generateMonthNames(startDate, endDate5);
                newTimeSlot = generateMonthlyCalendar(startDate.startOf('month'), endDate5.startOf('month'));
                newNoOfColumn = newTimeSlot?.length;
                newMinWidthOfColumn = 43;
                setTimelineType(TimelineType.TimelineDaySlotType);
                break;
        }

        setDateSlot(newDateSlot);
        setTimeSlot({ noOfColumn: newNoOfColumn, slot: newTimeSlot });
        setMinWidthOfColumn(newMinWidthOfColumn);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [views, customStartDate, customEndDate])

    const overlaps = (event1: EventType, event2: EventType): boolean => {
        const start1 = parseDate(event1.start);
        const end1 = parseDate(event1.end);
        const start2 = parseDate(event2.start);
        const end2 = parseDate(event2.end);

        return (
            (start1 >= start2 && start1 <= end2)
            || (end1 >= start2 && end1 <= end2)
            || (start1 <= start2 && end1 >= end2)
        );
    };

    const overlapsForTimeSlot = (event1: EventType, event2: EventType): boolean => {
        const start1 = parseDateTime(event1.start);
        const end1 = parseDateTime(event1.end);
        const start2 = parseDateTime(event2.start);
        const end2 = parseDateTime(event2.end);

        return (
            (start1 >= start2 && start1 <= end2)
            || (end1 >= start2 && end1 <= end2)
            || (start1 <= start2 && end1 >= end2)
        );
    };

    const filterAndSortingEventsForDate = (events: EventType[], slot: string[]) => {
        if (slot.length < 2) {
            console.error('Slot should contain at least two date strings for the range.');
        }

        const startTime = moment(slot[0]);
        const endTime = moment(slot[slot.length - 1]);

        const filteredEvents = events.filter(event => {
            const eventStartDate = moment(event.start);
            const eventEndDate = moment(event.end);
            return (
                (startTime >= eventStartDate && startTime <= eventEndDate)
                || (endTime >= eventStartDate && endTime <= eventEndDate)
                || (startTime <= eventStartDate && endTime >= eventEndDate)
            );
        });

        filteredEvents.sort((a, b) => {
            const endA = parseDate(a.end);
            const endB = parseDate(b.end);
            return endA.isBefore(endB) ? -1 : 1;
        });

        return filteredEvents;
    }

    const filterAndSortingEventsForTimeSlot = (events: EventType[], day: string) => {
        const startOfDay = moment(day).startOf('day');
        const endOfDay = moment(day).endOf('day');

        const filteredEvents = events.filter(event => {
            const eventStartDate = parseDateTime(event.start);
            const eventEndDate = parseDateTime(event.end);
            return (
                (eventStartDate >= startOfDay && eventStartDate <= endOfDay)
                || (eventEndDate >= startOfDay && eventEndDate <= endOfDay)
                || (eventStartDate <= startOfDay && eventEndDate >= endOfDay)
            );
        });

        filteredEvents.sort((a, b) => {
            const endA = parseDateTime(a.end);
            const endB = parseDateTime(b.end);
            return endA.isBefore(endB) ? -1 : 1;
        });

        return filteredEvents;
    };

    function groupEvents(events: EventType[]): Track[] {
        const tracks: Track[] = [];

        events.forEach(event => {
            let isPlaced = false;

            for (let track of tracks) {
                const lastEventInTrack = (Array.isArray(track) && track.length > 0) ? track[track.length - 1] : null;
                if (lastEventInTrack !== null && (TimelineType.TimelineTimeSlotType === timelineType ? !overlapsForTimeSlot(lastEventInTrack, event) : !overlaps(lastEventInTrack, event))) {
                    track.push(event);
                    isPlaced = true;
                    break;
                }
            }

            if (!isPlaced) {
                tracks.push([event]);
            }
        });

        return tracks;
    }

    const getResources = (groups: GroupType[], resources: ChildType[], events: EventType[]): ResourceGroupType[] => {
        const data: ResourceGroupType[] = groups.map((group) => {
            const children = (Array.isArray(resources) && resources?.filter(resource => resource.groupId === group.id)) || [];

            return {
                ...group,
                events: (Array.isArray(events) && groupEvents(
                    TimelineType.TimelineTimeSlotType === timelineType
                        ? filterAndSortingEventsForTimeSlot(events?.filter(event => event.resourceId === group.id), timeSlot?.slot[0])
                        : filterAndSortingEventsForDate(events?.filter(event => event.resourceId === group.id), timeSlot?.slot)
                )) || [],
                children: children?.map(child => ({
                    ...child,
                    events: (Array.isArray(events) && groupEvents(
                        TimelineType.TimelineTimeSlotType === timelineType
                            ? filterAndSortingEventsForTimeSlot(events?.filter(event => event.resourceId === child.id), timeSlot?.slot[0])
                            : filterAndSortingEventsForDate(events?.filter(event => event.resourceId === child.id), timeSlot?.slot)
                    )) || []
                })) || [],
                isChildHidden: false
            }
        });

        return data;
    }

    useEffect(() => {
        if (groupsIsLoaded && resourcesIsLoaded && eventsIsLoaded) {
            const newResourceData = getResources(groups, resources, events) || [];

            if (newResourceData.length) {
                setResourcesData(newResourceData);
                setFilterResourcesData(newResourceData?.filter(resData => resData?.title?.toLowerCase()?.includes(searchValue?.toLowerCase())));
                setResourcesDataIsLoaded(true);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupsIsLoaded, resourcesIsLoaded, eventsIsLoaded])

    const timeOutId = useRef<NodeJS.Timeout | null>(null);

    const handleTheResourceSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchValue(searchValue);

        if (timeOutId.current) {
            setResourcesDataIsLoaded(true);
            clearTimeout(timeOutId.current);
        }

        setResourcesDataIsLoaded(false);

        timeOutId.current = setTimeout(() => {
            const newResourceData = resourcesData?.filter(resData => resData?.title?.toLowerCase()?.includes(searchValue));
            if (newResourceData?.length > 0) {
                setFilterResourcesData(newResourceData);
                setResourcesDataIsLoaded(true);
            } else {
                const filteredResourcesData = resourcesData?.map((resData: ResourceGroupType) => {
                    const newResData = resData?.children?.filter(item => item?.title?.toLowerCase()?.includes(searchValue));
                    if (newResData?.length > 0) {
                        return {
                            ...resData,
                            children: newResData
                        }
                    }

                    return undefined;
                })
                    .filter((resData): resData is ResourceGroupType => resData !== undefined);
                if (filteredResourcesData?.length > 0) {
                    setFilterResourcesData(filteredResourcesData);
                    setResourcesDataIsLoaded(true);
                } else {
                    setFilterResourcesData([]);
                    setResourcesDataIsLoaded(true);
                }
            }
        }, 500);
    }

    const renderEvent = (event: EventType, index: number) => {
        const slot = timeSlot?.slot;
        const startDate = TimelineType.TimelineTimeSlotType === timelineType ? parseDateTime(slot[0]) : parseDate(slot[0]);
        const endDate = TimelineType.TimelineTimeSlotType === timelineType ? parseDateTime(slot[slot.length - 1]).add(29, "minute") : parseDate(slot[slot.length - 1]);

        const eventStart = TimelineType.TimelineTimeSlotType === timelineType ? parseDateTime(event.start) : parseDate(event.start);
        const eventEnd = TimelineType.TimelineTimeSlotType === timelineType ? parseDateTime(event.end) : parseDate(event.end);

        const validStartDate = eventStart < startDate ? startDate : eventStart;
        const validEndDate = eventEnd > endDate ? endDate : eventEnd;

        const startX = TimelineType.TimelineTimeSlotType === timelineType ? dateTimeToPosition(startDate, validStartDate) : dateToPosition(startDate, validStartDate);
        const width = TimelineType.TimelineTimeSlotType === timelineType ? dateTimeToPosition(validStartDate, validEndDate) : dateToPosition(validStartDate, validEndDate);

        const columnWidth = minWidthOfColumn * (timeSlot?.noOfColumn / timeSlot?.slot?.length);
        const leftPosition = startX * columnWidth;
        const eventWidth = (width + 1) * columnWidth;

        return (
            <div
                className={`${resourcesDataIsLoaded ? "" : "calendar_skeleton"}`}
                style={{
                    position: "absolute",
                    top: `${index * (rowHeight - 3)}px`,
                    left: `${Views.TimeSlotWEEK === views ? `calc(${leftPosition}% + 1.5px)` : `${leftPosition + 1.5}px`}`,
                    width: `${Views.TimeSlotWEEK === views ? `calc(${eventWidth}% - 3px)` : `${eventWidth - 3}px`}`,
                    backgroundColor: colorConfigs.topbar.bg
                }}
            >
                <div
                    className={`${resourcesDataIsLoaded ? "" : "calendar_skeleton"}`}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "0px",
                        fontSize: `${rowHeight * (0.85 / 33) > 2 ? 1.85 : rowHeight * (0.85 / 33)}em`,
                        position: "relative",
                        zIndex: 1,
                        backgroundColor: event?.bgColor || "##bdbdbd",
                        border: `1px solid ${event?.borderColor || "#000000"}`
                    }}
                >
                    <div
                        className={`${resourcesDataIsLoaded ? "" : "calendar_skeleton"}`}
                        style={{
                            flexGrow: 1,
                            flexShrink: 1,
                            minWidth: "0px",
                            borderRight: resourcesDataIsLoaded ? "5px solid var(--secondary) !important" : "transparent",
                            marginRight: "-1px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex"
                            }}
                        >
                            <div
                                style={{
                                    flexGrow: 1,
                                    flexShrink: 1,
                                    minWidth: "0px"
                                }}
                            >
                                <div
                                    className={`${resourcesDataIsLoaded ? "" : "calendar_skeleton"} calendar_event_title`}
                                    style={{
                                        top: "0px",
                                        padding: `${rowHeight * (4 / 33)}px ${rowHeight * (6 / 33)}px`,
                                        whiteSpace: "nowrap",
                                        display: "inline-block",
                                        left: "0px",
                                        maxWidth: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        right: "0px",
                                        verticalAlign: "top",
                                        position: "sticky",
                                        color: "white",
                                        cursor: resourcesDataIsLoaded ? "pointer" : "default"
                                    }}
                                    onClick={() => resourcesDataIsLoaded && onItemClick(event?.id)}
                                >
                                    <span>{event?.title?.split("-")[0]}</span>
                                    <span
                                        style={{
                                            verticalAlign: "middle"
                                        }}
                                    >
                                        {
                                            (eventStart.isBefore(startDate) && eventEnd.isAfter(endDate))
                                                ? <>
                                                    <ChevronLeftIcon
                                                        style={{
                                                            fontSize: `${rowHeight * (1.25 / 33) > 2 ? 2.25 : rowHeight * (1.25 / 33)}em`
                                                        }} />

                                                    <ChevronRightIcon
                                                        style={{
                                                            fontSize: `${rowHeight * (1.25 / 33) > 2 ? 2.25 : rowHeight * (1.25 / 33)}em`
                                                        }} />
                                                </>
                                                : eventStart.isBefore(startDate)
                                                    ? <ChevronLeftIcon
                                                        style={{
                                                            fontSize: `${rowHeight * (1.25 / 33) > 2 ? 2.25 : rowHeight * (1.25 / 33)}em`
                                                        }} />
                                                    : eventEnd.isAfter(endDate)
                                                        ? <ChevronRightIcon
                                                            style={{
                                                                fontSize: `${rowHeight * (1.25 / 33) > 2 ? 2.25 : rowHeight * (1.25 / 33)}em`
                                                            }} />
                                                        : ' - '
                                        }
                                    </span>
                                    <span>{`${event?.title?.split("-")[1]}-${event?.title?.split("-")[2]} - ${event?.title?.split("-")[3]}`}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    const renderResourceRow = (item: ResourceGroupType | ChildType, isChild: boolean) => {
        let height = rowHeight;
        if (item?.events?.length > 1) {
            height = item.events?.length * rowHeight;
        }

        const showAndHideChildren = (value: ResourceGroupType | ChildType) => {
            setFilterResourcesData(prevResourcesData =>
                prevResourcesData.map(resource =>
                    resource.id === value?.id
                        ? { ...resource, isChildHidden: !resource.isChildHidden }
                        : resource
                )
            );

            setResourcesData(prevResourcesData =>
                prevResourcesData.map(resource =>
                    resource.id === value?.id
                        ? { ...resource, isChildHidden: !resource.isChildHidden }
                        : resource
                )
            );
        }

        return (
            <tr
                onClick={() => !isChild && showAndHideChildren(item)}
            >
                <td
                    colSpan={1}
                    data-resource-id={item}
                    style={{
                        width: "100vw",
                        backgroundColor: isChild ? "transparent" : "#ECEFF1",
                        border: "1px solid #ddd",
                        borderTopWidth: "0px",
                        borderInlineWidth: "0px",
                        color: colorConfigs.topbar.bg,
                        cursor: isChild ? "move" : "pointer"
                    }}
                >
                    <div style={{ height: `calc(${height}px)` }}>
                        <div
                            style={{
                                overflow: "hidden",
                                padding: `${rowHeight * (6 / 33)}px`,
                                whiteSpace: "nowrap",
                                minWidth: "fit-content",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                fontSize: `${rowHeight * (1 / 33) > 2 ? 2 : rowHeight * (1 / 33)}em`
                            }}
                        >
                            {isChild
                                ? (
                                    <span style={{ paddingLeft: "16px" }}></span>
                                ) : (
                                    <span style={{ marginRight: "4px" }}>
                                        <ArrowDropDownIcon
                                            sx={{
                                                color: "black",
                                                rotate: item?.isChildHidden ? "-90deg" : "0deg",
                                                fontSize: `${rowHeight * (1.5 / 33) > 2 ? 2.5 : rowHeight * (1.5 / 33)}em`
                                            }}
                                        />
                                    </span>
                                )
                            }

                            <span>{item?.title}</span>
                        </div>
                    </div>
                </td>
            </tr>
        )
    }

    const renderEventRow = (item: ResourceGroupType | ChildType, isChild: boolean) => {
        let height = rowHeight;
        let eventContainerHeight = 0;
        if (item?.events?.length > 1) {
            height = item.events?.length * rowHeight;
            eventContainerHeight = item.events?.length * (rowHeight - 6);
        }

        return (
            <tr>
                <td
                    data-resource-id={item?.id}
                    style={{
                        border: "1px solid #ddd",
                        borderTopWidth: "0px",
                        borderInlineWidth: "0px",
                        backgroundColor: isChild ? "transparent" : "rgba(236, 239, 241, 0.5)",
                        color: colorConfigs.topbar.bg,
                        borderBottom: "1px solid #ddd",
                        position: "relative",
                        zIndex: 0
                    }}
                >
                    <div
                        style={{
                            height: `${height}px`,
                            position: "relative"
                        }}
                    >
                        <div className=""></div>

                        <div className="timeline_bg"
                            style={{
                                inset: 0,
                                position: "absolute",
                                zIndex: 0,
                                width: 0, //It is hide show timeslot row background
                            }}
                        ></div>

                        <div
                            style={{
                                height: `${eventContainerHeight}px`,
                                boxSizing: "content-box",
                                paddingBottom: "8px",
                                marginBottom: "2px",
                                position: "relative",
                                zIndex: 3,
                                width: "100"
                            }}
                        >
                            {
                                item?.events?.length > 0 &&
                                item.events.map((event: EventType[], index: number) => (
                                    event.length > 0 ? (
                                        event.map((e: EventType, i: number) => (
                                            <Fragment key={i}>
                                                {renderEvent(e, index)}
                                            </Fragment>
                                        ))
                                    ) : null
                                ))
                            }
                        </div>
                    </div>
                </td>
            </tr>
        )
    }

    const timeSlotBackground = (item: string) => {
        const date = moment(item);
        const isTodayItem = isToday(date);
        const isWeekendItem = isWeekend(date);
        const isCurrentHourItem = isCurrentHour(date);

        const getWeekendStyles = () => ({
            backgroundColor: TimelineType.TimelineTimeSlotType === timelineType
                ? isCurrentHourItem
                    ? "rgb(255, 254, 224, 0.5)"
                    : '#f3f3f5'
                : isTodayItem
                    ? "rgb(255, 254, 224, 0.5)"
                    : '#f3f3f5',
            background: TimelineType.TimelineTimeSlotType === timelineType
                ? isCurrentHourItem
                    ? 'repeating-linear-gradient(-45deg, #ffffff, #ffffff 2px, #FFFEE0 2px, #FFFEE0 4px)'
                    : 'repeating-linear-gradient(-45deg, #ffffff, #ffffff 2px, #f3f3f5 2px, #f3f3f5 4px)'
                : isTodayItem
                    ? 'repeating-linear-gradient(-45deg, #ffffff, #ffffff 2px, #FFFEE0 2px, #FFFEE0 4px)'
                    : 'repeating-linear-gradient(-45deg, #ffffff, #ffffff 2px, #f3f3f5 2px, #f3f3f5 4px)'
        });

        const getWeekdayStyles = () => ({
            backgroundColor: TimelineType.TimelineTimeSlotType === timelineType
                ? isCurrentHourItem
                    ? "rgb(255, 254, 224, 0.5)"
                    : "transparent"
                : isTodayItem
                    ? "rgb(255, 254, 224, 0.5)"
                    : "transparent"
        });

        return isWeekendItem ? getWeekendStyles() : getWeekdayStyles();
    }

    const renderTimeSlot = (item: string) => {
        return (
            <td
                colSpan={timeSlot?.noOfColumn / timeSlot?.slot?.length}
                data-date={item}
                style={{
                    borderLeftWidth: "0px !important",
                    borderBottom: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    ...timeSlotBackground(item)
                }}
            >
                <div>
                    <div></div>
                </div>
            </td>
        )
    }

    const divider = () => {
        return (
            <td
                style={{
                    border: "1px solid #ddd",
                    borderBlockWidth: "0px",
                    backgroundColor: "hsla(0, 0%, 82%, 0.3)",
                    cursor: "col-resize",
                    width: "6px",
                    height: "100%",
                }}
                data-index="0"
                //@ts-ignore
                onMouseDown={(e) => startResizing(e as MouseEvent)}
                //@ts-ignore
                onTouchStart={(e) => startResizing(e as TouchEvent)}
            ></td>
        )
    }

    const renderDateSlotFormat = (date: { monthName: string, days: number, date: moment.Moment }): string => {
        switch (views) {
            case Views.TimeSlotToday: return date?.monthName;
            case Views.TimeSlotWEEK: return date?.monthName;
            case Views.TimeSlotMONTH: return date?.monthName;
            case Views.TimeSlot3MONTH: return date?.monthName;
            case Views.TimeSlot6MONTH: return date?.monthName;
            case Views.TimeSlotYear: return date?.monthName;
            case Views.TimeSlotCustom: return date?.monthName;
            default: return date?.monthName;
        }
    }

    const renderTimeSlotFormat = (date: string): string => {
        switch (timelineType) {
            case TimelineType.TimelineTimeSlotType: return formatTime(moment(date));
            case TimelineType.TimelineDaySlotType: return convertWeek2DigitFormat(moment(date));
            default: return convertWeek2DigitFormat(moment(date));
        }
    }

    const printTheView = () => {
        if (timelineContainerRef.current) {
            const printWindow: Window | null = window.open('', '', 'height=600,width=800');
            if (printWindow) {
                printWindow.document.write(`
                    <!DOCTYPE html>
                    <html>
                    <head>
                      <title>RD Rental</title>
                      <style>
                        @media print {
                          @page {
                            size: landscape; /* Set to portrait if needed */
                            margin: 20mm; /* Adjust margin as needed */
                          }
                          body {
                            margin: 0;
                            font-family: Arial, sans-serif;
                          }
                          .container {
                            width: 100%;
                            box-sizing: border-box;
                          }
                          /* Ensure content fits the page */
                          .page-break {
                            page-break-before: always;
                          }
                          /* Prevent overflow */
                          * {
                            box-sizing: border-box;
                          }
                          .no-print {
                            display: none; /* Hide any elements not meant for printing */
                          }
                        }
                      </style>
                    </head>
                    <body>
                      <div class="container">
                        ${timelineContainerRef.current.innerHTML}
                      </div>
                    </body>
                    </html>
                  `);
                printWindow.document.write(timelineContainerRef.current.innerHTML);
                printWindow.document.write('</body></html>');
                printWindow.document.close();
                printWindow.focus();
                printWindow.print();

                setTimeout(() => {
                    printWindow.close();
                }, 100);
            }
        }
    }

    useEffect(() => {
        print(() => printTheView);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            ref={timelineContainerRef}
            className="timeline_main"
            style={{
                minHeight: "467.667px",
                height: `calc(100vh - ${timeLineHeight}px)`,
                position: "relative",
                flexGrow: 1
            }}
        >
            <div
                className="timeline_main_container"
                style={{
                    position: "absolute",
                    inset: 0
                }}>
                <table
                    className="timeline_scrollgrid"
                    style={{
                        height: "100%",
                        borderCollapse: "separate",
                        borderSpacing: 0,
                        fontSize: `${rowHeight * (1 / 33)}em`
                    }}
                    ref={tableRef}
                >
                    <colgroup>
                        <col style={{ width: "300px" }} />
                        <col />
                        <col />
                    </colgroup>

                    <thead>
                        <tr className="timeline_toolbar">
                            <th className="timeline_toolbar_resources">
                                <div>
                                    <div
                                        style={{
                                            overflow: "hidden"
                                        }}
                                    >

                                        <table
                                            style={{
                                                width: `${newWidth}px`,
                                                borderInlineWidth: "0px",
                                                borderTopWidth: "0px"
                                            }}
                                        >
                                            <colgroup>
                                                <col />
                                            </colgroup>

                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div
                                                            ref={columnHeaderRef}
                                                            style={{
                                                                height: "51px",
                                                                display: "flex",
                                                                justifyContent: "flex-start",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        border: "none",
                                                                        outline: "none",
                                                                        paddingInline: "8px"
                                                                    }}
                                                                    placeholder="Search..."
                                                                    value={searchValue}
                                                                    onChange={handleTheResourceSearch}
                                                                />
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>

                                        </table>
                                    </div>
                                </div>
                            </th>

                            {divider()}

                            <th
                                style={{
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                    scrollbarGutter: "stable"
                                }}
                            >
                                <div>
                                    <div
                                        ref={topbarRef}
                                        style={{
                                            overflow: "auto",
                                            position: "relative",
                                            scrollbarWidth: "none"
                                        }}
                                    >
                                        <div>
                                            <table
                                                style={{
                                                    border: "none",
                                                    minWidth: `${Views.TimeSlotWEEK === views ? "100%" : `${timeSlot?.noOfColumn * minWidthOfColumn}px`}`,
                                                    width: `${timeSlotContainerWidth}px`,
                                                }}
                                            >
                                                <colgroup>
                                                    {
                                                        new Array(timeSlot?.noOfColumn)?.fill(null)?.map((_: any, index: number) => (
                                                            <col key={index} style={{ minWidth: `${minWidthOfColumn}${Views.TimeSlotWEEK === views ? "%" : "px"}` }} />
                                                        ))
                                                    }
                                                </colgroup>

                                                <tbody
                                                    style={{
                                                        color: colorConfigs.topbar.bg
                                                    }}
                                                >
                                                    <tr
                                                        ref={dateSlotRef}
                                                        className="timeline_toolbar_date_header">
                                                        {
                                                            dateSlot?.map((item: { monthName: string, days: number, date: moment.Moment }, index: number) => (
                                                                <th
                                                                    key={index}
                                                                    colSpan={item?.days}
                                                                    data-date={item}
                                                                    style={{
                                                                        border: "1px solid #ddd",
                                                                        borderTopWidth: "0px",
                                                                        borderRightWidth: "0px",
                                                                        borderLeftWidth: index === 0 ? "0px" : "1px",
                                                                        backgroundColor: isCurrentMonth(item?.date) ? "rgb(255, 254, 224, 0.5)" : "transparent"
                                                                    }}
                                                                >
                                                                    <div style={{
                                                                        height: "25px",
                                                                        overflow: "hidden",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center"
                                                                    }}>
                                                                        <span>
                                                                            {renderDateSlotFormat(item)}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                            ))
                                                        }
                                                    </tr>

                                                    <tr className="timeline_toolbar_time_header">
                                                        {
                                                            timeSlot?.slot?.map((item: any, index: number) => (
                                                                (index % 2 === 0 || TimelineType.TimelineTimeSlotType !== timelineType) &&
                                                                <th
                                                                    key={index}
                                                                    colSpan={TimelineType.TimelineTimeSlotType === timelineType ? (timeSlot?.noOfColumn / timeSlot?.slot?.length) * 2 : timeSlot?.noOfColumn / timeSlot?.slot?.length}
                                                                    data-date={item}
                                                                    style={{
                                                                        border: "1px solid #ddd",
                                                                        borderRightWidth: "0px",
                                                                        borderLeftWidth: index === 0 ? "0px" : "1px",
                                                                        ...timeSlotBackground(item)
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            height: "25px",
                                                                            overflow: "hidden",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center"
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {renderTimeSlotFormat(item)}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                            ))
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>

                    {groupsIsLoaded && resourcesIsLoaded
                        ? <tbody>
                            <tr>
                                <td>
                                    <div
                                        style={{
                                            height: "100%",
                                            overflow: "hidden",
                                            position: "relative"
                                        }}
                                    >
                                        <div
                                            ref={resourcesRef}
                                            className="calendar-scrollbar"
                                            style={{
                                                inset: 0,
                                                position: "absolute",
                                                overflowY: "hidden",
                                                overflowX: "auto",
                                                scrollbarGutter: "stable"
                                            }}
                                        >
                                            <table
                                                style={{
                                                    minWidth: `${newWidth}px`,
                                                    borderStyle: "hidden"
                                                }}
                                            >
                                                <colgroup>
                                                    <col />
                                                </colgroup>

                                                <tbody>
                                                    {
                                                        filterResourcesData?.map((item, index) => (
                                                            <Fragment key={index}>
                                                                {renderResourceRow(item, false)}

                                                                {
                                                                    !item?.isChildHidden
                                                                    && (
                                                                        item?.children?.length
                                                                            ? item?.children?.map((childItem, childIndex) => (
                                                                                <Fragment key={childIndex}>
                                                                                    {renderResourceRow(childItem, true)}
                                                                                </Fragment>
                                                                            ))
                                                                            : null
                                                                    )
                                                                }
                                                            </Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </td>

                                {divider()}

                                <td>
                                    <div
                                        style={{
                                            height: "100%",
                                            overflow: "hidden",
                                            position: 'relative'
                                        }}>
                                        <div
                                            ref={contentRef}
                                            className="calendar-scrollbar"
                                            style={{
                                                overflow: "auto",
                                                cursor: "move",
                                                inset: 0,
                                                position: "absolute",
                                                scrollbarGutter: "stable"
                                            }}>
                                            <div
                                                style={{
                                                    minWidth: `${Views.TimeSlotWEEK === views ? "100%" : `${timeSlot?.noOfColumn * minWidthOfColumn}px`}`,
                                                    minHeight: "100%",
                                                    position: "relative",
                                                    zIndex: 0
                                                }}>

                                                <div
                                                    style={{
                                                        bottom: "0px",
                                                        position: "absolute",
                                                        top: "0px",
                                                        zIndex: -2
                                                    }}
                                                >
                                                    <table style={{
                                                        borderInlineWidth: "0px",
                                                        borderTopWidth: "0px",
                                                        height: "100%",
                                                        minWidth: `${Views.TimeSlotWEEK === views ? "100%" : `${timeSlot?.noOfColumn * minWidthOfColumn}px`}`,
                                                        width: `${timeSlotContainerWidth}px`
                                                    }}>

                                                        <colgroup>
                                                            {
                                                                new Array(timeSlot?.noOfColumn)?.fill(null)?.map((_: any, index: number) => (
                                                                    <col key={index} style={{ minWidth: `${minWidthOfColumn}${Views.TimeSlotWEEK === views ? "%" : "px"}` }} />
                                                                ))
                                                            }
                                                        </colgroup>

                                                        <tbody>
                                                            <tr>
                                                                {
                                                                    timeSlot?.slot?.map((item: any, index: number) => (
                                                                        <Fragment key={index}>
                                                                            {renderTimeSlot(item)}
                                                                        </Fragment>
                                                                    ))
                                                                }
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div
                                                    className="timeline_background"
                                                    style={{
                                                        position: "absolute",
                                                        inset: 0,
                                                        zIndex: 0
                                                    }}
                                                ></div>

                                                <table style={{
                                                    border: "none",
                                                    minWidth: `${Views.TimeSlotWEEK === views ? "100%" : `${timeSlot?.noOfColumn * minWidthOfColumn}px`}`,
                                                    width: `${timeSlotContainerWidth}px`
                                                }}>
                                                    <tbody>
                                                        {
                                                            filterResourcesData?.map((item, index) => (
                                                                <Fragment key={index}>
                                                                    {renderEventRow(item, false)}

                                                                    {
                                                                        !item?.isChildHidden
                                                                        && (
                                                                            item?.children?.length
                                                                                ? item?.children?.map((childItem, childIndex) => (
                                                                                    <Fragment key={childIndex}>
                                                                                        {renderEventRow(childItem, true)}
                                                                                    </Fragment>
                                                                                ))
                                                                                : null
                                                                        )
                                                                    }
                                                                </Fragment>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                        :
                        <tbody>
                            <tr>
                                <td
                                    colSpan={3}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        position: "relative",
                                    }}
                                >
                                    <div className="calendar-loader"></div>
                                </td>
                            </tr>
                        </tbody>
                    }
                </table>
            </div>
        </div>
    )
}

export default Timeliner
