import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

type AddRelationProps = {
  handleAllRelationShowClick: () => void;
  showPopup: boolean;
};

const vehicleOptions = [
  { value: "Ford F-150-V23-SNW0770", label: "Ford F-150-V23-SNW0770" },
  {
    value: "Ford Transit 15 Pass Mr-V15FM-PGP7020",
    label: "Ford Transit 15 Pass Mr-V15FM-PGP7020",
  },
];

export const AddRelation = (props: AddRelationProps) => {
  const [vehicle, setVehicle] = useState<string>("");

  return (
    <Dialog
      open={props.showPopup}
      maxWidth="md"
      PaperProps={{
        style: {
          width: "35%",
          marginTop: "-240px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", backgroundColor: "#f5f5f5" }}>
        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <p style={{ fontSize: "18px", marginLeft: "5px" }}>Add Relation</p>
          <CloseIcon
            sx={{ fontSize: "14px", marginRight: "5px" }}
            onClick={

              props.handleAllRelationShowClick
            }
          />
        </div>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: "16px",
          }}
        >
          <div style={{ width: "50%", marginRight: "16px" }}>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>First Name</p>
            <p style={{ fontSize: "12px" }}>First Name</p>

            <div>
              <label style={{ fontSize: "12px" }}>
                <input
                  type="radio"
                  value="Contact"
                  name="gender"
                  style={{ fontSize: "12px" }}
                />{" "}
                Contact
              </label>
              <label style={{ fontSize: "12px" }}>
                <input
                  type="radio"
                  value="Sheet"
                  name="gender"
                  style={{ fontSize: "12px" }}
                />{" "}
                Sheet
              </label>
            </div>

            <label
              htmlFor="vehicle"
              style={{ fontSize: "12px", fontStyle: "bold" }}
            >
              Contact
              <br />
              <Select
                styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={false}
                name="vehicle"
                value={vehicleOptions.find((item) => item.value === vehicle)}
                onChange={(vehicle: any) =>
                  setVehicle(vehicle ? vehicle.value : "")
                }
                options={vehicleOptions}
              />
            </label>

            <p style={{ fontSize: "12px", color: "blue", marginLeft: "10px" }}>
              Add new Contact
            </p>
          </div>
          <div style={{ width: "50%", marginLeft: "16px" }}>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>Last Name</p>
            <p style={{ fontSize: "12px" }}>Last Name</p>

            <div style={{ visibility: "hidden" }}>
              <label style={{ fontSize: "12px" }}>
                <input
                  type="radio"
                  value="Contact"
                  name="gender"
                  style={{ fontSize: "12px" }}
                />{" "}
                Contact
              </label>
              <label style={{ fontSize: "12px" }}>
                <input
                  type="radio"
                  value="Sheet"
                  name="gender"
                  style={{ fontSize: "12px" }}
                />{" "}
                Sheet
              </label>
            </div>

            <label
              htmlFor="vehicle"
              style={{ fontSize: "12px", fontStyle: "bold" }}
            >
              Relation
              <br />
              <Select
                styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                className="basic-single"
                classNamePrefix="select"
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={false}
                name="vehicle"
                value={vehicleOptions.find((item) => item.value === vehicle)}
                onChange={(vehicle: any) =>
                  setVehicle(vehicle ? vehicle.value : "")
                }
                options={vehicleOptions}
              />
            </label>
          </div>
        </div>

        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#f5f5f5",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </DialogContent>
    </Dialog>
  );
};
