import { Button, Dialog, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../../../configs/colorConfigs";
import Select, { components } from "react-select";
import { getFleetSettings, put_FleetSettings } from "../../../../services/fleetSettings";

const menuValues = [
    {
        value: "alternatePlane1",
        label: "Alternate Plane",
        icon: "https://cdn.iconscout.com/icon/free/png-512/free-plane-167-474991.png",
    },
    {
        value: "alternatePlane2",
        label: "Alternate Plane",
        icon: "https://cdn.iconscout.com/icon/free/png-512/free-plane-1440142-1214643.png",
    },
    {
        value: "mailBulk",
        label: "Mail Bulk",
        icon: "https://cdn.iconscout.com/icon/premium/png-512-thumb/bulk-mail-8106087-6606997.png",
    },
    {
        value: "alternateCar",
        label: "Alternate Car",
        icon: "https://cdn.iconscout.com/icon/free/png-512/free-car-1780538-1517628.png",
    },
    {
        value: "location",
        label: "Location",
        icon: "https://cdn.iconscout.com/icon/free/png-512/free-gps-fixed-1779970-1518174.png",
    },
    {
        value: "addressCard",
        label: "Address Card",
        icon: "https://cdn.iconscout.com/icon/premium/png-512-thumb/address-card-2240304-1867652.png",
    },
];

const Option = (props: any) => (
    <components.Option {...props} className="top-bar-icon-option">
        <img src={props.data.icon} alt="logo" className="top-bar-icon-logo" />
        {props.data.label}
    </components.Option>
);

export const FleetGeneralSettings = () => {
    const [id, setId] = useState<string>("");
    const [formsForVehicleParts, setFormsForVehicleParts] = useState<string>("");
    const [vehicleFormUniqueFields, setVehicleFormUniqueFields] = useState<string>("");
    const [vehicleLabel, setVehicleLabel] = useState<string>("");
    const [defaultVehicleSortBy, setDefaultVehicleSortBy] = useState<string>("A0");
    const [defaultVehicleSortOrder, setDefaultVehicleSortOrder] = useState<string>("A0");
    const [hideVehilceYear, setHideVehilceYear] = useState<boolean>(true);
    const [hideVehicleColor, setHideVehicleColor] = useState<boolean>(true);
    const [hideVehicleMakeAndModel, setHideVehicleMakeAndModel] = useState<boolean>(true);
    const [hideVehicleKey, setHideVehicleKey] = useState<boolean>(true);
    const [useRegistrationTracking, setUseRegistrationTracking] = useState<boolean>(true);
    const [enableLicensePlateValidation, setEnableLicensePlateValidation] = useState<boolean>(true);
    const [enableAccountingIntegration, setEnableAccountingIntegration] = useState<boolean>(true);
    const [vehilceClassCanBeChangedIfTheVehicleHasReservations, setVehilceClassCanBeChangedIfTheVehicleHasReservations] = useState<boolean>(true);
    const [enableMultipleClassesPerVehicle, setEnableMultipleClassesPerVehicle] = useState<boolean>(true);
    const [allowLocationsFromOtherBranchesInVehicleCurrentLocation, setAllowLocationsFromOtherBranchesInVehicleCurrentLocation] = useState<boolean>(true);
    const [enableVehicleLicensePlateExpiration, setEnableVehicleLicensePlateExpiration] = useState<boolean>(true);
    const [autoIncrementVehicleKey, setAutoIncrementVehicleKey] = useState<boolean>(true);
    const [enableFuelKey, setEnableFuelKey] = useState<boolean>(true);
    const [maxFuelLevel, setMaxFuelLevel] = useState<string>("");
    const [enableVehicleLicensePlateState, setEnableVehicleLicensePlateState] = useState<boolean>(true);
    const [enable17CharactersValidationForVin, setEnable17CharactersValidationForVin] = useState<boolean>(true);
    const [useVehicleDamagesFeature, setUseVehicleDamagesFeature] = useState<boolean>(true);
    const [useVehicleDamagesRegistration, setUseVehicleDamagesRegistration] = useState<boolean>(true);
    const [useAdvancedVehicleMapImage, setUseAdvancedVehicleMapImage] = useState<boolean>(true);
    const [paymentTypes, setPaymentTypes] = useState<string>("");
    const [contactCategoryForVendorExpenses, setContactCategoryForVendorExpenses] = useState<string>("A0");
    const [createExpenseForVehiclePurchase, setCreateExpenseForVehiclePurchase] = useState<boolean>(true);
    const [expenseTypeForVehiclePurchase, setExpenseTypeForVehiclePurchase] = useState<string>("A0");
    const [enableOptionToPrintVehicleTags, setEnableOptionToPrintVehicleTags] = useState<boolean>(true);
    const [fieldForPurchasePrice, setFieldForPurchasePrice] = useState<string>("A0");
    const [fieldForRestValue, setFieldForRestValue] = useState<string>("A0");
    const [fieldForPurchaseDate, setFieldForPurchaseDate] = useState<string>("A0");
    const [fieldForDistanceAtPurchase, setFieldForDistanceAtPurchase] = useState<string>("A0");
    const [fieldForDepreciatonTime, setFieldForDepreciatonTime] = useState<string>("A0");
    const [defaultDepreciatonTimeInMonths, setDefaultDepreciatonTimeInMonths] = useState<string>("");

    const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

    const [menuBarIcon, setMenuBarIcon] = useState<any>();
    const [menuTopBarIcon, setMenuTopBarIcon] = useState<string>("");

    const handleMenuChange = (value: any) => {
        setMenuBarIcon(value);
        setMenuTopBarIcon(value.value);
    };

    const SingleMenuValue = ({ children, ...props }: any) => (
        <components.SingleValue {...props}>
            <img src={menuBarIcon?.icon} alt="s-logo" className="selected-logo" />
            {children}
        </components.SingleValue>
    );

    const [submenuBarIcon, setSubmenuBarIcon] = useState<any>();
    const [submenuTopBarIcon, setSubmenuTopBarIcon] = useState<string>("");
    
    const handleSubMenuChange = (value: any) => {
        setSubmenuBarIcon(value);
        setSubmenuTopBarIcon(value.value);
    };

   const SingleSubMenuValue = ({ children, ...props }: any) => (
       <components.SingleValue {...props}>
           <img src={submenuBarIcon?.icon} alt="s-logo" className="selected-logo" />
           {children}
       </components.SingleValue>
   );

    useEffect(() => {
        getFleetSettings()
            .then(data => {
                setId(data[0].id);
                setMenuTopBarIcon(data[0].menuIcon);
                setMenuBarIcon(menuValues.find((item: any) => item.value === data[0].menuIcon));
                setMenuTopBarIcon(data[0].submenuIcon);
                setSubmenuBarIcon(menuValues.find((item: any) => item.value === data[0].submenuVehiclesIcon));
                setFormsForVehicleParts(data[0].formsForVehicleParts);
                setVehicleFormUniqueFields(data[0].vehicleFormUniqueFields);
                setVehicleLabel(data[0].vehicleLabel);
                setDefaultVehicleSortBy(data[0].defaultVehicleSortBy);
                setDefaultVehicleSortOrder(data[0].defaultVehicleSortOrder);
                setHideVehilceYear(data[0].hideVehilceYear);
                setHideVehicleColor(data[0].hideVehicleColor);
                setHideVehicleMakeAndModel(data[0].hideVehicleMakeAndModel);
                setHideVehicleKey(data[0].hideVehicleKey);
                setUseRegistrationTracking(data[0].useRegistrationTracking);
                setEnableLicensePlateValidation(data[0].enableLicensePlateValidation);
                setEnableAccountingIntegration(data[0].enableAccountingIntegration);
                setVehilceClassCanBeChangedIfTheVehicleHasReservations(data[0].vehilceClassCanBeChangedIfTheVehicleHasReservations);
                setEnableMultipleClassesPerVehicle(data[0].enableMultipleClassesPerVehicle);
                setAllowLocationsFromOtherBranchesInVehicleCurrentLocation(data[0].allowLocationsFromOtherBranchesInVehicleCurrentLocation);
                setEnableVehicleLicensePlateExpiration(data[0].enableVehicleLicensePlateExpiration);
                setAutoIncrementVehicleKey(data[0].autoIncrementVehicleKey);
                setEnableFuelKey(data[0].enableFuelKey);
                setMaxFuelLevel(data[0].maxFuelLevel);
                setEnableVehicleLicensePlateState(data[0].enableVehicleLicensePlateState);
                setEnable17CharactersValidationForVin(data[0].enable17CharactersValidationForVin);
                setUseVehicleDamagesFeature(data[0].useVehicleDamagesFeature);
                setUseVehicleDamagesRegistration(data[0].useVehicleDamagesRegistration);
                setUseAdvancedVehicleMapImage(data[0].useAdvancedVehicleMapImage);
                setPaymentTypes(data[0].paymentTypes);
                setContactCategoryForVendorExpenses(data[0].contactCategoryForVendorExpenses);
                setCreateExpenseForVehiclePurchase(data[0].createExpenseForVehiclePurchase);
                setExpenseTypeForVehiclePurchase(data[0].expenseTypeForVehiclePurchase);
                setEnableOptionToPrintVehicleTags(data[0].enableOptionToPrintVehicleTags);
                setFieldForPurchasePrice(data[0].fieldForPurchasePrice);
                setFieldForRestValue(data[0].fieldForRestValue);
                setFieldForPurchaseDate(data[0].fieldForPurchaseDate);
                setFieldForDistanceAtPurchase(data[0].fieldForDistanceAtPurchase);
                setFieldForDepreciatonTime(data[0].fieldForDepreciatonTime);
                setDefaultDepreciatonTimeInMonths(data[0].defaultDepreciatonTimeInMonths);

                setPleaseWaitPopup(false);
            })
            .catch(error => console.error("Error:", error));
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setPleaseWaitPopup(true);
        put_FleetSettings(
            JSON.stringify({
                id,
                menuIcon: menuTopBarIcon,
                submenuVehiclesIcon: submenuTopBarIcon,
                formsForVehicleParts,
                vehicleFormUniqueFields,
                vehicleLabel,
                defaultVehicleSortBy,
                defaultVehicleSortOrder,
                hideVehilceYear,
                hideVehicleColor,
                hideVehicleMakeAndModel,
                hideVehicleKey,
                useRegistrationTracking,
                enableLicensePlateValidation,
                enableAccountingIntegration,
                vehilceClassCanBeChangedIfTheVehicleHasReservations,
                enableMultipleClassesPerVehicle,
                allowLocationsFromOtherBranchesInVehicleCurrentLocation,
                enableVehicleLicensePlateExpiration,
                autoIncrementVehicleKey,
                enableFuelKey,
                maxFuelLevel,
                enableVehicleLicensePlateState,
                enable17CharactersValidationForVin,
                useVehicleDamagesFeature,
                useVehicleDamagesRegistration,
                useAdvancedVehicleMapImage,
                paymentTypes,
                contactCategoryForVendorExpenses,
                createExpenseForVehiclePurchase,
                expenseTypeForVehiclePurchase,
                enableOptionToPrintVehicleTags,
                fieldForPurchasePrice,
                fieldForRestValue,
                fieldForPurchaseDate,
                fieldForDistanceAtPurchase,
                fieldForDepreciatonTime,
                defaultDepreciatonTimeInMonths
            })
        )
            .then(data => setPleaseWaitPopup(false))
            .catch(error => console.error("Error:", error));
    };

    return (
        <div>
            <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    padding: "24px 26px",
                }}
            >
                <div
                    style={{
                        width: "85%",
                        marginRight: "3%",
                    }}
                >
                    <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
                        General Settings
                    </h5>
                    <hr />

                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "50%",
                                    marginRight: "3%",
                                    paddingLeft: "16px",
                                }}
                            >
                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="menuIcon"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Menu Icon
                                        <br />
                                        <Select
                                        name="menu"
                                            value={menuBarIcon}
                                            options={menuValues}
                                            onChange={handleMenuChange}
                                            styles={{
                                                singleValue: (base) => ({
                                                    ...base,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "14px",
                                                }),
                                            }}
                                            components={{
                                                Option,
                                                SingleValue: SingleMenuValue,
                                            }}
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="formsForVehicleParts"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Forms For Vehicle Parts
                                        <br />
                                        <input
                                            type="text"
                                            name="formsForVehicleParts"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={formsForVehicleParts}
                                            onChange={(e) =>
                                                setFormsForVehicleParts(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="vehicleLabel"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Vehicle Label
                                        <br />
                                        <input
                                            type="text"
                                            name="vehicleLabel"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={vehicleLabel}
                                            onChange={(e) =>
                                                setVehicleLabel(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Hide Vehicle Year
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="hideVehilceYear"
                                            style={{ fontSize: "12px" }}
                                            checked={hideVehilceYear ? true : false}
                                            onChange={(e) =>
                                                setHideVehilceYear(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="hideVehilceYear"
                                            style={{ fontSize: "12px" }}
                                            checked={hideVehilceYear ? false : true}
                                            onChange={(e) =>
                                                setHideVehilceYear(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Hide Vehicle Make and Model
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="hideVehicleMakeAndModel"
                                            style={{ fontSize: "12px" }}
                                            checked={hideVehicleMakeAndModel ? true : false}
                                            onChange={(e) =>
                                                setHideVehicleMakeAndModel(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="hideVehicleMakeAndModel"
                                            style={{ fontSize: "12px" }}
                                            checked={hideVehicleMakeAndModel ? false : true}
                                            onChange={(e) =>
                                                setHideVehicleMakeAndModel(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Use Registration Tracking?
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="useRegistrationTracking"
                                            style={{ fontSize: "12px" }}
                                            checked={useRegistrationTracking ? true : false}
                                            onChange={(e) =>
                                                setUseRegistrationTracking(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="useRegistrationTracking"
                                            style={{ fontSize: "12px" }}
                                            checked={useRegistrationTracking ? false : true}
                                            onChange={(e) =>
                                                setUseRegistrationTracking(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Enable Accounting Integration
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="enableAccountingIntegration"
                                            style={{ fontSize: "12px" }}
                                            checked={enableAccountingIntegration ? true : false}
                                            onChange={(e) =>
                                                setEnableAccountingIntegration(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="enableAccountingIntegration"
                                            style={{ fontSize: "12px" }}
                                            checked={enableAccountingIntegration ? false : true}
                                            onChange={(e) =>
                                                setEnableAccountingIntegration(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Vehicle Class Can be Changed if the Vehicle Has Reservations?
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="vehilceClassCanBeChangedIfTheVehicleHasReservations"
                                            style={{ fontSize: "12px" }}
                                            checked={vehilceClassCanBeChangedIfTheVehicleHasReservations ? true : false}
                                            onChange={(e) =>
                                                setVehilceClassCanBeChangedIfTheVehicleHasReservations(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="vehilceClassCanBeChangedIfTheVehicleHasReservations"
                                            style={{ fontSize: "12px" }}
                                            checked={vehilceClassCanBeChangedIfTheVehicleHasReservations ? false : true}
                                            onChange={(e) =>
                                                setVehilceClassCanBeChangedIfTheVehicleHasReservations(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Allow Location From Other Branches in Vehicle Current Location
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="allowLocationsFromOtherBranchesInVehicleCurrentLocation"
                                            style={{ fontSize: "12px" }}
                                            checked={allowLocationsFromOtherBranchesInVehicleCurrentLocation ? true : false}
                                            onChange={(e) =>
                                                setAllowLocationsFromOtherBranchesInVehicleCurrentLocation(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="allowLocationsFromOtherBranchesInVehicleCurrentLocation"
                                            style={{ fontSize: "12px" }}
                                            checked={allowLocationsFromOtherBranchesInVehicleCurrentLocation ? false : true}
                                            onChange={(e) =>
                                                setAllowLocationsFromOtherBranchesInVehicleCurrentLocation(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Autoincreament Vehicle key
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="autoIncrementVehicleKey"
                                            style={{ fontSize: "12px" }}
                                            checked={autoIncrementVehicleKey ? true : false}
                                            onChange={(e) =>
                                                setAutoIncrementVehicleKey(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="autoIncrementVehicleKey"
                                            style={{ fontSize: "12px" }}
                                            checked={autoIncrementVehicleKey ? false : true}
                                            onChange={(e) =>
                                                setAutoIncrementVehicleKey(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="maxFuelLevel"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Max Fuel Level
                                        <br />
                                        <input
                                            type="number"
                                            name="maxFuelLevel"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={maxFuelLevel}
                                            onChange={(e) =>
                                                setMaxFuelLevel(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                            </div>

                            <div
                                style={{
                                    width: "50%",
                                }}
                            >
                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="submenuVehiclesIcon"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Submenu Vehicles Icon
                                        <br />
                                        <Select
                                         name="menu"
                                            value={submenuBarIcon}
                                            options={menuValues}
                                            onChange={handleSubMenuChange}
                                            styles={{
                                                singleValue: (base) => ({
                                                    ...base,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "14px",
                                                }),
                                            }}
                                            components={{
                                                Option,
                                                SingleValue: SingleSubMenuValue,
                                            }}
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="vehicleFormUniqueFields"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Vehicle Form Unique Fields
                                        <br />
                                        <input
                                            type="text"
                                            name="vehicleFormUniqueFields"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={vehicleFormUniqueFields}
                                            onChange={(e) =>
                                                setVehicleFormUniqueFields(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "10px",
                                        marginRight: "3%"
                                    }}>
                                    <div
                                        style={{
                                            width: "50%",
                                            marginRight: "3%"
                                        }}
                                    >
                                        <label
                                            htmlFor="defaultVehicleSortBy"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Default Vehicle Sort By
                                            <br />
                                            <select
                                                name="defaultVehicleSortBy"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={defaultVehicleSortBy}
                                                onChange={(e) => setDefaultVehicleSortBy(e.target.value)}
                                            >
                                                <option value="Id">Id</option>
                                                <option value="Name">Name</option>
                                                <option value="Vin">Vin</option>
                                                <option value="VehicleKey">Vehicle Key</option>
                                                <option value="LicensePlate">License Plate</option>
                                            </select>
                                        </label>
                                    </div>

                                    <div
                                        style={{
                                            width: "50%"
                                        }}
                                    >
                                        <label
                                            htmlFor="defaultVehicleSortOrder"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Default Vehicle Sort Order
                                            <br />
                                            <select
                                                name="defaultVehicleSortOrder"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={defaultVehicleSortOrder}
                                                onChange={(e) => setDefaultVehicleSortOrder(e.target.value)}
                                            >
                                                <option value="Ascending">Ascending</option>
                                                <option value="Descending">Descending</option>

                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Hide Vehicle Color
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="hideVehicleColor"
                                            style={{ fontSize: "12px" }}
                                            checked={hideVehicleColor ? true : false}
                                            onChange={(e) =>
                                                setHideVehicleColor(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="hideVehicleColor"
                                            style={{ fontSize: "12px" }}
                                            checked={hideVehicleColor ? false : true}
                                            onChange={(e) =>
                                                setHideVehicleColor(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Hide Vehicle Key
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="hideVehicleKey"
                                            style={{ fontSize: "12px" }}
                                            checked={hideVehicleKey ? true : false}
                                            onChange={(e) =>
                                                setHideVehicleKey(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="hideVehicleKey"
                                            style={{ fontSize: "12px" }}
                                            checked={hideVehicleKey ? false : true}
                                            onChange={(e) =>
                                                setHideVehicleKey(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Enable License Plate Validation
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="enableLicensePlateValidation"
                                            style={{ fontSize: "12px" }}
                                            checked={enableLicensePlateValidation ? true : false}
                                            onChange={(e) =>
                                                setEnableLicensePlateValidation(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="enableLicensePlateValidation"
                                            style={{ fontSize: "12px" }}
                                            checked={enableLicensePlateValidation ? false : true}
                                            onChange={(e) =>
                                                setEnableLicensePlateValidation(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px", visibility: "hidden" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Send Failed Transaction Email
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name=""
                                            style={{ fontSize: "12px" }}
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name=""
                                            style={{ fontSize: "12px" }}
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Enable Multiple Classes Per Vehicle
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="enableMultipleClassesPerVehicle"
                                            style={{ fontSize: "12px" }}
                                            checked={enableMultipleClassesPerVehicle ? true : false}
                                            onChange={(e) =>
                                                setEnableMultipleClassesPerVehicle(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="enableMultipleClassesPerVehicle"
                                            style={{ fontSize: "12px" }}
                                            checked={enableMultipleClassesPerVehicle ? false : true}
                                            onChange={(e) =>
                                                setEnableMultipleClassesPerVehicle(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Enable Vehicle License Plate Expiration
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="enableVehicleLicensePlateExpiration"
                                            style={{ fontSize: "12px" }}
                                            checked={enableVehicleLicensePlateExpiration ? true : false}
                                            onChange={(e) =>
                                                setEnableVehicleLicensePlateExpiration(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="enableVehicleLicensePlateExpiration"
                                            style={{ fontSize: "12px" }}
                                            checked={enableVehicleLicensePlateExpiration ? false : true}
                                            onChange={(e) =>
                                                setEnableVehicleLicensePlateExpiration(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Enable Fuel Key
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="enableFuelKey"
                                            style={{ fontSize: "12px" }}
                                            checked={enableFuelKey ? true : false}
                                            onChange={(e) =>
                                                setEnableFuelKey(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="enableFuelKey"
                                            style={{ fontSize: "12px" }}
                                            checked={enableFuelKey ? false : true}
                                            onChange={(e) =>
                                                setEnableFuelKey(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Enable Vehicle License Plate State
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="enableVehicleLicensePlateState"
                                            style={{ fontSize: "12px" }}
                                            checked={enableVehicleLicensePlateState ? true : false}
                                            onChange={(e) =>
                                                setEnableVehicleLicensePlateState(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="enableVehicleLicensePlateState"
                                            style={{ fontSize: "12px" }}
                                            checked={enableVehicleLicensePlateState ? false : true}
                                            onChange={(e) =>
                                                setEnableVehicleLicensePlateState(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Enable 17 Characters Validation For Vin
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="enable17CharactersValidationForVin"
                                            style={{ fontSize: "12px" }}
                                            checked={enable17CharactersValidationForVin ? true : false}
                                            onChange={(e) =>
                                                setEnable17CharactersValidationForVin(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="enable17CharactersValidationForVin"
                                            style={{ fontSize: "12px" }}
                                            checked={enable17CharactersValidationForVin ? false : true}
                                            onChange={(e) =>
                                                setEnable17CharactersValidationForVin(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                            </div>
                        </div>

                        <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                            Vehicle Damages
                        </h5>
                        <hr />

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "50%",
                                    marginRight: "3%",
                                    paddingLeft: "16px",
                                }}
                            >

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Use Vehicle Damages Feature
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="useVehicleDamagesFeature"
                                            style={{ fontSize: "12px" }}
                                            checked={useVehicleDamagesFeature ? true : false}
                                            onChange={(e) =>
                                                setUseVehicleDamagesFeature(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="useVehicleDamagesFeature"
                                            style={{ fontSize: "12px" }}
                                            checked={useVehicleDamagesFeature ? false : true}
                                            onChange={(e) =>
                                                setUseVehicleDamagesFeature(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Use Advanced Vehicle Map Image
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="useAdvancedVehicleMapImage"
                                            style={{ fontSize: "12px" }}
                                            checked={useAdvancedVehicleMapImage ? true : false}
                                            onChange={(e) =>
                                                setUseAdvancedVehicleMapImage(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="useAdvancedVehicleMapImage"
                                            style={{ fontSize: "12px" }}
                                            checked={useAdvancedVehicleMapImage ? false : true}
                                            onChange={(e) =>
                                                setUseAdvancedVehicleMapImage(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                            </div>

                            <div
                                style={{
                                    width: "50%",
                                }}
                            >
                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Use vehicle Damages Registration(v2)
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="useVehicleDamagesRegistration"
                                            style={{ fontSize: "12px" }}
                                            checked={useVehicleDamagesRegistration ? true : false}
                                            onChange={(e) =>
                                                setUseVehicleDamagesRegistration(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="useVehicleDamagesRegistration"
                                            style={{ fontSize: "12px" }}
                                            checked={useVehicleDamagesRegistration ? false : true}
                                            onChange={(e) =>
                                                setUseVehicleDamagesRegistration(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                            Expenses
                        </h5>
                        <hr />

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "50%",
                                    marginRight: "3%",
                                    paddingLeft: "16px",
                                }}
                            >

                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="paymentTypes"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Payment Types
                                        <br />
                                        <textarea

                                            name="paymentTypes"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                                resize: "none",
                                                height: "80px"
                                            }}
                                            value={paymentTypes}
                                            onChange={(e) =>
                                                setPaymentTypes(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Create Expense For Vehicle Purchase
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="createExpenseForVehiclePurchase"
                                            style={{ fontSize: "12px" }}
                                            checked={createExpenseForVehiclePurchase ? true : false}
                                            onChange={(e) =>
                                                setCreateExpenseForVehiclePurchase(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="createExpenseForVehiclePurchase"
                                            style={{ fontSize: "12px" }}
                                            checked={createExpenseForVehiclePurchase ? false : true}
                                            onChange={(e) =>
                                                setCreateExpenseForVehiclePurchase(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                            </div>

                            <div
                                style={{
                                    width: "50%",
                                }}
                            >
                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="contactCategoryForVendorExpenses"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Contact Category For Vendor Expenses
                                        <br />
                                        <input
                                            type="text"
                                            name="contactCategoryForVendorExpenses"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={contactCategoryForVendorExpenses}
                                            onChange={(e) =>
                                                setContactCategoryForVendorExpenses(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "70px" }}>
                                    <label
                                        htmlFor="expenseTypeForVehiclePurchase"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Expense Type For Vehicle Purchase
                                        <br />
                                        <input
                                            type="text"
                                            name="expenseTypeForVehiclePurchase"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={expenseTypeForVehiclePurchase}
                                            onChange={(e) =>
                                                setExpenseTypeForVehiclePurchase(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                            Vehicles Scanning
                        </h5>
                        <hr />

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "50%",
                                    marginRight: "3%",
                                    paddingLeft: "16px",
                                }}
                            >
                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Enable Option To Print Vehicle Tags
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="enableOptionToPrintVehicleTags"
                                            style={{ fontSize: "12px" }}
                                            checked={enableOptionToPrintVehicleTags ? true : false}
                                            onChange={(e) =>
                                                setEnableOptionToPrintVehicleTags(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="enableOptionToPrintVehicleTags"
                                            style={{ fontSize: "12px" }}
                                            checked={enableOptionToPrintVehicleTags ? false : true}
                                            onChange={(e) =>
                                                setEnableOptionToPrintVehicleTags(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                            Reports
                        </h5>
                        <hr />

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "50%",
                                    marginRight: "3%",
                                    paddingLeft: "16px",
                                }}
                            >
                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="fieldForPurchasePrice"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Field For Purchase Price
                                        <br />
                                        <input
                                            type="text"
                                            name="fieldForPurchasePrice"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={fieldForPurchasePrice}
                                            onChange={(e) =>
                                                setFieldForPurchasePrice(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="fieldForPurchaseDate"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                         Field For Purchase Date
                                        <br />
                                        <input
                                            type="text"
                                            name="fieldForPurchaseDate"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={fieldForPurchaseDate}
                                            onChange={(e) =>
                                                setFieldForPurchaseDate(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="fieldForDepreciatonTime"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                         Field For Depreciation Time
                                        <br />
                                        <input
                                            type="text"
                                            name="fieldForDepreciatonTime"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={fieldForDepreciatonTime}
                                            onChange={(e) =>
                                                setFieldForDepreciatonTime(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>
                            </div>

                            <div
                                style={{
                                    width: "50%",
                                }}
                            >

                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="fieldForRestValue"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                         Field For Rest Value
                                        <br />
                                        <input
                                            type="text"
                                            name="fieldForRestValue"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={fieldForRestValue}
                                            onChange={(e) =>
                                                setFieldForRestValue(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="fieldForDistanceAtPurchase"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                         Field For Distance At Purchase
                                        <br />
                                        <input
                                            type="text"
                                            name="fieldForDistanceAtPurchase"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={fieldForDistanceAtPurchase}
                                            onChange={(e) =>
                                                setFieldForDistanceAtPurchase(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="maxDaysToKeepAuthorizations"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Default Depreciation Time(In Months)
                                        <br />
                                        <input
                                            type="number"
                                            name="defaultDepreciatonTimeInMonths"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={defaultDepreciatonTimeInMonths}
                                            onChange={(e) =>
                                                setDefaultDepreciatonTimeInMonths(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: colorConfigs.topbar.bg,
                                color: "white",
                                borderRadius: "0",
                                border: "none",
                                textTransform: "capitalize",
                                marginLeft: "16px",
                                marginTop: "40px",
                                "&:hover": {
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "white",
                                },
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                    </form>
                </div>
            </div >

            <Dialog
                open={pleaseWaitPopup}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        width: "32%",
                    },
                }}
            >
                <DialogContent sx={{ padding: "5px" }}>
                    <div
                        style={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "black",
                            textAlign: "center",
                        }}
                    >
                        Please Wait...
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    );
};
