import { Box, CssBaseline, Toolbar } from '@mui/material';
import React from 'react'
import { Outlet } from 'react-router-dom';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import Topbar from '../common/Topbar';
import AdminSidebar from '../common/AdminSidebar';

export const AdminMainLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar />

      <Box sx={{ backgroundColor: colorConfigs.sidebar.bg }}>
        <AdminSidebar />
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${sizeConfigs.sidebar.width})`,
          minHeight: "100vh",
          backgroundColor: colorConfigs.mainBg,
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        <Toolbar style={{ marginTop: "-16px" }} />

        <Outlet />
      </Box>
    </Box>
  );
}
