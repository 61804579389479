import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../../configs/colorConfigs";

type Props = {
  editPopup: boolean;
  hidePopup: () => void;
  customerEnquiryDetails: any;
}

export default function CustomersEnquiryPopup({ editPopup, hidePopup, customerEnquiryDetails }: Props) {

  return (
    <Dialog
      open={editPopup}
      maxWidth="md"
      fullScreen={false}
      PaperProps={{
        style: {
          width: "40%",
          display: "flex",
          position: "absolute",
          top: "30px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", width: "100%" }}>
        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 16px 8px 16px",
          }}
        >
          <p style={{ fontSize: "14px", marginLeft: "5px" }}>
            Customer Enquiry
          </p>
          <CloseIcon
            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
            onClick={() => hidePopup()}
          />
        </div>
        <hr style={{ marginBlock: "0px" }} />
      </DialogTitle>
      <DialogContent sx={{ padding: "0px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "10px"
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              marginTop: "10px"
            }}
          >
            <div style={{ width: "50%" }}>
              <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Name</p>
              <p style={{ fontSize: "12px", marginBlock: "0px" }}>{customerEnquiryDetails?.name}</p>
            </div>

            <div style={{ width: "50%" }}>
              <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Question</p>
              <p style={{ fontSize: "12px", marginBlock: "0px", whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'hidden'}}>{customerEnquiryDetails?.question}</p>
            </div>
          </div>
        </div>


        <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBlock: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
            backgroundColor: "#eeeeee",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#e0dee0",
                color: colorConfigs.sidebar.color,
                borderRadius: "0",
                border: "none",
                textTransform: "capitalize",
                fontSize: "12px",
                "&: hover": {
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                },
              }}
              onClick={() => hidePopup()}
            >
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}