import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import Select from "react-select";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DiscountIcon from "@mui/icons-material/Discount";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";
import baseURL from "../../../../configs/api";
import colorConfigs from "../../../../configs/colorConfigs";

import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React from "react";
import PleaseWaitPopup from "../../../PleaseWaitPopup";
import axois from "../../../../configs/axois";
import useAuth from "../../../../components/hooks/useAuth";

const pages = ["Edit"];

export default function EditVehicleClass() {
  const { auth }: any = useAuth();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const [branch, setBranch] = useState([]);
  const [name, setName] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const [availableOnWebsite, setAvailableOnWebsite] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [order, setOrder] = useState<string>("");
  const [calenderOrder, setCalenderOrder] = useState<string>("");
  const [allowedOverbookingQuantity, setAllowedOverbookingQuantity] =
    useState<string>("");
  const [overbookingBackupClassId, setOverbookingBackupClassId] =
    useState<string>("");
  const [minimumRentalAllowedAge, setMinimumRentalAllowedAge] =
    useState<string>("");
  const [maxRentalAllowedAge, setMaxRentalAllowedAge] = useState<string>("");
  const [tankSize, setTankSize] = useState<string>("");
  const [fuelChargeperOneByEight, setFuelChargeperOneByEight] =
    useState<string>("");
  const [allowedRoles, setAllowedRoles] = useState<string>("");
  const [allowedLocations, setAllowedLocations] = useState<string>("");
  const [restrictedLocations, setRestrictedLocations] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [spareQuantity, setSpareQuantity] = useState<string>("");
  const [distancelimit, setDistancelimit] = useState<string>("");
  const [distanceLimitperDay, setDistanceLimitperDay] = useState<string>("");
  const [distanceLimitperWeek, setDistanceLimitperWeek] = useState<string>("");
  const [distanceLimitperMonth, setDistanceLimitperMonth] =
    useState<string>("");
  const [
    additionalChargeforExceededDistance,
    setAdditionalChargeforExceededDistance,
  ] = useState<string>("");
  const [sipp, setSipp] = useState<string>("");
  const [driverYearsExperience, setDriverYearsExperience] =
    useState<string>("");
  const [enableFuelLevelTracking, seteEnableFuelLevelTracking] =
    useState<boolean>(false);
  const [enableOdometerTracking, setEnableOdometerTracking] =
    useState<boolean>(false);
  const [similarVehicleMakeId, setSimilarVehicleMakeId] = useState<string>("");
  const [similarVehicleModelId, setSimilarVehicleModelId] =
    useState<string>("");
  const [features, setFeatures] = useState<any>();
  const [featuresOptions, setFeaturesOptions] = useState<any[]>([]);
  const [label, setLabel] = useState<string>("");
  const [shortDiscription, setShortDiscription] = useState<string>("");
  const [discription, setDiscription] = useState<string>("");

  const [recommended, setRecommended] = useState<boolean>(false);
  const [limitedAvailability, setLimitedAvailability] =
    useState<boolean>(false);
  const [
    percentageforLimitedAvailability,
    setPercentageForLimitedAvailability,
  ] = useState<string>("");

  const [isWebsite, setIsWebsite] = useState<boolean>(true);

  const [currentPage, setCurrentPage] = useState<String>(pages[0]);

  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<string>("");
  const [file, setFile] = useState<FileList | null>(null);
  const [isClone, setIsClone] = useState<boolean>(false);
  const [imagePopup, setImagePopup] = useState<boolean>(false);

  const [websiteShowWebcam, setWebsiteShowWebcam] = useState<boolean>(false);
  const [websiteFilePath, setWebsiteFilePath] = useState<any[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [websiteImagePopup, setWebsiteImagePopup] = useState<boolean>(false);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then(response => {
        setBranch(
          response.data.map((item: any) => {
            return { value: item.id, label: item.branchName };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL}Feature`)
      .then((response) => {
        setFeaturesOptions(() =>
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.label
            };
          })
        );

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const idNumber = useLocation();

  useEffect(() => {
    if (isFirstTime) {
      setPleaseWaitPopup(true);
      axios
        .get(`${baseURL}vehicleClass/${idNumber.state}`)
        .then(response => {
          setName(response.data.name);
          setBranchId(response.data.branchId);
          setAvailableOnWebsite(response.data.availableOnWebsite);
          setActive(response.data.active);
          setRecommended(response.data.recommended);
          setOrder(response.data.order);
          setCalenderOrder(response.data.calenderOrder);
          setAllowedOverbookingQuantity(response.data.allowedOverbookingQuantity);
          setOverbookingBackupClassId(response.data.overbookingBackupClassId);
          setMinimumRentalAllowedAge(response.data.minimumRentalAllowedAge);
          setMaxRentalAllowedAge(response.data.maxRentalAllowedAge);
          setTankSize(response.data.tankSize);
          setLimitedAvailability(response.data.limitedAvailability);
          setPercentageForLimitedAvailability(
            response.data.percentageforLimitedAvailability
          );
          setFuelChargeperOneByEight(response.data.fuelChargeperOneByEight);
          setAllowedRoles(response.data.allowedRoles);
          setAllowedLocations(response.data.allowedLocations);
          setRestrictedLocations(response.data.restrictedLocations);
          setStartDate(response.data.startDate.split("T")[0]);
          setSpareQuantity(response.data.spareQuantity);
          setDistancelimit(response.data.distancelimit);
          setDistanceLimitperDay(response.data.distanceLimitperDay);
          setDistanceLimitperWeek(response.data.distanceLimitperWeek);
          setDistanceLimitperMonth(response.data.distanceLimitperMonth);
          setAdditionalChargeforExceededDistance(
            response.data.additionalChargeforExceededDistance
          );
          setSipp(response.data.sipp);
          setDriverYearsExperience(response.data.driverYearsExperience);
          seteEnableFuelLevelTracking(response.data.enableFuelLevelTracking);
          setEnableOdometerTracking(response.data.enableOdometerTracking);
          setSimilarVehicleMakeId(response?.data?.similarVehicleMakeId ? response?.data?.similarVehicleMakeId : "");
          setSimilarVehicleModelId(response.data.similarVehicleModelId ? response.data.similarVehicleModelId : "");
          setFeatures(response.data.features);
          setLabel(response.data.label);
          setEditorStateShort(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(response.data.shortDiscription).contentBlocks,
                convertFromHTML(response.data.shortDiscription).entityMap
              )
            )
          );
          setEditorStateDescription(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(response.data.discription).contentBlocks,
                convertFromHTML(response.data.discription).entityMap
              )
            )
          );

          if (response.data.imagePath.startsWith("data:image/png;base64,")) {
            setFilePath(response.data.imagePath);
          } else {
            setFilePath(
              `${baseURL}vehicleClassFile/Get?name=${response.data.imagePath}`,

            );
          }

          setIsFirstTime(false);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          setPleaseWaitPopup(false);
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axois
      .get(`${baseURL}fileUploadData/VehicleClass/${idNumber.state}`)
      .then((response) => {
        setWebsiteFilePath(
          response.data
            .map((item: any) => {
              return {
                id: item.id,
                fileName: item.fileName.startsWith("data:image/png;base64,")
                  ? item.fileName
                  : `${baseURL}vehicleClassFile/Get?name=${item.fileName}`,
              };
            })
        );
        if (response.data.length > 0) setFileIndex(0);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (featuresOptions.length > 0 && !isFirstTime) {
      const selectedFeature = featuresOptions.filter((_: any) => features?.split(",").find((item: string) => parseInt(item) === _.value));
      setFeatures(selectedFeature);
      setIsFirstTime(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresOptions, isFirstTime])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!pleaseWaitPopup) {
      if (filePath === "" && file === null) {
        alert("Please add a image");
      } else {
        setPleaseWaitPopup(true);
        if (file === null) {
          if (filePath.startsWith("data:image/png;base64,")) {
            saveVehicleClass(filePath)
          } else {
            saveVehicleClass(filePath.replace(`${baseURL}vehicleClassFile/Get?name=`, ""));
          }
        } else {
          const formData = new FormData();
          formData.append("ImageFile", file[0]);

          await axios
            .post(`${baseURL}vehicleClassFile/upload`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
              withCredentials: true,
            })
            .then(response => saveVehicleClass(response.data))
            .catch(error => console.error("Error:", error));
        }
      }
    }
  };

  const saveVehicleClass = async (fileName: string) => {
    if (isClone) {
      await axios
        .post(
          `${baseURL}vehicleClass`,
          JSON.stringify({
            name,
            branchId,
            imagePath: fileName,
            availableOnWebsite,
            active,
            recommended,
            order,
            calenderOrder,
            allowedOverbookingQuantity,
            overbookingBackupClassId,
            minimumRentalAllowedAge,
            maxRentalAllowedAge,
            tankSize,
            limitedAvailability,
            percentageforLimitedAvailability,
            fuelChargeperOneByEight,
            allowedRoles,
            allowedLocations,
            restrictedLocations,
            startDate: startDate + "T00:00:00",
            spareQuantity,
            distancelimit,
            distanceLimitperDay,
            distanceLimitperWeek,
            distanceLimitperMonth,
            additionalChargeforExceededDistance,
            sipp,
            driverYearsExperience,
            enableFuelLevelTracking,
            enableOdometerTracking,
            similarVehicleMakeId,
            similarVehicleModelId,
            features: features?.map((item: any) => item.value).join(","),
            label,
            shortDiscription,
            discription,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => saveWebsiteFile())
        .catch(error => console.error("Error:", error));
    } else {
      await axios
        .put(
          `${baseURL}vehicleClass`,
          JSON.stringify({
            id: idNumber.state,
            name,
            branchId,
            imagePath: fileName,
            availableOnWebsite,
            active,
            recommended,
            order,
            calenderOrder,
            allowedOverbookingQuantity,
            overbookingBackupClassId,
            minimumRentalAllowedAge,
            maxRentalAllowedAge,
            tankSize,
            limitedAvailability,
            percentageforLimitedAvailability,
            fuelChargeperOneByEight,
            allowedRoles,
            allowedLocations,
            restrictedLocations,
            startDate: startDate + "T00:00:00",
            spareQuantity,
            distancelimit,
            distanceLimitperDay,
            distanceLimitperWeek,
            distanceLimitperMonth,
            additionalChargeforExceededDistance,
            sipp,
            driverYearsExperience,
            enableFuelLevelTracking,
            enableOdometerTracking,
            similarVehicleMakeId,
            similarVehicleModelId,
            features: features?.map((item: any) => item.value).join(","),
            label,
            shortDiscription,
            discription,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => saveWebsiteFile())
        .catch(error => console.error("Error:", error));
    }
  }

  const saveWebsiteFile = () => {
    if (websiteFilePath.length > 0) {
      let count = 0;
      if (websiteFilePath.filter(item => item.id === 0).length > 0) {
        websiteFilePath.filter(item => item.id === 0)
          .forEach(async (item: any, _: number, arr: any[]) => {
            await axios
              .post(
                `${baseURL}fileUploadData`,
                JSON.stringify({
                  attributeName: "VehicleClass",
                  attributeId: idNumber.state,
                  fileName: item.fileName.replace(`${baseURL}vehicleClassFile/Get?name=`, ""),
                  tabName: "VehicleClass",
                  userId: auth?.id,
                  createdDate: new Date(),
                }),
                {
                  headers: { "Content-Type": "application/json" },
                  withCredentials: true,
                }
              )
              .then(() => {
                count++;
                if (arr.length === count) {
                  setPleaseWaitPopup(false);
                  navigate(-1);
                }
              })
              .catch(error => console.error("Error:", error));
          });
      } else {
        setPleaseWaitPopup(false);
        navigate(-1);
      }
    } else {
      setPleaseWaitPopup(false);
      navigate(-1);
    }
  }

  const handleDelete = async () => {
    if (
      window.confirm("Are you sure you want to delete this Vehicle?") === true
    ) {
      await axios
        .delete(`${baseURL}vehicleClass?id=${idNumber.state}`)
        .then(() => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const navigate = useNavigate();

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setCurrentPage(page);
  };

  const webRef = useRef(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles && selectedFiles.length > 0) {
      const file = selectedFiles[0];
      const fileName = file.name.toLowerCase();

      const supportedExtensions = ['.jpg', '.jpeg', '.png', '.heif'];

      const isSupported = supportedExtensions.some(ext => fileName.endsWith(ext));

      if (isSupported) {
        setFilePath("");
        setFile(selectedFiles);
      } else {
        console.error('Unsupported file type');
        alert('Unsupported file type');        
      }
    }
  };

  const inputWebsiteFileRef = useRef<HTMLInputElement>(null);
  const onWebsiteFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPleaseWaitPopup(true);
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles && selectedFiles.length > 0) {
      const file = selectedFiles[0];
      const fileName = file.name.toLowerCase();

      const supportedExtensions = ['.jpg', '.jpeg', '.png', '.heif'];

      const isSupported = supportedExtensions.some(ext => fileName.endsWith(ext));

      if (isSupported) {
        const formData = new FormData();
        formData.append("ImageFile", selectedFiles[0]);

        axios
          .post(`${baseURL}vehicleClassFile/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          })
          .then((response) => {
            const file: any = {
              id: 0,
              fileName: `${baseURL}vehicleClassFile/Get?name=${response.data}`
            };
            setWebsiteFilePath(prevPath => [...prevPath, file]);
            setPleaseWaitPopup(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        setPleaseWaitPopup(false);
        console.error('Unsupported file type');
        alert('Unsupported file type');
      }
    }


  };

  const handleDeleteImage = () => {
    if (window.confirm("Are you sure you want to delete this Image?") === true) {
      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
      setFile(null);
      setFilePath("");
    }
  }

  const [editorStateShort, setEditorStateShort] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorStateDescription, setEditorStateDescription] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    setShortDiscription(
      draftToHtml(convertToRaw(editorStateShort.getCurrentContent()))
    );
  }, [editorStateShort]);

  useEffect(() => {
    setDiscription(
      draftToHtml(convertToRaw(editorStateDescription.getCurrentContent()))
    );
  }, [editorStateDescription]);

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <DiscountIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Vehicle Classes / {isClone ? "Add Vehicle Class" : name}
          </Typography>
        </Box>

        {!isClone && (
          <div>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#81D4FA",
                borderRadius: "0",
                textTransform: "capitalize",
                marginRight: "5px",
              }}
              onClick={() => setIsClone(true)}
            >
              Clone
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                borderRadius: "0",
                textTransform: "capitalize",
                "&: hover": {
                  backgroundColor: "red",
                }
              }}
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
          </div>
        )}
      </Toolbar>

      {isClone ? (
        <hr />
      ) : (
        <AppBar
          position="static"
          sx={{
            backgroundColor: colorConfigs.sidebar.bg,
            border: "none",
            paddingLeft: "18px",
            paddingRight: "18px",
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: "black", paddingBlock: "4px" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                paddingTop: "8px",
                paddingLeft: "0px",
                marginLeft: "-20px",
              }}
            >
              {pages.map((page) => {
                if (currentPage === page) {
                  return (
                    <Button
                      disableElevation={false}
                      key={page}
                      onClick={() => handleOnClick(page)}
                      variant="text"
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        paddingBlock: "0px",
                        marginBlock: "0px",
                        borderRadius: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "none",
                        textAlign: "center",

                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                        "&: hover": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      disableElevation={false}
                      key={page}
                      onClick={() => handleOnClick(page)}
                      variant="text"
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        paddingBlock: "0px",
                        marginBlock: "0px",
                        borderRadius: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "none",
                        textAlign: "center",
                        border: "none",
                        "&: focus": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                        "&: active": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  );
                }
              })}
            </Box>
          </Container>
        </AppBar>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "75%",
            marginRight: "3%",
          }}
        >
          <div
            style={{
              width: "100%",
              marginRight: "3%",
            }}
          >
            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              General
            </h5>
            <hr />

            <form onSubmit={(e) => handleSubmit(e)}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="name"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Name
                      <br />
                      <input
                        type="text"
                        name="name"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="image"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Images
                      <br />
                      {(file || filePath) && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {file && (
                            <img
                              src={URL.createObjectURL(file[0])}
                              width={40}
                              height={40}
                              alt="vehicle"
                              onClick={() => setImagePopup(true)}
                            />
                          )}

                          {filePath && (
                            <img
                              src={filePath}
                              width={40}
                              height={40}
                              alt="vehicle"
                              onClick={() => setImagePopup(true)}
                            />
                          )}

                          <RemoveCircleIcon
                            sx={{
                              fontSize: "14px",
                              color: colorConfigs.topbar.bg,
                              fontWeight: "bold",
                              cursor: "pointer",
                              "&: hover": {
                                color: "red",
                              },
                            }}
                            onClick={handleDeleteImage}
                          />
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        ref={inputFileRef}
                        onChangeCapture={onFileChangeCapture}
                        style={{ display: "none" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          marginTop: "8px",
                        }}
                      >
                        <p
                          style={{
                            border: "1px dashed black",
                            borderRadius: "3px",
                            padding: "3px 5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                            backgroundColor: "white",
                          }}
                          onClick={(e) => {
                            if (inputFileRef.current !== null) {
                              inputFileRef.current.click();
                            }
                          }}
                        >
                          {" "}
                          <AddIcon
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          />
                          Add Image
                        </p>

                        <p
                          style={{
                            border: "1px dashed black",
                            borderRadius: "3px",
                            padding: "3px 5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                            backgroundColor: "white",
                          }}
                          onClick={() => setShowWebcam(true)}
                        >
                          {" "}
                          <LocalSeeIcon
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          />
                        </p>
                      </div>
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor=" branch"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Branch
                      <br />
                      <select
                        name="branch"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={branchId}
                        onChange={(e) => setBranchId(e.target.value)}
                      >
                        {branch.map((item: any) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  </div>
                </div>
              </div>

              <h5
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                Rental Process
              </h5>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="availableOnWebsite"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Available on Website?
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="availableOnWebsite"
                            style={{ fontSize: "12px" }}
                            checked={availableOnWebsite ? true : false}
                            onChange={(e) =>
                              setAvailableOnWebsite(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="availableOnWebsite"
                            style={{ fontSize: "12px" }}
                            checked={availableOnWebsite ? false : true}
                            onChange={(e) =>
                              setAvailableOnWebsite(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="recommended"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Recommended?
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="recommended"
                            style={{ fontSize: "12px" }}
                            checked={recommended ? true : false}
                            onChange={(e) =>
                              setRecommended(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="recommended"
                            style={{ fontSize: "12px" }}
                            checked={recommended ? false : true}
                            onChange={(e) =>
                              setRecommended(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="order"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Order
                      <br />
                      <input
                        type="number"
                        name="order"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="allowedOverbookingQuantity"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Overbooking Quantity
                      <br />
                      <input
                        type="number"
                        name="allowedOverbookingQuantity"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={allowedOverbookingQuantity}
                        onChange={(e) =>
                          setAllowedOverbookingQuantity(e.target.value)
                        }
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="minimumRentalAllowedAge"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Minimum Rental Allowed Age
                      <br />
                      <input
                        type="number"
                        name="minimumRentalAllowedAge"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={minimumRentalAllowedAge}
                        onChange={(e) =>
                          setMinimumRentalAllowedAge(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="tankSize"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Tank Size
                      <br />
                      <input
                        type="number"
                        name="tankSize"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={tankSize}
                        onChange={(e) => setTankSize(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="limitedAvailability"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Limited Availability
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="limitedAvailability"
                            style={{ fontSize: "12px" }}
                            checked={limitedAvailability ? true : false}
                            onChange={(e) =>
                              setLimitedAvailability(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="limitedAvailability"
                            style={{ fontSize: "12px" }}
                            checked={limitedAvailability ? false : true}
                            onChange={(e) =>
                              setLimitedAvailability(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="active"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Active?
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="active"
                            style={{ fontSize: "12px" }}
                            checked={active ? true : false}
                            onChange={(e) =>
                              setActive(e.target.value === "Yes" ? true : false)
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="active"
                            style={{ fontSize: "12px" }}
                            checked={active ? false : true}
                            onChange={(e) =>
                              setActive(e.target.value === "Yes" ? true : false)
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="calenderOrder"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Calendar Order
                      <br />
                      <input
                        type="number"
                        name="calenderOrder"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={calenderOrder}
                        onChange={(e) => setCalenderOrder(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="overbookingBackupClassId"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Overbooking Backup Class
                      <br />
                      <input
                        type="number"
                        name="overbookingBackupClassId"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={overbookingBackupClassId}
                        onChange={(e) =>
                          setOverbookingBackupClassId(e.target.value)
                        }
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="maxRentalAllowedAge"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Maximum Rental Allowed Age
                      <br />
                      <input
                        type="number"
                        name="maxRentalAllowedAge"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={maxRentalAllowedAge}
                        onChange={(e) => setMaxRentalAllowedAge(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="fuelChargeperOneByEight"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Fuel Charge per 1/8
                      <br />
                      <input
                        type="number"
                        name="fuelChargeperOneByEight"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={fuelChargeperOneByEight}
                        onChange={(e) =>
                          setFuelChargeperOneByEight(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="percentageForLimitedAvailability"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Percentage for Limited Availability
                      <br />
                      <input
                        type="number"
                        name="percentageForLimitedAvailability"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={percentageforLimitedAvailability}
                        onChange={(e) =>
                          setPercentageForLimitedAvailability(e.target.value)
                        }
                      />
                    </label>
                  </div>
                </div>
              </div>

              <h5
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                Advanced
              </h5>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="allowedRoles"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Roles
                      <br />
                      <input
                        type="text"
                        name="allowedRoles"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={allowedRoles}
                        onChange={(e) => setAllowedRoles(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="allowedLocations"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Locations
                      <br />
                      <input
                        type="text"
                        name="allowedLocations"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={allowedLocations}
                        onChange={(e) => setAllowedLocations(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="startDate"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Start Date
                      <br />
                      <input
                        type="date"
                        name="startDate"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="distancelimit"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Distance limit
                      <br />
                      <input
                        type="number"
                        name="distancelimit"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={distancelimit}
                        onChange={(e) => setDistancelimit(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="distanceLimitperDay"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Distance Limit per Day
                      <br />
                      <input
                        type="number"
                        name="distanceLimitperDay"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={distanceLimitperDay}
                        onChange={(e) => setDistanceLimitperDay(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="distanceLimitperMonth"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Distance Limit per Month
                      <br />
                      <input
                        type="number"
                        name="distanceLimitperMonth"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={distanceLimitperMonth}
                        onChange={(e) =>
                          setDistanceLimitperMonth(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="sipp"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      SIPP Code
                      <br />
                      <input
                        type="text"
                        name="sipp"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={sipp}
                        onChange={(e) => setSipp(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="driverYearsExperience"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Driver Years Experience
                      <br />
                      <input
                        type="number"
                        name="driverYearsExperience"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={driverYearsExperience}
                        onChange={(e) =>
                          setDriverYearsExperience(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="enableFuelLevelTracking"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Enable Fuel Level Tracking
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="enableFuelLevelTracking"
                            style={{ fontSize: "12px" }}
                            checked={enableFuelLevelTracking ? true : false}
                            onChange={(e) =>
                              seteEnableFuelLevelTracking(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="enableFuelLevelTracking"
                            style={{ fontSize: "12px" }}
                            checked={enableFuelLevelTracking ? false : true}
                            onChange={(e) =>
                              seteEnableFuelLevelTracking(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px", visibility: "hidden" }}>
                    <label
                      htmlFor=" vehicle"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Branch
                      <br />
                      <input
                        type="text"
                        name="vehicle"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="restrictedLocations"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Restricted Locations
                      <br />
                      <input
                        type="text"
                        name="restrictedLocations"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={restrictedLocations}
                        onChange={(e) => setRestrictedLocations(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="spareQuantity"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Spare Quantity
                      <br />
                      <input
                        type="number"
                        name="spareQuantity"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={spareQuantity}
                        onChange={(e) => setSpareQuantity(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px", visibility: "hidden" }}>
                    <label
                      htmlFor="firstName"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Roles
                      <br />
                      <input
                        type="text"
                        name="firstName"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="distanceLimitperWeek"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Distance Limit per Week
                      <br />
                      <input
                        type="number"
                        name="distanceLimitperWeek"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={distanceLimitperWeek}
                        onChange={(e) =>
                          setDistanceLimitperWeek(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="additionalChargeforExceededDistance"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Additional Charge for Exceeded Distance
                      <br />
                      <input
                        type="number"
                        name="additionalChargeforExceededDistance"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={additionalChargeforExceededDistance}
                        onChange={(e) =>
                          setAdditionalChargeforExceededDistance(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px", visibility: "hidden" }}>
                    <label
                      htmlFor="firstName"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Roles
                      <br />
                      <input
                        type="text"
                        name="firstName"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px", visibility: "hidden" }}>
                    <label
                      htmlFor="firstName"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Roles
                      <br />
                      <input
                        type="text"
                        name="firstName"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="enableOdometerTracking"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Enable Odometer Tracking
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="enableOdometerTracking"
                            style={{ fontSize: "12px" }}
                            checked={enableOdometerTracking ? true : false}
                            onChange={(e) =>
                              setEnableOdometerTracking(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="enableOdometerTracking"
                            style={{ fontSize: "12px" }}
                            checked={enableOdometerTracking ? false : true}
                            onChange={(e) =>
                              setEnableOdometerTracking(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <h5
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setIsWebsite(!isWebsite)}
              >
                {isWebsite ? (
                  <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                ) : (
                  <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
                )}{" "}
                Website
              </h5>
              <hr />

              {isWebsite && (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        marginRight: "3%",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <label
                          htmlFor="similarVehicleMakeId"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Similar Vehicle Make
                          <br />
                          <input
                            type="number"
                            name="similarVehicleMakeId"
                            style={{
                              width: "100%",
                              fontSize: "14px",
                              paddingBlock: "5px",
                            }}
                            value={similarVehicleMakeId}
                            onChange={(e) =>
                              setSimilarVehicleMakeId(e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>

                    <div
                      style={{
                        width: "50%",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <label
                          htmlFor="similarVehicleModelId"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Similar Vehicle Model
                          <br />
                          <input
                            type="number"
                            name="similarVehicleModelId"
                            style={{
                              width: "100%",
                              fontSize: "14px",
                              paddingBlock: "5px",
                            }}
                            value={similarVehicleModelId}
                            onChange={(e) =>
                              setSimilarVehicleModelId(e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="image"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Website Images
                      <br />
                      <div
                        style={{
                          maxWidth: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                          flexWrap: "wrap"
                        }}
                      >
                        {websiteFilePath.map((item: any, outerIndex: number) => {
                          return (
                            <React.Fragment key={outerIndex}>
                              <img
                                src={item.fileName}
                                width={40}
                                height={40}
                                alt="Vehcile Class"
                                onClick={() => {
                                  setFileIndex(outerIndex);
                                  setWebsiteImagePopup(true);
                                }}
                              />
                              <RemoveCircleIcon
                                sx={{
                                  fontSize: "14px",
                                  color: colorConfigs.topbar.bg,
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  "&: hover": {
                                    color: "red",
                                  },
                                }}
                                onClick={() => {
                                  if (window.confirm("Are you sure you want to delete this image?")) {
                                    if (websiteFilePath[outerIndex].id === 0) {
                                      setWebsiteFilePath(websiteFilePath.filter((_, index: number) => index !== outerIndex));
                                    } else {
                                      axios
                                        .delete(`${baseURL}fileUploadData?id=${websiteFilePath[outerIndex].id}`)
                                        .then(() => setWebsiteFilePath(websiteFilePath.filter((_, index: number) => index !== outerIndex)))
                                        .catch(error => console.error("Error:", error));
                                    }
                                  }

                                  if (inputWebsiteFileRef.current) {
                                    inputWebsiteFileRef.current.value = "";
                                  }
                                }}
                              />
                            </React.Fragment>
                          );
                        })}

                        <input
                          type="file"
                          accept="image/*"
                          name="inputWebsiteFileRef"
                          ref={inputWebsiteFileRef}
                          onChangeCapture={onWebsiteFileChangeCapture}
                          style={{ display: "none" }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          marginTop: "8px",
                        }}
                      >
                        <p
                          style={{
                            border: "1px dashed black",
                            borderRadius: "3px",
                            padding: "3px 5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                            backgroundColor: "white",
                            cursor: "pointer"
                          }}
                          onClick={(e) => {
                            if (inputWebsiteFileRef.current !== null) {
                              inputWebsiteFileRef.current.click();
                            }
                          }}
                        >
                          {" "}
                          <AddIcon
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          />
                          Add Image
                        </p>

                        <p
                          style={{
                            border: "1px dashed black",
                            borderRadius: "3px",
                            padding: "3px 5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                            backgroundColor: "white",
                            cursor: "pointer"
                          }}
                          onClick={() => setWebsiteShowWebcam(true)}
                        >
                          {" "}
                          <LocalSeeIcon
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          />
                        </p>
                      </div>
                    </label>
                  </div>

                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="features"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Features
                        <br />
                        <Select
                          isMulti
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={false}
                          name="features"
                          value={features}
                          onChange={setFeatures}
                          options={featuresOptions}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="label"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Label
                        <br />
                        <input
                          type="text"
                          name="label"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={label}
                          onChange={(e) => setLabel(e.target.value)}
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="firstName"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Short Description
                      </label>
                      <label
                        style={{ fontSize: "12px" }}
                      >
                        <br />
                        <Editor
                          editorState={editorStateShort}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          onEditorStateChange={setEditorStateShort}
                          toolbar={{
                            inline: { inDropdown: false },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                          }}
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="firstName"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Description
                      </label>
                      <label
                        style={{ fontSize: "12px" }}
                      >
                        <br />
                        <Editor
                          editorState={editorStateDescription}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          onEditorStateChange={setEditorStateDescription}
                          toolbar={{
                            inline: { inDropdown: false },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </>
              )}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                  }}
                  type="submit"
                >
                  Save
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#e0dee0",
                    color: "black",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                  }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>

        {!isClone && (
          <div
            style={{
              width: "25%",
            }}
          >
            <h5 style={{ marginBottom: "0px", paddingBottom: "0px" }}>Links</h5>
            <hr />

            <h6
              style={{
                fontSize: "13px",
                marginTop: "16px",
                marginBottom: "0px",
              }}
            >
              Public Link for Calendar
            </h6>
            <p style={{ color: "blue", fontSize: "12px", marginTop: "0px" }}>
              {" "}
              Public Link for Calendar
            </p>

            <h5 style={{ marginBottom: "0px", paddingBottom: "0px" }}>
              Website Integration
            </h5>
            <hr />

            <h6
              style={{
                fontSize: "10px",
                marginTop: "22px",
                marginBottom: "0px",
              }}
            >
              Class Calendar
            </h6>
            <button
              style={{
                paddingBlock: "8px",
                backgroundColor: "#81D4FA",
                color: "white",
                fontSize: "10px",
                border: "none",
              }}
            >
              Copy Snippet
            </button>
          </div>
        )}
      </div>

      <Dialog
        open={showWebcam}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-16%",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <Webcam
            width={480}
            height={320}
            ref={webRef}
            screenshotFormat="image/png"
            audio={false}
          />

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => setShowWebcam(false)}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (webRef.current !== null) {
                    setFile(null);
                    //@ts-ignore
                    const imageSrc = webRef.current.getScreenshot();
                    setFilePath(imageSrc);
                    setShowWebcam(false);
                  }
                }}
              >
                Capture an Image
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={imagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>Vehicle Image</p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>
          {(file || filePath) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {file && (
                <img
                  src={URL.createObjectURL(file[0])}
                  width={700}
                  height={350}
                  alt="vehicle"
                />
              )}

              {filePath && (
                <img width={700} height={350} src={filePath} alt="vehicle" />
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>


      <Dialog
        open={websiteShowWebcam}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-16%",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <Webcam
            width={480}
            height={320}
            ref={webRef}
            screenshotFormat="image/png"
            audio={false}
          />

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => setWebsiteShowWebcam(false)}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (webRef.current !== null) {
                    setFile(null);
                    //@ts-ignore
                    const imageSrc = webRef.current.getScreenshot();
                    setWebsiteFilePath(prevFilePath => [...prevFilePath, { id: 0, fileName: imageSrc }])
                    setWebsiteShowWebcam(false);
                  }
                }}
              >
                Capture an Image
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={websiteImagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              Vehicle Image
            </p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setWebsiteImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>
          {websiteFilePath.length !== 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  left: "0",
                  top: "50%",
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    cursor: fileIndex !== 0 ? "pointer" : "",
                    opacity: fileIndex === 0 ? "0.5" : "1",
                    color: "gray",
                  }}
                  onClick={() => {
                    if (fileIndex !== 0) {
                      setFileIndex(fileIndex - 1);
                    }
                  }}
                />
              </div>
              <img
                width={700}
                height={350}
                src={websiteFilePath[fileIndex]?.fileName}
                alt="VehicleClass"
              />

              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  right: "0",
                  top: "50%",
                  color: "gray",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    cursor: fileIndex !== websiteFilePath.length - 1 ? "pointer" : "",
                    opacity: fileIndex === websiteFilePath.length - 1 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== websiteFilePath.length - 1) {
                      setFileIndex(fileIndex + 1);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
    </>
  );
}
