import moment from 'moment';

const generateHourlyTimestamps = (date: moment.Moment): string[] => {
    const hourlyTimestamps: string[] = [];

    const startOfDay = date.clone().startOf('day');

    for (let slot = 0; slot < 48; slot++) {
        const currentTime = startOfDay.clone().add(slot * 30, 'minutes');

        const timestamp = currentTime.format('YYYY-MM-DDTHH:mm:ss');
        hourlyTimestamps.push(timestamp);
    }

    return hourlyTimestamps;
}

const generateWeeklyCalendar = (startDate: moment.Moment): string[] => {
    const dateStrings: string[] = [];

    const startOfWeek = startDate.clone();

    for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
        const currentDay = startOfWeek.clone().add(dayOffset, 'days');
        const dateString = currentDay.format('YYYY-MM-DD');
        dateStrings.push(dateString);
    }

    return dateStrings;
}

const generateDateRangeCalendar = (startDate: moment.Moment, endDate: moment.Moment): string[] => {
    const dateStrings: string[] = [];

    const start = startDate.clone();
    const end = endDate.clone();

    for (let dayOffset = 0; dayOffset <= end.diff(start, "days"); dayOffset++) {
        const currentDay = start.clone().add(dayOffset, 'days');
        const dateString = currentDay.format('YYYY-MM-DD');
        dateStrings.push(dateString);
    }

    return dateStrings;
}

const generateMonthlyCalendar = (startDate: moment.Moment, endDate: moment.Moment) => {
    const slots = [];
    const startOfMonth = startDate.clone().startOf('month');
    const endOfMonth = endDate.clone().startOf('month');

    let currentDay = startOfMonth.clone();

    while (currentDay.isBefore(endOfMonth, 'day')) {
        slots.push(currentDay.format('YYYY-MM-DD'));

        currentDay.add(1, 'day');
    }

    return slots;
}

const generateMonthNames = (startDate: moment.Moment, endDate: moment.Moment) => {
    const months = [];
    let currentMonth = startDate.clone();

    while (currentMonth.isBefore(endDate, 'month')) {
        const monthName = currentMonth.format('MMMM'); // Full month name
        const daysInMonth = currentMonth.daysInMonth(); // Number of days in the month
        const startOfMonth = currentMonth.clone().startOf('day'); // Start day of month

        months.push({
            monthName,
            days: daysInMonth,
            date: startOfMonth
        });
        currentMonth.add(1, 'month');
    }

    return months;
};

const generateMonthNamesForDateRange = (startDate: moment.Moment, endDate: moment.Moment) => {
    const months = [];

    let currentMonth = startDate.clone().startOf('month'); // Start at the beginning of the start month

    while (currentMonth.isBefore(endDate, 'month') || currentMonth.isSame(endDate, 'month')) {
        const monthName = currentMonth.format('MMMM'); // Full month name

        let daysInMonth;
        // Check if it's the start month
        if (currentMonth.isSame(startDate, 'month')) {
            // Count days from startDate to the end of the month
            daysInMonth = currentMonth.daysInMonth() - startDate.date() + 1;
        } else if (currentMonth.isSame(endDate, 'month')) {
            // Count days from the start of the month to endDate
            daysInMonth = endDate.date();
        } else {
            // For all other months, count all days in the month
            daysInMonth = currentMonth.daysInMonth();
        }

        const startOfMonth = currentMonth.clone().startOf('day'); // Start day of month

        months.push({
            monthName,
            days: daysInMonth,
            date: startOfMonth
        });

        currentMonth.add(1, 'month'); // Move to the next month
    }

    return months;
};

const isCurrentMonth = (date: moment.Moment): boolean => {
    return date.isSame(moment(), 'month');
};

const isWeekend = (date: moment.Moment): boolean => {
    const dayOfWeek = date?.day(); // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    return dayOfWeek === 0 || dayOfWeek === 6;
};

const isToday = (date: moment.Moment): boolean => {
    return date.isSame(moment(), 'day');
};

const isCurrentHour = (date: moment.Moment): boolean => {
    const now = moment();
    return date.isSame(now, 'hour');
};

const formatTime = (date: moment.Moment): string => moment(date).format('hh a')

const formatTimeWithCustomAMPM = (date: moment.Moment): string => {
    const formatString = 'h:mm a';
    const time = date?.format(formatString);

    // Replace 'am' with 'a' and 'pm' with 'p'
    return time.replace('am', 'a').replace('pm', 'p');
}

// Format: 'Su 1 Mo 2' for weekly calendar slots
const convertWeek2DigitFormat = (date: moment.Moment): string => {
    const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

    const dayNumber = date.date();
    const dayName = daysOfWeek[date.day()];

    return `${dayName} ${dayNumber}`;
}

const parseDate = (dateStr: string): moment.Moment => moment(moment(dateStr).format("YYYY-MM-DD"));

const parseDateTime = (dateStr: string): moment.Moment => moment(dateStr);

const formatInDateOnly = (date: moment.Moment) => date.format("YYYY-MM-DD");

const dateToPosition = (startDate: moment.Moment, endDate: moment.Moment): number => endDate.diff(startDate, "days");

const dateTimeToPosition = (startDate: moment.Moment, endDate: moment.Moment): number => {
    const diffInMinutes = endDate.diff(startDate, 'minutes');
    return Math.floor(diffInMinutes / 30);
};

const enum Views {
    TimeSlotToday,
    TimeSlotWEEK,
    TimeSlotMONTH,
    TimeSlot3MONTH,
    TimeSlot6MONTH,
    TimeSlotYear,
    TimeSlotCustom
};

const enum TimelineType {
    TimelineTimeSlotType,
    TimelineDaySlotType
};

export {
    generateHourlyTimestamps,
    generateMonthlyCalendar,
    generateMonthNames,
    generateMonthNamesForDateRange,
    generateWeeklyCalendar,
    generateDateRangeCalendar,
    isCurrentMonth,
    isWeekend,
    isToday,
    isCurrentHour,
    formatTime,
    formatTimeWithCustomAMPM,
    convertWeek2DigitFormat,
    parseDate,
    parseDateTime,
    formatInDateOnly,
    dateToPosition,
    dateTimeToPosition,
    Views,
    TimelineType
};