import React from 'react'

type PhoneNumberInputProps = {
    name: string;
    value: string;
    setValue: (value: string) => void;
    fontSize?: string;
    paddingBlock?: string;
    required?: boolean;
}

const PhoneNumberInput = ({
    name,
    value,
    setValue,
    fontSize = "12px",
    paddingBlock = "3px",
    required = false
}: PhoneNumberInputProps) => {
    const onChangePhoneNumber = (value: string) => {
        const { length } = value;

        const regex = () => value.replace(/[^\d.]+/g, '');

        const areaCode = () => `(${regex().slice(0, 3)})`;

        const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

        const trailer = (start: number) => `${regex().slice(start, regex().length)}`;
        if (length < 3) {
            setValue(regex());
        } else if (length >= 3 && length < 10) {
            setValue(`${areaCode()} ${trailer(3)}`);
        } else if (length === 5) {
            setValue(`${areaCode().replace(")", "")}`);
        } else if (length >= 10 && length < 15) {
            setValue(`${firstSix()}-${trailer(6)}`);
        }
    }


    return (
        <input
            style={{
                width: "100%",
                fontSize: fontSize,
                paddingBlock: paddingBlock,
            }}
            type="text"
            pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
            title="Please Enter a 10 digits numbers."
            name={name}
            value={value}
            onChange={(e) => onChangePhoneNumber(e.target.value)}
            required={required}
        />
    )
}

export default PhoneNumberInput
