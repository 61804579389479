
import React, { useEffect, useState } from "react";
import TenTabLayout from "./TenTabLayout";
import { CurrentType } from "../../../enum/CurrentType";
import { useLocation } from "react-router-dom";

export default function AddReservation() {
  const [currentType, setCurrentType] = useState<CurrentType>(CurrentType.ADD)
  const data = useLocation().state;

  useEffect(() => {
    if (data) setCurrentType(CurrentType.ADD_FROM_QUOTES);
  }, [data])

  return (
    <TenTabLayout
      currentType={currentType}
      setCurrentType={(value) => setCurrentType(value)}
      _primaryKey={0} 
      data={data} />
  );
}
