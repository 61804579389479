import React, { useEffect, useState } from 'react'
import { Box, Button, Toolbar, Typography } from '@mui/material';
import { AdminPanelSettings, } from "@mui/icons-material";
import GeneralSettings from './GeneralSettings';
import colorConfigs from '../../../configs/colorConfigs';
import EmailSettings from './EmailSettings';
import Website from './Website';
import Advanced from './Advanced';
import Security from './Security';
import PleaseWaitPopup from '../../PleaseWaitPopup';
import Integration from './Integration';
import { getGeneralSettings, put_GeneralSetting } from '../../../services/generalSetting';

export const Admin = () => {
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  //General settings
  const [companyName, setCompanyName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [smsSender, setSmsSender] = useState<string>("");
  const [timezone, setTimezone] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [SupportedLanguages, setSupportedLanguages] = useState<any>();
  const [supportedLanguagesArray, setSupportedLanguagesArray] = useState<any[]>([]);
  const [preferredDateFormat, setPreferredDateFormat] = useState<string>("");
  const [metricSystem, setMetricSystem] = useState<string>("");
  const [filePath, setFilePath] = useState<string>("");
  const supportedLanguagesOptions: any[] = [
    { value: "English", label: "English" }
  ]

  //Email Settings
  const [isPretendEmails, setIsPretendEmails] = useState<string>("No");
  const [replyToEmail, setReplyToEmail] = useState<string>("");
  const [isDisabledSmsFailureNotificationEmails, setIsDisabledSmsFailureNotificationEmails] = useState<string>("No");
  const [emailForFailureNotifications, setEmailForFailureNotifications] = useState<string>("");
  const [mainLayout, setMainLayout] = useState<string>("");
  const [emailSignature, setEmailSignature] = useState<string>("");
  const [mailFooter, setMailFooter] = useState<string>("");
  const [isShowLogoOnEmails, setIsShowLogoOnEmails] = useState<string>("No");
  const [mailBarColor, setMailBarColor] = useState<string>("#000000");
  const [emailFilePath, setEmailFilePath] = useState<string>("");

  //Integration
  const [mailChimpKey, setMailChimpKey] = useState<string>("");
  const [mailgunSender, setMailgunSender] = useState<string>("");
  const [mailgunDomain, setMailgunDomain] = useState<string>("");
  const [googleAnalyticsKey, setGoogleAnalyticsKey] = useState<string>("");
  const [extraCcUsersForSupportTickets, setExtraCcUsersForSupportTickets] = useState<string>("");

  //Website
  const [websiteHeaderColor, setWebsiteHeaderColor] = useState<string>("");
  const [websiteHeaderSubColor, setWebsiteHeaderSubColor] = useState<string>("");
  const [websiteFooterColor, setWebsiteFooterColor] = useState<string>("");
  const [websiteFooterSubColor, setWebsiteFooterSubColor] = useState<string>("");
  const [websiteIndexPageImage, setWebsiteIndexPageImage] = useState<string>("");
  const [websiteSubPageImage, setWebsiteSubPageImage] = useState<string>("");
  const [websiteEmail, setWebsiteEmail] = useState<string>("");
  const [websitePhone, setWebsitePhone] = useState<string>("");
  const [websiteBranch, setWebsiteBranch] = useState<string>("");

  useEffect(() => {
    setPleaseWaitPopup(true);
    getGeneralSettings()
      .then(data => {
        setId(data[0].id);
        setCompanyName(data[0].companyName);
        setCountry(data[0].country);
        setSmsSender(data[0].smsSender);
        setTimezone(data[0].timezone);
        setLanguage(data[0].language);
        const supportedArray =
          data[0].supportedLanguages.split(",");

        ;
        setSupportedLanguagesArray(
          supportedLanguagesOptions.filter((item: any) =>
            supportedArray.some((data: string) => item.value === data)
          )
        );

        setSupportedLanguages(
          supportedLanguagesOptions.filter((item: any) =>
            supportedArray.some((data: string) => item.value === data)
          )
        );

        setPreferredDateFormat(data[0].preferredDateFormat);
        setMetricSystem(data[0].metricSystem);
        setFilePath(data[0].tentantLogo);
        setIsPretendEmails(data[0].pretendEmails ? "Yes" : "No");
        setReplyToEmail(data[0].replyToEmail);
        setIsDisabledSmsFailureNotificationEmails(data[0].disableSmsFailureNotificationEmails ? "Yes" : "No");
        setEmailForFailureNotifications(data[0].emailForFailureNotifications);
        setMainLayout(data[0].mailLayout);
        setEmailSignature(data[0].emailSignature);
        setMailFooter(data[0].mailFooter);
        setIsShowLogoOnEmails(data[0].showLogoOnEmails ? "Yes" : "No");
        setMailBarColor(data[0].mailBarColor);
        setEmailFilePath(data[0].mailBarLogo);
        setMailChimpKey(data[0].mailChimpKey);
        setMailgunSender(data[0].mailgunSender);
        setMailgunDomain(data[0].mailgunDomain);
        setGoogleAnalyticsKey(data[0].googleAnalyticsKey);
        setExtraCcUsersForSupportTickets(data[0].extraCCUsersForSuppportTickets);
        setWebsiteHeaderColor(data[0].websiteHeaderColor)
        setWebsiteHeaderSubColor(data[0].websiteHeaderSubColor)
        setWebsiteFooterColor(data[0].websiteFooterColor)
        setWebsiteFooterSubColor(data[0].websiteFooterSubColor)
        setWebsiteIndexPageImage(data[0].websiteIndexPageImage)
        setWebsiteSubPageImage(data[0].websiteSubPageImage)
        setWebsiteEmail(data[0].websiteEmail)
        setWebsitePhone(data[0].websitePhone)
        setWebsiteBranch(data[0].dataFetchingBranchId);
        setPleaseWaitPopup(false);
      })
      .catch(error => console.error("Error:", error));
  }, []);

  useEffect(() => {
    if (SupportedLanguages) {
      const arrray = [];
      for (let i = 0; i < SupportedLanguages.length; i++) {
        arrray.push(SupportedLanguages[i]);
      }

      setSupportedLanguagesArray(arrray);
    }
  }, [SupportedLanguages]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setPleaseWaitPopup(true);
    await put_GeneralSetting({
      id,
      companyName,
      country,
      smsSender,
      timezone,
      language,
      SupportedLanguages: SupportedLanguages?.map((item: any) => item.value).join(","),
      preferredDateFormat,
      metricSystem,
      tentantLogo: filePath,
      pretendEmails: isPretendEmails === "Yes" ? true : false,
      replyToEmail,
      disableSmsFailureNotificationEmails: isDisabledSmsFailureNotificationEmails === "Yes" ? true : false,
      emailForFailureNotifications,
      mailLayout: mainLayout,
      emailSignature,
      mailFooter,
      showLogoOnEmails: isShowLogoOnEmails === "Yes" ? true : false,
      mailBarColor,
      mailBarLogo: emailFilePath,
      mailChimpKey,
      mailgunSender,
      mailgunDomain,
      googleAnalyticsKey,
      extraCCUsersForSuppportTickets: extraCcUsersForSupportTickets,
      websiteHeaderColor,
      websiteHeaderSubColor,
      websiteFooterColor,
      websiteFooterSubColor,
      websiteIndexPageImage,
      websiteSubPageImage,
      websiteEmail,
      websitePhone,
      dataFetchingBranchId: websiteBranch
    })
      .then(() => setPleaseWaitPopup(false))
      .catch(error => console.error("Error:", error));
  }

  return (
    <Box>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <AdminPanelSettings
            sx={{ fontSize: "24px", marginRight: "5px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Settings
          </Typography>
        </Box>
      </Toolbar>

      <div style={{ height: "5px", maxWidth: "100%", minWidth: "100%", background: colorConfigs.sidebar.color }} />

      <form style={{ padding: "24px", width: "75%" }} onSubmit={(e) => handleSubmit(e)}>

        <GeneralSettings
          companyName={companyName}
          setCompanyName={(value) => setCompanyName(value)}
          country={country}
          setCountry={(value) => setCountry(value)}
          smsSender={smsSender}
          setSmsSender={(value) => setSmsSender(value)}
          timezone={timezone}
          setTimezone={(value) => setTimezone(value)}
          language={language}
          setLanguage={(value) => setLanguage(value)}
          supportedLanguagesOptions={supportedLanguagesOptions}
          SupportedLanguages={SupportedLanguages}
          setSupportedLanguages={(value) => setSupportedLanguages(value)}
          supportedLanguagesArray={supportedLanguagesArray}
          setSupportedLanguagesArray={(value) => setSupportedLanguagesArray(value)}
          preferredDateFormat={preferredDateFormat}
          setPreferredDateFormat={(value) => setPreferredDateFormat(value)}
          metricSystem={metricSystem}
          setMetricSystem={(value) => setMetricSystem(value)}
          pleaseWaitPopup={pleaseWaitPopup}
          setPleaseWaitPopup={(value) => setPleaseWaitPopup(value)}
          filePath={filePath}
          setFilePath={(value) => setFilePath(value)}
        />

        <EmailSettings
          isPretendEmails={isPretendEmails}
          setIsPretendEmails={(value) => setIsPretendEmails(value)}
          replyToEmail={replyToEmail}
          setReplyToEmail={(value) => setReplyToEmail(value)}
          isDisabledSmsFailureNotificationEmails={isDisabledSmsFailureNotificationEmails}
          setIsDisabledSmsFailureNotificationEmails={(value) => setIsDisabledSmsFailureNotificationEmails(value)}
          emailForFailureNotifications={emailForFailureNotifications}
          setEmailForFailureNotifications={(value) => setEmailForFailureNotifications(value)}
          mainLayout={mainLayout}
          setMainLayout={(value) => setMainLayout(value)}
          emailSignature={emailSignature}
          setEmailSignature={(value) => setEmailSignature(value)}
          mailFooter={mailFooter}
          setMailFooter={(value) => setMailFooter(value)}
          isShowLogoOnEmails={isShowLogoOnEmails}
          setIsShowLogoOnEmails={(value) => setIsShowLogoOnEmails(value)}
          mailBarColor={mailBarColor}
          setMailBarColor={(value) => setMailBarColor(value)}
          emailFilePath={emailFilePath}
          setEmailFilePath={(value) => setEmailFilePath(value)}
          pleaseWaitPopup={pleaseWaitPopup}
          setPleaseWaitPopup={(value) => setPleaseWaitPopup(value)}
        />

        <Integration
          mailChimpKey={mailChimpKey}
          setMailChimpKey={(value) => setMailChimpKey(value)}
          mailgunSender={mailgunSender}
          setMailgunSender={(value) => setMailgunSender(value)}
          mailgunDomain={mailgunDomain}
          setMailgunDomain={(value) => setMailgunDomain(value)}
          googleAnalyticsKey={googleAnalyticsKey}
          setGoogleAnalyticsKey={(value) => setGoogleAnalyticsKey(value)}
          extraCcUsersForSupportTickets={extraCcUsersForSupportTickets}
          setExtraCcUsersForSupportTickets={(value) => setExtraCcUsersForSupportTickets(value)}
        />

        <Website
          websiteHeaderColor={websiteHeaderColor}
          setWebsiteHeaderColor={setWebsiteHeaderColor}
          websiteHeaderSubColor={websiteHeaderSubColor}
          setWebsiteHeaderSubColor={setWebsiteHeaderSubColor}
          websiteFooterColor={websiteFooterColor}
          setWebsiteFooterColor={setWebsiteFooterColor}
          websiteFooterSubColor={websiteFooterSubColor}
          setWebsiteFooterSubColor={setWebsiteFooterSubColor}
          websiteIndexPageImage={websiteIndexPageImage}
          setWebsiteIndexPageImage={setWebsiteIndexPageImage}
          websiteSubPageImage={websiteSubPageImage}
          setWebsiteSubPageImage={setWebsiteSubPageImage}
          websiteEmail={websiteEmail}
          setWebsiteEmail={setWebsiteEmail}
          websitePhone={websitePhone}
          setWebsitePhone={setWebsitePhone}
          websiteBranch={websiteBranch}
          setWebsiteBranch={setWebsiteBranch}
          setPleaseWaitPopup={setPleaseWaitPopup}
        />

        <Advanced />

        <Security />

        <Button
          variant="contained"
          sx={{
            backgroundColor: colorConfigs.topbar.bg,
            color: "white",
            borderRadius: "0",
            border: "none",
            textTransform: "capitalize",
            marginLeft: "16px",
            marginTop: "10px",
            fontSize: "12px",
            width: "60px",
            "&:hover": {
              backgroundColor: colorConfigs.topbar.bg,
              color: "white",
            },
          }}
          type="submit"
        >
          Save
        </Button>
      </form>

      <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
    </Box>
  );
};

