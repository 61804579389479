import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import DiscountIcon from "@mui/icons-material/Discount";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import baseURL from "../../../../configs/api";
import colorConfigs from "../../../../configs/colorConfigs";

const pages = ["Edit"];

export default function EditDiscounts() {
  const idNumber = useLocation().state;

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [firstTime, setFirstTime] = useState<String>(pages[0]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  const [branch, setBranch] = useState([]);
  const [name, setName] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const [discounttype, setDiscounttype] = useState<string>("Percentage");
  const [discountAmount, setDiscountAmount] = useState<string>("");
  const [couponCode, setCouponCode] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [discountScope, setDiscountScope] = useState<string>("All");
  const [visibleForDirectbooker, setVisibleForDirectbooker] =
    useState<boolean>(false);
  const [order, setOrder] = useState<string>("");
  const [vehicleClassIds, setVehicleClassIds] = useState<string>("");
  const [allowedLocationIds, setAllowedLocationIds] = useState<string>("");
  const [includedAdditionalChargeIds, setIncludedAdditionalChargeIds] =
    useState<string>("");
  const [availableFrom, setAvailableFrom] = useState<string>("");
  const [availableUntil, setAvailableUntil] = useState<string>("");
  const [isReuse, setIsReuse] = useState<boolean>(false);
  const [daysToApplyThisDiscount, setDaysToApplyThisDiscount] = useState<string>("");
  const [itemsselected, setItemsselected] = useState<string>("");
  const [freeAdditionalCharges, setFreeAdditionalCharges] = useState<string>("")
  const [topDaysToApplyThisDiscount, setTopDaysToApplyThisDiscount] = useState<string>("")

  const [
    isSkipAutomaticApplicationBasedonDays,
    setIsSkipAutomaticApplicationBasedonDays,
  ] = useState<boolean>(false);

  const [isClone, setIsClone] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then((response) => {
        setBranch(
          response.data.map((item: any) => {
            return { value: item.id, label: item.branchName };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL}discount/${idNumber}`)
      .then((response) => {
        setName(response.data.name);
        setBranchId(response.data.branchId);
        setDiscounttype(response.data.discounttype);
        setDiscountAmount(response.data.discountAmount);
        setCouponCode(response.data.couponCode);
        setIsActive(response.data.isActive);
        setDiscountScope(response.data.discountScope);
        setVehicleClassIds(response.data.vehicleClassIds);
        setOrder(response.data.order);
        setVisibleForDirectbooker(response.data.visibleForDirectbooker);
        setAllowedLocationIds(response.data.allowedLocationIds);
        setIncludedAdditionalChargeIds(
          response.data.includedAdditionalChargeIds
        );
        setAvailableFrom(response.data.availableFrom.split("T")[0]);
        setAvailableUntil(response.data.availableUntil.split("T")[0]);
        setIsReuse(response.data.isReuse);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isClone) {
      axios
        .post(
          `${baseURL}discount`,
          JSON.stringify({
            name,
            branchId,
            discounttype,
            discountAmount,
            couponCode,
            isActive,
            discountScope,
            visibleForDirectbooker,
            order,
            vehicleClassIds,
            allowedLocationIds,
            includedAdditionalChargeIds,
            availableFrom: availableFrom + "T00:00:00",
            availableUntil: availableUntil + "T00:00:00",
            isReuse,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      axios
        .put(
          `${baseURL}discount`,
          JSON.stringify({
            id: idNumber,
            name,
            branchId,
            discounttype,
            discountAmount,
            couponCode,
            isActive,
            discountScope,
            visibleForDirectbooker,
            order,
            vehicleClassIds,
            allowedLocationIds,
            includedAdditionalChargeIds,
            availableFrom: availableFrom + "T00:00:00",
            availableUntil: availableUntil + "T00:00:00",
            isReuse,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const navigate = useNavigate();

  const deleteHandle = async () => {
    if (
      window.confirm("Are you sure you want to delete this discount?") === true
    ) {
      await axios
        .delete(`${baseURL}discount?id=${idNumber}`)
        .then((response) => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <DiscountIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Discounts / {isClone ? "Add" : "Edit"} Discount
          </Typography>
        </Box>

        {!isClone && (
          <div>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#81D4FA",
                borderRadius: "0",
                textTransform: "capitalize",
                marginRight: "5px",
              }}
              onClick={() => setIsClone(true)}
            >
              Clone
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                borderRadius: "0",
                textTransform: "capitalize",
              }}
              onClick={() => deleteHandle()}
            >
              Delete
            </Button>
          </div>
        )}
      </Toolbar>

      {!isClone && (
        <AppBar
          position="static"
          sx={{
            backgroundColor: colorConfigs.sidebar.bg,
            border: "none",
            paddingLeft: "18px",
            paddingRight: "18px",
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: "black", paddingBlock: "4px" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                paddingTop: "8px",
                paddingLeft: "0px",
                marginLeft: "-20px",
              }}
            >
              {pages.map((page) => {
                if (firstTime === page) {
                  return (
                    <Button
                      disableElevation={false}
                      key={page}
                      onClick={() => handleOnClick(page)}
                      variant="text"
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        paddingBlock: "0px",
                        marginBlock: "0px",
                        borderRadius: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "none",
                        textAlign: "center",

                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                        "&: hover": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      disableElevation={false}
                      key={page}
                      onClick={() => handleOnClick(page)}
                      variant="text"
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        paddingBlock: "0px",
                        marginBlock: "0px",
                        borderRadius: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "none",
                        textAlign: "center",
                        border: "none",
                        "&: focus": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                        "&: active": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  );
                }
              })}
            </Box>
          </Container>
        </AppBar>
      )}

      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "75%",
            marginRight: "3%",
          }}
        >
          <div
            style={{
              width: "100%",
              marginRight: "3%",
            }}
          >
            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginTop: "0px",
                color: "gray",
              }}
            >
              Discount Overview
            </h5>
            <hr />

            <form onSubmit={(e) => handleSubmit(e)}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="branchName"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Branch
                      <br />
                      <select
                        name="branchName"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={branchId}
                        onChange={(e) => setBranchId(e.target.value)}
                      >
                        {branch.map((item: any) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </select>
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="discounttype"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Discount type
                      <br />
                      <select
                        name="discounttype"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={discounttype}
                        onChange={(e) => setDiscounttype(e.target.value)}
                        required
                      >
                        <option value="Percentage">Percentage</option>
                        <option value="Amount">Amount</option>
                      </select>
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="couponCode"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Coupon Code
                      <br />
                      <input
                        type="text"
                        name="couponCode"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="discountScope"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Discount Scope
                      <br />
                      <select
                        name="discountScope"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={discountScope}
                        onChange={(e) => setDiscountScope(e.target.value)}
                        required
                      >
                        <option value="All">All</option>
                      </select>
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="order"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Order
                      <br />
                      <input
                        type="text"
                        name="order"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                        required
                      />
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="name"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Name
                      <br />
                      <input
                        type="text"
                        name="name"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="discountAmount"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Discount
                      <br />
                      <input
                        type="text"
                        name="discountAmount"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={discountAmount}
                        onChange={(e) => setDiscountAmount(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="isActive"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Active?
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="isActive"
                            style={{ fontSize: "12px" }}
                            checked={isActive ? true : false}
                            onChange={(e) =>
                              setIsActive(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="isActive"
                            style={{ fontSize: "12px" }}
                            checked={isActive ? false : true}
                            onChange={(e) =>
                              setIsActive(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="visibleForDirectbooker"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Visible For Direct booker?
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="visibleForDirectbooker"
                            style={{ fontSize: "12px" }}
                            checked={visibleForDirectbooker ? true : false}
                            onChange={(e) =>
                              setVisibleForDirectbooker(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="visibleForDirectbooker"
                            style={{ fontSize: "12px" }}
                            checked={visibleForDirectbooker ? false : true}
                            onChange={(e) =>
                              setVisibleForDirectbooker(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <h5
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  color: "gray",
                }}
              >
                Restrictions
              </h5>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="vehicleClassIds"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Vehicle Classes
                      <br />
                      <input
                        type="text"
                        name="vehicleClassIds"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={vehicleClassIds}
                        onChange={(e) => setVehicleClassIds(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="includedAdditionalChargeIds"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Included Additional Charges
                      <br />
                      <input
                        type="text"
                        name="includedAdditionalChargeIds"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={includedAdditionalChargeIds}
                        onChange={(e) =>
                          setIncludedAdditionalChargeIds(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="itemsselected"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      When Selected, These Items can't be selected
                      <br />
                      <input
                        type="text"
                        name="itemsselected"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={itemsselected}
                        onChange={(e) => setItemsselected(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="availableFrom"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Available From
                      <br />
                      <input
                        type="date"
                        name="availableFrom"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={availableFrom}
                        onChange={(e) => setAvailableFrom(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="daysToApplyThisDiscount"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Days to apply this discount
                      <br />
                      <input
                        type="text"
                        name="daysToApplyThisDiscount"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={daysToApplyThisDiscount}
                        onChange={(e) => setDaysToApplyThisDiscount(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="isSkipAutomaticApplicationBasedonDays"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Skip Automatic Application Based on Days
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="isSkipAutomaticApplicationBasedonDays"
                            style={{ fontSize: "12px" }}
                            checked={
                              isSkipAutomaticApplicationBasedonDays
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              setIsSkipAutomaticApplicationBasedonDays(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="isSkipAutomaticApplicationBasedonDays"
                            style={{ fontSize: "12px" }}
                            checked={
                              isSkipAutomaticApplicationBasedonDays
                                ? false
                                : true
                            }
                            onChange={(e) =>
                              setIsSkipAutomaticApplicationBasedonDays(
                                e.target.value === "Yes" ? true : false
                              )
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="allowedLocationIds"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Locations
                      <br />
                      <input
                        type="text"
                        name="allowedLocationIds"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={allowedLocationIds}
                        onChange={(e) => setAllowedLocationIds(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="freeAdditionalCharges"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Free Additional Charges
                      <br />
                      <input
                        type="text"
                        name="freeAdditionalCharges"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={freeAdditionalCharges}
                        onChange={(e) =>
                          setFreeAdditionalCharges(e.target.value)
                        }
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px", visibility: "hidden" }}>
                    <label
                      htmlFor="maxRentalAllowedAge"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Maximum Rental Allowed Age
                      <br />
                      <input
                        type="number"
                        name="maxRentalAllowedAge"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="availableUntil"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Available Until
                      <br />
                      <input
                        type="date"
                        name="availableUntil"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={availableUntil}
                        onChange={(e) => setAvailableUntil(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="topDaysToApplyThisDiscount"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Top Days to apply this discount
                      <br />
                      <input
                        type="number"
                        name="topDaysToApplyThisDiscount"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={topDaysToApplyThisDiscount}
                        onChange={(e) =>
                          setTopDaysToApplyThisDiscount(e.target.value)
                        }
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="isReuse"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Disable Re-use
                      <br />
                      <div>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="Yes"
                            name="isReuse"
                            style={{ fontSize: "12px" }}
                            checked={isReuse ? true : false}
                            onChange={(e) =>
                              setIsReuse(e.target.value === "Yes" ? true : false)
                            }
                          />{" "}
                          Yes
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="radio"
                            value="No"
                            name="isReuse"
                            style={{ fontSize: "12px" }}
                            checked={isReuse ? false : true}
                            onChange={(e) =>
                              setIsReuse(e.target.value === "Yes" ? true : false)
                            }
                          />{" "}
                          No
                        </label>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                  }}
                  type="submit"
                >
                  Save
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#e0dee0",
                    color: "black",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                  }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>

        <div
          style={{
            width: "25%",
          }}
        ></div>
      </div>
    </>
  );
}
