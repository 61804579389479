import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { LocalPrintshopOutlined, Groups, CloudUploadOutlined } from "@mui/icons-material";
import colorConfigs from "../../../configs/colorConfigs";
import { useLocation, useNavigate } from "react-router-dom";

import Contact from "./Contact"
import CustomerReservations from "./CustomerReservations";
import CreditCards from "./creditCards/CreditCards";
import CustomerFiles from "./CustomerFiles";
import baseURL from "../../../configs/api";
import axois from "../../../configs/axois";
import axios from "axios";
import Notes from "./notes/CustomerNotes";
import CustomerQuotes from "./CustomerQuotes";
import styled from "@emotion/styled";
import PleaseWaitPopup from "../../PleaseWaitPopup";
import useAuth from "../../../components/hooks/useAuth";
import { useDispatch } from "react-redux";
import { setAppState } from "../../../redux/features/appStateSlice";


const pages: string[] = ["Contact", "Reservations", "Quotes", "Credit Cards", "Files", "Notes"];

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export const EditCustomer = () => {
  const dispatch = useDispatch();
  const { auth }: any = useAuth();
  const [addCreditCardPopup, setAddCreditCardPopup] =
    useState<boolean>(false);
  const [addNotePopup, setAddNotePopup] =
    useState<boolean>(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [currentPage, setCurrentPage] = React.useState<String>(pages[0]);
  const [pleaseWaitPopup, setPleaseWaitPopup] = React.useState<boolean>(false);
  const [isFileChanged, setIsFileChanged] = useState<boolean>(false);
  const [newFile, setNewFile] = useState<any>();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setCurrentPage(page);
  };

  const customer = useLocation();
  const [customerData, setCustomerData] = useState<any>();

  useEffect(() => {
    dispatch(setAppState("contacts.Customers"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    axois
      .get(`${baseURL}Customer/${customer.state}`)
      .then((response) => {
        setCustomerData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const deleteTheCustomer = async () => {
    if (window.confirm("Are you sure you want to delete this Customer?") === true) {
      await axios
        .delete(`${baseURL}customer?id=${customerData.id}`)
        .then((response) => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPleaseWaitPopup(true);
    const selectedFiles = event.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}customerFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then(response => saveFileOnFileUploadApi(response.data))
        .catch(error => console.error("Error:", error));
    } else {
      setPleaseWaitPopup(false);
    }
  };

  const saveFileOnFileUploadApi = (fileName: string) => {
    axios
      .post(
        `${baseURL}fileUploadData`,
        JSON.stringify({
          attributeName: "Customer",
          attributeId: customer.state,
          fileName: fileName,
          tabName: "Customer",
          userId: 1,
          createdDate: new Date(),
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(response => {
        setPleaseWaitPopup(false);
        setIsFileChanged(true);
        setNewFile({ fileName: fileName, userId: 1, createdDate: new Date() });
      })
      .catch(error => console.error("Error:", error));
  }

  const navigate = useNavigate();

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Groups sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px", color: "blue" }}>
            Customer{customerData && <span style={{ color: "black" }}>{" - " + customerData.firstName + " " + customerData.lastName}</span>}
          </Typography>
        </Box>

        <Stack direction="row" alignItems="center" spacing={1}>
          {(currentPage === pages[0] || currentPage === pages[1]) && (
            <>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  borderRadius: "0",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                  }
                }}
                onClick={() => {
                  navigate("/carrental/reservations/addreservation");
                }}
              >
                New Reservation
              </Button>

              <IconButton aria-label="print" size="large"
                sx={{
                  width: "40px",
                  height: "35px",
                  borderRadius: 0,
                  border: "1px solid black",
                }}
              >
                <LocalPrintshopOutlined />
              </IconButton>

              {auth.givenname.toLowerCase() === "admin" &&
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    borderRadius: "0",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "red",
                    }
                  }}
                  onClick={() => deleteTheCustomer()}
                >
                  Delete
                </Button>
              }
            </>
          )}

          {currentPage === pages[2] &&
            <IconButton aria-label="menu" size="large"
              sx={{
                width: "40px",
                height: "35px",
                borderRadius: 0,
                border: "1px solid black",
                background: "#e0e0e0"
              }}
            >
              <MenuIcon />
            </IconButton>
          }

          {currentPage === pages[3] && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                borderRadius: "0",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                }
              }}
              onClick={() => setAddCreditCardPopup(true)}
            >
              Add Credit Card
            </Button>
          )}

          {currentPage === pages[4] && (
            <Button
              component="label"
              variant="contained"
              htmlFor="file-upload"
              startIcon={<CloudUploadOutlined />}
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                borderRadius: "0",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                }
              }}
            >
              Upload
              <VisuallyHiddenInput
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Button>
          )}

          {currentPage === pages[5] && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                borderRadius: "0",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                }
              }}
              onClick={() => setAddNotePopup(true)}
            >
              Add Note
            </Button>
          )}
        </Stack>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page: string, index: number) => {
                if (currentPage === page) {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => handleOnClick(page)}
                      sx={{
                        backgroundColor: colorConfigs.sidebar.bg,
                        color: colorConfigs.sidebar.color,
                      }}
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={index} onClick={() => handleOnClick(page)}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page: string, index: number) => {
              if (currentPage === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={index}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={index}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>

      <div style={{ display: currentPage === pages[0] ? "block" : "none" }}>
        <Contact customerData={customerData} />
      </div>

      <div style={{ display: currentPage === pages[1] ? "block" : "none" }}>
        {customerData && <CustomerReservations customerId={customerData.id} />}
      </div>


      <div style={{ display: currentPage === pages[2] ? "block" : "none" }}>
        {customerData && <CustomerQuotes customerId={customerData.id} />}
      </div>

      <div style={{ display: currentPage === pages[3] ? "block" : "none" }}>
        {customerData && <CreditCards customerId={customerData.id} customerName={customerData.firstName + " " + customerData.lastName} addCreditCardPopup={addCreditCardPopup}
          setAddCreditCardPopup={() => setAddCreditCardPopup(false)} />}
      </div>

      <div style={{ display: currentPage === pages[4] ? "block" : "none" }}>
        {customerData && <CustomerFiles customerId={customerData.id} newFile={newFile} isFileChanged={isFileChanged} setIsFileChanged={(value) => setIsFileChanged(value)} />}
      </div>

      <div style={{ display: currentPage === pages[5] ? "block" : "none" }}>
        {customerData && <Notes customerId={customerData.id} addNotePopup={addNotePopup}
          setAddNotePopup={() => setAddNotePopup(false)} />}
      </div>

      <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
    </>
  );
};
