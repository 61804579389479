import axios from "axios"
import baseURL from "../configs/api"

export const post_ReservationAdditionalCharge = (data: any) => {
    return axios({
        url: `${baseURL}reservationAdditionalCharge`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_ReservationAdditionalCharge = (primaryKey: number) => {
    return axios({
        url: `${baseURL}reservationAdditionalCharge`,
        method: "DELETE",
        params: {id: primaryKey}
    }).then(response => response.data);
}

export const getReservationAdditionalCharges = () => {
    return getFetch("reservationAdditionalCharge");
}

export const getReservationAdditionalCharge = (primaryKey: string) => {
    return getFetch(`reservationAdditionalCharge/${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}