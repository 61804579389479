import React, { useEffect, useState } from "react";
import baseURL from "../../../configs/api";
import axois from "../../../configs/axois";

type ViewProps = {
  repairOrderId: string;
};

export const ViewRepairOrder = (props: ViewProps) => {
  const [vehicleOptions, setVehicleOption] = React.useState([]);

  useEffect(() => {
    axois
      .get(`${baseURL}vehicles`)
      .then((response) => {
        setVehicleOption(
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axois
      .get(`${baseURL}vehicleRepairOrder/${props.repairOrderId}`)
      .then((response) => {
        const vehicleValue: any = vehicleOptions.find(
          (item: any) => item.value === response.data.vehicleId
        );
        setVehicle(vehicleValue.value);
    
      })
      .catch((error) => {
        console.error(error);
      });
  }, [vehicleOptions]);

  const [vehicle, setVehicle] = useState<string>("");

  return (
    <div>
      {vehicleOptions.map((item: any) => {
        if (item.value === vehicle) return item.label;
      })}
    </div>
  );
};
