import { InputBase, Box, Stack, Button } from "@mui/material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import baseURL from "../../../../configs/api";
import { ArrowDropDown, Close } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import { Link } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  width: "100%",
  paddingBottom: "0px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  paddingLeft: `calc(1em + ${theme.spacing(2)})`,
  paddingBottom: "0px",
}));

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    editable: false,
    sortable: false,
  },
  {
    field: "label",
    headerName: "Label",
    width: 150,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <Link
          to="/admin/paymentgateways/editpaymentgatewaysform"
          state={params.id}
        >
          {params.value}
        </Link>
      );
    },
  },
  {
    field: "#",
    headerName: "Adapter",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "methods",
    headerName: "Methods",
    type: "number",
    width: 80,
    editable: false,
    sortable: false,
  },
  {
    field: "isActive",
    headerName: "Active?",
    editable: false,
    sortable: false,
    width: 170,
  },
  {
    field: "testMode",
    headerName: "Test Mode",
    width: 170,
    editable: false,
    sortable: false,
  },
  {
    field: "order",
    headerName: "Order",
    width: 120,
    editable: false,
    sortable: false,
  },
];

export default function AllGateways() {
  const [tableData, setTableData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [operatorFilter, setOperatorFilter] = useState<number>(0);

  useEffect(() => {
    fetch(`${baseURL}paymentGateway`)
      .then((res) => res.json())
      .then((data) => {
        setTableData(
          data.map((item: any) => {
            return {
              ...item,
              isActive: item.isActive ? "Yes" : "No",
              testMode: item.testMode ? "Yes" : "No",
            };
          })
        );
        setIsDataLoaded(true);
      });
  }, []);


  return (
    <>
      <Box sx={{ padding: "24px" }}>
        <Stack
          direction="row"
          spacing={3}
          sx={{ border: "1px solid black", alignItems: "center" }}
        >
          <div style={{ position: "relative" }}>
            <Button
              variant="contained"
              endIcon={<ArrowDropDown sx={{ color: "#bdbdbd" }} />}
              sx={{
                color: "black",
                backgroundColor: "#e0dee0",
                textTransform: "unset",
                fontSize: "12px",
                border: "none",
                borderRadius: "0px",

                "&: hover": {
                  backgroundColor: "white",
                },
              }}
            >
              Filter
            </Button>

            <div
              style={{
                display: displayFilter ? "block" : "none",
                width: "120px",
                position: "absolute",
                zIndex: "100",
                top: "-100%",
                left: "100%",
                background: "white",
                border: "1px solid black",
                padding: "8px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="fields"
                    style={{ fontSize: "10px", fontWeight: "bold" }}
                  >
                    Fields
                    <br />
                  </label>

                  <Close
                    sx={{
                      width: "18px",
                      fontSize: "10px",
                      cursor: "pointer",
                      background: "#e0e0e0",
                    }}
                    onClick={() => setDisplayFilter(false)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="operator"
                  style={{ fontSize: "10px", fontWeight: "bold" }}
                >
                  Operator
                  <br />
                  <select
                    name="operator"
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      paddingBlock: "3px",
                    }}
                    value={operatorFilter}
                    onChange={(e) => setOperatorFilter(parseInt(e.target.value))}
                  >
                    <option value="0">In a list</option>
                  </select>
                </label>
              </div>
            </div>
          </div>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Search>
          <Button
            variant="text"
            sx={{
              width: "12%",
              color: "black",
              backgroundColor: "#e0dee0",
              textTransform: "unset",
              fontSize: "12px",
              border: "none",
              borderRadius: "0px",
            }}
          >
            Save this search
          </Button>
        </Stack>

        <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
          {isDataLoaded ? (
            <DataGrid
              rows={tableData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
              disableRowSelectionOnClick
              disableColumnMenu
              checkboxSelection
              showCellVerticalBorder={false}
              sx={{
                border: "none",
                fontSize: "12px",
                wordWrap: "break-word",
                overflow: "visible",
              }}
            />
          ) : (
            <div className="loading">
              <p>Please wait</p>
              <span>
                <i></i>
                <i></i>
              </span>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
}

