import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import colorConfigs from '../../../configs/colorConfigs';
import CloseIcon from "@mui/icons-material/Close";
import DOMPurify from 'dompurify';

type Props = {
    particularAudit: any;
    showPopup: boolean;
    hidePopup: () => void;
}

const AuditDetailsPopup = ({ particularAudit, showPopup, hidePopup }: Props) => {

    const [oldValue, setOldValue] = useState<any>("");
    const [newValue, setNewValue] = useState<any>("");

    useEffect(() => {
        if (showPopup) {
            const oldStr = JSON.stringify(particularAudit?.formattedOldValue, undefined, 2)
            const newStr = JSON.stringify(particularAudit?.formattedNewValue, undefined, 2)

            getStrDiff(oldStr, newStr)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPopup])



    const getStrDiff = (oldStr: string, newStr: string) => {
        let oldStrResult = "";
        let newStrResult = "";

        if (oldStr !== 'null' && newStr !== 'null') {

            const oldJson = JSON.parse(oldStr);
            const newJson = JSON.parse(newStr);

            Object.keys(oldJson).forEach((key, ind) => {
                if (key !== Object.keys(newJson)[ind]) {
                    if (ind === 0) {
                        oldStrResult += `<span style="background-color: #f76b6b; padding: 0 3px;">{<br/> ${key}</span>:`
                        newStrResult += `<span style="background-color: #80d683; padding: 0 3px;">{<br/> ${Object.keys(newJson)[ind]}</span>:`
                    }
                    else {
                        oldStrResult += `<span style="background-color: #f76b6b; padding: 0 3px;">${key}</span>:`
                        newStrResult += `<span style="background-color: #80d683; padding: 0 3px;">${Object.keys(newJson)[ind]}</span>:`
                    }

                }
                else {
                    if (ind === 0) {
                        oldStrResult += `{<br/>${key}:`
                        newStrResult += `{<br/>${key}:`
                    }
                    else {
                        oldStrResult += `${key}:`
                        newStrResult += `${key}:`
                    }
                }
                if (oldJson[key] !== Object.values(newJson)[ind]) {
                    oldStrResult += `<span style="background-color: #f76b6b; padding: 0 3px;">${oldJson[key]}</span>,<br/>`
                    newStrResult += `<span style="background-color: #80d683; padding: 0 3px;">${Object.values(newJson)[ind]}</span>,<br/>`
                }
                else {
                    oldStrResult += `${oldJson[key]},<br/>`
                    newStrResult += `${Object.values(newJson)[ind]},<br/>`
                }


            })

            oldStrResult += '}'
            newStrResult += '}'
        }
        else {

            oldStrResult = `<span style="background-color: #f76b6b; padding: 0 3px;">${oldStr}</span>`

            newStrResult = `<span style="background-color: #80d683; padding: 0 3px;">${newStr}</span>`


        }
        setOldValue(() => DOMPurify.sanitize(oldStrResult, {
            USE_PROFILES: { html: true },
        }))

        setNewValue(() => DOMPurify.sanitize(newStrResult, {
            USE_PROFILES: { html: true },
        }))
    }

    return (
        <>
            <Dialog
                open={showPopup}
                fullScreen={false}
                maxWidth="md"
                PaperProps={{
                    style: {
                        width: "100%",
                        display: "flex",
                        position: "absolute",
                        top: "30px",
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>Audit Details</p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
                            onClick={() => hidePopup()}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                </DialogTitle>
                <DialogContent sx={{ padding: "0px" }}>
                    <div
                        style={{
                            width: "100%",
                            justifyContent: "center",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            overflowY: "auto",
                            maxHeight: "320px"
                        }}
                    >

                        <div style={{
                            flexDirection: "column",
                        }}>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "8px",
                                    marginTop: "10px"
                                }}
                            >
                                <div style={{ width: "50%" }}>
                                    <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Audit User</p>
                                    <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularAudit?.auditUser}</p>
                                </div>

                                <div style={{ width: "50%" }}>
                                    <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Audit Type</p>
                                    <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularAudit?.auditType}</p>
                                </div>

                            </div>

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "8px",
                                    marginTop: "5px"
                                }}
                            >
                                <div style={{ width: "50%" }}>
                                    <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Table Name</p>
                                    <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularAudit?.tableName}</p>
                                </div>

                                <div style={{ width: "50%" }}>
                                    <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Date</p>
                                    <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularAudit?.auditDateTimeUtc}</p>
                                </div>

                            </div>

                        </div>

                        <div style={{
                            width: "100%",
                            marginTop: "5px"
                        }}>
                            <div style={{ width: "50%" }}>
                                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px", marginTop: "5px" }}>Event Count</p>
                                <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularAudit?.keyValues}</p>
                            </div>

                        </div>

                        <div style={{
                            wordWrap: "break-word"
                        }}>

                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px", marginTop: "5px" }}>Path</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularAudit?.changedColumns}</p>

                        </div>


                        <div
                            style={{
                                width: "100%",
                                marginTop: "15px",
                                marginBottom: "10px",
                                flexDirection: "row",
                                gap: "8px",
                                display: "flex",
                                wordWrap: "break-word"
                            }}
                        >

                            <div style={{ width: "50%" }}>
                                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Old Value</p>
                                <span style={{ fontSize: "12px", marginBlock: "0px", whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{
                                    __html: oldValue
                                }} />
                            </div>

                            <div style={{ width: "50%" }}>
                                <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>New Value</p>
                                <span style={{ fontSize: "12px", marginBlock: "0px", whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{
                                    __html: newValue
                                }} />
                            </div>

                        </div>


                    </div>



                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >
                        <div style={{ width: "25%" }} >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => hidePopup()}
                            >
                                Cancel
                            </Button>
                        </div>


                    </div>
                </DialogContent>
            </Dialog >


        </>
    )
}

export default AuditDetailsPopup