import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import baseURL from "../../../../configs/api";
import { Button, Dialog, DialogContent } from "@mui/material";
import colorConfigs from "../../../../configs/colorConfigs";
import axois from "../../../../configs/axois";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

export default function ReturnTemplates() {
    const [logo, setLogo] = useState<string>("");
    const [logoInput, setLogoInput] = useState<string>("Logo");
    const [logoObj, setLogoObj] = useState<any>();
    const [logoFirst, setLogoFirst] = useState<string>("");
    const [logoFirstInput, setLogoFirstInput] = useState<string>("Title First");
    const [logoFirstObj, setLogoFirstObj] = useState<any>();
    const [logoSecond, setLogoSecond] = useState<string>("");
    const [logoSecondInput, setLogoSecondInput] = useState<string>("Title Second");
    const [logoSecondObj, setLogoSecondObj] = useState<any>();
    const [rentalInformation, setRentalInformation] = useState<string>("");
    const [rentalInformationInput, setRentalInformationInput] =
      useState<string>("Rental Information");
    const [rentalInformationObj, setRentalInformationObj] = useState<any>();
    const [rentalInformationSecond, setRentalInformationSecond] =
      useState<string>("");
    const [rentalInformationSecondInput, setRentalInformationSecondInput] =
      useState<string>("Rental Information");
    const [rentalInformationSecondObj, setRentalInformationSecondObj] =
      useState<any>();
    const [footer, setFooter] = useState<string>("");
    const [footerObj, setFooterObj] = useState<any>();
    const [vehicleInformation, setVehicleInformation] = useState<string>("");
    const [vehicleInformationInput, setVehicleInformationInput] =
      useState<string>("Vehicle Information");
    const [vehicleInformationObj, setVehicleInformationObj] = useState<any>();
    const [chargeInformation, setChargeInformation] = useState<string>("");
    const [chargeInformationInput, setChargeInformationInput] =
      useState<string>("Rental Summary");
    const [chargeInformationObj, setChargeInformationObj] = useState<any>();
    const [signature, setSignature] = useState<string>("");
    const [signatureInput, setSignatureInput] = useState<string>("Signature");
    const [signatureObj, setSignatureObj] = useState<any>();
  
    const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);
    const [getValue, setGetValue] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      setPleaseWaitPopup(true);

      if (footerObj) {
        await axios
          .put(
            `${baseURL}returnReceipt`,
            JSON.stringify({
              id: footerObj?.id,
              receiptName: "Footer",
              receiptNote: footer,
              createdDate: new Date(),
              createdByUserId: 1,
              updatedDate: new Date(),
              updatedByUserId: 1,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        if (rentalInformation || rentalInformationInput) {
          await axios
            .post(
              `${baseURL}returnReceipt`,
              JSON.stringify({
                receiptName: "Footer",
                receiptNote: footer,
                createdDate: new Date(),
                createdByUserId: 1,
                updatedDate: new Date(),
                updatedByUserId: 1,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
  
      if (rentalInformationObj) {
        await axios
          .put(
            `${baseURL}returnReceipt`,
            JSON.stringify({
              id: rentalInformationObj?.id,
              receiptName: rentalInformationInput,
              receiptNote: rentalInformation,
              createdDate: new Date(),
              createdByUserId: 1,
              updatedDate: new Date(),
              updatedByUserId: 1,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        if (rentalInformation || rentalInformationInput) {
          await axios
            .post(
              `${baseURL}returnReceipt`,
              JSON.stringify({
                receiptName: rentalInformationInput,
                receiptNote: rentalInformation,
                createdDate: new Date(),
                createdByUserId: 1,
                updatedDate: new Date(),
                updatedByUserId: 1,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
  
      if (rentalInformationSecondObj) {
        await axios
          .put(
            `${baseURL}returnReceipt`,
            JSON.stringify({
              id: rentalInformationSecondObj?.id,
              receiptName: rentalInformationSecondInput,
              receiptNote: rentalInformationSecond,
              createdDate: new Date(),
              createdByUserId: 1,
              updatedDate: new Date(),
              updatedByUserId: 1,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        if (rentalInformationSecond || rentalInformationSecondInput) {
          await axios
            .post(
              `${baseURL}returnReceipt`,
              JSON.stringify({
                receiptName: rentalInformationSecondInput,
                receiptNote: rentalInformationSecond,
                createdDate: new Date(),
                createdByUserId: 1,
                updatedDate: new Date(),
                updatedByUserId: 1,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
  
      if (vehicleInformationObj) {
        await axios
          .put(
            `${baseURL}returnReceipt`,
            JSON.stringify({
              id: vehicleInformationObj?.id,
              receiptName: vehicleInformationInput,
              receiptNote: vehicleInformation,
              createdDate: new Date(),
              createdByUserId: 1,
              updatedDate: new Date(),
              updatedByUserId: 1,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        if (vehicleInformation || vehicleInformationInput) {
          await axios
            .post(
              `${baseURL}returnReceipt`,
              JSON.stringify({
                receiptName: vehicleInformationInput,
                receiptNote: vehicleInformation,
                createdDate: new Date(),
                createdByUserId: 1,
                updatedDate: new Date(),
                updatedByUserId: 1,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
  
      if (chargeInformationObj) {
        await axios
          .put(
            `${baseURL}returnReceipt`,
            JSON.stringify({
              id: chargeInformationObj?.id,
              receiptName: chargeInformationInput,
              receiptNote: chargeInformation,
              createdDate: new Date(),
              createdByUserId: 1,
              updatedDate: new Date(),
              updatedByUserId: 1,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        if (chargeInformation || chargeInformationInput) {
          await axios
            .post(
              `${baseURL}returnReceipt`,
              JSON.stringify({
                receiptName: chargeInformationInput,
                receiptNote: chargeInformation,
                createdDate: new Date(),
                createdByUserId: 1,
                updatedDate: new Date(),
                updatedByUserId: 1,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then((response) => {
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
  
      if (signatureObj) {
        await axios
          .put(
            `${baseURL}returnReceipt`,
            JSON.stringify({
              id: signatureObj?.id,
              receiptName: signatureInput,
              receiptNote: signature,
              createdDate: new Date(),
              createdByUserId: 1,
              updatedDate: new Date(),
              updatedByUserId: 1,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        if (signature || signatureInput) {
          await axios
            .post(
              `${baseURL}returnReceipt`,
              JSON.stringify({
                receiptName: signatureInput,
                receiptNote: signature,
                createdDate: new Date(),
                createdByUserId: 1,
                updatedDate: new Date(),
                updatedByUserId: 1,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
  
      if (logoObj) {
        await axios
          .put(
            `${baseURL}returnReceipt`,
            JSON.stringify({
              id: logoObj?.id,
              receiptName: logoInput,
              receiptNote: logo,
              createdDate: new Date(),
              createdByUserId: 1,
              updatedDate: new Date(),
              updatedByUserId: 1,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        if (logo) {
          await axios
            .post(
              `${baseURL}returnReceipt`,
              JSON.stringify({
                receiptName: logoInput,
                receiptNote: logo,
                createdDate: new Date(),
                createdByUserId: 1,
                updatedDate: new Date(),
                updatedByUserId: 1,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
  
      if (logoFirstObj) {
        await axios
          .put(
            `${baseURL}returnReceipt`,
            JSON.stringify({
              id: logoFirstObj?.id,
              receiptName: logoFirstInput,
              receiptNote: logoFirst,
              createdDate: new Date(),
              createdByUserId: 1,
              updatedDate: new Date(),
              updatedByUserId: 1,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        if (logoFirst) {
          await axios
            .post(
              `${baseURL}returnReceipt`,
              JSON.stringify({
                receiptName: logoFirstInput,
                receiptNote: logoFirst,
                createdDate: new Date(),
                createdByUserId: 1,
                updatedDate: new Date(),
                updatedByUserId: 1,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
  
      if (logoSecondObj) {
        await axios
          .put(
            `${baseURL}returnReceipt`,
            JSON.stringify({
              id: logoSecondObj?.id,
              receiptName: logoSecondInput,
              receiptNote: logoSecond,
              createdDate: new Date(),
              createdByUserId: 1,
              updatedDate: new Date(),
              updatedByUserId: 1,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            setPleaseWaitPopup(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        if (logoSecond) {
          await axios
            .post(
              `${baseURL}returnReceipt`,
              JSON.stringify({
                receiptName: logoSecondInput,
                receiptNote: logoSecond,
                createdDate: new Date(),
                createdByUserId: 1,
                updatedDate: new Date(),
                updatedByUserId: 1,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
              setPleaseWaitPopup(false);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
  
    };
  
    useEffect(() => {
      setPleaseWaitPopup(true);
  
      axois
        .get(`${baseURL}returnReceipt`)
        .then((response) => {
          setRentalInformationObj(
            () =>
              response.data.filter(
                (item: any) =>
                  item.receiptName !== "Footer"
              )[7]
          );
          setRentalInformationSecondObj(
            () =>
              response.data.filter(
                (item: any) =>
                  item.receiptName !== "Footer"
              )[6]
          );
          setVehicleInformationObj(
            () =>
              response.data.filter(
                (item: any) =>
                  item.receiptName !== "Footer"
              )[5]
          );
          setChargeInformationObj(
            () =>
              response.data.filter(
                (item: any) =>
                  item.receiptName !== "Footer"
              )[4]
          );
          setSignatureObj(
            () =>
              response.data.filter(
                (item: any) =>
                  item.receiptName !== "Footer"
              )[3]
          );
          setLogoObj(() => 
          response.data.filter(
            (item: any) =>
              item.receiptName !== "Footer"
          )[2])
          setLogoFirstObj(() =>
          response.data.filter(
            (item: any) =>
              item.receiptName !== "Footer"
          )[1])
           setLogoSecondObj(() =>
           response.data.filter(
            (item: any) =>
              item.receiptName !== "Footer"
          )[0]
          );
          setFooterObj(() =>
            response.data.find((item: any) => item.receiptName === "Footer")
          );
  
          setGetValue(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }, []);
  
    useEffect(() => {
      if (pleaseWaitPopup && getValue) {
        setLogo(() => logoObj?.receiptNote);
        setLogoInput(() => logoObj?.receiptName)
        setLogoFirst(() => logoFirstObj?.receiptNote);
        setLogoFirstInput(() => logoFirstObj?.receiptName);
        setLogoSecond(() => logoSecondObj?.receiptNote);
        setLogoSecondInput(() => logoSecondObj?.receiptName)
        setRentalInformation(() => rentalInformationObj?.receiptNote);
        setRentalInformationInput(() => rentalInformationObj?.receiptName);
        setRentalInformationSecond(
          () => rentalInformationSecondObj?.receiptNote
        );
        setRentalInformationSecondInput(
          () => rentalInformationSecondObj?.receiptName
        );
        setFooter(() => footerObj?.receiptNote);
        setVehicleInformation(() => vehicleInformationObj?.receiptNote);
        setVehicleInformationInput(() => vehicleInformationObj?.receiptName);
        setChargeInformation(() => chargeInformationObj?.receiptNote);
        setChargeInformationInput(() => chargeInformationObj?.receiptName);
        setSignature(() => signatureObj?.receiptNote);
        setSignatureInput(() => signatureObj?.receiptName);
  
        setPleaseWaitPopup(false);
        setGetValue(false);
      }
    }, [pleaseWaitPopup, getValue]);
  
    return (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: "24px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              width: "100%",
              marginRight: "3%",
            }}
          >
            <h3
              style={{
                paddingBottom: "0px",
                marginBlock: "0px",
                fontSize: "12px",
                color: "gray",
              }}
            >
              Overview
            </h3>
            <hr />
  
            <form onSubmit={(e) => handleSubmit(e)}>
              <div
                style={{
                  width: "100%",
                  height: "400px",
                  display: "flex",
                  flexDirection: "row",
                  padding: "16px",
                  gap: "16px",
                  border: "1px black dashed",
                }}
              >
                <div style={{ width: "33%", height: "300px" }}>

                <input
                      type="text"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                        marginBottom: "8px",
                      }}
                      value={logoInput}
                      onChange={(e) => setLogoInput(e.target.value)}
                    />
  
                  <ReactQuill
                    style={{ height: "225px" }}
                    theme="snow"
                    modules={modules}
                    defaultValue={logo}
                    value={logo}
                    onChange={setLogo}
                  />
                </div>
  
                <div style={{ width: "33%", height: "300px" }}>
                <input
                      type="text"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                        marginBottom: "8px",
                      }}
                      value={logoFirstInput}
                      onChange={(e) => setLogoFirstInput(e.target.value)}
                    />
  
                  <ReactQuill
                    style={{ height: "225px" }}
                    theme="snow"
                    modules={modules}
                    defaultValue={logoFirst}
                    value={logoFirst}
                    onChange={setLogoFirst}
                  />
                </div>
  
                <div style={{ width: "33%", height: "300px" }}>
                <input
                      type="text"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                        marginBottom: "8px",
                      }}
                      value={logoSecondInput}
                      onChange={(e) => setLogoSecondInput(e.target.value)}
                    />
  
                  <ReactQuill
                    style={{ height: "225px" }}
                    theme="snow"
                    modules={modules}
                    defaultValue={logoSecond}
                    value={logoSecond}
                    onChange={setLogoSecond}
                  />
                </div>
              </div>
  
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "16px",
                  marginTop: "40px",
                  padding: "16px 16px 80px 16px",
                  border: "1px black dashed",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                        marginBottom: "8px",
                      }}
                      value={rentalInformationInput}
                      onChange={(e) => setRentalInformationInput(e.target.value)}
                    />
  
                    <ReactQuill
                      style={{ height: "225px" }}
                      theme="snow"
                      modules={modules}
                      defaultValue={rentalInformation}
                      value={rentalInformation}
                      onChange={setRentalInformation}
                    />
                  </div>
  
                  <div style={{ marginTop: "80px" }}>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                        marginBottom: "8px",
                      }}
                      value={rentalInformationSecondInput}
                      onChange={(e) =>
                        setRentalInformationSecondInput(e.target.value)
                      }
                    />
  
                    <ReactQuill
                      style={{ height: "225px" }}
                      theme="snow"
                      modules={modules}
                      defaultValue={rentalInformationSecond}
                      value={rentalInformationSecond}
                      onChange={setRentalInformationSecond}
                    />
                  </div>
  
                  <div style={{ marginTop: "80px" }}>
  
                    <ReactQuill
                      style={{ height: "225px" }}
                      theme="snow"
                      modules={modules}
                      defaultValue={footer}
                      value={footer}
                      onChange={setFooter}
                    />
                  </div>
                </div>
  
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                        marginBottom: "8px",
                      }}
                      value={chargeInformationInput}
                      onChange={(e) => setChargeInformationInput(e.target.value)}
                    />
  
                    <ReactQuill
                      style={{ height: "225px" }}
                      theme="snow"
                      defaultValue={chargeInformation}
                      value={chargeInformation}
                      modules={modules}
                      onChange={setChargeInformation}
                    />
                  </div>
  
                  <div style={{ marginTop: "80px" }}>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                        marginBottom: "8px",
                      }}
                      value={vehicleInformationInput}
                      onChange={(e) => setVehicleInformationInput(e.target.value)}
                    />
  
                    <ReactQuill
                      style={{ height: "225px" }}
                      theme="snow"
                      defaultValue={vehicleInformation}
                      value={vehicleInformation}
                      modules={modules}
                      onChange={setVehicleInformation}
                    />
                  </div>
  
                  <div style={{ marginTop: "80px" }}>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                        marginBottom: "8px",
                      }}
                      value={signatureInput}
                      onChange={(e) => setSignatureInput(e.target.value)}
                    />
  
                    <ReactQuill
                      style={{ height: "225px" }}
                      theme="snow"
                      defaultValue={signature}
                      value={signature}
                      modules={modules}
                      onChange={setSignature}
                    />
                  </div>
                </div>
              </div>
  
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  marginTop: "16px",
                }}
                type="submit"
              >
                Save
              </Button>
            </form>
          </div>
        </div>
  
        <Dialog
          open={pleaseWaitPopup}
          maxWidth="md"
          fullScreen={false}
          PaperProps={{
            style: {
              width: "32%",
            },
          }}
        >
          <DialogContent sx={{ padding: "5px" }}>
            <div
              style={{
                padding: "8px",
                backgroundColor: "white",
                color: "black",
                textAlign: "center",
              }}
            >
              Please Wait...
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
  
