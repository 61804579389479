import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { getUsers } from "../services/getUsers";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";

type Props = {
  id: number;
  userId: string;
  createdDate: string;
  notes: string;
  deleteTheComment: (id: number) => void;
};

export default function AddComments({ id, userId, createdDate, notes, deleteTheComment }: Props) {
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    getUsers()
      .then(data => setUsers(
        data.map((item: any) => {
          return {
            id: item.id,
            userName: item?.firstName + " " + item?.lastName
          }
        }
        ))
      )
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "50%" }}>
          <h6 style={{ marginBlock: "0px", fontSize: "12px" }}>{users?.find((item: any) => item.id === parseInt(userId))?.userName}</h6>
          <p style={{ fontSize: "12px", marginTop: "0px" }}>{notes}</p>
        </div>
        <div
          style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}
        >
          <p style={{ fontSize: "12px", marginTop: "0px" }}>
            {format(new Date(createdDate), "MM/dd/yyyy kk:mm a")}
          </p>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              width: "7%",
              marginBlock: "0px",
            }}
          >
            <RemoveCircleOutlinedIcon
              sx={{
                fontSize: "12px",
                color: "gray",
                cursor: "pointer",
                "&: hover": {
                  color: "red",
                },
              }}
              onClick={() => deleteTheComment(id)}
            />
          </p>
        </div>
      </div>

      <hr />
    </>
  );

}
