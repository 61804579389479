import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import baseURL from "../../../../configs/api";

import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { getActiveElement } from "@mui/x-data-grid/internals";
import AddNote from "./AddNote";
import { format } from "date-fns";

type Props = {
    customerId: string;
    addNotePopup: boolean;
    setAddNotePopup: () => void;
};

export default function CustomerNotes({ customerId, addNotePopup, setAddNotePopup }: Props) {
    const [tableData, setTableData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isSaved, setIsSaved] = useState<boolean>(true);

    const columns: GridColDef[] = [
        {
            field: "notes",
            headerName: "Notes Text",
            width: 700,
            editable: false,
            sortable: false,
        },
        {
            field: "createdDate",
            headerName: "Date",
            width: 300,
            editable: false,
            sortable: false,
        },
    ];

    useEffect(() => {
        if (isSaved) {
            fetch(`${baseURL}comment/Customer,${customerId}`)
                .then((res) => res.json())
                .then((data) => {
                    setTableData(() =>
                        data?.map((item: any) => {
                            return {
                                ...item,
                                notes: item.notes.replace(/<[^>]+>/g, ''),
                                createdDate: format(
                                    new Date(item.createdDate),
                                    "MM/dd/yyyy KK:mm a"
                                ),
                            }
                        })
                    );
                    setIsDataLoaded(true);
                    setIsSaved(false);
                });
        }
    }, [isSaved]);

    const handleOnClick = (data: unknown) => {
        console.log(data);
    };

    return (
        <>
            <Box sx={{ padding: "24px" }}>
                <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
                    {isDataLoaded ? (
                        <>
                            <hr style={{ marginBlock: "0px" }} />
                            <DataGrid
                                rows={tableData}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                                disableRowSelectionOnClick
                                disableColumnMenu
                                showColumnVerticalBorder
                                onRowClick={() => handleOnClick(getActiveElement)}
                                showCellVerticalBorder={true}
                                sx={{
                                    border: "none",
                                    fontSize: "12px",
                                    wordWrap: "break-word",
                                    overflow: "visible",
                                }}
                            />
                        </>
                    ) : (
                        <div className="loading">
                            <p>Please wait</p>
                            <span>
                                <i></i>
                                <i></i>
                            </span>
                        </div>
                    )}
                </Box>

            </Box>

            <AddNote
                customerId={customerId}
                addNotePopup={addNotePopup}
                setAddNotePopup={() => {
                    setAddNotePopup();
                    setIsSaved(true);
                }} />

        </>
    );
};