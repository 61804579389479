import React, { useEffect, useRef, useState } from 'react'
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
import { commonFileUrl, post_CommonFile } from '../../../services/commonFile';
import { getBranches } from '../../../services/branch';

type Props = {
    websiteHeaderColor: string;
    setWebsiteHeaderColor: (value: string) => void;
    websiteHeaderSubColor: string;
    setWebsiteHeaderSubColor: (value: string) => void;
    websiteFooterColor: string;
    setWebsiteFooterColor: (value: string) => void;
    websiteFooterSubColor: string;
    setWebsiteFooterSubColor: (value: string) => void;
    websiteIndexPageImage: string;
    setWebsiteIndexPageImage: (value: string) => void;
    websiteSubPageImage: string;
    setWebsiteSubPageImage: (value: string) => void;
    websiteEmail: string;
    setWebsiteEmail: (value: string) => void;
    websitePhone: string;
    setWebsitePhone: (value: string) => void;
    websiteBranch: string;
    setWebsiteBranch: (value: string) => void;
    setPleaseWaitPopup: (value: boolean) => void;
}

const Website = ({
    websiteHeaderColor,
    setWebsiteHeaderColor,
    websiteHeaderSubColor,
    setWebsiteHeaderSubColor,
    websiteFooterColor,
    setWebsiteFooterColor,
    websiteFooterSubColor,
    setWebsiteFooterSubColor,
    websiteIndexPageImage,
    setWebsiteIndexPageImage,
    websiteSubPageImage,
    setWebsiteSubPageImage,
    websiteEmail,
    setWebsiteEmail,
    websitePhone,
    setWebsitePhone,
    websiteBranch,
    setWebsiteBranch,
    setPleaseWaitPopup,
}: Props) => {
    const [isWebsite, setIsWebsite] = useState<boolean>(true);
    const [branches, setBranches] = useState<any[]>([]);

    useEffect(() => {
        getBranches()
            .then(data => setBranches(
                data.map((item: any) => ({
                    value: item.id,
                    label: item.branchName
                }))
            ))
            .catch(error => console.error(error));
    }, [])

    const websiteHeaderColorRef = useRef<HTMLInputElement>(null);
    const websiteHeaderSubColorRef = useRef<HTMLInputElement>(null);
    const websiteFooterColorRef = useRef<HTMLInputElement>(null);
    const websiteFooterSubColorRef = useRef<HTMLInputElement>(null);

    const websiteIndexPageImageRef = useRef<HTMLInputElement>(null);
    const onWebsiteIndexPageImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPleaseWaitPopup(true);
        const selectedFiles = e.target.files as FileList;

        if (selectedFiles.length !== 0) {
            const formData = new FormData();
            formData.append("ImageFile", selectedFiles[0]);

            post_CommonFile(formData)
                .then(data => {
                    setWebsiteIndexPageImage(data);
                    setPleaseWaitPopup(false);
                })
                .catch(error => console.error("Error:", error));
        } else {
            setPleaseWaitPopup(false);
        }
    };

    const websiteSubPageImageRef = useRef<HTMLInputElement>(null);
    const onWebsiteSubPageImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPleaseWaitPopup(true);
        const selectedFiles = e.target.files as FileList;

        if (selectedFiles.length !== 0) {
            const formData = new FormData();
            formData.append("ImageFile", selectedFiles[0]);

            post_CommonFile(formData)
                .then(data => {
                    setWebsiteSubPageImage(data);
                    setPleaseWaitPopup(false);
                })
                .catch(error => console.error("Error:", error));
        } else {
            setPleaseWaitPopup(false);
        }
    };

    return (
        <>
            <h5
                style={{
                    marginBottom: "0px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    cursor: "pointer",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                }}
                onClick={() => setIsWebsite(!isWebsite)}
            >
                {isWebsite ? (
                    <ExpandMore sx={{ fontSize: "20px" }} />
                ) : (
                    <KeyboardArrowRight sx={{ fontSize: "20px" }} />
                )}{" "}
                Website
            </h5>
            <hr />

            {isWebsite &&
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
                >

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="websiteEmail"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Website Email
                                <br />
                                <input
                                    type="email"
                                    name="websiteEmail"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={websiteEmail}
                                    onChange={(e) => setWebsiteEmail(e.target.value)}
                                />
                            </label>
                        </div>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="websitePhone"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Website Phone
                                <br />
                                <input
                                    type="text"
                                    name="websitePhone"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={websitePhone}
                                    onChange={(e) => setWebsitePhone(e.target.value)}
                                />
                            </label>
                        </div>

                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="websiteBranch"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Website Branch
                                <br />
                                <select
                                    name="websiteBranch"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={websiteBranch}
                                    onChange={(e) => setWebsiteBranch(e.target.value)}
                                >
                                    {branches.map((item: any) => (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </label>
                        </div>

                        <div style={{ width: "50%" }} />

                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="websiteHeaderColor"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Website Header Color
                                <br />
                                <input
                                    type="color"
                                    name="websiteHeaderColor"
                                    ref={websiteHeaderColorRef}
                                    style={{ display: "none" }}
                                    value={websiteHeaderColor}
                                    onChange={(e) => setWebsiteHeaderColor(e.target.value)}
                                />

                                <div style={{
                                    background: websiteHeaderColor,
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                }}
                                    onClick={() => websiteHeaderColorRef.current?.click()}
                                />
                            </label>
                        </div>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="websiteHeaderSubColor"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Website Header SubColor
                                <br />
                                <input
                                    type="color"
                                    name="websiteHeaderSubColor"
                                    ref={websiteHeaderSubColorRef}
                                    style={{ display: "none" }}
                                    value={websiteHeaderSubColor}
                                    onChange={(e) => setWebsiteHeaderSubColor(e.target.value)}
                                />

                                <div style={{
                                    background: websiteHeaderSubColor,
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                }}
                                    onClick={() => websiteHeaderSubColorRef.current?.click()}
                                />
                            </label>
                        </div>

                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="websiteFooterColor"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Website Footer Color
                                <br />
                                <input
                                    type="color"
                                    name="websiteFooterColor"
                                    ref={websiteFooterColorRef}
                                    style={{ display: "none" }}
                                    value={websiteFooterColor}
                                    onChange={(e) => setWebsiteFooterColor(e.target.value)}
                                />
                                <div style={{
                                    background: websiteFooterColor,
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                }}
                                    onClick={() => websiteFooterColorRef.current?.click()}
                                />
                            </label>
                        </div>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="websiteFooterSubColor"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Website Footer SubColor
                                <br />
                                <input
                                    type="color"
                                    name="websiteFooterSubColor"
                                    ref={websiteFooterSubColorRef}
                                    style={{ display: "none" }}
                                    value={websiteFooterSubColor}
                                    onChange={(e) => setWebsiteFooterSubColor(e.target.value)}
                                />

                                <div style={{
                                    background: websiteFooterSubColor,
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                }}
                                    onClick={() => websiteFooterSubColorRef.current?.click()}
                                />
                            </label>
                        </div>

                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            marginTop: "10px"
                        }}>

                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                gap: "8px"
                            }}>
                                <label
                                    htmlFor="tenantLogo"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Website IndexPage Image
                                </label>

                                {!websiteIndexPageImage ?
                                    <label
                                        style={{
                                            color: "blue",
                                            fontSize: "12px",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => websiteIndexPageImageRef.current?.click()}
                                    >
                                        Add Image

                                    </label>
                                    :
                                    <label
                                        style={{
                                            color: "blue",
                                            fontSize: "12px",
                                            cursor: "pointer"
                                        }}
                                        onClick={(e) => setWebsiteIndexPageImage("")}
                                    >
                                        Delete
                                    </label>
                                }

                            </div>

                            <div
                                style={{
                                    maxWidth: "50%",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                {websiteIndexPageImage &&
                                    <img
                                        src={`${commonFileUrl + websiteIndexPageImage}`}
                                        width={200}
                                        height={125}
                                        alt="websiteIndexPageImage"
                                    />
                                }
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                name="websiteIndexPageImage"
                                ref={websiteIndexPageImageRef}
                                onChangeCapture={onWebsiteIndexPageImageChange}
                                style={{ display: "none" }}
                            />

                        </div>

                        <div style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            marginTop: "10px"
                        }}>

                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                gap: "8px"
                            }}>
                                <label
                                    htmlFor="tenantLogo"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Website SubPage Image
                                </label>

                                {!websiteSubPageImage ?
                                    <label
                                        style={{
                                            color: "blue",
                                            fontSize: "12px",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => websiteSubPageImageRef.current?.click()}
                                    >
                                        Add Image

                                    </label>
                                    :
                                    <label
                                        style={{
                                            color: "blue",
                                            fontSize: "12px",
                                            cursor: "pointer"
                                        }}
                                        onClick={(e) => setWebsiteSubPageImage("")}
                                    >
                                        Delete
                                    </label>
                                }

                            </div>

                            <div
                                style={{
                                    maxWidth: "50%",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                {websiteSubPageImage &&
                                    <img
                                        src={`${commonFileUrl + websiteSubPageImage}`}
                                        width={200}
                                        height={125}
                                        alt="websiteSubPageImage"
                                    />
                                }
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                name="websiteSubPageImage"
                                ref={websiteSubPageImageRef}
                                onChangeCapture={onWebsiteSubPageImageChange}
                                style={{ display: "none" }}
                            />

                        </div>

                    </div>

                </div>
            }
        </>
    )
}

export default Website