import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import colorConfigs from "../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../configs/api";
import AddIdentification from "../../contacts/customers/AddIdentification";
import { AddRelation } from "../../contacts/customers/AddRelation";
import axois from "../../../configs/axois";
import { format } from "date-fns";
import useAuth from "../../../components/hooks/useAuth";
import PhoneNumberInput from "../../../components/common/PhoneNumberInput";

type ExtraInformation = {
  id: number;
  idNumber: string;
  issueDate: string;
  exprationDate: string;
  filePath: string;
  idTypeId: string;
  customerId: number;
  type: string;
  imageSize: number,
  uploadedAt: string,
  uploadedBy: string
};

type Props = {
  handleNewCustomer: (customerId: string) => void;
  closeNewCustomer: () => void;
  customerId: string;
  isFromDriver: boolean;
};

const pages = ["Contact"];

const monthNameOptions = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const daysOptions: any = [];
for (let i = 1; i <= 31; i++) {
  daysOptions.push({ value: i, label: i });
}

const yearOptions: any = [];
for (let i = 1940; i <= new Date().getFullYear(); i++) {
  yearOptions.push({ value: i, label: i });
}

export const AddNewCustomer = ({
  handleNewCustomer,
  closeNewCustomer,
  customerId,
  isFromDriver,
}: Props) => {
  const { auth }: any = useAuth();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [Street, setStreet] = useState<string>("");
  const [street2, setStreet2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [isAddRelationShown, setIsAddRelationShown] = useState<boolean>(false);

  const [extraRow, setExtraRow] = useState<ExtraInformation[]>([]);
  const [allExtraValue, setAllExtraValue] = useState<boolean>(false);
  const [indexNumber, setIndexNumber] = useState<number>(1);

  const handleAddExtraRow = () => {
    const data: ExtraInformation = {
      id: indexNumber,
      idNumber: "",
      issueDate: "",
      exprationDate: "",
      filePath: "",
      idTypeId: "",
      customerId: 1,
      type: "POST",
      imageSize: 0,
      uploadedAt: `${format(new Date(), "MM/dd/yyyy")} ${format(new Date(), "hh: mm: ss a")}`,
      uploadedBy: auth.givenname,
    };

    setExtraRow((extraRow: ExtraInformation[]) => [...extraRow, data]);

    setIndexNumber(() => indexNumber + 1);
  };

  const handleDeleteExtraRow = (index: number) => {
    const newRow: ExtraInformation[] = extraRow.filter(
      (row: ExtraInformation) => row.id !== index
    );
    setExtraRow(newRow);
  };

  const changeOnExtraValue = (row: ExtraInformation) => {
    setExtraRow((extraRow) =>
      extraRow.map((rowValue: ExtraInformation) => {
        return rowValue.id === row.id ? row : rowValue;
      })
    );
  };

  useEffect(() => {
    if (customerId) {
      axois
        .get(`${baseURL}Customer/${customerId}`)
        .then((response) => {
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
          setEmailAddress(response.data.emailAddress);
          setPhoneNumber(response.data.phoneNumber);
          setStreet(response.data.street);
          setStreet2(response.data.street2);
          setCity(response.data.city);
          setState(response.data.state);
          setCountry(response.data.country);
          setZip(response.data.zip);
          setYear(new Date(response.data.birthday).getFullYear().toString());
          setDay(new Date(response.data.birthday).getDate().toString());
          setMonth((new Date(response.data.birthday).getMonth() + 1).toString());
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setFirstName("");
      setLastName("");
      setEmailAddress("");
      setPhoneNumber("");
      setStreet("");
      setStreet2("");
      setCity("");
      setState("");
      setCountry("");
      setZip("");

      setYear("");
      setDay("");
      setMonth("");
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId) {
      axois
        .get(`${baseURL}customerExtraInformation`)
        .then((response) => {
          setExtraRow(
            response.data.map((item: ExtraInformation, index: number) => {
              return {
                id: index + 1,
                idNumber: item.idNumber,
                issueDate: format(new Date(item.issueDate), "yyyy-MM-dd"),
                exprationDate: format(
                  new Date(item.exprationDate),
                  "yyyy-MM-dd"
                ),
                filePath: item.filePath,
                idTypeId: item.idTypeId,
                customerId: item.customerId,
                type: "PUT" + " " + item.id,
                imageSize: item.imageSize,
                uploadedAt: item.uploadedAt,
                uploadedBy: item.uploadedBy
              };
            })
          );

          setExtraRow((extraRow) =>
            extraRow.filter(
              (item: ExtraInformation) =>
                parseInt(customerId) === item.customerId
            )
          );

          setAllExtraValue(true);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setAllExtraValue(false);
      setIndexNumber(1);
      setExtraRow([]);
    }
  }, [customerId]);

  useEffect(() => {
    const lastIndexValue: any = extraRow.find(
      (item: ExtraInformation) => extraRow[extraRow.length - 1] === item
    );
    setIndexNumber(lastIndexValue?.id + 1);
  }, [allExtraValue]);

  const handleTheCustomerSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    if (isFromDriver) {
      if (customerId) {
        handleNewCustomer(customerId);
        closeNewCustomer();
      } else {
        saveNewCustomer();
      }
    } else {
      saveNewCustomer();
    }
  };

  const saveNewCustomer = async () => {
    const customerExtraInformations = extraRow.map((item: any) => {
      return {
        idNumber: item.idNumber,
        issueDate: new Date(item.issueDate),
        exprationDate: new Date(item.exprationDate),
        filePath: item.filePath,
        idTypeId: item.idTypeId,
        customerId: item.customerId,
        imageSize: item.imageSize,
        uploadedAt: new Date(item.uploadedAt),
        uploadedBy: item.uploadedBy
      };
    });

    await axios
      .post(
        `${baseURL}Customer`,
        JSON.stringify({
          firstName,
          lastName,
          emailAddress,
          phoneNumber,
          Street,
          street2,
          city,
          state,
          country,
          zip,
          birthday: `${year}-${day}-${month}`,
          customerExtraInformations,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        handleNewCustomer(response.data.id);
        closeNewCustomer();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h3
            style={{
              paddingBottom: "0px",
              marginBlock: "0px",
              fontSize: "12px",
            }}
          >
            Customer Information
          </h3>
          <hr />

          <form onSubmit={(e) => handleTheCustomerSubmit(e)}>
            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            >

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="firstName"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    First Name
                    <br />
                    <input
                      type="text"
                      name="firstName"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="lastName"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Last Name
                    <br />
                    <input
                      type="text"
                      name="lastName"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </label>
                </div>

              </div>

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="emailAddress"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Email Address
                    <br />
                    <input
                      type="email"
                      name="emailAddress"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="phoneNumber"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Phone Number
                    <br />


                    <PhoneNumberInput
                      name={"phoneNumber"}
                      value={phoneNumber}
                      setValue={setPhoneNumber}
                      fontSize={"14px"}
                      paddingBlock={"5px"}
                      required={true} />
                  </label>
                </div>

              </div>

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="Street"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Street
                    <br />
                    <input
                      type="text"
                      name="Street"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={Street}
                      onChange={(e) => setStreet(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="street2"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Street 2
                    <br />
                    <input
                      type="text"
                      name="Street2"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={street2}
                      onChange={(e) => setStreet2(e.target.value)}
                    />
                  </label>
                </div>

              </div>

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="city"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    City
                    <br />
                    <input
                      type="text"
                      name="city"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="state"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    State
                    <br />
                    <input
                      type="text"
                      name="state"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      required
                    />
                  </label>
                </div>

              </div>

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="zip"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Zip
                    <br />
                    <input
                      type="text"
                      name="zip"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="country"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Country
                    <br />
                    <input
                      type="text"
                      name="country"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    />
                  </label>
                </div>

              </div>

              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "10px"
              }}>

                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <div style={{ width: "33.33%" }}>
                    <label
                      htmlFor="birthday"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Birthday
                      <br />
                      <Select
                        styles={{
                          control: (base) => ({ ...base, fontSize: "12px" }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        name="birthday"
                        value={monthNameOptions.find(
                          (item) => item.value === month
                        )}
                        onChange={(month: any) =>
                          setMonth(month ? month.value : "")
                        }
                        options={monthNameOptions}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ width: "33.33%" }}>
                    <br />
                    <Select
                      styles={{
                        control: (base) => ({ ...base, fontSize: "12px" }),
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      name="birthday"
                      value={daysOptions.find(
                        (item: any) => item.value === parseInt(day)
                      )}
                      onChange={(day: any) =>
                        setDay(day ? day.value : "")
                      }
                      options={daysOptions}
                      required
                    />
                  </div>

                  <div style={{ width: "33.33%" }}>
                    <br />
                    <Select
                      styles={{
                        control: (base) => ({ ...base, fontSize: "12px" }),
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      name="birthday"
                      value={yearOptions.find(
                        (item: any) => item.value === parseInt(year)
                      )}
                      onChange={(year: any) =>
                        setYear(year ? year.value : "")
                      }
                      options={yearOptions}
                      required
                    />
                  </div>
                </div>

              </div>

            </div>

            <h3
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                marginTop: "32px",
                fontSize: "12px",
              }}
            >
              Extra Information
            </h3>
            <hr />
            <label style={{ fontSize: "12px" }}>Driver license</label>

            <table>
              <tr>
                <th style={{ width: "15%" }}>Type</th>
                <th style={{ width: "25%" }}>ID Number</th>
                <th style={{ width: "15%" }}>Issued On</th>
                <th style={{ width: "15%" }}>Exp. Date</th>
                <th style={{ width: "35%" }}>File</th>
              </tr>

              {extraRow.map((row: ExtraInformation) => {
                return (
                  <AddIdentification
                    key={row.id}
                    itemIndex={row.id}
                    handleDeleteExtraRow={(index) =>
                      handleDeleteExtraRow(index)
                    }
                    row={row}
                    changeOnExtraValue={(row) => changeOnExtraValue(row)}
                  />
                );
              })}
            </table>
            <div
              style={{
                height: "40px",
                borderStyle: "none dashed dashed dashed",
                borderWidth: "1px",
                borderColor: "#D5D4D8",
                marginTop: "-10px",
                paddingTop: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleAddExtraRow()}
              >
                <AddOutlinedIcon
                  sx={{
                    fontSize: "12px",
                    textAlign: "center",
                    paddingTop: "3px",
                  }}
                />
                Add another Identification
              </p>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => closeNewCustomer()}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div style={{ display: isAddRelationShown ? "block" : "none" }}>
        <AddRelation
          handleAllRelationShowClick={() => setIsAddRelationShown(false)}
          showPopup={isAddRelationShown}
        />
      </div>
    </>
  );
};
