import React, { useState } from 'react'
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";

const Advanced = () => {
    const [isAdvanced, setIsAdvanced] = useState<boolean>(false);

    return (
        <>
            <h5
                style={{
                    marginBottom: "0px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    cursor: "pointer",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                }}
                onClick={() => setIsAdvanced(!isAdvanced)}
            >
                {isAdvanced ? (
                    <ExpandMore sx={{ fontSize: "20px" }} />
                ) : (
                    <KeyboardArrowRight sx={{ fontSize: "20px" }} />
                )}{" "}
                Advanced
            </h5>
            <hr />

            {isAdvanced &&
                <div>
                    Advanced
                </div>
            }
        </>
    )
}

export default Advanced