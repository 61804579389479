import { Box, Button, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import colorConfigs from "../../../../configs/colorConfigs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../../configs/api";

export const AddRates = () => {  
  const [seasonId, setSeasonId] = useState<string>("");  
  const [vehicleClassId, setVehicleClassId] = useState<string>("");
  const [hourlyRate, setHourlyRate] = useState<string>("");
  const [weeklyRate, setWeeklyRate] = useState<string>("");
  const [dailyRate, setDailyRate] = useState<string>("");
  const [monthilyRate, setMonthilyRate] = useState<string>("");
  const [decreasingRateBasedOnIntervalPerHour, setDecreasingRateBasedOnIntervalPerHour] = 
  useState(false);
  const [decreasingRateBasedOnIntervalPerDay, setDecreasingRateBasedOnIntervalPerDay] = 
  useState(false);
  

  const [seasonOptions, setSeasonOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}season`)
      .then((response) => {
        setSeasonOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name};
          })
        );

        setSeasonId(response.data[0].id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);   

  
  const [vehicleClassOptions, setVehicleClassOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}vehicleClass`)
      .then((response) => {
        setVehicleClassOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );

        setVehicleClassId(response.data[0].id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);   

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}rate`,
        JSON.stringify({
          seasonId,
          vehicleClassId,
          hourlyRate,
          weeklyRate,
          monthilyRate,
          dailyRate,
          decreasingRateBasedOnIntervalPerHour,
          decreasingRateBasedOnIntervalPerDay
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <CreditCardIcon
            sx={{ fontSize: "24px", marginRight: "5px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Rates / Add Rates
          </Typography>
        </Box>
      </Toolbar>
      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingTop: "10px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px",fontSize: "18px" }}>
           General Settings
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="season"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Season
                      <br />
                      <Select
                        styles={{
                          menu: (base) => ({ ...base, fontSize: "12px" }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={false}
                        name="season"
                        value={seasonOptions.find(
                          (item: any) => item.value === seasonId
                        )}
                        onChange={(season: any) =>
                          setSeasonId(
                            season ? season.value : ""
                          )
                        }
                        options={seasonOptions}
                        required
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="vehicleClass"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Vehicle Class
                      <br />
                      <Select
                styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                className="basic-single"
                classNamePrefix="select"
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={false}
                name="vehicle"
                value={vehicleClassOptions.find(
                  (item: any) => item.value === parseInt(vehicleClassId)
                )}
                onChange={(vehicle: any) =>
                  setVehicleClassId(vehicle ? vehicle.value : "")
                }
                options={vehicleClassOptions}
                required
              />
                    </label>
                  </div>
                  </div>
                </div>
                     <div
                        style={{
                        width: "100%",
                        marginRight: "3%",
                        }}
                      >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px",fontSize: "18px" }}>
           Rates
          </h3>
          <hr />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >

               <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Decreasing Rate Based on Interval (Per Hour)
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="enabledHour"
                        style={{ fontSize: "12px" }}
                        checked={decreasingRateBasedOnIntervalPerHour}
                        onChange={(e) =>
                          setDecreasingRateBasedOnIntervalPerHour(e.target.value === 'Yes')
                        }
                      />
                      Yes
                    </label>                    
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="enabledHour"
                        style={{ fontSize: "12px" }}
                        checked={!decreasingRateBasedOnIntervalPerHour}
                        onChange={(e) =>
                          setDecreasingRateBasedOnIntervalPerHour(e.target.value === 'Yes')
                        }
                      />
                      No
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="hourlyRate"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Hourly Rate ($)
                    <br />
                    <input
                      type="text"
                      name="hourlyRate"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={hourlyRate}
                      onChange={(e) => setHourlyRate(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="weeklyRate"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Weekly Rate ($)
                    <br />
                    <input
                      type="text"
                      name="weeklyRate"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={weeklyRate}
                      onChange={(e) => setWeeklyRate(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
              

              <div
                style={{
                  width: "50%",
                }}
              >
                 <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Decreasing Rate Based on Interval (Per Day)
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="enabledDay"
                        style={{ fontSize: "12px" }}
                        checked={decreasingRateBasedOnIntervalPerDay}
                        onChange={(e) =>
                          setDecreasingRateBasedOnIntervalPerDay(e.target.value === 'Yes')
                        }
                      />
                      Yes
                    </label>
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="enabledDay"
                        style={{ fontSize: "12px" }}
                        checked={!decreasingRateBasedOnIntervalPerDay}
                        onChange={(e) =>
                          setDecreasingRateBasedOnIntervalPerDay(e.target.value === 'Yes')
                        }
                      />
                      No
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="dailyRate"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Daily Rate ($)
                    <br />
                    <input
                      type="text"
                      name="dailyRate"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={dailyRate}
                      onChange={(e) => setDailyRate(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="monthlyRate"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Monthly Rate ($)
                    <br />
                    <input
                      type="text"
                      name="monthlyRate"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={monthilyRate}
                      onChange={(e) => setMonthilyRate(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
            </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
