import { Box, Button, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Select from "react-select";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import colorConfigs from "../../../configs/colorConfigs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../configs/api";
import axois from "../../../configs/axois";

type CommentType = {
  attributeName: string;
  attributeId: string;
  notes: string;
  userId: string;
  createdDate: string;
};

const fuelLevelOptions: any = [];
for (let i = 0; i <= 8; i++) {
  fuelLevelOptions.push(<option key={i} value={i}>{i}</option>);
}

export const AddVehicles = () => {
  const [vin, setvin] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [vehicleKey, setVehicleKey] = useState<string>("");
  const [licensePlate, setLicensePlate] = useState<string>("");
  const [vehicleMarkeId, setVehicleMarkeId] = useState("");
  const [vehicleModel, setVehicleModel] = useState<any>();
  const [vehicleEngine, setVehicleEngine] = useState<any>();
  const [vehicleClassId, setVehicleClassId] = useState(null);
  const [vehiclSecondaryClassId, setVehiclSecondaryClassId] = useState(null);
  const [vehicleTypeId, setVehicleTypeId] = useState<string>("");
  const [availableDate, setAvailableDate] = useState<string>("");
  const [availableUntil, setAvailableUtil] = useState<string>("");
  const [vehicleColor, setColor] = useState<string>("");
  const [odometer, setOdometer] = useState<number>(0);
  const [statusId, setStatusId] = useState<string>("");
  const [fuelLevel, setFuelLevel] = useState<string>("");
  const [currentLocations, setCurrentLocations] = useState<any[]>([]);
  const [currentLocationId, setCurrentLocationId] = useState<string>("");
  const [vehicleYear, setVehicleYear] = useState<string>("");
  const [lastMaintenanceOdoMeter, setLastMaintenanceOdoMeter] =
    useState<number>(0);
  const [lastMaintenanceDate, setLastMaintenanceDate] = useState<string>("");
  const [lastWheelRotationOdoMeter, setLastWheelRotationOdoMeter] =
    useState<number>(0);
  const [lastWheelRotationDate, setLastWheelRotationDate] =
    useState<string>("");
  const [lastWheelAlignmentOdoMeter, setLastWheelAlignmentOdoMeter] =
    useState<number>(0);
  const [lastWheelAlignmentDate, setLastWheelAlignmentDate] =
    useState<string>("");

  const [vehicleClassOptions, setVehicleClassOptions] = useState<any[]>([]);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<any[]>([]);
  const [vehicleMakeOptions, setVehicleMakeOptions] = useState<any[]>([]);
  const [availableVehicleMakeOptions, setAvailableVehicleMakeOptions] =
    useState<any[]>([]);
  const [vehicleModeOptions, setVehicleModeOptions] = useState<any[]>([]);
  const [availableVehicleModeOptions, setAvailableVehicleModeOptions] =
    useState<any[]>([]);
  const [vehicleEngineOptions, setVehicleEngineOptions] = useState<any[]>([]);
  const [availableVehicleEngineOptions, setAvailableVehicleEngineOptions] =
    useState<any[]>([]);
  const [vehicleStatus, setVehicleStatus] = useState<any[]>([]);

  useEffect(() => {
    axois
      .get(`${baseURL}location`)
      .then((response) => {
        setCurrentLocations(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.locationName };
          })
        );

        setCurrentLocationId(response.data[0]?.id);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axois
      .get(`${baseURL}vehicleClass`)
      .then((response) => {
        setVehicleClassOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axois
      .get(`${baseURL}vehicleType`)
      .then((response) => {
        setVehicleTypeOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axois
      .get(`${baseURL}vehicleMake`)
      .then((response) => {
        setVehicleMakeOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.makeName };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (vehicleMakeOptions) {
      setAvailableVehicleMakeOptions(() => vehicleMakeOptions);
    }
  }, [vehicleMakeOptions]);

  useEffect(() => {
    axois
      .get(`${baseURL}vehicleModel`)
      .then((response) => {
        setVehicleModeOptions(() =>
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.modelName,
              vehicleMakeId: item.vehicleMakeId,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (vehicleModeOptions && vehicleMakeOptions) {
      setAvailableVehicleModeOptions(() =>
        vehicleModeOptions.filter(
          (item: any) => item.vehicleMakeId === vehicleMarkeId
        )
      );
    }
  }, [vehicleModeOptions, vehicleMarkeId]);

  useEffect(() => {
    setVehicleModel(() => availableVehicleModeOptions[0]);
  }, [availableVehicleModeOptions]);

  useEffect(() => {
    axois
      .get(`${baseURL}vehicleEngine`)
      .then((response) => {
        setVehicleEngineOptions(() =>
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.engineName,
              vehicleModelId: item.vehicleModelId,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (vehicleEngineOptions && vehicleModeOptions) {
      setAvailableVehicleEngineOptions(() =>
        vehicleEngineOptions.filter(
          (item: any) => item.vehicleModelId === vehicleModel?.value
        )
      );
    }
  }, [vehicleEngineOptions, vehicleModeOptions, vehicleModel]);

  useEffect(() => {
    setVehicleEngine(() => availableVehicleEngineOptions[0]);
  }, [availableVehicleEngineOptions]);

  useEffect(() => {
    axois
      .get(`${baseURL}vehicleStatus`)
      .then((response) => {
        setVehicleStatus(() =>
          response.data
            .map((item: any) => {
              return {
                value: item.id,
                label: item.statusName,
                attributeName: item.attributeName,
              };
            })
            .filter((value: any) => value.attributeName === "Vehicle")
        );

        setStatusId(() => response.data[0].id);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await axios
      .post(
        `${baseURL}vehicles`,
        JSON.stringify({
          name,
          vin,
          vehicleKey,
          licensePlate,
          vehicleMarkeId,
          vehicleModelId: vehicleModel?.value,
          vehicleEngineId: vehicleEngine?.value,
          vehicleClassId,
          vehiclSecondaryClassId,
          vehicleTypeId,
          odometer,
          fuelLevel,
          statusId,
          vehicleColor,
          availableDate: `${availableDate}T00:00:00`,
          availableUntil: `${availableUntil}T00:00:00`,
          currentLocationId,
          vehicleYear,
          lastMaintenanceOdoMeter,
          lastMaintenanceDate: lastMaintenanceDate
            ? `${lastMaintenanceDate}T00:00:00`
            : lastMaintenanceDate,
          lastWheelRotationOdoMeter,
          lastWheelRotationDate: lastWheelRotationDate
            ? `${lastWheelRotationDate}T00:00:00`
            : lastWheelRotationDate,
          lastWheelAlignmentOdoMeter,
          lastWheelAlignmentDate: lastWheelAlignmentDate
            ? `${lastWheelAlignmentDate}T00:00:00`
            : lastWheelAlignmentDate,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <DirectionsCarIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Add Vehicle
          </Typography>
        </Box>
      </Toolbar>

      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "50%",
            marginRight: "3%",
          }}
        >
          <h3>Vehicle Overview</h3>
          <hr />

          <form
            onSubmit={(e) => handleSubmit(e)}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="vin"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Vin
                  <br />
                  <input
                    type="text"
                    name="vin"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={vin}
                    onChange={(e) => setvin(e.target.value)}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor=" vehicleKey"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Vehicle Key
                  <br />
                  <input
                    type="text"
                    name="vehicleKey"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={vehicleKey}
                    onChange={(e) => {
                      setVehicleKey(e.target.value);
                      setName(e.target.value);
                    }}
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="licensePlate"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  License Plate
                  <br />
                  <input
                    type="text"
                    name="licensePlate"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={licensePlate}
                    onChange={(e) => setLicensePlate(e.target.value)}
                    required
                  />
                </label>
              </div>

              <div style={{ visibility: "hidden", width: "50%" }}>
                <label
                  htmlFor="vehicleYear"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Vehicle Year
                  <br />
                  <input
                    type="text"
                    name="vehicleYear"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={vehicleYear}
                    onChange={(e) => setVehicleYear(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="vehicleMake"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Vehicle Make
                  <br />
                  <Select
                    styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    name="vehicleMake"
                    value={availableVehicleMakeOptions.find(
                      (item) => item.value === vehicleMarkeId
                    )}
                    onChange={(vehicleMarke: any) =>
                      setVehicleMarkeId(vehicleMarke ? vehicleMarke.value : "")
                    }
                    options={availableVehicleMakeOptions}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="vehicleMode"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Vehicle Model
                  <br />
                  <Select
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        backgroundColor: "#000",
                      }),
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                    name="vehicleMode"
                    value={vehicleModel}
                    onChange={(value: any) => setVehicleModel(value)}
                    options={availableVehicleModeOptions}
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="vehicleEngine"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Vehicle Engine
                  <br />
                  <Select
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        backgroundColor: "#000",
                      }),
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                    name="vehicleEngine"
                    value={vehicleEngine}
                    onChange={(value: any) => setVehicleEngine(value)}
                    options={availableVehicleEngineOptions}
                  />
                </label>
              </div>

              <div style={{ visibility: "hidden", width: "50%" }}>
                <label style={{ fontSize: "12px", fontStyle: "bold" }}>
                  Secondary Vehicle Class
                  <br />
                  <Select
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        backgroundColor: "#000",
                      }),
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="vehicleClass"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Vehicle Class
                  <br />
                  <Select
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        backgroundColor: "#000",
                      }),
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                    name="vehicleClass"
                    value={vehicleClassOptions.find(
                      (item) => item.value === vehicleClassId
                    )}
                    onChange={(vehicleClass: any) =>
                      setVehicleClassId(vehicleClass ? vehicleClass.value : "")
                    }
                    options={vehicleClassOptions}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="vehiclSecondaryClass"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Secondary Vehicle Class
                  <br />
                  <Select
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        backgroundColor: "#000",
                      }),
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                    name="vehiclSecondaryClass"
                    value={vehicleClassOptions.find(
                      (item) => item.value == vehiclSecondaryClassId
                    )}
                    onChange={(vehicleClass: any) =>
                      setVehiclSecondaryClassId(
                        vehicleClass ? vehicleClass.value : ""
                      )
                    }
                    options={vehicleClassOptions}
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="vehicleType"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Vehicle Type
                  <br />
                  <select
                    name="vehicleType"
                    id="cars"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={vehicleTypeId}
                    onChange={(e) => setVehicleTypeId(e.target.value)}
                  >
                    {vehicleTypeOptions.map((item: any) => {
                      return <option value={item.value}>{item.label}</option>;
                    })}
                  </select>
                </label>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="vehicleYear"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Vehicle Year
                  <br />
                  <input
                    type="text"
                    name="vehicleYear"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={vehicleYear}
                    onChange={(e) => setVehicleYear(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%", marginRight: "3%" }}>
                  <label
                    htmlFor="year"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Avaliable Date{" "}
                    <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                    <br />
                    <input
                      type="date"
                      name="year"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={availableDate}
                      onChange={(e) => setAvailableDate(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="year"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Avaliable Until{" "}
                    <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                    <br />
                    <input
                      type="date"
                      name="year"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={availableUntil}
                      onChange={(e) => setAvailableUtil(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="color"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Color
                  <br />
                  <input
                    type="text"
                    name="color"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={vehicleColor}
                    onChange={(e) => setColor(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="odemeter"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Odometer
                  <br />
                  <input
                    type="number"
                    name="odemeter"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={odometer}
                    onChange={(e) => setOdometer(e.target.valueAsNumber)}
                    required
                  />
                </label>
              </div>

              <div style={{ visibility: "hidden", width: "50%" }}>
                <label
                  htmlFor=" vehicle"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Vehicle Key
                  <br />
                  <input
                    type="text"
                    name="vehicle"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="status"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Status
                  <br />
                  <select
                    name="status"
                    id="cars"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={statusId}
                    onChange={(e) => setStatusId(e.target.value)}
                    required
                  >
                    {vehicleStatus.map((item: any) => {
                      return <option key={item.value} value={item.value}>{item.label}</option>;
                    })}
                  </select>
                </label>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="status"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Fuel Level
                  <br />
                  <select
                    name="status"
                    id="cars"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={fuelLevel}
                    onChange={(e) => setFuelLevel(e.target.value)}
                    required
                  >
                    {fuelLevelOptions}
                  </select>
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="currentLocation"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Current Location
                  <br />
                  <select
                    name="currentLocation"
                    id="cars"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={currentLocationId}
                    onChange={(e) => setCurrentLocationId(e.target.value)}
                    required
                  >
                    {currentLocations.map((item: any) => {
                      return <option key={item.value} value={item.value}>{item.label}</option>
                    })}
                  </select>
                </label>
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  visibility: "hidden",
                }}
              >
                <div style={{ width: "50%", marginRight: "3%" }}>
                  <label
                    htmlFor="year"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Vehicle Entry Date{" "}
                    <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                    <br />
                    <input
                      type="date"
                      name="year"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="year"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Vehicle Exit Date
                    <br />
                    <input
                      type="date"
                      name="year"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="lastMaintenanceOdoMeter"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Last Maintenance OdoMeter
                  <br />
                  <input
                    type="number"
                    name="lastMaintenanceOdoMeter"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={lastMaintenanceOdoMeter}
                    onChange={(e) =>
                      setLastMaintenanceOdoMeter(e.target.valueAsNumber)
                    }
                    required
                  />
                </label>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="lastMaintenanceDate"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Last Maintenance Date
                  <br />
                  <input
                    type="date"
                    name="lastMaintenanceDate"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={lastMaintenanceDate}
                    onChange={(e) => setLastMaintenanceDate(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="lastWheelRotationOdoMeter"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Last Wheel Rotation OdoMeter
                  <br />
                  <input
                    type="number"
                    name="lastWheelRotationOdoMeter"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={lastWheelRotationOdoMeter}
                    onChange={(e) =>
                      setLastWheelRotationOdoMeter(e.target.valueAsNumber)
                    }
                    required
                  />
                </label>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="lastWheelRotationDate"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Last Wheel Rotation Date
                  <br />
                  <input
                    type="date"
                    name="lastWheelRotationDate"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={lastWheelRotationDate}
                    onChange={(e) => setLastWheelRotationDate(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="lastWheelAlignmentOdoMeter"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Last Wheel Alignment OdoMeter
                  <br />
                  <input
                    type="number"
                    name="lastWheelAlignmentOdoMeter"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={lastWheelAlignmentOdoMeter}
                    onChange={(e) =>
                      setLastWheelAlignmentOdoMeter(e.target.valueAsNumber)
                    }
                    required
                  />
                </label>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="lastWheelAlignmentDate"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Last Wheel Alignment Date
                  <br />
                  <input
                    type="date"
                    name="lastWheelAlignmentDate"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={lastWheelAlignmentDate}
                    onChange={(e) => setLastWheelAlignmentDate(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  marginTop: "20px",
                }}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </div>

        <div
          style={{
            width: "50%",
          }}
        >
          <h3>Maintenance History</h3>
          <hr />

          <table>
            <tr>
              <th>Type</th>
              <th>Last Service At</th>
              <th>Next Service At</th>
            </tr>
          </table>
          <div
            style={{
              height: "40px",
              borderStyle: "none dashed dashed dashed",
              borderWidth: "1px",
              borderColor: "#D5D4D8",
              marginTop: "-10px",
              paddingTop: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "12px", textAlign: "center" }}>
              <AddOutlinedIcon
                sx={{
                  fontSize: "12px",
                  textAlign: "center",
                  paddingTop: "3px",
                }}
              />
              Add
            </p>
          </div>

        </div>
      </div>
    </>
  );
};