import {
  Box,
  Toolbar,
  Typography,
  Stack,
  Button,
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import React from "react";
import colorConfigs from "../../../../configs/colorConfigs";
import { DisabledUsers } from "./DisabledUsers";
import { AllUsers } from "./AllUsers";
import { EnabledUsers } from "./EnabledUsers";
import { useNavigate } from "react-router-dom";

const pages = ["Enabled", "Disabled", "All"];

export const Users = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const [firstTime, setFirstTime] = React.useState<String>(pages[0]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  return (
    <Box>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PeopleAltIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Users
          </Typography>
        </Box>

        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs.topbar.bg,
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => navigate("/admin/usermanagement/users/adduser")}
          >
            Add User
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#e0dee0",
              color: "black",
              borderRadius: "0",
              border: "none",
            }}
          >
            <MenuIcon />
          </Button>
        </Stack>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => {
                if (firstTime === page) {
                  return (
                    <MenuItem
                      key={page}
                      onClick={() => handleOnClick(page)}
                      sx={{
                        backgroundColor: colorConfigs.sidebar.bg,
                        color: colorConfigs.sidebar.color,
                      }}
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={page} onClick={() => handleOnClick(page)}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>

        </Container>
      </AppBar>

      {
        firstTime === pages[0] && (
          <EnabledUsers />
        )
      }

      {
        firstTime === pages[1] && (
          <DisabledUsers />
        )
      }

      {
        firstTime === pages[2] && (
          <AllUsers />
        )
      }

    </Box>
  );
};
