import {
  Box,
  Toolbar,
  Typography,
  Stack,
  Button,
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MenuIcon from "@mui/icons-material/Menu";
import colorConfigs from "../../../../configs/colorConfigs";
import { AllFleetLocations } from "./AllFleetLocations";
import axios from "axios";
import baseURL from "../../../../configs/api";

export const FleetLocations = () => {
  const [pages, setPages] = React.useState<any[]>([{ id: 0, name: "All" }]);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [currentPage, setCurrentPage] = React.useState<any>(pages[0]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [oldPage, setOldPage] = useState<number>(-1);
  const [searchTableData, setSearchTableData] = useState<any[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [totalLength, setTotalLength] = useState<number>(0);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: any) => {
    setAnchorElNav(null);
    setCurrentPage(page);
    setSearchValue("");
    setPaginationModel({
      page: 0,
      pageSize: 10,
    })
  };

  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then((response) => {
        response.data.forEach((item: any) => {
          setPages((pages) => [
            ...pages,
            { id: item.id, name: "JJ Van Rental-" + item.branchName },
          ]);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchValue === "" && !isFirstTime) {
      const newPaginationModel = {
        page: 0,
        pageSize: paginationModel.pageSize,
      };

      setPaginationModel(newPaginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setIsFirstTime(false);
      apiCall(paginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const apiCall = (newPaginationModel: any) => {

    setIsDataLoaded(true);
    if (currentPage.id === 0) {
      fetch(`${baseURL}location/GetLocationsReportPaging/${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.locationReportDto.map((item: any) => {
            return {
              ...item,
              address: `${item.street} ${item.city} ${item.state} ${item.zipCode} ${item.country}`,
              isAirport: item.isAirport ? "Yes" : "No",
              isActive: item.isActive ? "Yes" : "No",
              allowPickupDuringClosedHours: item.allowPickupDuringClosedHours
                ? "Yes"
                : "No",
              allowReturnDuringClosedHours: item.allowReturnDuringClosedHours
                ? "Yes"
                : "No",
              isShowOnWebsite: item.isShowOnWebsite ? "Yes" : "No",
            };
          })

          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
    else {
      fetch(`${baseURL}location/GetLocationsReportPagingWithBranchId/${currentPage.id},${paginationModel.page},${paginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.locationReportDto.map((item: any) => {
            return {
              ...item,
              address: `${item.street} ${item.city} ${item.state} ${item.zipCode} ${item.country}`,
              isAirport: item.isAirport ? "Yes" : "No",
              isActive: item.isActive ? "Yes" : "No",
              allowPickupDuringClosedHours: item.allowPickupDuringClosedHours
                ? "Yes"
                : "No",
              allowReturnDuringClosedHours: item.allowReturnDuringClosedHours
                ? "Yes"
                : "No",
              isShowOnWebsite: item.isShowOnWebsite ? "Yes" : "No",
            };
          })

          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
  }

  return (
    <Box>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LocationCityIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Fleet Locations
          </Typography>
        </Box>

        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs.topbar.bg,
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => {
              navigate("/admin/fleet/fleetlocations/addfleetlocation");
            }}
          >
            Add Location
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#e0dee0",
              color: "black",
              borderRadius: "0",
              border: "none",
            }}
          >
            <MenuIcon />
          </Button>
        </Stack>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page: any) => {
                if (currentPage.id === page.id) {
                  return (
                    <MenuItem
                      key={page.id}
                      onClick={() => handleOnClick(page)}
                      sx={{
                        backgroundColor: colorConfigs.sidebar.bg,
                        color: colorConfigs.sidebar.color,
                      }}
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={page.id} onClick={() => handleOnClick(page)}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (currentPage.id === page.id) {
                return (
                  <Button
                    disableElevation={false}
                    key={page.id}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page.id}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                );
              }
            })}
          </Box>

        </Container>
      </AppBar>

      <AllFleetLocations
        branchId={currentPage.id}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        totalLength={totalLength}
        setTotalLength={setTotalLength}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setSearchTableData={setSearchTableData}
        oldPage={oldPage}
        setOldPage={setOldPage}
        searchTableData={searchTableData}
        isDataLoaded={isDataLoaded}
        setIsDataLoaded={setIsDataLoaded}
      />
    </Box>
  );
};
