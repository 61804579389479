import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../../../configs/colorConfigs";

type Props = {
    editPopup: boolean;
    hidePopup: () => void;
    particularTransactionDetails: any;
}

export default function TransactionInfo({ editPopup, hidePopup, particularTransactionDetails }: Props) {

    return (
        <Dialog
            open={editPopup}
            maxWidth="md"
            fullScreen={false}
            PaperProps={{
                style: {
                    width: "45%",
                    display: "flex",
                    position: "absolute",
                    top: "10px",
                },
            }}
        >
            <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                <div
                    style={{
                        height: "50px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px 16px 8px 16px",
                    }}
                >
                    <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                        Transaction Info
                    </p>
                    <CloseIcon
                        sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
                        onClick={() => hidePopup()}
                    />
                </div>
                <hr style={{ marginBlock: "0px" }} />
            </DialogTitle>
            <DialogContent sx={{ padding: "0px" }}>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        marginBottom: "10px",
                    }}
                >

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Reference</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.reference}</p>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Status</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.status}</p>
                        </div>

                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Contact</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.contact}</p>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Item</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px", color: "blue" }}>{particularTransactionDetails?.item}</p>
                        </div>

                        <div style={{ width: "25%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Amount</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.amount}</p>
                        </div>

                        <div style={{ width: "25%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Refunded Amount</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.refundedAmount}</p>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Gateway</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.gateway}</p>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Description</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.description}</p>
                        </div>

                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Status Description</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.statusDescription}</p>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Created At</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.createdAt}</p>
                        </div>

                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Charged At</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.chargedAt}</p>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Authorization Charged?</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.authorizationCharged}</p>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>UUID</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.uuid}</p>
                        </div>

                    </div>

                    <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                        Credit Card Details
                    </h5>
                    <hr style={{ width: "100%" }} />

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Cardholder's Name</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.cardholderName}</p>
                        </div>

                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Brand</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.brand}</p>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Last 4 Digits</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>....{particularTransactionDetails?.last4Digits}</p>
                        </div>

                        <div style={{ width: "50%" }}>
                            <p style={{ fontSize: "12px", fontWeight: "bold", marginBlock: "0px" }}>Zip Code</p>
                            <p style={{ fontSize: "12px", marginBlock: "0px" }}>{particularTransactionDetails?.zipCode}</p>
                        </div>

                    </div>

                </div>


                <div
                    style={{
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBlock: "8px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        backgroundColor: "#eeeeee",
                    }}
                >
                    <div style={{ width: "50%" }}>

                    </div>

                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#e0dee0",
                                color: colorConfigs.sidebar.color,
                                borderRadius: "0",
                                border: "none",
                                textTransform: "capitalize",
                                fontSize: "12px",
                                "&: hover": {
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                },
                            }}
                            onClick={() => hidePopup()}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}