import React, { useState } from 'react'
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";

const Security = () => {
    const [isSecurity, setIsSecurity] = useState<boolean>(false);

    return (
        <>
            <h5
                style={{
                    marginBottom: "0px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    cursor: "pointer",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                }}
                onClick={() => setIsSecurity(!isSecurity)}
            >
                {isSecurity ? (
                    <ExpandMore sx={{ fontSize: "20px" }} />
                ) : (
                    <KeyboardArrowRight sx={{ fontSize: "20px" }} />
                )}{" "}
                Security
            </h5>
            <hr />

            {isSecurity &&
                <div>
                    Security
                </div>
            }
        </>
    )
}

export default Security