import React, { useState, useRef, useEffect } from 'react';
import {
    Toolbar,
    Box,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { PaidOutlined, Add, LocalSee, FileDownloadOutlined, Close } from "@mui/icons-material";
import './Editor.css';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import baseURL from '../../../../configs/api';
import colorConfigs from '../../../../configs/colorConfigs';
import Webcam from "react-webcam";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { saveAs } from "file-saver";


export default function PaymentGatewaysForm() {
    const { state } = useLocation();
    const [label, setLabel] = useState<string>("");
    const [supportedCurrencies, setSupportedCurrencies] = useState<string>("");
    const [defaultCurrency, setDefaultCurrency] = useState<string>("");
    const [apiLoginId, setApiLoginId] = useState<string>("");
    const [transactionKey, setTransactionKey] = useState<string>("");
    const [publicKey, setPublicKey] = useState<string>("");
    const [active, setActive] = useState<boolean>(true);
    const [threeDsEnabled, setThreeDsEnabled] = useState<boolean>(true);
    const [skipCreditCardStorage, setSkipCreditCardStorage] = useState<boolean>(true);
    const [collectBillingAddress, setCollectBillingAddress] = useState<boolean>(true);
    const [onlyInternalCharges, setOnlyInternalCharges] = useState<boolean>(true);
    const [allowprocessingpaymentsAfterDeactivation,
        setAllowprocessingpaymentsAfterDeactivation] = useState<boolean>(true);
    const [testMode, setTestMode] = useState<boolean>(true);
    const [onlyPublicCharges, setOnlyPublicCharges] = useState<boolean>(true);
    const [authorizedUserRoles, setAuthorizedUserRoles] = useState<string>("");
    const [order, setOrder] = useState<string>("");
    const [commentforForm, setCommentforForm] = useState<string>("");
    const [textforPaymentButtonOnForm, setTextforPaymentButtonOnForm] = useState<string>("");
    const [textforAuthorizationButtonOnForm, setTextforAuthorizationButtonOnForm] = useState<string>("");
    const [textForSaveCreditCardButtonOnForm, setTextForSaveCreditCardButtonOnForm] = useState<string>("");
    const [filePath, setFilePath] = useState<string>("");
    const [imagePopup, setImagePopup] = useState<boolean>(false);
    const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

    const [customCss, setCustomCss] = useState<string>('');
    const customCssLines = customCss.split('\n').length;

    const [customHtmlAfterBody, setCustomHtmlAfterBody] = useState<string>('');
    const customHtmlAfterBodyLines = customHtmlAfterBody.split('\n').length;

    const [customHtmlBeforeBody, setCustomHtmlBeforeBody] = useState<string>('');
    const customHtmlBeforeBodyLines = customHtmlBeforeBody.split('\n').length;

    const [showWebcam, setShowWebcam] = useState<boolean>(false);

    const webRef = useRef(null);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPleaseWaitPopup(true);
        const selectedFiles = e.target.files as FileList;

        if (selectedFiles.length !== 0) {
            const formData = new FormData();
            formData.append("ImageFile", selectedFiles[0]);

            axios
                .post(`${baseURL}commonFile/upload`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true,
                })
                .then((response) => {
                    setFilePath(response.data);
                    setPleaseWaitPopup(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });

        } else {
            setPleaseWaitPopup(false);
        }
    };

    const navigate = useNavigate();
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        await axios
            .post(`${baseURL}paymentGateway`, JSON.stringify({
                paymentGateWayMenuItemId: state.id,
                label,
                supportedCurrencies,
                defaultCurrency,
                publicClientKey: publicKey,
                transactionKey,
                apiLoginId,
                isActive: active,
                testMode,
                skipCreditCardStorage,
                collectBillingAddress,
                onlyInternalCharges,
                onlyPublicCharges,
                allowprocessingpaymentsAfterDeactivation,
                authorizedUserRoles,
                imagesName: filePath,
                order,
                customCSS: customCss,
                customHTMLAfterBody: customHtmlAfterBody,
                customHTMLBeforeBodyCloseTag: customHtmlBeforeBody,
                commentforForm,
                textforPaymentButtonOnForm,
                textforAuthorizationButtonOnForm,
                textForSaveCreditCardButtonOnForm,
            }), {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then(() => {
                navigate(-1);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    useEffect(() => {
        setCommentforForm(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }, [editorState]);

    const downloadTheImage = () => {
        if (filePath.startsWith("data:image/png")) {
            handleConvertClick(filePath.replace("data:image/png;base64,", ""));
        } else {
            saveAs(
                `${baseURL}commonFile/download?name=${filePath}`,
                `${state.provider}.png`
            );
        }
    };

    const handleConvertClick = (base64Image: string) => {
        const byteCharacters = atob(base64Image);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });

        saveAs(blob, `${state.provider}.png`);
    };

    return (
        <div>
            <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
                <Box
                    sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                >
                    <PaidOutlined sx={{ fontSize: "24px", marginRight: "5px" }} />
                    <Typography variant="body1" sx={{ fontSize: "16px" }}>
                        <span style={{ color: "blue" }}>Payment Gateways</span> / Add Payment Gateways - {state.provider}
                    </Typography>
                </Box>
            </Toolbar>

            <hr />

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                }}
            >
                <div
                    style={{
                        width: "85%",
                        marginRight: "3%",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            marginRight: "3%",
                        }}
                    >
                        <h5
                            style={{
                                paddingBottom: "0px",
                                marginBottom: "0px",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }}
                        >
                            {state.provider}
                        </h5>
                        <hr />

                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div
                                    style={{
                                        width: "50%",
                                        marginRight: "3%",
                                    }}
                                >
                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="label"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Label
                                            <br />
                                            <input
                                                type="text"
                                                name="label"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={label}
                                                onChange={(e) => setLabel(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="supportedCurrencies"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Supported Currencies
                                            <br />
                                            <input
                                                type="text"
                                                name="supportedCurrencies"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={supportedCurrencies}
                                                onChange={(e) => setSupportedCurrencies(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        width: "50%",
                                    }}
                                >
                                    <div style={{ marginTop: "10px", visibility: 'hidden' }}>
                                        <label
                                            htmlFor="name"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Name
                                            <br />
                                            <input
                                                type="text"
                                                name="name"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                            />
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="defaultCurrency"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Default Currency
                                            <br />
                                            <input
                                                type="text"
                                                name="defaultCurrency"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={defaultCurrency}
                                                onChange={(e) => setDefaultCurrency(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <h5
                                style={{
                                    paddingBottom: "0px",
                                    marginBottom: "0px",
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }}
                            >
                                Authentication
                            </h5>
                            <hr />

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div
                                    style={{
                                        width: "70%",
                                        marginRight: "3%",
                                    }}
                                >
                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="apiLoginId"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Api Login ID
                                            <br />
                                            <input
                                                type="text"
                                                name="apiLoginId"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={apiLoginId}
                                                onChange={(e) => setApiLoginId(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="transactionKey"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Transaction Key
                                            <br />
                                            <input
                                                type="text"
                                                name="transactionKey"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={transactionKey}
                                                onChange={(e) => setTransactionKey(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="active"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Active?
                                            <br />
                                            <div>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        name="active"
                                                        style={{ fontSize: "12px" }}
                                                        checked={active ? true : false}
                                                        onChange={(e) =>
                                                            setActive(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        name="active"
                                                        style={{ fontSize: "12px" }}
                                                        checked={active ? false : true}
                                                        onChange={(e) =>
                                                            setActive(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="threeDsEnabled"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            3DS Enabled
                                            <br />
                                            <div>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        name="threeDsEnabled"
                                                        style={{ fontSize: "12px" }}
                                                        checked={threeDsEnabled ? true : false}
                                                        onChange={(e) =>
                                                            setThreeDsEnabled(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        name="threeDsEnabled"
                                                        style={{ fontSize: "12px" }}
                                                        checked={threeDsEnabled ? false : true}
                                                        onChange={(e) =>
                                                            setThreeDsEnabled(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="skipCreditCardStorage"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Skip Credit Card Storage?
                                            <br />
                                            <div>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        name="skipCreditCardStorage"
                                                        style={{ fontSize: "12px" }}
                                                        checked={skipCreditCardStorage ? true : false}
                                                        onChange={(e) =>
                                                            setSkipCreditCardStorage(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        name="skipCreditCardStorage"
                                                        style={{ fontSize: "12px" }}
                                                        checked={skipCreditCardStorage ? false : true}
                                                        onChange={(e) =>
                                                            setSkipCreditCardStorage(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="collectBillingAddress"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Collect Billing Address
                                            <br />
                                            <div>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        name="collectBillingAddress"
                                                        style={{ fontSize: "12px" }}
                                                        checked={collectBillingAddress ? true : false}
                                                        onChange={(e) =>
                                                            setCollectBillingAddress(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        name="collectBillingAddress"
                                                        style={{ fontSize: "12px" }}
                                                        checked={collectBillingAddress ? false : true}
                                                        onChange={(e) =>
                                                            setCollectBillingAddress(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="onlyInternalCharges"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Only Internal Charges
                                            <br />
                                            <div>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        name="onlyInternalCharges"
                                                        style={{ fontSize: "12px" }}
                                                        checked={onlyInternalCharges ? true : false}
                                                        onChange={(e) =>
                                                            setOnlyInternalCharges(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        name="onlyInternalCharges"
                                                        style={{ fontSize: "12px" }}
                                                        checked={onlyInternalCharges ? false : true}
                                                        onChange={(e) =>
                                                            setOnlyInternalCharges(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="allowprocessingpaymentsAfterDeactivation"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Allow Processing Payments for Saved Credit Cards After Deactivation
                                            <br />
                                            <div>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        name="allowprocessingpaymentsAfterDeactivation"
                                                        style={{ fontSize: "12px" }}
                                                        checked={allowprocessingpaymentsAfterDeactivation ? true : false}
                                                        onChange={(e) =>
                                                            setAllowprocessingpaymentsAfterDeactivation(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        name="allowprocessingpaymentsAfterDeactivation"
                                                        style={{ fontSize: "12px" }}
                                                        checked={allowprocessingpaymentsAfterDeactivation ? false : true}
                                                        onChange={(e) =>
                                                            setAllowprocessingpaymentsAfterDeactivation(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="authorizedUserRoles"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Authorized User Roles
                                            <br />
                                            <input
                                                type="text"
                                                name=" authorizedUserRoles"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={authorizedUserRoles}
                                                onChange={(e) => setAuthorizedUserRoles(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>

                                </div>

                                <div
                                    style={{
                                        width: "70%",
                                    }}
                                >
                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="publicKey"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Public Key
                                            <br />
                                            <input
                                                type="text"
                                                name="publicKey"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={publicKey}
                                                onChange={(e) => setPublicKey(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "20px" }}>
                                        <label
                                            htmlFor="active"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Test Mode
                                            <br />
                                            <div>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        name="testMode"
                                                        style={{ fontSize: "12px" }}
                                                        checked={testMode ? true : false}
                                                        onChange={(e) =>
                                                            setTestMode(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        name="testMode"
                                                        style={{ fontSize: "12px" }}
                                                        checked={testMode ? false : true}
                                                        onChange={(e) =>
                                                            setTestMode(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px", visibility: 'hidden' }}>
                                        <label
                                            htmlFor="active"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Active?
                                            <br />
                                            <div>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        name="active"
                                                        style={{ fontSize: "12px" }}
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        name="active"
                                                        style={{ fontSize: "12px" }}
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px", visibility: 'hidden' }}>
                                        <label
                                            htmlFor="active"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Active?
                                            <br />
                                            <div>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        name="active"
                                                        style={{ fontSize: "12px" }}
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        name="active"
                                                        style={{ fontSize: "12px" }}
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="active"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Only Public
                                            <br />
                                            <div>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        name="onlyPublic"
                                                        style={{ fontSize: "12px" }}
                                                        checked={onlyPublicCharges ? true : false}
                                                        onChange={(e) =>
                                                            setOnlyPublicCharges(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label style={{ fontSize: "12px" }}>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        name="onlyPublic"
                                                        style={{ fontSize: "12px" }}
                                                        checked={onlyPublicCharges ? false : true}
                                                        onChange={(e) =>
                                                            setOnlyPublicCharges(e.target.value === "Yes" ? true : false)
                                                        }
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <h5
                                style={{
                                    paddingBottom: "0px",
                                    marginBottom: "0px",
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }}
                            >
                                Website
                            </h5>
                            <hr />

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div
                                    style={{
                                        width: "50%",
                                        marginRight: "3%",
                                    }}
                                >

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="image"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Images
                                            <br />
                                            <div
                                                style={{
                                                    maxWidth: "50%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    gap: "5px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => setImagePopup(true)}
                                            >
                                                {filePath &&
                                                    <>
                                                        <img
                                                            src={filePath.startsWith("data:image/png") ?
                                                                filePath
                                                                :
                                                                `${baseURL}commonFile/Get?name=${filePath}`}
                                                            width={40}
                                                            height={40}
                                                            alt="mailBarLogo"
                                                        />
                                                    </>
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                name="mailBarLogo"
                                                ref={inputFileRef}
                                                onChangeCapture={onFileChangeCapture}
                                                style={{ display: "none" }}
                                            />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "8px",
                                                    marginTop: "8px",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        border: "1px dashed black",
                                                        borderRadius: "3px",
                                                        padding: "3px 5px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "12px",
                                                        backgroundColor: "white",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={(e) => {
                                                        if (inputFileRef.current !== null) {
                                                            inputFileRef.current.click();
                                                        }
                                                    }}
                                                >
                                                    {" "}
                                                    <Add
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "black",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                    Add Image
                                                </p>

                                                <p
                                                    style={{
                                                        border: "1px dashed black",
                                                        borderRadius: "3px",
                                                        padding: "3px 5px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "12px",
                                                        backgroundColor: "white",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => setShowWebcam(true)}
                                                >
                                                    {" "}
                                                    <LocalSee
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "black",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                </p>

                                                {filePath &&
                                                    <p
                                                        style={{
                                                            border: "1px dashed black",
                                                            borderRadius: "3px",
                                                            padding: "3px 5px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "12px",
                                                            backgroundColor: "white",
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => downloadTheImage()}
                                                    >
                                                        {" "}
                                                        <FileDownloadOutlined
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "black",
                                                                fontWeight: "bold",
                                                            }}
                                                        />
                                                    </p>
                                                }
                                            </div>
                                        </label>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        width: "50%",
                                    }}
                                >
                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="order"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Order
                                            <br />
                                            <input
                                                type="number"
                                                name="order"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={order}
                                                onChange={(e) => setOrder(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                <label
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Custom CSS
                                    <br />
                                </label>
                                <div className="code-editor">
                                    <div className="line-numbers">
                                        {Array.from({ length: customCssLines }, (_, i) => (
                                            <div key={i} className="line-number">
                                                {i + 1}
                                            </div>
                                        ))}
                                    </div>
                                    <textarea
                                        className="code-input"
                                        value={customCss}
                                        onChange={(e) => setCustomCss(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: "20px" }}>
                                <label
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Custom HTML After Body
                                    <br />
                                </label>
                                <div className="code-editor">
                                    <div className="line-numbers">
                                        {Array.from({ length: customHtmlAfterBodyLines }, (_, i) => (
                                            <div key={i} className="line-number">
                                                {i + 1}
                                            </div>
                                        ))}
                                    </div>
                                    <textarea
                                        className="code-input"
                                        value={customHtmlAfterBody}
                                        onChange={(e) => setCustomHtmlAfterBody(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: "20px" }}>
                                <label
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Custom HTML Before Body Close Tag
                                    <br />
                                </label>
                                <div className="code-editor">
                                    <div className="line-numbers">
                                        {Array.from({ length: customHtmlBeforeBodyLines }, (_, i) => (
                                            <div key={i} className="line-number">
                                                {i + 1}
                                            </div>
                                        ))}
                                    </div>
                                    <textarea
                                        className="code-input"
                                        value={customHtmlBeforeBody}
                                        onChange={(e) => setCustomHtmlBeforeBody(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                <label
                                    htmlFor="content"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Comment For Form
                                </label>
                                <br />

                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    onEditorStateChange={setEditorState}
                                    toolbar={{
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "20px 16px 0px 0px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        width: "62px",
                                        borderStyle: "solid solid none solid",
                                        borderWidth: "1px",
                                        borderColor: "gray",
                                        textAlign: "center",
                                        padding: "5px 5px 0px 5px",
                                    }}
                                >
                                    English
                                </div>

                                <div
                                    style={{
                                        width: "100%",
                                        borderStyle: "none none solid none",
                                        borderWidth: "1px",
                                        borderColor: "gray",
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div
                                    style={{
                                        width: "50%",
                                        marginRight: "3%",
                                    }}
                                >
                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="textforPaymentButtonOnForm"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Text For Payment Button on Form
                                            <br />
                                            <input
                                                type="text"
                                                name="textforPaymentButtonOnForm"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={textforPaymentButtonOnForm}
                                                onChange={(e) => setTextforPaymentButtonOnForm(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="textForSaveCreditCardButtonOnForm"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Text For Save Credit Card Button on Form
                                            <br />
                                            <input
                                                type="text"
                                                name="textForSaveCreditCardButtonOnForm"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={textForSaveCreditCardButtonOnForm}
                                                onChange={(e) => setTextForSaveCreditCardButtonOnForm(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        width: "50%",
                                    }}
                                >
                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="textforAuthorizationButtonOnForm"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Text For Authorization Button on Form
                                            <br />
                                            <input
                                                type="text"
                                                name="textforAuthorizationButtonOnForm"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={textforAuthorizationButtonOnForm}
                                                onChange={(e) => setTextforAuthorizationButtonOnForm(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "5px",
                                    marginTop: "20px",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "white",
                                        borderRadius: "0",
                                        border: "none",
                                        textTransform: "capitalize",
                                    }}
                                    type="submit"
                                >
                                    Save
                                </Button>

                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#e0dee0",
                                        color: "black",
                                        borderRadius: "0",
                                        border: "none",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Dialog
                open={showWebcam}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        marginTop: "-16%",
                    },
                }}
            >
                <DialogContent sx={{ padding: "0px" }}>
                    <Webcam
                        width={480}
                        height={320}
                        ref={webRef}
                        screenshotFormat="image/png"
                        audio={false}
                    />

                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => setShowWebcam(false)}
                            >
                                Cancel
                            </Button>
                        </div>

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "white",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "white",
                                    },
                                }}
                                onClick={() => {
                                    if (webRef.current !== null) {
                                        //@ts-ignore
                                        const imageSrc = webRef.current.getScreenshot();
                                        setFilePath(imageSrc);
                                        setShowWebcam(false);
                                    }
                                }}
                            >
                                Capture an Image
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={pleaseWaitPopup}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        width: "32%",
                    },
                }}
            >
                <DialogContent sx={{ padding: "5px" }}>
                    <div
                        style={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "black",
                            textAlign: "center",
                        }}
                    >
                        Please Wait...
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={imagePopup}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        marginTop: "-14%",
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            Payment Gateways File
                        </p>
                        <Close
                            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
                            onClick={() => setImagePopup(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                    <div
                        style={{
                            width: "100%",
                            height: "5%",
                            backgroundColor: colorConfigs.sidebar.bg,
                            paddingLeft: "8px",
                            fontSize: "14px",
                        }}
                    >
                        Preview
                    </div>
                    <hr style={{ marginTop: "0px" }} />
                </DialogTitle>

                <DialogContent sx={{ padding: "5px" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <img
                            width={700}
                            height={350}
                            src={`${baseURL}/commonFile/Get?name=${filePath}`}
                            alt="commonFile"
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}