import { Box, Toolbar, Typography, Stack, Button, AppBar, Container, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import colorConfigs from '../../../configs/colorConfigs';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import MenuIcon from "@mui/icons-material/Menu";
import AllReservations from './AllReservations';
import TodaysPickups from './TodaysPickups';
import TodaysReturns from './TodaysReturns';
import TomorrowsPickups from './TomorrowsPickups';
import TomorrowsReturns from './TomorrowsReturns';
import OnRent from './OnRent';
import Completed from './Completed';
import Cancelled from './Cancelled';
import OutstandingPayment from './OutstandingPayment';
import baseURL from '../../../configs/api';
import { format } from 'date-fns';
import OpenReservation from './OpenReservation';

let pages = ["All"];

const Reservations = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(
    null
  );
  const [firstTime, setFirstTime] = useState<String>(pages[0]);
  const [searchTableData, setSearchTableData] = useState<any[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [totalLength, setTotalLength] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [oldPage, setOldPage] = useState<number>(-1);
  const [isFristTime, setIsFristTime] = useState<boolean>(true);


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
    setSearchValue("");
    setPaginationModel({
      page: 0,
      pageSize: 10,
    })
  };

  const navigate = useNavigate();


  useEffect(() => {
    if (searchValue === "" && !isFristTime) {
      const newPaginationModel = {
        page: 0,
        pageSize: paginationModel.pageSize,
      };

      setPaginationModel(newPaginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setIsFristTime(false);
      apiCall(paginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const apiCall = (newPaginationModel: any) => {
    setIsDataLoaded(true);
    if (firstTime === pages[0]) {
      fetch(`${baseURL}reservation/GetReservationsReportPaging/${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.reservationReport.map((item: any) => {
            return {
              ...item,
              pickupDate: format(
                new Date(item.pickupDate),
                "MM/dd/yyyy KK:mm a"
              ),
              returnDate: format(
                new Date(item.returenDate),
                "MM/dd/yyyy KK:mm a"
              ),
              totalPrice: "$" + item.totalPrice,
              totalRevenue: "$" + item.totalRevenue,
              totalPaid: "$" + item.totalPaid,
              totalRefunded: "$" + item.totalRefunded,
              outstandingBalance: "$" + item.outstandingBalance,
            };
          })

          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }

          pages = [
            "All",
            "Today's Pickups",
            "Today's Returns",
            "Tomorrow's Pickups",
            "Tomorrow's Returns",
            "On Rent",
            "Completed",
            "Cancelled",
            "Outstanding Payment"
          ];
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
    else if (firstTime === pages[1]) {
      const todayDate = new Date().toISOString().split("T")[0];
      fetch(`${baseURL}reservation/GetReservationsReportWithPickupDatePaging/${todayDate},${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.reservationReport.map((item: any) => {
            return {
              ...item,
              pickupDate: format(
                new Date(item.pickupDate),
                "MM/dd/yyyy KK:mm a"
              ),
              returnDate: format(
                new Date(item.returenDate),
                "MM/dd/yyyy KK:mm a"
              ),
              totalPrice: "$" + item.totalPrice,
              totalRevenue: "$" + item.totalRevenue,
              totalPaid: "$" + item.totalPaid,
              totalRefunded: "$" + item.totalRefunded,
              outstandingBalance: "$" + item.outstandingBalance,
            };
          })

          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }

          pages = [
            "All",
            "Today's Pickups",
            "Today's Returns",
            "Tomorrow's Pickups",
            "Tomorrow's Returns",
            "Rental",
            "Completed",
            "Cancelled",
            "Open",
            "Outstanding Payment"
          ];
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
    else if (firstTime === pages[2]) {
      const todayDate = new Date().toISOString().split("T")[0];
      fetch(`${baseURL}reservation/GetReservationsReportWithReturnDatePaging/${todayDate},${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.reservationReport.map((item: any) => {
            return {
              ...item,
              pickupDate: format(
                new Date(item.pickupDate),
                "MM/dd/yyyy KK:mm a"
              ),
              returnDate: format(
                new Date(item.returenDate),
                "MM/dd/yyyy KK:mm a"
              ),
              totalPrice: "$" + item.totalPrice,
              totalRevenue: "$" + item.totalRevenue,
              totalPaid: "$" + item.totalPaid,
              totalRefunded: "$" + item.totalRefunded,
              outstandingBalance: "$" + item.outstandingBalance,
            };
          })

          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }

          pages = [
            "All",
            "Today's Pickups",
            "Today's Returns",
            "Tomorrow's Pickups",
            "Tomorrow's Returns",
            "Rental",
            "Completed",
            "Cancelled",
            "Open",
            "Outstanding Payment"
          ];
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
    else if (firstTime === pages[3]) {
      const tomorrowDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split("T")[0];
      fetch(`${baseURL}reservation/GetReservationsReportWithPickupDatePaging/${tomorrowDate},${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.reservationReport.map((item: any) => {
            return {
              ...item,
              pickupDate: format(
                new Date(item.pickupDate),
                "MM/dd/yyyy KK:mm a"
              ),
              returnDate: format(
                new Date(item.returenDate),
                "MM/dd/yyyy KK:mm a"
              ),
              totalPrice: "$" + item.totalPrice,
              totalRevenue: "$" + item.totalRevenue,
              totalPaid: "$" + item.totalPaid,
              totalRefunded: "$" + item.totalRefunded,
              outstandingBalance: "$" + item.outstandingBalance,
            };
          })

          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }

          pages = [
            "All",
            "Today's Pickups",
            "Today's Returns",
            "Tomorrow's Pickups",
            "Tomorrow's Returns",
            "Rental",
            "Completed",
            "Cancelled",
            "Open",
            "Outstanding Payment"
          ];
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
    else if (firstTime === pages[4]) {
      const tomorrowDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split("T")[0];
      fetch(`${baseURL}reservation/GetReservationsReportWithReturnDatePaging/${tomorrowDate},${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.reservationReport.map((item: any) => {
            return {
              ...item,
              pickupDate: format(
                new Date(item.pickupDate),
                "MM/dd/yyyy KK:mm a"
              ),
              returnDate: format(
                new Date(item.returenDate),
                "MM/dd/yyyy KK:mm a"
              ),
              totalPrice: "$" + item.totalPrice,
              totalRevenue: "$" + item.totalRevenue,
              totalPaid: "$" + item.totalPaid,
              totalRefunded: "$" + item.totalRefunded,
              outstandingBalance: "$" + item.outstandingBalance,
            };
          })

          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }

          pages = [
            "All",
            "Today's Pickups",
            "Today's Returns",
            "Tomorrow's Pickups",
            "Tomorrow's Returns",
            "Rental",
            "Completed",
            "Cancelled",
            "Open",
            "Outstanding Payment"
          ];
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
    else if (firstTime === pages[5] || firstTime === pages[6] || firstTime === pages[7] || firstTime === pages[8]) {
      fetch(`${baseURL}reservation/GetReservationsReportWithStatusPaging/${firstTime},${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.reservationReport.map((item: any) => {
            return {
              ...item,
              pickupDate: format(
                new Date(item.pickupDate),
                "MM/dd/yyyy KK:mm a"
              ),
              returnDate: format(
                new Date(item.returenDate),
                "MM/dd/yyyy KK:mm a"
              ),
              totalPrice: "$" + item.totalPrice,
              totalRevenue: "$" + item.totalRevenue,
              totalPaid: "$" + item.totalPaid,
              totalRefunded: "$" + item.totalRefunded,
              outstandingBalance: "$" + item.outstandingBalance,
            };
          })

          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }

          pages = [
            "All",
            "Today's Pickups",
            "Today's Returns",
            "Tomorrow's Pickups",
            "Tomorrow's Returns",
            "Rental",
            "Completed",
            "Cancelled",
            "Open",
            "Outstanding Payment"
          ];
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
    else {
      fetch(`${baseURL}reservation/GetReservationsReportWithOutStandingPaymentPaging/${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.reservationReport.map((item: any) => {
            return {
              ...item,
              pickupDate: format(
                new Date(item.pickupDate),
                "MM/dd/yyyy KK:mm a"
              ),
              returnDate: format(
                new Date(item.returenDate),
                "MM/dd/yyyy KK:mm a"
              ),
              totalPrice: "$" + item.totalPrice,
              totalRevenue: "$" + item.totalRevenue,
              totalPaid: "$" + item.totalPaid,
              totalRefunded: "$" + item.totalRefunded,
              outstandingBalance: "$" + item.outstandingBalance,
            };
          })

          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }

          pages = [
            "All",
            "Today's Pickups",
            "Today's Returns",
            "Tomorrow's Pickups",
            "Tomorrow's Returns",
            "Rental",
            "Completed",
            "Cancelled",
            "Open",
            "Outstanding Payment"
          ];
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
  }
  return (
    <Box>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <EventAvailableIcon
            sx={{ fontSize: "24px", marginRight: "5px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Reservations
          </Typography>
        </Box>

        <Stack spacing={1} direction="row">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#eeeeee",
              color: "black",
              borderRadius: "0",
              border: "1px solid black",
              textTransform: "capitalize",
              "&: hover": {
                backgroundColor: "#eeeeee",
                color: "black",
              }
            }}
            onClick={() => {
              navigate("/fleet/vehicles/addrepairorder");
            }}
          >
            Availability Check
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs.topbar.bg,
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => {
              navigate("/carrental/reservations/addreservation");
            }}
          >
            New Reservation
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#e0dee0",
              color: "black",
              borderRadius: "0",
              border: "none",
            }}
          >
            <MenuIcon />
          </Button>
        </Stack>
      </Toolbar>

      <AppBar
        position="static"
        sx={{ backgroundColor: colorConfigs.sidebar.bg, border: "none", paddingLeft: "18px", paddingRight: "18px" }}
      >
        <Container maxWidth="xl">

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >

            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      }
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      }
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>

        </Container>
      </AppBar>

      {
        firstTime === pages[0] && (
          <AllReservations
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalLength={totalLength}
            setTotalLength={setTotalLength}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchTableData={setSearchTableData}
            oldPage={oldPage}
            setOldPage={setOldPage}
            searchTableData={searchTableData}
            isDataLoaded={isDataLoaded}
            setIsDataLoaded={setIsDataLoaded}
          />
        )
      }


      {
        firstTime === pages[1] && (
          <TodaysPickups
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalLength={totalLength}
            setTotalLength={setTotalLength}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchTableData={setSearchTableData}
            oldPage={oldPage}
            setOldPage={setOldPage}
            searchTableData={searchTableData}
            isDataLoaded={isDataLoaded}
            setIsDataLoaded={setIsDataLoaded} />
        )
      }


      {
        firstTime === pages[2] && (
          <TodaysReturns
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalLength={totalLength}
            setTotalLength={setTotalLength}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchTableData={setSearchTableData}
            oldPage={oldPage}
            setOldPage={setOldPage}
            searchTableData={searchTableData}
            isDataLoaded={isDataLoaded}
            setIsDataLoaded={setIsDataLoaded} />
        )}

      {
        firstTime === pages[3] && (
          <TomorrowsPickups
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalLength={totalLength}
            setTotalLength={setTotalLength}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchTableData={setSearchTableData}
            oldPage={oldPage}
            setOldPage={setOldPage}
            searchTableData={searchTableData}
            isDataLoaded={isDataLoaded}
            setIsDataLoaded={setIsDataLoaded} />
        )}

      {
        firstTime === pages[4] && (
          <TomorrowsReturns
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalLength={totalLength}
            setTotalLength={setTotalLength}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchTableData={setSearchTableData}
            oldPage={oldPage}
            setOldPage={setOldPage}
            searchTableData={searchTableData}
            isDataLoaded={isDataLoaded}
            setIsDataLoaded={setIsDataLoaded} />
        )}

      {
        firstTime === pages[5] && (

          <OnRent
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalLength={totalLength}
            setTotalLength={setTotalLength}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchTableData={setSearchTableData}
            oldPage={oldPage}
            setOldPage={setOldPage}
            searchTableData={searchTableData}
            isDataLoaded={isDataLoaded}
            setIsDataLoaded={setIsDataLoaded} />
        )}

      {
        firstTime === pages[6] && (
          <Completed
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalLength={totalLength}
            setTotalLength={setTotalLength}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchTableData={setSearchTableData}
            oldPage={oldPage}
            setOldPage={setOldPage}
            searchTableData={searchTableData}
            isDataLoaded={isDataLoaded}
            setIsDataLoaded={setIsDataLoaded} />
        )}

      {
        firstTime === pages[7] && (
          <Cancelled
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalLength={totalLength}
            setTotalLength={setTotalLength}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchTableData={setSearchTableData}
            oldPage={oldPage}
            setOldPage={setOldPage}
            searchTableData={searchTableData}
            isDataLoaded={isDataLoaded}
            setIsDataLoaded={setIsDataLoaded} />
        )}

      {
        firstTime === pages[8] && (
          <OpenReservation
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalLength={totalLength}
            setTotalLength={setTotalLength}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchTableData={setSearchTableData}
            oldPage={oldPage}
            setOldPage={setOldPage}
            searchTableData={searchTableData}
            isDataLoaded={isDataLoaded}
            setIsDataLoaded={setIsDataLoaded} />
        )}
      {
        firstTime === pages[9] && (
          <OutstandingPayment
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalLength={totalLength}
            setTotalLength={setTotalLength}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchTableData={setSearchTableData}
            oldPage={oldPage}
            setOldPage={setOldPage}
            searchTableData={searchTableData}
            isDataLoaded={isDataLoaded}
            setIsDataLoaded={setIsDataLoaded} />
        )}

    </Box>
  );
};

export default Reservations;