import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../configs/api";
import { PDFDocument } from "pdf-lib";
import PleaseWaitPopup from "../../PleaseWaitPopup";

const PrintQuotes = () => {
  const { search } = useLocation();
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>("");

  useEffect(() => {
    let id = search.split("=")[1];

    const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
      let binary = "";
      let bytes = new Uint8Array(buffer);
      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    async function fetchAndConvertPdf() {
      setPleaseWaitPopup(true);
      try {
        const response = await axios.get(
          `${baseURL}QuotePdf?quoteId=${id}`,
          {
            responseType: "arraybuffer",
          }
        );

        const pdfDoc = await PDFDocument.load(response.data);

        pdfDoc.setTitle(`Quote_${id}.pdf`);
        pdfDoc.setAuthor("Website");
        pdfDoc.setSubject("Quote");
        pdfDoc.setProducer("Website");
        pdfDoc.setCreator("Website");

        const modifiedBytes = await pdfDoc.save();

        setPleaseWaitPopup(false);
        setFileUrl(arrayBufferToBase64(modifiedBytes));
      } catch (error) {
        setPleaseWaitPopup(false);
        console.error("Error fetching or converting PDF:", error);
      }
    }

    if (id) fetchAndConvertPdf();
  }, [search]);

  return (
    <>
      {!pleaseWaitPopup &&
        <embed
          src={`data:application/pdf;base64,${fileUrl}`}
          type="application/pdf"
          style={{ width: "100%", minHeight: "100vh" }}
        />
      }
      <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
    </>
  )
}

export default PrintQuotes