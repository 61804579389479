import { Button, Dialog, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../../../configs/colorConfigs";
import Select from "react-select";
import axios from "axios";
import baseURL from "../../../../configs/api";
import ReactQuill from "react-quill";

export const GeneralSettings = () => {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const [
    enableRepairOrderWhenVehicleIsOnRent,
    setEnableRepairOrderWhenVehicleIsOnRent,
  ] = useState<boolean>(true);
  const [amountOfPartsIsMandatory, setAmountOfPartsIsMandatory] =
    useState<boolean>(true);
  const [useAmountsInRepairOrders, setUseAmountsInRepairOrders] =
    useState<boolean>(true);
  const [amountOfLaborIsMandatory, setAmountOfLaborIsMandatory] =
    useState<boolean>(true);
  const [notesIsMandatory, setNotesIsMandatory] = useState<boolean>(true);
  const [enablePickupAndReturnLocations, setEnablePickupAndReturnLocations] =
    useState<boolean>(true);
  const [defaultStatusId, setDefaultStatusId] = useState<string>("");
  const [disableVehicleMaintenance, setDisableVehicleMaintenance] =
    useState<boolean>(true);
  const [userToNotify, setUserToNotify] = useState<any>();
  const [sendMaintenanceAlertsEveryXDays, setSendMaintenanceAlertsEveryXDays] =
    useState<string>("");
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailContent, setEmailContent] = useState<any>();
  const [defaultStatusOptions, setDefaultStatusOptions] = useState([]);
  const [userToNotifyOptions, setUserToNotifyOptions] = useState<any[]>([]);
  const [userToNotifyArray, setUserToNotifyArrray] = useState<any[]>([]);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

  const [maintenanceStatusIsLoaded, setMaintenanceStatusIsLoaded] =
    useState<boolean>(false);
  const [usersDataIsLoaded, setUsersDataIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setPleaseWaitPopup(true);
    axios
      .get(`${baseURL}maintenanceStatus`)
      .then((response) => {
        setDefaultStatusOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );

        setMaintenanceStatusIsLoaded(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL}users`)
      .then((response) => {
        setUserToNotifyOptions(() =>
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
            };
          })
        );

        setUsersDataIsLoaded(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    if (maintenanceStatusIsLoaded && usersDataIsLoaded)
      axios
        .get(`${baseURL}maintenanceGeneralSetting`)
        .then((response) => {
          setEnableRepairOrderWhenVehicleIsOnRent(
            response.data.enableRepairOrderWhenVehicleIsOnRent
          );
          setAmountOfPartsIsMandatory(response.data.amountOfPartsIsMandatory);
          setUseAmountsInRepairOrders(response.data.useAmountsInRepairOrders);
          setAmountOfLaborIsMandatory(response.data.amountOfLaborIsMandatory);
          setNotesIsMandatory(response.data.notesIsMandatory);
          setEnablePickupAndReturnLocations(
            response.data.enablePickupAndReturnLocations
          );
          setDefaultStatusId(response.data.maintenanceStatusId);
          setDisableVehicleMaintenance(response.data.disableVehicleMaintenance);
          setSendMaintenanceAlertsEveryXDays(
            response.data.sendMaintenanceAlertsEveryXDays
          );
          setEmailSubject(response.data.emailSubject);
          setEmailContent(response.data.emailContent);
          const notifyArray =
            response.data.usersToNotifyForMaintenanceAlerts.split(",");

          setUserToNotifyArrray(
            userToNotifyOptions.filter((item: any) =>
              notifyArray.some((data: string) => item.value === parseInt(data))
            )
          );
          setUserToNotify(
            userToNotifyOptions.filter((item: any) =>
              notifyArray.some((data: string) => item.value === parseInt(data))
            )
          );

          setMaintenanceStatusIsLoaded(false);
          setUsersDataIsLoaded(false);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
  }, [maintenanceStatusIsLoaded, usersDataIsLoaded]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setPleaseWaitPopup(true);
    await axios
      .put(
        `${baseURL}maintenanceGeneralSetting`,
        JSON.stringify({
          id: 1,
          enableRepairOrderWhenVehicleIsOnRent,
          amountOfPartsIsMandatory,
          notesIsMandatory,
          useAmountsInRepairOrders,
          amountOfLaborIsMandatory,
          enablePickupAndReturnLocations,
          maintenanceStatusId: defaultStatusId,
          disableVehicleMaintenance,
          usersToNotifyForMaintenanceAlerts: userToNotifyArray
            ?.map((item) => item.value)
            .join(","),
          sendMaintenanceAlertsEveryXDays,
          emailSubject,
          emailContent,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => setPleaseWaitPopup(false))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (userToNotify) {
      const arrray = [];
      for (let i = 0; i < userToNotify.length; i++) {
        arrray.push(userToNotify[i]);
      }

      setUserToNotifyArrray(arrray);
    }
  }, [userToNotify]);

  return (
    <div>
      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px 26px",
        }}
      >
        <div
          style={{
            width: "85%",
            marginRight: "3%",
          }}
        >
          <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
            Repair Orders
          </h5>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                  paddingLeft: "16px",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Enable Repair Order When Vehicle is on Rent
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="enableRepairOrderWhenVehicleIsOnRent"
                      style={{ fontSize: "12px" }}
                      checked={
                        enableRepairOrderWhenVehicleIsOnRent ? true : false
                      }
                      onChange={(e) =>
                        setEnableRepairOrderWhenVehicleIsOnRent(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="enableRepairOrderWhenVehicleIsOnRent"
                      style={{ fontSize: "12px" }}
                      checked={
                        enableRepairOrderWhenVehicleIsOnRent ? false : true
                      }
                      onChange={(e) =>
                        setEnableRepairOrderWhenVehicleIsOnRent(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Amount of Parts is Mandatory?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="amountOfPartsIsMandatory"
                      style={{ fontSize: "12px" }}
                      checked={amountOfPartsIsMandatory ? true : false}
                      onChange={(e) =>
                        setAmountOfPartsIsMandatory(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="amountOfPartsIsMandatory"
                      style={{ fontSize: "12px" }}
                      checked={amountOfPartsIsMandatory ? false : true}
                      onChange={(e) =>
                        setAmountOfPartsIsMandatory(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Notes is Mandatory?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="notesIsMandatory"
                      style={{ fontSize: "12px" }}
                      checked={notesIsMandatory ? true : false}
                      onChange={(e) =>
                        setNotesIsMandatory(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="notesIsMandatory"
                      style={{ fontSize: "12px" }}
                      checked={notesIsMandatory ? false : true}
                      onChange={(e) =>
                        setNotesIsMandatory(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="defaultStatus"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Default Status
                    <br />
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      name="defaultStatus"
                      value={defaultStatusOptions.find(
                        (item: any) => item.value === defaultStatusId
                      )}
                      onChange={(itemType: any) =>
                        setDefaultStatusId(itemType ? itemType.value : "")
                      }
                      options={defaultStatusOptions}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Disable Vehicle Maintenance
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="disableVehicleMaintenance"
                      style={{ fontSize: "12px" }}
                      checked={disableVehicleMaintenance ? true : false}
                      onChange={(e) =>
                        setDisableVehicleMaintenance(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="disableVehicleMaintenance"
                      style={{ fontSize: "12px" }}
                      checked={disableVehicleMaintenance ? false : true}
                      onChange={(e) =>
                        setDisableVehicleMaintenance(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Use Amounts in Repair Orders
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="useAmountsInRepairOrders"
                      style={{ fontSize: "12px" }}
                      checked={useAmountsInRepairOrders ? true : false}
                      onChange={(e) =>
                        setUseAmountsInRepairOrders(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="useAmountsInRepairOrders"
                      style={{ fontSize: "12px" }}
                      checked={useAmountsInRepairOrders ? false : true}
                      onChange={(e) =>
                        setUseAmountsInRepairOrders(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Amount of Labor is Mandatory?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="amountOfLaborIsMandatory"
                      style={{ fontSize: "12px" }}
                      checked={amountOfLaborIsMandatory ? true : false}
                      onChange={(e) =>
                        setAmountOfLaborIsMandatory(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="amountOfLaborIsMandatory"
                      style={{ fontSize: "12px" }}
                      checked={amountOfLaborIsMandatory ? false : true}
                      onChange={(e) =>
                        setAmountOfLaborIsMandatory(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Enable Pickup and Return Locations
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="enablePickupAndReturnLocations"
                      style={{ fontSize: "12px" }}
                      checked={enablePickupAndReturnLocations ? true : false}
                      onChange={(e) =>
                        setEnablePickupAndReturnLocations(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="enablePickupAndReturnLocations"
                      style={{ fontSize: "12px" }}
                      checked={enablePickupAndReturnLocations ? false : true}
                      onChange={(e) =>
                        setEnablePickupAndReturnLocations(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>
              </div>
            </div>

            <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              Maintenance Alerts
            </h5>
            <hr />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                  paddingLeft: "16px",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="usersToNotifyForMaintenanceAlerts"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Users To Notify For Maintenance Alerts
                    <br />
                    <Select
                      isMulti
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      name="usersToNotifyForMaintenanceAlerts"
                      value={userToNotify}
                      onChange={(value) => setUserToNotify(value)}
                      options={userToNotifyOptions}
                      required
                    />
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="tax"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Send Maintenance Alerts Every X Days
                    <br />
                    <input
                      type="number"
                      name="tax"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={sendMaintenanceAlertsEveryXDays}
                      onChange={(e) =>
                        setSendMaintenanceAlertsEveryXDays(e.target.value)
                      }
                      required
                    />
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "16px 16px 0px 16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "70px",
                  borderStyle: "solid solid none solid",
                  borderWidth: "1px",
                  borderColor: "gray",
                  padding: "5px 5px 0px 5px",
                  fontSize: "12px",
                  justifyContent: "center",
                }}
              >
                English
              </div>

              <div
                style={{
                  width: "100%",
                  borderStyle: "none none solid none",
                  borderWidth: "1px",
                  borderColor: "gray",
                }}
              />
            </div>

            <div
              style={{
                marginTop: "10px",
                width: "100%",
                paddingLeft: "16px",
              }}
            >
              <label
                htmlFor="emailSubject"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Email Subject
                <br />
                <input
                  type="text"
                  name="emailSubject"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={emailSubject}
                  onChange={(e) => setEmailSubject(e.target.value)}
                  required
                />
              </label>
            </div>

            <div
              style={{
                marginTop: "10px",
                width: "100%",
                height: "150px",
                paddingLeft: "16px",
              }}
            >
              <label
                htmlFor="emailContent"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Email Content
              </label>
              <br />
              <ReactQuill
                style={{ height: "100%" }}
                theme="snow"
                modules={modules}
                value={emailContent}
                onChange={setEmailContent}
              />
            </div>

            <Button
              variant="contained"
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                borderRadius: "0",
                border: "none",
                textTransform: "capitalize",
                marginLeft: "16px",
                marginTop: "80px",
                "&:hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              type="submit"
            >
              Save
            </Button>
          </form>
        </div>
      </div>

      <Dialog
        open={pleaseWaitPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "32%",
          },
        }}
      >
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
              backgroundColor: "white",
              color: "black",
              textAlign: "center",
            }}
          >
            Please Wait...
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
