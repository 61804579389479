import React from 'react'

type Props ={
    branchId: number
}

export default function PaymentScheduleAfterPickup({branchId}: Props) {
  return (
    <div>Payment Schedule After Pickup {branchId}</div>
  )
}
