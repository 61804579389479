import React from 'react';
import './UnAuthorize.css';

const UnAuthorize = () => {
    document.body.style.backgroundColor = "#253455";
    return (
        <main>
            <div className="message">You are not authorized.
            </div>
            <div className="message2">Please contact your admin</div>
            <div className="container">
                <div className="neon">403</div>
                <div className="door-frame">
                    <div className="door">
                        <div className="rectangle">
                        </div>
                        <div className="handle">
                        </div>
                        <div className="window">
                            <div className="eye">
                            </div>
                            <div className="eye eye2">
                            </div>
                            <div className="leaf">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default UnAuthorize