import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import baseURL from "../../../configs/api";

import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import colorConfigs from "../../../configs/colorConfigs";
import { Close, ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

type Props = {
    customerId: string;
    newFile: any;
    isFileChanged: boolean;
    setIsFileChanged: (value: boolean) => void;
};

export default function CustomerFiles({ customerId, newFile, isFileChanged, setIsFileChanged }: Props) {
    const [tableData, setTableData] = useState<any[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [imagePopup, setImagePopup] = useState<boolean>(false);
    const [particularFile, setParticularFile] = useState<any>();
    const [fileIndex, setFileIndex] = useState<number>(0);
    const [isExtraInformIsLoaded, setIsExtraInformIsLoaded] = useState<boolean>(false);

    const columns: GridColDef[] = [
        {
            field: "filePath",
            headerName: "File",
            width: 380,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <p
                        style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setImagePopup(true);
                            setParticularFile(params.row);
                            setFileIndex(tableData.indexOf(params.row))
                        }}
                    >
                        {params.value}
                    </p>
                );
            },
        },
        {
            field: "uploadedBy",
            headerName: "Author",
            width: 250,
            editable: false,
            sortable: false,
        },
        {
            field: "imageSize",
            headerName: "Size",
            width: 250,
            editable: false,
            sortable: false,
        },
        {
            field: "uploadedAt",
            headerName: "Date",
            editable: false,
            sortable: false,
            width: 280,
        },
    ];

    useEffect(() => {
        fetch(`${baseURL}customerExtraInformation`)
            .then((res) => res.json())
            .then((data) => {
                setTableData(() =>
                    data.filter((value: any) => value.customerId === parseInt(customerId))
                        .map((item: any) => {
                            return {
                                id: item.id,
                                filePath: item.filePath,
                                uploadedBy: item.uploadedBy,
                                imageSize: (item.imageSize === 0 ? "-" : (item.imageSize / 1024).toFixed(2)) + " KB",
                                uploadedAt: format(
                                    new Date(item.uploadedAt),
                                    "MM/dd/yyyy KK:mm a"
                                ),
                            }
                        })
                );
                setIsExtraInformIsLoaded(true);
            });
                // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isExtraInformIsLoaded) {
            fetch(`${baseURL}fileUploadData`)
                .then((res) => res.json())
                .then((data) => {
                    data.filter((value: any) => value.attributeId === parseInt(customerId) && value.attributeName === "Customer")
                        .forEach((item: any) => {
                            const newArrayObject = {
                                id: item.id,
                                filePath: item.fileName,
                                uploadedBy: "",
                                imageSize: "-",
                                uploadedAt: format(
                                    new Date(item.createdDate),
                                    "MM/dd/yyyy KK:mm a"
                                )
                            }
                            setTableData(prevTableData => [...prevTableData, newArrayObject]);
                        })

                    setIsExtraInformIsLoaded(false);
                    setIsDataLoaded(true);
                });
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExtraInformIsLoaded]);

    useEffect(() => {
        if (isFileChanged) {
            setTableData(prevTableData => [...prevTableData, {
                id: tableData.length + 1,
                filePath: newFile.fileName,
                uploadedBy: "",
                imageSize: "-",
                uploadedAt: format(
                    new Date(newFile.createdDate),
                    "MM/dd/yyyy KK:mm a"
                )
            }])

            setIsFileChanged(false);
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFileChanged])


    const generateRandom = () => {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvryfycvh",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    return (
        <>
            <Box sx={{ padding: "24px" }}>
                <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
                    {isDataLoaded ? (
                        <>
                            <hr style={{ marginBlock: "0px" }} />
                            <DataGrid
                                rows={tableData}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                                disableRowSelectionOnClick
                                disableColumnMenu
                                showColumnVerticalBorder
                                getRowId={(row: any) => generateRandom()}
                                showCellVerticalBorder={true}
                                sx={{
                                    border: "none",
                                    fontSize: "12px",
                                    wordWrap: "break-word",
                                    overflow: "visible",
                                }}
                            />
                        </>
                    ) : (
                        <div className="loading">
                            <p>Please wait</p>
                            <span>
                                <i></i>
                                <i></i>
                            </span>
                        </div>
                    )}
                </Box>
            </Box>

            <Dialog
                open={imagePopup}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        marginTop: "-14%",
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            Customer File
                        </p>
                        <Close
                            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
                            onClick={() => setImagePopup(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                    <div
                        style={{
                            width: "100%",
                            height: "5%",
                            backgroundColor: colorConfigs.sidebar.bg,
                            paddingLeft: "8px",
                            fontSize: "14px",
                        }}
                    >
                        Preview
                    </div>
                    <hr style={{ marginTop: "0px" }} />
                </DialogTitle>

                <DialogContent sx={{ padding: "5px" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "transparent",
                                position: "absolute",
                                padding: "10px",
                                left: "0",
                                top: "50%",
                            }}
                        >
                            <ArrowBackIos
                                sx={{
                                    cursor: fileIndex > 0 ? "pointer" : "",
                                    opacity: fileIndex === 0 ? "0.5" : "1",
                                    color: "#2196f3",
                                }}
                                onClick={() => {
                                    if (fileIndex > 0) {
                                        setFileIndex(fileIndex - 1);
                                        setParticularFile(tableData.find((_: any, index: number) => index === fileIndex - 1));
                                    }
                                }}
                            />
                        </div>
                        <img
                            width={700}
                            height={350}
                            src={`${baseURL}/customerFile/Get?name=${particularFile?.filePath}`}
                            alt="customerFile"
                        />

                        <div
                            style={{
                                backgroundColor: "transparent",
                                position: "absolute",
                                padding: "10px",
                                right: "0",
                                top: "50%",
                                color: "#2196f3",
                            }}
                        >
                            <ArrowForwardIos
                                sx={{
                                    cursor: fileIndex < tableData.length - 1 ? "pointer" : "",
                                    opacity: fileIndex === tableData.length - 1 ? "0.5" : "1",
                                }}
                                onClick={() => {
                                    if (fileIndex < tableData.length - 1) {
                                        setFileIndex(fileIndex + 1);
                                        setParticularFile(tableData.find((_: any, index: number) => index === fileIndex + 1));
                                    }
                                }}
                            />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};