import axios from "axios"
import baseURL from "../configs/api"

export const getCustomersExtraInformation = () => {
    return getFetch("customerExtraInformation");
}

export const getCustomerExtraInformation = (primaryKey: string) => {
    return getFetch(`customerExtraInformation/${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}