import axios from "axios"
import baseURL from "../configs/api"

export const getDiscounts = () => {
    return getFetch("discount")
}

export const getDiscount = (primaryKey: string) => {
    return getFetch(`discount/${primaryKey}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}