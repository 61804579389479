import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsAccessibility from "highcharts/modules/accessibility";

type Props = {
    options: any
}

const Chart = ({ options }: Props) => {
    if (typeof Highcharts === 'object') {
        HighchartsExporting(Highcharts)
        HighchartsAccessibility(Highcharts);
    }

    return (
        <div style={{ display: "flex", flex: 1 }}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export default Chart;