import axios from "axios"
import baseURL from "../configs/api"

export const post_reservationDiscountDiscount = (reservationId: string, discountDefinition: string, discountType: string, discountAmount: number, manualDiscount: boolean, auth: any) => {
    return axios({
        url: `${baseURL}reservationDiscount`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: JSON.stringify({
            reservationId,
            discountDefinition,
            discountType,
            discountAmount,
            dateofDiscount: new Date(),
            supportingDocuments: false,
            createdAt: new Date(),
            createdBy: auth.givenname,
            manualDiscount
        })
    }).then(response => response.data);
}

export const delete_reservationDiscountDiscount = (primaryKey: number) => {
    return axios({
        url: `${baseURL}reservationDiscount`,
        method: "DELETE",
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getreservationDiscountDiscounts = () => {
    return getFetch("reservationDiscount");
}

export const getreservationDiscountDiscount = (primaryKey: string) => {
    return getFetch(`reservationDiscount/${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}