import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import colorConfigs from '../../../configs/colorConfigs'
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import baseURL from '../../../configs/api';
import { getCustomers } from '../../../services/customer';
import { format } from 'date-fns';

type Props = {
    sendEmailPopUp: boolean;
    hideSendEmailPopup: () => void;
    branchId: string;
    vehicleClassId: string;
    pickupDate: string;
    pickupTime: string;
    returnDate: string;
    returnTime: string;
    pickupLocation: string;
    returnLocation: string;
    totalAmountAfterDiscount: number;
    totalOfSelectedVehicleClass: number;
    discountTotalAmount: number;
    securityDepositAmountToPaid: string;
    isCreatingPdf: boolean;
}

const SendQuotes = ({
    sendEmailPopUp,
    hideSendEmailPopup,
    branchId,
    vehicleClassId,
    pickupDate,
    pickupTime,
    returnDate,
    returnTime,
    pickupLocation,
    returnLocation,
    totalAmountAfterDiscount,
    totalOfSelectedVehicleClass,
    discountTotalAmount,
    securityDepositAmountToPaid,
    isCreatingPdf
}: Props) => {
    const [customers, setCustomers] = useState<any[]>([]);
    const [customerId, setCustomerId] = useState<string>("");
    const [tags, setTags] = useState<string>("");
    const [cc, setCc] = useState<string>("");
    const [language, setLanguage] = useState<string>("");

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [addAndEditCustomerPopup, setAddAndEditCustomerPopup] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [isChanged, setIsChanged] = useState<boolean>(true);

    let latestPickup = new Date();
    let latestReturn = new Date();
    if (pickupDate) latestPickup = new Date(pickupTime ? `${pickupDate}T${pickupTime}` : pickupDate);
    if (returnDate) latestReturn = new Date(returnTime ? `${returnDate}T${returnTime}` : returnDate);  

    useEffect(() => {
        if (isChanged) {
            getCustomers()
                .then(data => {
                    setCustomers(
                        data.map((item: any) => {
                            return {
                                ...item,
                                value: item.id,
                                label: item.firstName + " " + item.lastName,
                            };
                        })
                    );
                    setIsChanged(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [isChanged]);

    const addCustomer = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setIsEditing(false);
        setAddAndEditCustomerPopup(true);
    }

    const editCustomer = () => {
        setFirstName(customers.find((item: any) => item.id === parseInt(customerId)) ? customers.find((item: any) => item.id === parseInt(customerId))?.firstName : "");
        setLastName(customers.find((item: any) => item.id === parseInt(customerId)) ? customers.find((item: any) => item.id === parseInt(customerId))?.lastName : "");
        setEmail(customers.find((item: any) => item.id === parseInt(customerId)) ? customers.find((item: any) => item.id === parseInt(customerId))?.emailAddress : "");
        setPhoneNumber(customers.find((item: any) => item.id === parseInt(customerId)) ? customers.find((item: any) => item.id === parseInt(customerId))?.phoneNumber : "");
        setIsEditing(true);
        setAddAndEditCustomerPopup(true);
    }

    const handleCustomerSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (isEditing) {
            await axios
                .put(
                    `${baseURL}Customer`,
                    JSON.stringify({
                        id: parseInt(customerId),
                        firstName,
                        lastName,
                        emailAddress: email,
                        phoneNumber: phoneNumber,
                        Street: "",
                        street2: "",
                        city: "",
                        state: "",
                        country: "",
                        zip: "",
                        birthday: "",
                        customerExtraInformations: []
                    }),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    setIsChanged(true);
                    setIsEditing(false);
                    setCustomerId(response.data.id);
                    setAddAndEditCustomerPopup(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            await axios
                .post(
                    `${baseURL}Customer`,
                    JSON.stringify({
                        firstName,
                        lastName,
                        emailAddress: email,
                        phoneNumber: phoneNumber,
                    }),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    setIsChanged(true);
                    setIsEditing(false);
                    setCustomerId(response.data.id);
                    setAddAndEditCustomerPopup(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }

    const confrimTheQuote = async () => {
        await axios
            .post(
                `${baseURL}quote`,
                JSON.stringify({
                    reservationType: "Reservations",
                    customerId: parseInt(customerId),
                    vehicleClassId: parseInt(vehicleClassId),
                    branchId: parseInt(branchId),
                    pickupLoationId: parseInt(pickupLocation),
                    returenLoationId: parseInt(returnLocation),
                    pickupDate: `${format(latestPickup, 'MM/dd/yyyy')} ${format(latestPickup, 'hh:mm:ss a')}`,
                    returenDate: `${format(latestReturn, 'MM/dd/yyyy')} ${format(latestReturn, 'hh:mm:ss a')}`,
                    securityDepositAmount: parseInt(securityDepositAmountToPaid),
                    commissionPartner: "",
                    clientPaysatCommissionPartner: false,
                    rackPrice: totalOfSelectedVehicleClass,
                    totalPrice: totalAmountAfterDiscount,
                    discount: discountTotalAmount,
                    status: isCreatingPdf ? "Create Pdf" : "Send Email",
                    tag: tags
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then((response) => {
                hideSendEmailPopup();
                if(isCreatingPdf)
                window.open(`/carrental/reservations/print-quote?id=${response.data.id}`, "_blank", "noopener,noreferrer");
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    return (
        <>
            <Dialog
                open={sendEmailPopUp}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        width: "35%",
                        display: "flex",
                        position: "absolute",
                        top: "30px",
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            {isCreatingPdf ? "Create Pdf of Quote" : "Send Quote to email"}
                        </p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
                            onClick={() => hideSendEmailPopup()}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                </DialogTitle>
                <DialogContent sx={{ padding: "0px" }}>
                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: "16px",
                                paddingRight: "16px",
                                marginBlock: "8px",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "8px",
                                }}
                            >

                                <div style={{ width: "50%" }}>
                                    <label
                                        htmlFor="customer"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Customer
                                        <br />
                                        <select
                                            name="customer"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={customerId}
                                            onChange={(e) => setCustomerId(e.target.value)}
                                        >
                                            <option value={""}></option>
                                            {customers.map((item: any) => {
                                                return (
                                                    <option key={item.value} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </label>
                                    <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                                        <h6 style={{ fontWeight: "normal", color: "blue", marginBlock: "0px", cursor: "pointer" }} onClick={() => addCustomer()}>Add Contact</h6>
                                        <h6 style={{ display: customerId ? "block" : "none", fontWeight: "normal", color: "blue", marginBlock: "0px", cursor: "pointer" }} onClick={() => editCustomer()}>Edit Contact</h6>
                                    </div>
                                </div>

                                <div style={{ width: "50%" }}>
                                    <label
                                        htmlFor="tags"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Tags
                                        <br />
                                        <input
                                            type="text"
                                            name="tags"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={tags}
                                            onChange={(e) => setTags(e.target.value)}
                                        />
                                    </label>
                                </div>

                            </div>

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "8px",
                                }}
                            >

                                <div style={{ width: "50%" }}>
                                    <label
                                        htmlFor="cc"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Cc
                                        <br />
                                        <input
                                            type="email"
                                            name="cc"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={cc}
                                            onChange={(e) => setCc(e.target.value)}
                                        />
                                    </label>
                                </div>

                            </div>

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "8px",
                                }}
                            >

                                <div style={{ width: "50%" }}>
                                    <label
                                        htmlFor="language"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Language
                                        <br />
                                        <select
                                            name="language"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={language}
                                            onChange={(e) => setLanguage(e.target.value)}
                                        >
                                            <option value={"English"}>English</option>
                                        </select>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                            marginTop: "8px",
                        }}
                    >
                        <div style={{ width: "50%" }} />

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "8px",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => hideSendEmailPopup()}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "white",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "white",
                                    },
                                }}
                                onClick={() => confrimTheQuote()}
                            >
                                 {isCreatingPdf ? "Create Pdf" : "Send Quote"}
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={addAndEditCustomerPopup}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        width: "35%",
                        display: "flex",
                        position: "absolute",
                        top: "30px",
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            {isEditing ? "Editing " : "Create "}Contact
                        </p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
                            onClick={() => setAddAndEditCustomerPopup(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                </DialogTitle>
                <DialogContent sx={{ padding: "0px" }}>
                    <form onSubmit={handleCustomerSubmit}>
                        <div
                            style={{
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                    marginBlock: "8px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "8px",
                                    }}
                                >

                                    <div style={{ width: "50%" }}>
                                        <label
                                            htmlFor="firstName"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            First Name
                                            <br />
                                            <input
                                                name="firstName"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </label>
                                    </div>

                                    <div style={{ width: "50%" }}>
                                        <label
                                            htmlFor="lastName"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Last Name
                                            <br />
                                            <input
                                                type="text"
                                                name="lastName"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </label>
                                    </div>

                                </div>

                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "8px",
                                    }}
                                >

                                    <div style={{ width: "50%" }}>
                                        <label
                                            htmlFor="email"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Email
                                            <br />
                                            <input
                                                name="email"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </label>
                                    </div>

                                    <div style={{ width: "50%" }}>
                                        <label
                                            htmlFor="phoneNumber"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Phone Number
                                            <br />
                                            <input
                                                name="phoneNumber"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                            />
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                width: "100%",
                                height: "50px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                paddingBlock: "8px",
                                paddingLeft: "16px",
                                paddingRight: "16px",
                                backgroundColor: "#eeeeee",
                                marginTop: "8px",
                            }}
                        >
                            <div style={{ width: "50%" }} />

                            <div
                                style={{
                                    width: "50%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: "8px",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                        borderRadius: "0",
                                        border: "none",
                                        textTransform: "capitalize",
                                        fontSize: "12px",
                                        "&: hover": {
                                            backgroundColor: "#e0dee0",
                                            color: colorConfigs.sidebar.color,
                                        },
                                    }}
                                    onClick={() => setAddAndEditCustomerPopup(false)}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "white",
                                        borderRadius: "0",
                                        border: "none",
                                        textTransform: "capitalize",
                                        fontSize: "12px",
                                        "&: hover": {
                                            backgroundColor: colorConfigs.topbar.bg,
                                            color: "white",
                                        },
                                    }}
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SendQuotes