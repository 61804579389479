import React, { useEffect, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import colorConfigs from '../../configs/colorConfigs';


type CalendarDay = Date;
const weeks: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat'];
const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const shortMonths: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];
const enum DatePickerSideViews {
    RdToday,
    RdYesterday,
    RdThisWeek,
    RdThisMonth,
    RdLastMonth,
    RdThisYear,
    RdLastYear,
    RdCustom
}

const sideBarViews = (() => {
    const now = new Date();

    // Format function for date
    const formatDate = (date: Date) => {
        return `${date.getDate()} ${shortMonths[date.getMonth()]} ${date.getFullYear()}`;
    };

    // Calculate Yesterday
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    // Calculate start and end of the current week (assuming week starts on Monday)
    const startOfWeek = new Date(now);
    const endOfWeek = new Date(now);

    const currentDay = now.getDay();
    startOfWeek.setDate(now.getDate() - currentDay); // Monday
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday

    // Calculate start and end of the current month
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the month

    // Calculate start and end of the last month
    const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of last month

    // Calculate start and end of the current year
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const endOfYear = new Date(now.getFullYear(), 11, 31); // Last day of the year

    // Calculate start and end of the last year
    const startOfLastYear = new Date(now.getFullYear() - 1, 0, 1);
    const endOfLastYear = new Date(now.getFullYear() - 1, 11, 31); // Last day of last year

    return [
        {
            type: DatePickerSideViews.RdToday,
            label: "Today",
            date: formatDate(now)
        },
        {
            type: DatePickerSideViews.RdYesterday,
            label: "Yesterday",
            date: formatDate(yesterday)
        },
        {
            type: DatePickerSideViews.RdThisWeek,
            label: "This Week",
            date: `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`
        },
        {
            type: DatePickerSideViews.RdThisMonth,
            label: "This Month",
            date: `${formatDate(startOfMonth)} - ${formatDate(endOfMonth)}`
        },
        {
            type: DatePickerSideViews.RdLastMonth,
            label: "Last Month",
            date: `${formatDate(startOfLastMonth)} - ${formatDate(endOfLastMonth)}`
        },
        {
            type: DatePickerSideViews.RdThisYear,
            label: "This Year",
            date: `${formatDate(startOfYear)} - ${formatDate(endOfYear)}`
        },
        {
            type: DatePickerSideViews.RdLastYear,
            label: "Last Year",
            date: `${formatDate(startOfLastYear)} - ${formatDate(endOfLastYear)}`
        },
        {
            type: DatePickerSideViews.RdCustom,
            label: "Custom Range",
            date: ``
        }
    ];
})();

type DateRangePickerProps = {
    showDateRangePicker: boolean;
    setShowDateRangePicker: (value: boolean) => void;
    setDateRange: (startDate: string, endDate: string) => void;
}

const DateRangePicker = ({
    showDateRangePicker,
    setShowDateRangePicker,
    setDateRange
}: DateRangePickerProps) => {
    const [currentMonthDate, setCurrentMonthDate] = useState<Date>(new Date());
    const [nextMonthDate, setNextMonthDate] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() + 1)));
    const [currentMonth, setCurrentMonth] = useState<any[]>([]);
    const [nextMonth, setNexMonth] = useState<any[]>([]);

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [hoverDate, setHoverDate] = useState<Date | null>(null);
    const [selecting, setSelecting] = useState<boolean>(false);
    const [selectedSidebarViews, setSelectedSidebarViews] = useState<DatePickerSideViews>(DatePickerSideViews.RdCustom);

    const daysInMonth = (month: number, year: number): number => {
        return new Date(year, month + 1, 0).getDate();
    };

    const prevMonthFun = (date: Date): Date => new Date(date.setMonth(date.getMonth() - 1));

    const nextMonthFun = (date: Date): Date => new Date(date.setMonth(date.getMonth() + 1));


    const generateCalendar = (month: number, year: number): CalendarDay[] => {
        const days: CalendarDay[] = [];
        const firstDay = new Date(year, month, 1).getDay();
        const lastDay = new Date(year, month + 1, 0).getDay();
        const numDays = daysInMonth(month, year);

        // Calculate days in the previous month
        const prevMonthDays = daysInMonth(month - 1, year);

        // Fill initial empty days from the previous month
        for (let i = prevMonthDays - firstDay + 1; i <= prevMonthDays; i++) {
            days.push(new Date(year, month - 1, i)); // Previous month's days
        }

        // Fill days of the current month
        for (let i = 1; i <= numDays; i++) {
            days.push(new Date(year, month, i)); // Current month's days
        }

        // Fill one week of the next month
        for (let i = 1; i <= 7 - (lastDay + 1); i++) {
            days.push(new Date(year, month + 1, i)); // Next month's days
        }

        return days;
    };

    useEffect(() => {
        const currentMonth = currentMonthDate.getMonth();
        const currentYear = currentMonthDate.getFullYear();
        const days = generateCalendar(currentMonth, currentYear);
        setCurrentMonth(days);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMonthDate])


    useEffect(() => {
        const nextMonth = nextMonthDate.getMonth();
        const nextYear = nextMonthDate.getFullYear();
        setNexMonth(generateCalendar(nextMonth, nextYear));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextMonthDate])


    const handleDayClick = (day: Date) => {
        if (!selecting) {
            setStartDate(day);
            setEndDate(null);
            setSelecting(true);
            setSelectedSidebarViews(DatePickerSideViews.RdCustom);
        } else {
            if (day >= (startDate as Date)) {
                setEndDate(day);
            } else {
                setEndDate(startDate);
                setStartDate(day);
            }
            setSelecting(false);
        }
    };

    const handleDayHover = (day: Date) => {
        if (selecting) {
            setHoverDate(day);
        }
    };

    const onClickSidebarViews = (datePickerSideViews: DatePickerSideViews) => {
        const now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        setSelectedSidebarViews(datePickerSideViews);
        setSelecting(false);

        switch (datePickerSideViews) {
            case DatePickerSideViews.RdToday:
                setStartDate(now);
                setEndDate(now);
                return;
            case DatePickerSideViews.RdYesterday:
                const yesterday = new Date(now);
                yesterday.setDate(now.getDate() - 1);

                setStartDate(yesterday);
                setEndDate(yesterday);
                return;
            case DatePickerSideViews.RdThisWeek:
                const startOfWeek = new Date(now);
                const endOfWeek = new Date(now);

                const currentDay = now.getDay();
                startOfWeek.setDate(now.getDate() - currentDay); // Monday
                endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday

                setStartDate(startOfWeek);
                setEndDate(endOfWeek);
                return;
            case DatePickerSideViews.RdThisMonth:
                // Calculate start and end of the current month
                const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
                const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the month

                setStartDate(startOfMonth);
                setEndDate(endOfMonth)
                return;
            case DatePickerSideViews.RdLastMonth:
                const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of last month

                setStartDate(startOfLastMonth);
                setEndDate(endOfLastMonth);
                return;
            case DatePickerSideViews.RdThisYear:
                const startOfYear = new Date(now.getFullYear(), 0, 1);
                const endOfYear = new Date(now.getFullYear(), 11, 31); // Last day of the year

                setStartDate(startOfYear);
                setEndDate(endOfYear);
                return;
            case DatePickerSideViews.RdLastYear:
                const startOfLastYear = new Date(now.getFullYear() - 1, 0, 1);
                const endOfLastYear = new Date(now.getFullYear() - 1, 11, 31); // Last day of last year

                setStartDate(startOfLastYear);
                setEndDate(endOfLastYear);
                return;
            case DatePickerSideViews.RdCustom:
                setStartDate(null);
                setEndDate(null);
                return;
        }
    }


    const renderMonth = (day: Date, index: number, isCurrentMonth = true) => {
        let bgColor = "#ffffff";
        let inRangeBgColor = "#ffffff";
        let opacity = "100%";
        let borderColor = "transparent";
        let color = "#36454F";
        let borderRadius = "0%";
        let topLeftRaduis = "0%";
        let topRightRaduis = "0%";
        let bottomLeftRaduis = "0%";
        let bottomRightRaduis = "0%";


        const currentDate = isCurrentMonth ? currentMonthDate : nextMonthDate;
        const targetMonth = currentDate.getMonth();
        const targetYear = currentDate.getFullYear();

        const dayDate = new Date(day).toISOString().split('T')[0];
        const startDateStr = startDate && new Date(startDate).toISOString().split('T')[0];
        const endDateStr = endDate && new Date(endDate).toISOString().split('T')[0];
        const hoverDateStr = hoverDate && new Date(hoverDate).toISOString().split('T')[0];


        if (isCurrentMonth && day.getFullYear() === new Date().getFullYear() && day.getMonth() === new Date().getMonth() && day.getDate() === new Date().getDate()) {
            borderColor = colorConfigs.topbar.bg;
            color = colorConfigs.topbar.bg;
            borderRadius = "50%";
        }

        if (startDate && day === startDate) {
            bgColor = colorConfigs.topbar.bg;
            borderColor = colorConfigs.topbar.bg;
        }

        if (startDateStr && hoverDateStr) {
            if (dayDate > startDateStr && (dayDate >= startDateStr && dayDate <= hoverDateStr)) {
                inRangeBgColor = "#F2F4F7";
                bgColor = "transparent";
                color = colorConfigs.topbar.bg;
                borderRadius = "0%";
                topLeftRaduis = "50%";
                topRightRaduis = "50%";
                bottomLeftRaduis = "50%";
                bottomRightRaduis = "50%";
                borderColor = "transparent";
            } else if (dayDate >= hoverDateStr && dayDate <= hoverDateStr) {
                inRangeBgColor = "#F2F4F7";
                bgColor = "transparent";
                color = colorConfigs.topbar.bg;
                borderRadius = "0%";
                topLeftRaduis = "50%";
                topRightRaduis = "50%";
                bottomLeftRaduis = "50%";
                bottomRightRaduis = "50%";
                borderColor = "transparent";
            } else {
                borderRadius = "50%";
            }
        }

        if (startDateStr && endDateStr) {
            if (dayDate >= startDateStr && dayDate <= endDateStr) {
                bgColor = "transparent";
                inRangeBgColor = "rgba(41, 91, 126, 0.2)";
                borderRadius = "0%";
            }
        }

        if (day.getMonth() !== targetMonth || day.getFullYear() !== targetYear) {
            bgColor = "#ffffff";
            color = "#36454F";
            borderColor = "transparent";
            opacity = "50%";
        } else {
            if (dayDate === startDateStr || dayDate === endDateStr) {
                bgColor = colorConfigs.topbar.bg;
                inRangeBgColor = "transparent";
                borderColor = colorConfigs.topbar.bg;
                borderRadius = "50%";
                color = "#F2F4F7";
            }

            if (startDateStr && endDateStr) {
                topLeftRaduis = "0%";
                topRightRaduis = "0%";
                bottomLeftRaduis = "0%";
                bottomRightRaduis = "0%";

                if (dayDate === startDateStr) {
                    inRangeBgColor = "rgba(41, 91, 126, 0.2)";
                    topLeftRaduis = "50%";
                    bottomLeftRaduis = "50%";
                }

                if (dayDate === endDateStr) {
                    inRangeBgColor = "rgba(41, 91, 126, 0.2)";
                    topRightRaduis = "50%";
                    bottomRightRaduis = "50%";
                }
            }
        }

        return (
            <div
                key={index}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "32px",
                    height: "32px",
                    backgroundColor: inRangeBgColor,
                    color: color,
                    opacity: opacity,
                    position: "relative",
                    borderTopLeftRadius: topLeftRaduis,
                    borderTopRightRadius: topRightRaduis,
                    borderBottomLeftRadius: bottomLeftRaduis,
                    borderBottomRightRadius: bottomRightRaduis
                }}
                onClick={() => handleDayClick(day)}
                onMouseOver={() => handleDayHover(day)}
                onTouchMove={() => handleDayHover(day)}
            >

                <span style={{ fontSize: "12px" }}>
                    {day && String(day?.getDate()).padStart(2, '0')}
                </span>
                <div
                    style={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: bgColor,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: borderColor,
                        borderRadius: borderRadius,
                        position: "absolute",
                        inset: 0,
                        zIndex: -1
                    }}
                >
                </div>
            </div>
        )
    }

    const closeTheDatePicker = () => {
        setShowDateRangePicker(false);
    }

    const confirmTheDatePicker = () => {
        if (startDate && endDate) {
            setDateRange(startDate?.toDateString(), endDate?.toDateString());
            setShowDateRangePicker(false);
        }
    }


    return (
        <div style={{
            display: showDateRangePicker ? "block" : "none",
            boxSizing: "border-box",
            position: "absolute",
            zIndex: 10,
            top: 124,
            right: 4,
            backgroundColor: "white",
            border: "2px solid #ddd",
            borderRadius: "10px"
        }}>

            <div
                style={{
                    display: "flex",
                    position: "relative"
                }}>

                <div style={{
                    height: "100%"
                }}>

                    <ul style={{
                        textDecoration: "none",
                        listStyle: "none",
                        padding: "0px 8px",
                        display: "flex",
                        flexDirection: "column",
                        columnGap: "8px"
                    }}>
                        {sideBarViews?.map((item: any, index: number) => (
                            <li
                                key={index}
                                style={{
                                    padding: "8px 6px",
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                    backgroundColor: item?.type === selectedSidebarViews ? "rgba(41, 91, 126, 0.2)" : "transparent",
                                    borderRadius: "4px"
                                }}
                                onClick={() => onClickSidebarViews(item?.type)}
                            >
                                <span
                                    style={{
                                        color: "black",
                                        fontSize: "0.75rem",
                                    }}
                                >
                                    {item?.label}
                                </span>

                                <span
                                    style={{
                                        color: "gray",
                                        fontSize: "0.675rem",
                                    }}
                                >
                                    {item?.date}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>


                <div style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div style={{
                        display: "flex",
                        height: "100%",
                        borderBottom: "2px solid #ddd"
                    }}>
                        <div style={{
                            width: "100%",
                            borderLeft: "2px solid #ddd"
                        }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    color: "#36454F",
                                    paddingInline: "16px",
                                }}
                            >
                                <ArrowBackIosIcon style={{ fontSize: "1rem", cursor: "pointer" }}
                                    sx={{
                                        "&hover": {
                                            backgroundColor: "#F2F4F7",
                                            borderRadius: "50%"
                                        }
                                    }}
                                    onClick={() => setCurrentMonthDate(prevMonthFun(currentMonthDate))}
                                />
                                <h5>{`${months[currentMonthDate.getMonth()]} ${currentMonthDate.getFullYear()}`}</h5>

                                <ArrowForwardIosIcon
                                    style={{ fontSize: "1rem", cursor: "pointer" }}
                                    sx={{
                                        "&hover": {
                                            backgroundColor: "#F2F4F7",
                                            borderRadius: "50%"
                                        }
                                    }}
                                    onClick={() => {
                                        const nextMonth = nextMonthFun(currentMonthDate);
                                        if (nextMonth.getMonth() === nextMonthDate.getMonth() && nextMonth.getFullYear() === nextMonthDate.getFullYear()) {
                                            setNextMonthDate(nextMonthFun(nextMonthDate));
                                        }
                                        setCurrentMonthDate(nextMonth);
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    width: "100%",
                                    height: "fit-content",
                                    display: "grid",
                                    gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
                                    gridTemplateRows: 'repeat(7, minmax(0, 1fr))',
                                    gap: 0,
                                    paddingInline: "4px"
                                }}>

                                {weeks?.map((item: string, index: number) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "32px",
                                            height: "32px",
                                            border: "1px solid transparent",
                                            color: "#36454F"
                                        }}
                                    >
                                        <h5 style={{ fontSize: "12px" }}>{item}</h5>
                                    </div>
                                ))}

                                {currentMonth?.map((item: Date, index: number) => (
                                    renderMonth(item, index)
                                ))}
                            </div>

                        </div>

                        <div style={{
                            width: "100%",
                            borderLeft: "2px solid #ddd"
                        }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    color: "#36454F",
                                    paddingInline: "16px",
                                }}
                            >
                                <ArrowBackIosIcon style={{ fontSize: "1rem", cursor: "pointer" }}
                                    sx={{
                                        "&hover": {
                                            backgroundColor: "#F2F4F7",
                                            borderRadius: "50%"
                                        }
                                    }}
                                    onClick={() => {
                                        const prevMonth = prevMonthFun(nextMonthDate);
                                        if (prevMonth.getMonth() === currentMonthDate.getMonth() && prevMonth.getFullYear() === currentMonthDate.getFullYear()) {
                                            setCurrentMonthDate(prevMonthFun(currentMonthDate));
                                        }
                                        setNextMonthDate(prevMonth);
                                    }}
                                />
                                <h5>{`${months[nextMonthDate.getMonth()]} ${nextMonthDate.getFullYear()}`}</h5>

                                <ArrowForwardIosIcon
                                    style={{ fontSize: "1rem", cursor: "pointer" }}
                                    sx={{
                                        "&hover": {
                                            backgroundColor: "#F2F4F7",
                                            borderRadius: "50%"
                                        }
                                    }}
                                    onClick={() => setNextMonthDate(nextMonthFun(nextMonthDate))}
                                />
                            </div>

                            <div
                                style={{
                                    width: "fit-content",
                                    height: "fit-content",
                                    display: "grid",
                                    gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
                                    gridTemplateRows: 'repeat(7, minmax(0, 1fr))',
                                    gap: 0,
                                    paddingInline: "4px"
                                }}>

                                {weeks?.map((item: string, index: number) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "32px",
                                            height: "32px",
                                            border: "1px solid transparent",
                                            color: "#36454F"
                                        }}
                                    >
                                        <h5 style={{ fontSize: "12px" }}>{item}</h5>
                                    </div>
                                ))}

                                {nextMonth?.map((item: Date, index: number) => (
                                    renderMonth(item, index, false)
                                ))}
                            </div>

                        </div>

                    </div>


                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px 16px",
                        borderLeft: "2px solid #ddd"
                    }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px"
                        }}>
                            <span
                                style={{
                                    backgroundColor: "#ffffff",
                                    border: startDate ? `1px solid ${colorConfigs.topbar.bg}` : "none",
                                    borderRadius: "5px",
                                    padding: "6px 12px"
                                }}
                            >
                                {
                                    startDate
                                        ? `${shortMonths[startDate?.getMonth()]} ${startDate?.getDate()}, ${startDate?.getFullYear()}`
                                        : ""
                                }
                            </span>
                            <span>-</span>
                            <span
                                style={{
                                    backgroundColor: "#ffffff",
                                    border: endDate ? `1px solid ${colorConfigs.topbar.bg}` : "none",
                                    borderRadius: "5px",
                                    padding: "6px 12px"
                                }}
                            >
                                {
                                    endDate
                                        ? `${shortMonths[endDate?.getMonth()]} ${endDate?.getDate()}, ${endDate?.getFullYear()}`
                                        : ""
                                }
                            </span>
                        </div>

                        <div style={{
                            display: "flex",
                            gap: "12px"
                        }}>

                            <button
                                type='button'
                                style={{
                                    backgroundColor: "#ffffff",
                                    border: `1px solid ${colorConfigs.topbar.bg}`,
                                    borderRadius: "5px",
                                    padding: "6px 12px",
                                    color: colorConfigs.topbar.bg,
                                    cursor: "pointer"
                                }}
                                onClick={closeTheDatePicker}
                            >
                                Cancel
                            </button>

                            <button
                                type='button'
                                style={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    border: `1px solid ${colorConfigs.topbar.bg}`,
                                    borderRadius: "5px",
                                    padding: "6px 12px",
                                    color: "#ffffff",
                                    cursor: !(startDate && endDate) ? "not-allowed" : "pointer",
                                    opacity: !(startDate && endDate) ? "50%" : "100%"
                                }}
                                onClick={confirmTheDatePicker}
                                disabled={!(startDate && endDate)}
                            >
                                Confirm
                            </button>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default DateRangePicker
