import {
  Box,
  Toolbar,
  Typography,
  Stack,
  Button,
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tune, FileDownloadOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import colorConfigs from "../../../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../../../configs/api";
import AllRoles from "./AllRoles";
import PleaseWaitPopup from "../../../../PleaseWaitPopup";
import Cookies from "js-cookie";
import { put_AccessControlByGroupName } from "../../../../../services/acessControl";

export default function AccessControl() {
  const token = Cookies.get("token-jj-rental-ui");
  const [pages, setPages] = React.useState<any[]>([]);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [currentPage, setCurrentPage] = React.useState<any | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [expandListGroup, setExpandListGroup] = useState<any[]>([]);
  const [pleaseWait, setPleaseWait] = useState<boolean>(false);
  const [isChange, setIsChange] = useState<number>(0);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: any) => {
    setAnchorElNav(null);
    setCurrentPage(page);
  };

  useEffect(() => {
    setPleaseWait(true);
    axios
      .get(`${baseURL}roles`)
      .then((response) => {
        setPages(() =>
          response.data
            .filter((value: any) => value.roleName.toLowerCase() !== "admin")
            .map((item: any) => {
              return {
                id: item.id,
                name: item.roleName,
              };
            })
        );

        setCurrentPage({
          id: response.data.filter(
            (value: any) => value.roleName.toLowerCase() !== "admin"
          )[0].id,
          name: response.data.filter(
            (value: any) => value.roleName.toLowerCase() !== "admin"
          )[0].roleName,
        });
        setPleaseWait(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getExpandGroupsDetails = (expandList: any[]) => {
    setExpandListGroup(() => expandList);
  };

  const updateToNotSetAll = () => {
    if (expandListGroup.length !== 0) {
      setPleaseWait(true);
      let count = 0;
      expandListGroup.forEach(async (item: any) => {
        put_AccessControlByGroupName(item.sections[0].roleId, item.groupName, 1, token!.toString())
          .then(() => {
            count++;
            if (expandListGroup.length === count) {
              setIsChange(1);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
    }
  };

  const updateToAllowAll = () => {
    if (expandListGroup.length !== 0) {
      setPleaseWait(true);
      let count = 0;
      expandListGroup.forEach(async (item: any) => {
        put_AccessControlByGroupName(item.sections[0].roleId, item.groupName, 2, token!.toString())
          .then(() => {
            count++;
            if (expandListGroup.length === count) {
              setIsChange(2);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
    }
  };

  const updateToDenyAll = () => {
    if (expandListGroup.length !== 0) {
      setPleaseWait(true);
      let count = 0;
      expandListGroup.forEach(async (item: any) => {
        put_AccessControlByGroupName(item.sections[0].roleId, item.groupName, 3, token!.toString())
          .then(() => {
            count++;
            if (expandListGroup.length === count) {
              setIsChange(3);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
    }
  };

  return (
    <Box>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tune sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "16px" }}>
            Access Control
          </Typography>
        </Box>

        <Stack spacing={0.5} direction="row">
          <input
            type="text"
            placeholder="Search..."
            style={{
              maxHeight: "30px",
              minHeight: "30px",
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />

          <button
            style={{
              backgroundColor: "white",
              color: "gray",
              borderRadius: "3px",
              border: "1px solid gray",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: "bold",
              cursor: "pointer",
              maxHeight: "30px",
              minHeight: "30px",
            }}
            onClick={() => updateToNotSetAll()}
          >
            Not Set All
          </button>

          <button
            style={{
              backgroundColor: "#66BB6A",
              color: "white",
              borderRadius: "3px",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: "bold",
              cursor: "pointer",
              maxHeight: "30px",
              minHeight: "30px",
              border: "none",
            }}
            onClick={() => updateToAllowAll()}
          >
            Allow All
          </button>

          <button
            style={{
              backgroundColor: "#ff0000",
              color: "white",
              borderRadius: "3px",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: "bold",
              cursor: "pointer",
              maxHeight: "30px",
              minHeight: "30px",
              border: "none",
            }}
            onClick={() => updateToDenyAll()}
          >
            Deny All
          </button>

          <div
            style={{
              backgroundColor: "#ffffff",
              color: "black",
              borderRadius: "3px",
              border: "2px solid #bdbdbd",
              textTransform: "capitalize",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "5px",
              maxHeight: "30px",
              minHeight: "30px",
            }}
          >
            <FileDownloadOutlined />
          </div>
        </Stack>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page: any) => {
                if (currentPage.id === page.id) {
                  return (
                    <MenuItem
                      key={page.id}
                      onClick={() => handleOnClick(page)}
                      sx={{
                        backgroundColor: colorConfigs.sidebar.bg,
                        color: colorConfigs.sidebar.color,
                      }}
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={page.id} onClick={() => handleOnClick(page)}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (currentPage.id === page.id) {
                return (
                  <Button
                    disableElevation={false}
                    key={page.id}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page.id}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>

      {currentPage && (
        <AllRoles
          roleId={currentPage.id}
          searchValue={searchValue}
          getExpandGroupsDetails={(expandList) =>
            getExpandGroupsDetails(expandList)
          }
          setPleaseWait={(wait) => setPleaseWait(wait)}
          isChange={isChange}
          updateToZeroChange={() => setIsChange(0)}
        />
      )}

      <PleaseWaitPopup pleaseWaitPopup={pleaseWait} />
    </Box>
  );
}
