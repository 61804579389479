import React, { useEffect, useState } from "react";

type OpenHourType = {
  id: number;
  locationId: number;
  startDate: string;
  endDate: string;
  branchId: number;
  isSundayOpen: boolean;
  sundayOpenTime: string;
  sundayCloseTime: string;
  isMondayOpen: boolean;
  mondayOpenTime: string;
  mondayCloseTime: string;
  isTuesdayOpen: boolean;
  tuesdayOpenTime: string;
  tuesdayCloseTime: string;
  isWednesdayOpen: boolean;
  wednesdayOpenTime: string;
  wednesdayCloseTime: string;
  isThursdayOpen: boolean;
  thursdayOpenTime: string;
  thursdayCloseTime: string;
  isFridayOpen: boolean;
  fridayOpenTime: string;
  fridayCloseTime: string;
  isSaturdayOpen: boolean;
  saturdayOpenTime: string;
  saturdayCloseTime: string;
  uniqueId: number;
};

type Props = {
  index: number;
  uniqueNumber: number;
  data: OpenHourType;
  changeDataOnOpenHour: (data: OpenHourType, index: number) => void;
  deleteTheFleetOpenHour: (index: number) => void;
};

export default function AddOpenHour({
  index,
  uniqueNumber,
  data,
  changeDataOnOpenHour,
  deleteTheFleetOpenHour
}: Props) {
  const [startDate, setStartDate] = useState<string>(data.startDate);
  const [endDate, setEndDate] = useState<string>(data.endDate);
  const [isSundayOpen, setIsSundayOpen] = useState<boolean>(data.isSundayOpen);
  const [sundayOpenTime, setSundayOpenTime] = useState<string>(
    data.sundayOpenTime
  );
  const [sundayCloseTime, setSundayCloseTime] = useState<string>(
    data.sundayCloseTime
  );
  const [isMondayOpen, setIsMondayOpen] = useState<boolean>(data.isMondayOpen);
  const [mondayOpenTime, setMondayOpenTime] = useState<string>(
    data.mondayOpenTime
  );
  const [mondayCloseTime, setMondayCloseTime] = useState<string>(
    data.mondayCloseTime
  );
  const [isTuesdayOpen, setIsTuesdayOpen] = useState<boolean>(
    data.isTuesdayOpen
  );
  const [tuesdayOpenTime, setTuesdayOpenTime] = useState<string>(
    data.tuesdayOpenTime
  );
  const [tuesdayCloseTime, setTuesdayCloseTime] = useState<string>(
    data.tuesdayCloseTime
  );
  const [isWednesdayOpen, setIsWednesdayOpen] = useState<boolean>(
    data.isWednesdayOpen
  );
  const [wednesdayOpenTime, setWednesdayOpenTime] = useState<string>(
    data.wednesdayOpenTime
  );
  const [wednesdayCloseTime, setWednesdayCloseTime] = useState<string>(
    data.wednesdayCloseTime
  );
  const [isThursdayOpen, setIsThursdayOpen] = useState<boolean>(
    data.isThursdayOpen
  );
  const [thursdayOpenTime, setThursdayOpenTime] = useState<string>(
    data.thursdayOpenTime
  );
  const [thursdayCloseTime, setThursdayCloseTime] = useState<string>(
    data.thursdayCloseTime
  );
  const [isFridayOpen, setIsFridayOpen] = useState<boolean>(data.isFridayOpen);
  const [fridayOpenTime, setFridayOpenTime] = useState<string>(
    data.fridayOpenTime
  );
  const [fridayCloseTime, setFridayCloseTime] = useState<string>(
    data.fridayCloseTime
  );
  const [isSaturdayOpen, setIsSaturdayOpen] = useState<boolean>(
    data.isSaturdayOpen
  );
  const [saturdayOpenTime, setSaturdayOpenTime] = useState<string>(
    data.saturdayOpenTime
  );
  const [saturdayCloseTime, setSaturdayCloseTime] = useState<string>(
    data.saturdayCloseTime
  );

  useEffect(() => {
    const addOpenHourData: OpenHourType = {
      id: data.id,
      locationId: data.locationId,
      startDate: startDate,
      endDate: endDate,
      branchId: data.branchId,
      isSundayOpen: isSundayOpen,
      sundayOpenTime: sundayOpenTime,
      sundayCloseTime: sundayCloseTime,
      isMondayOpen: isMondayOpen,
      mondayOpenTime: mondayOpenTime,
      mondayCloseTime: mondayCloseTime,
      isTuesdayOpen: isTuesdayOpen,
      tuesdayOpenTime: tuesdayOpenTime,
      tuesdayCloseTime: tuesdayCloseTime,
      isWednesdayOpen: isWednesdayOpen,
      wednesdayOpenTime: wednesdayOpenTime,
      wednesdayCloseTime: wednesdayCloseTime,
      isThursdayOpen: isThursdayOpen,
      thursdayOpenTime: thursdayOpenTime,
      thursdayCloseTime: thursdayCloseTime,
      isFridayOpen: isFridayOpen,
      fridayOpenTime: fridayOpenTime,
      fridayCloseTime: fridayCloseTime,
      isSaturdayOpen: isSaturdayOpen,
      saturdayOpenTime: saturdayOpenTime,
      saturdayCloseTime: saturdayCloseTime,
      uniqueId: uniqueNumber
    };

    changeDataOnOpenHour(addOpenHourData, index);
  }, [
    startDate,
    endDate,
    isSundayOpen,
    sundayOpenTime,
    sundayCloseTime,
    isMondayOpen,
    mondayOpenTime,
    mondayCloseTime,
    isTuesdayOpen,
    tuesdayOpenTime,
    tuesdayCloseTime,
    isWednesdayOpen,
    wednesdayOpenTime,
    wednesdayCloseTime,
    isThursdayOpen,
    thursdayOpenTime,
    thursdayCloseTime,
    isFridayOpen,
    fridayOpenTime,
    fridayCloseTime,
    isSaturdayOpen,
    saturdayOpenTime,
    saturdayCloseTime,
  ]);

  return (
    <>
      <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Open Hour Block
                </div>

                <div
                  style={{ color: "blue", fontSize: "12px", cursor: "pointer" }}
                  onClick={() => deleteTheFleetOpenHour(uniqueNumber)}
                >
                  Delete
                </div>
              </div>

              <hr />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          paddingLeft: "8px",
          paddingRight: "8px",
        }}
      >
        <div
          style={{
            width: "50%",
          }}
        >
          <label
            htmlFor="startDate"
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            Start Date
            <br />
            <input
              type="date"
              name="startDate"
              style={{
                width: "100%",
                fontSize: "14px",
                paddingBlock: "5px",
              }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
        </div>

        <div
          style={{
            width: "50%",
          }}
        >
          <label
            htmlFor="endDate"
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            End Date
            <br />
            <input
              type="date"
              name="endDate"
              style={{
                width: "100%",
                fontSize: "14px",
                paddingBlock: "5px",
              }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "20px",
          gap: "8px",
        }}
      >
        <div
          style={{
            width: "5%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          Open?
        </div>

        <div
          style={{
            width: "11%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          Day of week
        </div>
        <div
          style={{
            width: "28%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          Open Time
        </div>

        <div
          style={{
            width: "28%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          Close Time
        </div>

        <div
          style={{
            width: "28%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          Lunch Time Interval
        </div>
      </div>
      <hr style={{ marginBottom: "0px" }} />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          <div
            style={{
              width: "5%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="checkbox"
              checked={isSundayOpen}
              onChange={(e) => setIsSundayOpen(!isSundayOpen)}
            />
          </div>

          <div
            style={{
              width: "11%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            Sunday
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={sundayOpenTime}
              onChange={(e) => setSundayOpenTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={sundayCloseTime}
              onChange={(e) => setSundayCloseTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="text"
            />
          </div>
        </div>

        <hr />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          <div
            style={{
              width: "5%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="checkbox"
              checked={isMondayOpen}
              onChange={(e) => setIsMondayOpen(!isMondayOpen)}
            />
          </div>

          <div
            style={{
              width: "11%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            Monday
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={mondayOpenTime}
              onChange={(e) => setMondayOpenTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={mondayCloseTime}
              onChange={(e) => setMondayCloseTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="text"
            />
          </div>
        </div>

        <hr />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          <div
            style={{
              width: "5%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="checkbox"
              checked={isTuesdayOpen}
              onChange={(e) => setIsTuesdayOpen(!isTuesdayOpen)}
            />
          </div>

          <div
            style={{
              width: "11%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            Tuesday
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={tuesdayOpenTime}
              onChange={(e) => setTuesdayOpenTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={tuesdayCloseTime}
              onChange={(e) => setTuesdayCloseTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="text"
            />
          </div>
        </div>

        <hr />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          <div
            style={{
              width: "5%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="checkbox"
              checked={isWednesdayOpen}
              onChange={(e) => setIsWednesdayOpen(!isWednesdayOpen)}
            />
          </div>

          <div
            style={{
              width: "11%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            Wednesday
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={wednesdayOpenTime}
              onChange={(e) => setWednesdayOpenTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={wednesdayCloseTime}
              onChange={(e) => setWednesdayCloseTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="text"
            />
          </div>
        </div>

        <hr />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          <div
            style={{
              width: "5%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="checkbox"
              checked={isThursdayOpen}
              onChange={(e) => setIsThursdayOpen(!isThursdayOpen)}
            />
          </div>

          <div
            style={{
              width: "11%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            Thursday
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={thursdayOpenTime}
              onChange={(e) => setThursdayOpenTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={thursdayCloseTime}
              onChange={(e) => setThursdayCloseTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="text"
            />
          </div>
        </div>

        <hr />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          <div
            style={{
              width: "5%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="checkbox"
              checked={isFridayOpen}
              onChange={(e) => setIsFridayOpen(!isFridayOpen)}
            />
          </div>

          <div
            style={{
              width: "11%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            Friday
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={fridayOpenTime}
              onChange={(e) => setFridayOpenTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={fridayCloseTime}
              onChange={(e) => setFridayCloseTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="text"
            />
          </div>
        </div>

        <hr />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          <div
            style={{
              width: "5%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="checkbox"
              checked={isSaturdayOpen}
              onChange={(e) => setIsSaturdayOpen(!isSaturdayOpen)}
            />
          </div>

          <div
            style={{
              width: "11%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            Saturday
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={saturdayOpenTime}
              onChange={(e) => setSaturdayOpenTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="time"
              value={saturdayCloseTime}
              onChange={(e) => setSaturdayCloseTime(e.target.value)}
            />
          </div>

          <div
            style={{
              width: "28%",
              color: "gray",
              textAlign: "start",
              fontSize: "12px",
              marginBlock: "4px",
            }}
          >
            <input
              style={{ width: "100%" }}
              type="text"
            />
          </div>
        </div>

        <hr />
      </div>
    </>
  );
}
