import axios from "axios"
import baseURL from "../configs/api"

export const getAddOnCategories = () => {
    return getFetch("addOnCategory");
}

export const getAddOnCategory = (primaryKey: string) => {
    return getFetch(`addOnCategory/${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}