import { Outlet } from "react-router-dom";


const Fleet = () => {
  return (
    <><Outlet /></>
  );
};

export default Fleet;
