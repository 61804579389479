import React from 'react'

type Props ={
    branchId: number
}

export default function PaymentScheduleBeforePickup({branchId}: Props) {
  return (
    <div>Payment Schedule Before Pickup {branchId}</div>
  )
}
