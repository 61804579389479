import React, { useRef } from 'react'

type DateInputProps = {
    name: string;
    value: string;
    setValue: (value: string) => void;
    paddingBlock?: string;
    required?: boolean;
}

const DateInput = ({ name, value, setValue, paddingBlock = "3px", required = false }: DateInputProps) => {
    const dateRef = useRef<HTMLInputElement>(null);
    

    return (
        <input
            style={{
                width: "100%",
                fontSize: "12px",
                paddingBlock: paddingBlock,
            }}
            type="date"
            name={name}
            ref={dateRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onClick={() => dateRef.current?.showPicker()}
            required={required}
        />
    )
}

export default DateInput
