import {
  InputBase,
  Box,
  Stack,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { GridColDef, DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import baseURL from "../../../configs/api";
import {
  Build,
  ContentCut,
  WatchLater,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import axios from "axios";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  width: "100%",
  paddingBottom: "0px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  paddingLeft: `calc(1em + ${theme.spacing(2)})`,
  paddingBottom: "0px",
}));

type AllMaintenanceProps = {
  paginationModel: any;
  setPaginationModel: (value: any) => void;
  totalLength: number;
  setSearchTableData: (value: any[]) => void;
  searchTableData: any[];
  setIsDataLoaded: (value: boolean) => void;
  isDataLoaded: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  oldPage: number;
  setOldPage: (value: number) => void;
  setTotalLength: (value: number) => void;
}

export default function AllMaintenance({
  paginationModel,
  setPaginationModel,
  totalLength,
  setSearchTableData,
  searchTableData,
  setIsDataLoaded,
  searchValue,
  setSearchValue,
  isDataLoaded,
  oldPage,
  setOldPage,
  setTotalLength
}: AllMaintenanceProps) {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 120,
      editable: false,
      sortable: false,
    },
    {
      field: "vehicleName",
      headerName: "Vehicle",
      headerAlign: "center",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "maintenanceTypeName",
      headerName: "Maintenance Type",
      width: 240,
      editable: false,
      sortable: false,
    },
    {
      field: "lastMaintenaceDate",
      headerName: "Last Maintenace Date",
      editable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "lastMaintenaceOdometer",
      headerName: "Last Maintenace Odometer",
      type: "number",
      width: 160,
      editable: false,
      sortable: false,
    },
    {
      field: "nextMaintenaceDate",
      headerName: "Next Maintenace Date",
      type: "number",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "nextMaintenaceOdometer",
      headerName: "Next Maintenace Odometer",
      type: "number",
      width: 160,
      editable: false,
      sortable: false,
    },
    {
      field: "maintenanceStatusName",
      headerName: "Status",
      type: "number",
      width: 110,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        let color = "#bdbdbd";
        switch (params.value) {
          case "Pending":
            color = "#808080";
            break;
          case "Skipped":
            color = "#ff0000";
            break;
          case "Done":
            color = "#66BB6A";
            break;
        }

        return (
          <p
            style={{
              width: "50px",
              color: "white",
              background: color,
              padding: "1px 3px",
              textAlign: "center",
              borderRadius: "2px",
              fontSize: "10px",
            }}
          >
            {params.value}
          </p>
        );
      },
    },
    {
      field: "locationName",
      headerName: "Vehicle Current Location",
      type: "number",
      width: 160,
      editable: false,
      sortable: false,
    },
    {
      field: "outstandingBalance",
      headerName: "Action",
      type: "number",
      width: 150,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box>
            {params.row.maintenanceStatus === "Skipped" ? (
              <Tooltip title="Set to Pending">
                <IconButton
                  onClick={() => {
                    axios
                      .put(
                        `${baseURL}maintenanceStatus`,
                        JSON.stringify({
                          id: params.row.maintenanceStatusId,
                          name: "Pending",
                        }),
                        {
                          headers: { "Content-Type": "application/json" },
                          withCredentials: true,
                        }
                      )
                      .then((response) => {
                        setPaginationModel({
                          page: 0,
                          pageSize: paginationModel.pageSize,
                        })
                      })
                      .catch((error) => {
                        console.error("Error:", error);
                      });
                  }}
                >
                  <WatchLater sx={{ fontSize: "14px", color: "blue" }} />
                </IconButton>
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Create Repair Order">
                  <IconButton
                    onClick={() =>
                      navigate("/fleet/maintenance/createrepairorder", {
                        state: params.id,
                      })
                    }
                  >
                    <Build
                      sx={{
                        fontSize: "14px",
                        color: "blue",
                        transform: "rotate(90deg)",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Skip Maintenance">
                  <IconButton
                    onClick={() => {
                      if (
                        window.confirm(
                          "If you Skipped this maintenance the next alert will be created in the next interval applicable from the settings. This action can not be reverted"
                        )
                      ) {
                        axios
                          .put(
                            `${baseURL}maintenanceStatus`,
                            JSON.stringify({
                              id: params.row.maintenanceStatusId,
                              name: "Skipped",
                            }),
                            {
                              headers: { "Content-Type": "application/json" },
                              withCredentials: true,
                            }
                          )
                          .then((response) => {
                            setPaginationModel({
                              page: 0,
                              pageSize: paginationModel.pageSize,
                            })
                          })
                          .catch((error) => {
                            console.error("Error:", error);
                          });
                      }
                    }}
                  >
                    <ContentCut sx={{ fontSize: "14px", color: "blue" }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        );
      },
    },
  ];

  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [maintenanceFilterValue, setMaintenanceFilterValue] = useState(0);
  const [operatorFilter, setOpertorFilter] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState<string>("Maintenance Type Name");

  useEffect(() => {

    let timeOutId: NodeJS.Timeout;

    if (searchValue !== "") {
      timeOutId = setTimeout(() => {
        const newPaginationModel = {
          page: 0,
          pageSize: paginationModel.pageSize,
        };

        setPaginationModel(newPaginationModel);

      }, 1000);

    }

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue !== "") {
      apiCall(paginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const apiCall = (newPaginationModel: any) => {
    let filterData: any[] = [];
    setIsDataLoaded(true);
    switch (maintenanceFilterValue) {
      case 0:
        fetch(`${baseURL}maintenance/GetMaintenanceReportSearchWithMaintenanceTypeNamePaging/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
          .then((res) => res.json())
          .then((data) => {

            filterData = data.maintenanceReport.map((item: any) => {
              return {
                ...item,
                lastMaintenaceDate: new Intl.DateTimeFormat("en-US").format(
                  new Date(item.lastMaintenaceDate)
                ),
                nextMaintenaceDate: new Intl.DateTimeFormat("en-US").format(
                  new Date(item.nextMaintenaceDate)
                ),
              };
            });

            let newArray = [];
            if (paginationModel.page === 0) {
              newArray = filterData;
            } else if (paginationModel.page > oldPage) {
              newArray = searchTableData.concat(filterData);
            } else {
              newArray = searchTableData;
            }

            setSearchTableData(newArray);
            setTotalLength(data.count);
            setIsDataLoaded(false);

            if (paginationModel.page === 0) {
              setOldPage(-1);
            } else {
              setOldPage(paginationModel.page - 1);
            }
          });
        break;
      case 1:
        fetch(`${baseURL}maintenance/GetMaintenanceReportSearchWithVehicleNamePaging/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
          .then((res) => res.json())
          .then((data) => {

            filterData = data.maintenanceReport.map((item: any) => {
              return {
                ...item,
                lastMaintenaceDate: new Intl.DateTimeFormat("en-US").format(
                  new Date(item.lastMaintenaceDate)
                ),
                nextMaintenaceDate: new Intl.DateTimeFormat("en-US").format(
                  new Date(item.nextMaintenaceDate)
                ),
              };
            });

            let newArray = [];
            if (paginationModel.page === 0) {
              newArray = filterData;
            } else if (paginationModel.page > oldPage) {
              newArray = searchTableData.concat(filterData);
            } else {
              newArray = searchTableData;
            }

            setSearchTableData(newArray);
            setTotalLength(data.count);
            setIsDataLoaded(false);
            if (paginationModel.page === 0) {
              setOldPage(-1);
            } else {
              setOldPage(paginationModel.page - 1);
            }
          });
        break;
      case 2:
        fetch(`${baseURL}maintenance/GetMaintenanceReportSearchWithMaintenanceStatusNamePaging/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
          .then((res) => res.json())
          .then((data) => {

            filterData = data.maintenanceReport.map((item: any) => {
              return {
                ...item,
                lastMaintenaceDate: new Intl.DateTimeFormat("en-US").format(
                  new Date(item.lastMaintenaceDate)
                ),
                nextMaintenaceDate: new Intl.DateTimeFormat("en-US").format(
                  new Date(item.nextMaintenaceDate)
                ),
              };
            });

            let newArray = [];
            if (paginationModel.page === 0) {
              newArray = filterData;
            } else if (paginationModel.page > oldPage) {
              newArray = searchTableData.concat(filterData);
            } else {
              newArray = searchTableData;
            }

            setSearchTableData(newArray);
            setTotalLength(data.count);
            setIsDataLoaded(false);
            if (paginationModel.page === 0) {
              setOldPage(-1);
            } else {
              setOldPage(paginationModel.page - 1);
            }
          });
        break;
      case 3:
        fetch(`${baseURL}maintenance/GetMaintenanceReportSearchWithLocationNamePaging/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
          .then((res) => res.json())
          .then((data) => {

            filterData = data.maintenanceReport.map((item: any) => {
              return {
                ...item,
                lastMaintenaceDate: new Intl.DateTimeFormat("en-US").format(
                  new Date(item.lastMaintenaceDate)
                ),
                nextMaintenaceDate: new Intl.DateTimeFormat("en-US").format(
                  new Date(item.nextMaintenaceDate)
                ),
              };
            });

            let newArray = [];
            if (paginationModel.page === 0) {
              newArray = filterData;
            } else if (paginationModel.page > oldPage) {
              newArray = searchTableData.concat(filterData);
            } else {
              newArray = searchTableData;
            }

            setSearchTableData(newArray);
            setTotalLength(data.count);
            setIsDataLoaded(false);
            if (paginationModel.page === 0) {
              setOldPage(-1);
            } else {
              setOldPage(paginationModel.page - 1);
            }
          });
        break;

    }
  }


  const applyFilter = () => {
    setSelectedFilter(() =>
      maintenanceFilterValue === 0 ? "Maintenance Type Name" : maintenanceFilterValue === 1 ? "Vehicle Name" : maintenanceFilterValue === 2 ? "Maintenance Status Name" : "Location Name"
    );
    setSearchValue("");
    setDisplayFilter(false);
  };

  const clearFilter = () => {
    setSearchValue("");
    setDisplayFilter(false);
  };


  return (
    <>
      <Box sx={{ padding: "24px" }}>
        <Stack
          direction="row"
          spacing={3}
          sx={{ border: "1px solid black", alignItems: "center" }}
        >
          <div style={{ position: "relative", width: "150px" }}>
            <Button
              variant="contained"
              endIcon={<ArrowDropDownIcon sx={{ color: "#bdbdbd" }} />}
              sx={{
                color: "black",
                backgroundColor: "#e0dee0",
                textTransform: "unset",
                fontSize: "12px",
                border: "none",
                borderRadius: "0px",

                "&: hover": {
                  backgroundColor: "white",
                },
              }}
              onClick={() => setDisplayFilter(() => !displayFilter)}
            >
              {selectedFilter}
            </Button>

            <div
              style={{
                display: displayFilter ? "block" : "none",
                width: "170px",
                position: "absolute",
                zIndex: "100",
                top: "-100%",
                left: "100%",
                background: "white",
                border: "1px solid black",
                padding: "8px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="fields"
                    style={{ fontSize: "10px", fontWeight: "bold" }}
                  >
                    Fields
                    <br />
                  </label>

                  <CloseIcon
                    sx={{
                      width: "18px",
                      fontSize: "10px",
                      cursor: "pointer",
                      background: "#e0e0e0",
                    }}
                    onClick={() => setDisplayFilter(false)}
                  />
                </div>

                <select
                  name="fields"
                  style={{
                    width: "100%",
                    fontSize: "10px",
                    paddingBlock: "3px",
                  }}
                  value={maintenanceFilterValue}
                  onChange={(e) =>
                    setMaintenanceFilterValue(parseInt(e.target.value))
                  }
                >
                  <option value="0">Maintenance Type Name</option>
                  <option value="1">Vehicle Name</option>
                  <option value="2">Maintenance Status Name</option>
                  <option value="3">Location Name</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="operator"
                  style={{ fontSize: "10px", fontWeight: "bold" }}
                >
                  Operator
                  <br />
                  <select
                    name="operator"
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      paddingBlock: "3px",
                    }}
                    value={operatorFilter}
                    onChange={(e) => setOpertorFilter(parseInt(e.target.value))}
                  >
                    <option value="0">In a list</option>
                  </select>
                </label>
              </div>


              <div
                style={{
                  display: "flex",

                  marginTop: "7px",
                  justifyContent: "space-between",
                  whiteSpace: "nowrap",
                }}
              >
                <button
                  className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                  onClick={() => applyFilter()}
                >
                  Apply Filter
                </button>

                <button
                  className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                  onClick={() => clearFilter()}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);

              }}
            />
          </Search>
        </Stack>


        <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
          {!isDataLoaded ? searchTableData.length > 0 ? (
            <DataGrid
              rows={searchTableData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
              rowCount={totalLength}
              paginationModel={paginationModel}
              onPaginationModelChange={(model: GridPaginationModel) => {
                if (model.pageSize !== paginationModel.pageSize) {
                  setPaginationModel({
                    page: 0,
                    pageSize: model.pageSize,
                  });
                  return;
                }

                setPaginationModel({
                  page: model.page,
                  pageSize: model.pageSize,
                });
              }}
              disableRowSelectionOnClick
              disableColumnMenu
              checkboxSelection
              showCellVerticalBorder={false}
              sx={{
                border: "none",
                fontSize: "12px",
                wordWrap: "break-word",
                overflow: "visible",
              }}
            />
          ) : (
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <p>No Data Found</p>

            </div>
          ) : (
            <div className="loading">
              <p>Please wait</p>
              <span>
                <i></i>
                <i></i>
              </span>
            </div>
          )}
        </Box>
      </Box>

    </>
  );
}
