import axios from "axios"
import baseURL from "../configs/api"

export const getCountries = () => {
    return getFetch("country")
}

export const getCountry = (primaryKey: string) => {
    return getFetch(`country/${primaryKey}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}