import axios from "axios";
import baseURL from "../configs/api";

export const getEmailChecking = (email: string) => {
    return getFetch(`users/DoesEmailExist/${encodeURIComponent(email)}`);
}

export const getCustomerEmailChecking = (email: string) => {
    return getFetch(`Customer/DoesCustomerEmailExist/${encodeURIComponent(email)}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}