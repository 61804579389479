import React, { useEffect, useState } from "react";
import axios from "axios";
import baseURL from "../../../configs/api";

type Props = {
  reservationAdditionalCharge: any[];
  changesAddditionalChanges: (additionalCharge: any) => void;
};

export default function ChangesAdditionalCharges({
  reservationAdditionalCharge,
  changesAddditionalChanges,
}: Props) {
  const [additionalCharges, setAdditionalCharge] = useState<any[]>([]);
  const [reservationAdditionalCharges, setReservationAdditionalCharges] =
    useState<any[]>([]);
  const [
    changeReservationAdditionalCharge,
    setChangeReservationAdditionalCharge,
  ] = useState<any>({});
  const [isChange, setIsChange] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`${baseURL}additionalCharge`)
      .then((response) => {
        setAdditionalCharge(() => response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (reservationAdditionalCharge && !isChange) {
      setReservationAdditionalCharges(() => reservationAdditionalCharge);
    }
  }, [reservationAdditionalCharge]);

  useEffect(() => {
    if (isChange) {
      changesAddditionalChanges(changeReservationAdditionalCharge);
      setIsChange(false);
    }
  }, [isChange]);

  return (
    <div
      style={{
        width: "100%",
        padding: "24px",
      }}
    >
      <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
        Additional Charges
      </h5>
      <hr />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "20px",
          gap: "8px",
        }}
      >
        <div
          style={{
            width: "10%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          #
        </div>
        <div
          style={{
            width: "30%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Additional Charge
        </div>
        <div
          style={{
            width: "30%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Quantity
        </div>
        <div
          style={{
            width: "30%",
            color: "gray",
            textAlign: "start",
            fontSize: "12px",
          }}
        >
          Base Price
        </div>
      </div>
      <hr style={{ marginBottom: "0px" }} />

      {reservationAdditionalCharges.map((item: any, index: number) => {
        return (
          <React.Fragment key={item.id}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
              }}
            >
              <div
                style={{
                  width: "10%",
                  color: "gray",
                  textAlign: "start",
                  fontSize: "12px",
                  marginBlock: "4px",
                }}
              >
                {item.id}
              </div>

              <div
                style={{
                  width: "30%",
                  color: "gray",
                  textAlign: "start",
                  fontSize: "12px",
                  marginBlock: "4px",
                }}
              >
                {
                  additionalCharges.find(
                    (charge: any) =>
                      charge.id === item.additionalChargeByBranchId
                  )?.name
                }
              </div>

              <hr style={{ marginBlock: "0px" }} />

              <div
                style={{
                  width: "30%",
                  color: "gray",
                  textAlign: "start",
                  fontSize: "12px",
                  marginBlock: "4px",
                }}
              >
                <input
                  style={{ width: "100%" }}
                  type="text"
                  value={item.quantity}
                  onChange={(e) => {
                    setIsChange(true);
                    setReservationAdditionalCharges(
                      (prevReservationAdditionalCharge) => {
                        const result = [...prevReservationAdditionalCharge];
                        result[index] = {
                          ...result[index],
                          quantity: e.target.value,
                        };
                        return result;
                      }
                    );

                    setChangeReservationAdditionalCharge(() => {
                      return {
                        ...item,
                        quantity: e.target.value,
                      };
                    });
                  }}
                />
              </div>

              <hr style={{ marginBlock: "0px" }} />

              <div
                style={{
                  width: "30%",
                  color: "gray",
                  textAlign: "start",
                  fontSize: "12px",
                  marginBlock: "4px",
                }}
              >
                <input
                  style={{ width: "100%" }}
                  type="text"
                  value={item.basePrice}
                  onChange={(e) => {
                    setIsChange(true);
                    setReservationAdditionalCharges(
                      (prevReservationAdditionalCharge) => {
                        const result = [...prevReservationAdditionalCharge];
                        result[index] = {
                          ...result[index],
                          basePrice: e.target.value,
                        };
                        return result;
                      }
                    );

                    setChangeReservationAdditionalCharge(() => {
                      return {
                        ...item,
                        basePrice: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </div>

            <hr style={{ marginBlock: "0px" }} />
          </React.Fragment>
        );
      })}
    </div>
  );
}
