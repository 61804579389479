import { Outlet } from "react-router-dom";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import { useState } from "react";

const MainLayout = () => {
  const[fullScreen, setFullScreen] = useState<boolean>(true);

  const handleScreen = () => {
    setFullScreen(!fullScreen);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar />

      <Box
        component="nav"
      >
        <Sidebar handleScreen={handleScreen}/>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${sizeConfigs.sidebar.width})`,
          minHeight: "100vh",
          backgroundColor: colorConfigs.mainBg,
          paddingLeft: fullScreen ? "0px" : "10px",
          paddingRight: "0px",
          marginLeft: fullScreen ? "0" :"40px"
        }}
      >
        <Toolbar style={{ marginTop: "-16px" }} />

        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
