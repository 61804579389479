import axios from "axios"
import baseURL from "../configs/api"

export const getCustomers = () => {
    return getFetch("Customer");
}

export const getCustomer = (primaryKey: string) => {
    return getFetch(`Customer/${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}