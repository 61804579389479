import axios from "axios"
import baseURL from "../configs/api"

export const getLoctionOpenHours = () => {
    return getFetch("locationOpenHour")
}

export const getLoctionOpenHour = (primaryKey: string) => {
    return getFetch(`locationOpenHour/${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}