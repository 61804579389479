import { Box, 
    Button, 
    Toolbar, 
    Typography, 
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import colorConfigs from "../../../../configs/colorConfigs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../../configs/api";


export const AddVehicleModel = () => {  
  const [label, setLabel] = useState<string>(""); 
  const [vehicleMakeId, setVehicleMakeId] = useState<string>("");   
  
  const [vehicleMakeOptions, setVehicleMakeOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}vehicleMake`)
      .then((response) => {
        setVehicleMakeOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.makeName };
          })
        );

        setVehicleMakeId(response.data[0].id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);   


  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}vehicleModel`,
        JSON.stringify({
          modelName: label,
          vehicleMakeId
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <LocalOfferIcon
            sx={{ fontSize: "24px", marginRight: "5px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Vehicle Model / Add Vehicle Model
          </Typography>
        </Box>
      </Toolbar>
      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingTop: "10px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px",fontSize: "18px" }}>
           Vehicle Model Overview
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                <label
                    htmlFor="label"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Label
                    <br />
                    <input
                      type="text"
                      name="label"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
                


                <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="vehicleMake"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Vehicle Make
                      <br />
                      <Select
                styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                className="basic-single"
                classNamePrefix="select"
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={false}
                name="vehicleMake"
                value={vehicleMakeOptions.find(
                  (item: any) => item.value === parseInt(vehicleMakeId)
                )}
                onChange={(vehicleMake: any) =>
                  setVehicleMakeId(vehicleMake ? vehicleMake.value : "")
                }
                options={vehicleMakeOptions}
                required
              />
                    </label>
                  </div>
                  </div>
                  </div>
                
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
      
    </>
  );
};