import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import baseURL from "../../../../../configs/api";
import colorConfigs from "../../../../../configs/colorConfigs";

type Props = {
    popup: boolean;
    hidePopup: () => void;
    isSaved: () => void;
};

export default function AddDamageTypes({
    popup,
    hidePopup,
    isSaved,
}: Props) {
    const [damageName, setDamageName] = useState<string>("");
    const [isActive, setIsActive] = useState<boolean>(true);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios
            .post(
                `${baseURL}damageType`,
                JSON.stringify({
                    damageName,
                    isActive,
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then(() => {
                isSaved();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <Dialog
            open={popup}
            maxWidth="md"
            PaperProps={{
                style: {
                    width: "40%",
                    marginTop: "-150px",
                },
            }}
        >
            <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                <div
                    style={{
                        height: "50px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px 16px 8px 16px",
                    }}
                >
                    <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                        Add Damage Type
                    </p>
                    <CloseIcon
                        sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
                        onClick={() => hidePopup()}
                    />
                </div>
                <hr style={{ marginBlock: "0px" }} />
            </DialogTitle>

            <DialogContent sx={{ padding: "0px" }}>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <form onSubmit={(e) => handleSubmit(e)} style={{}}>
                        <div style={{ padding: "2px 2px 0px 2px" }}>

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "0px 16px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        marginRight: "3%",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            padding: "16px 16px 0px 0px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "70px",
                                                borderStyle: "solid solid none solid",
                                                borderWidth: "1px",
                                                borderColor: "gray",
                                                padding: "5px 5px 0px 5px",
                                                fontSize: "12px",
                                                justifyContent: "center",
                                            }}
                                        >
                                            English
                                        </div>

                                        <div
                                            style={{
                                                width: "100%",
                                                borderStyle: "none none solid none",
                                                borderWidth: "1px",
                                                borderColor: "gray",
                                            }}
                                        />
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label
                                            htmlFor="label"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Name
                                            <br />
                                            <input
                                                type="text"
                                                name="damageName"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                value={damageName}
                                                onChange={(e) => setDamageName(e.target.value)}
                                                required
                                            />
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                            Is Active?
                                        </label>{" "}
                                        <br />
                                        <label style={{ fontSize: "12px" }}>
                                            <input
                                                type="radio"
                                                value="Yes"
                                                name="isActive"
                                                style={{ fontSize: "12px" }}
                                                checked={isActive ? true : false}
                                                onChange={(e) =>
                                                    setIsActive(e.target.value === "Yes" ? true : false)
                                                }
                                            />
                                            Yes
                                        </label>
                                        <label style={{ fontSize: "12px" }}>
                                            <input
                                                type="radio"
                                                value="No"
                                                name="isActive"
                                                style={{ fontSize: "12px" }}
                                                checked={isActive ? false : true}
                                                onChange={(e) =>
                                                    setIsActive(e.target.value === "Yes" ? true : false)
                                                }
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    marginBottom: "20px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "50%",
                                        marginRight: "3%",
                                        paddingLeft: "16px",
                                    }}
                                >
                                </div>

                                <div
                                    style={{
                                        width: "50%",
                                    }}
                                >
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "50px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                paddingBlock: "8px",
                                paddingLeft: "16px",
                                paddingRight: "16px",
                                backgroundColor: "#eeeeee",
                            }}
                        >
                            <div style={{ width: "50%" }} />

                            <div
                                style={{
                                    width: "50%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                        borderRadius: "0",
                                        border: "none",
                                        textTransform: "capitalize",
                                        fontSize: "12px",
                                        "&: hover": {
                                            backgroundColor: "#e0dee0",
                                            color: colorConfigs.sidebar.color,
                                        },
                                    }}
                                    onClick={() => hidePopup()}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "white",
                                        borderRadius: "0",
                                        border: "none",
                                        textTransform: "capitalize",
                                        fontSize: "12px",
                                        "&: hover": {
                                            backgroundColor: colorConfigs.topbar.bg,
                                            color: "white",
                                        },
                                    }}
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
}
