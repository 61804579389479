import React, { useRef } from 'react'

type DateInputProps = {
    name: string;
    value: string;
    setValue: (value: string) => void;
    paddingBlock?: string;
    required?: boolean;
}

const TimeInput = ({ name, value, setValue, paddingBlock = "3px", required = false }: DateInputProps) => {
    const timeRef = useRef<HTMLInputElement>(null);


    return (
        <input
            style={{
                width: "100%",
                fontSize: "12px",
                paddingBlock: paddingBlock,
            }}
            type="time"
            name={name}
            ref={timeRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onClick={() => timeRef.current?.showPicker()}
            required={required}
        />
    )
}

export default TimeInput
