  import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Select from "react-select";
  import MenuIcon from "@mui/icons-material/Menu";
  import CreditCardIcon from '@mui/icons-material/CreditCard';
  import colorConfigs from "../../../../configs/colorConfigs";
  import { useLocation, useNavigate } from "react-router-dom";
  import axios from "axios";
  import baseURL from "../../../../configs/api";
  import axois from "../../../../configs/axois";
  
  const pages = [
    "Edit"
  ];
  
  export const EditRates = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
      null
    );
  
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
    const [seasonId, setSeasonId] = useState<string>("");  
    const [vehicleClassId, setVehicleClassId] = useState<string>("");
    const [hourlyRate, setHourlyRate] = useState<string>("");
    const [weeklyRate, setWeeklyRate] = useState<string>("");
    const [dailyRate, setDailyRate] = useState<string>("");
    const [monthilyRate, setMonthilyRate] = useState<string>("");
    const [decreasingRateBasedOnIntervalPerHour, setDecreasingRateBasedOnIntervalPerHour] = 
    useState(false);
    const [decreasingRateBasedOnIntervalPerDay, setDecreasingRateBasedOnIntervalPerDay] = 
    useState(false);
  
    const [firstTime, setFirstTime] = React.useState<String>(pages[0]);
  
    const [seasonOptions, setSeasonOptions] = useState([]);
    useEffect(() => {
      axios
        .get(`${baseURL}season`)
        .then((response) => {
          setSeasonOptions(() =>
            response.data.map((item: any) => {
              return { value: item.id, label: item.name};
            })
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, []);   
  
    
    const [vehicleClassOptions, setVehicleClassOptions] = useState([]);
    useEffect(() => {
      axios
        .get(`${baseURL}vehicleClass`)
        .then((response) => {
          setVehicleClassOptions(() =>
            response.data.map((item: any) => {
              return { value: item.id, label: item.name };
            })
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, []);   
  
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const idNumber = useLocation();
    useEffect(() => {
      axois
        .get(`${baseURL}rate/${idNumber.state}`)
        .then((response) => {
          setSeasonId(response.data.seasonId);
          setVehicleClassId(response.data.vehicleClassId);
          setHourlyRate(response.data.hourlyRate);
          setWeeklyRate(response.data.weeklyRate);
          setDailyRate(response.data.dailyRate);
          setMonthilyRate(response.data.monthilyRate);
          setDecreasingRateBasedOnIntervalPerHour(response.data.decreasingRateBasedOnIntervalPerHour);
          setDecreasingRateBasedOnIntervalPerDay(response.data.decreasingRateBasedOnIntervalPerDay);
        })
        .catch((error) => {
          console.error(error);
        });
    }, []);     
  
    const handleOnClick = (page: string) => {
      setAnchorElNav(null);
      setFirstTime(page);
    };
  
    const navigate = useNavigate();
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      await axios
        .put(
          `${baseURL}rate`,
          JSON.stringify({
            id: idNumber.state,
            seasonId,
            vehicleClassId,
            hourlyRate,
            weeklyRate,
            monthilyRate,
            dailyRate,
            decreasingRateBasedOnIntervalPerHour,
            decreasingRateBasedOnIntervalPerDay
          }),  
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };       
  
    const deleteTheItem = async () => {
      if (
        window.confirm("Are you sure you want to delete this Rate?") === true
      ) {
        await axios
          .delete(`${baseURL}rate?id=${idNumber.state}`)
          .then(() => {
            navigate(-1);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    };   
  
    return (
      <>
        <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <CreditCardIcon
              sx={{ fontSize: "24px", marginRight: "5px" }}
            />
            <Typography variant="body1" sx={{ fontSize: "20px" }}>
              Rates / Edit Rates
            </Typography>
          </Box>
          <div>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                borderRadius: "0",
                textTransform: "capitalize",
              }}
              onClick={() => deleteTheItem()}
            >
              Delete
            </Button>
          </div>
        </Toolbar>
  
        <AppBar
          position="static"
          sx={{
            backgroundColor: colorConfigs.sidebar.bg,
            border: "none",
            paddingLeft: "18px",
            paddingRight: "18px",
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: "black", paddingBlock: "4px" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
  
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                paddingTop: "8px",
                paddingLeft: "0px",
                marginLeft: "-20px",
              }}
            >
              {pages.map((page) => {
                if (firstTime === page) {
                  return (
                    <Button
                      disableElevation={false}
                      key={page}
                      onClick={() => handleOnClick(page)}
                      variant="text"
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        paddingBlock: "0px",
                        marginBlock: "0px",
                        borderRadius: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "none",
                        textAlign: "center",
  
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                        "&: hover": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      disableElevation={false}
                      key={page}
                      onClick={() => handleOnClick(page)}
                      variant="text"
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        paddingBlock: "0px",
                        marginBlock: "0px",
                        borderRadius: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "none",
                        textAlign: "center",
                        border: "none",
                        "&: focus": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                        "&: active": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  );
                }
              })}
            </Box>
          </Container>
        </AppBar>
  
        <div
          style={{
            width: "100%",
            display: firstTime === pages[0] ? "flex" : "none",
            flexDirection: "row",
            padding: "24px",
          }}
        >
          <div
            style={{
              width: "100%",
              marginRight: "3%",
            }}
          >
            <h3 style={{ paddingBottom: "0px", marginBottom: "0px",fontSize: "18px" }}>
           General Settings
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="season"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Season
                      <br />
                      <Select
                        styles={{
                          menu: (base) => ({ ...base, fontSize: "12px" }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={false}
                        name="season"
                        value={seasonOptions.find(
                          (item: any) => item.value === parseInt(seasonId)
                        )}
                        onChange={(season: any) =>
                          setSeasonId(
                            season ? season.value : ""
                          )
                        }
                        options={seasonOptions}
                        required
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="vehicleClass"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Vehicle Class
                      <br />
                      <Select
                        styles={{
                          menu: (base) => ({ ...base, fontSize: "12px" }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={false}
                        name="vehicleClass"
                        value={vehicleClassOptions.find(
                          (item: any) => item.value === parseInt(vehicleClassId)
                        )}
                        onChange={(vehicleClass: any) =>
                          setVehicleClassId(
                            vehicleClass ? vehicleClass.value : ""
                          )
                        }
                        options={vehicleClassOptions}
                        required
                      />
                    </label>
                  </div>
                  </div>
                </div>
                     <div
                        style={{
                        width: "100%",
                        marginRight: "3%",
                        }}
                      >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px",fontSize: "18px" }}>
           Rates
          </h3>
          <hr />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >

               <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Decreasing Rate Based on Interval (Per Hour)
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="enabledHour"
                        style={{ fontSize: "12px" }}
                        checked={decreasingRateBasedOnIntervalPerHour}
                        onChange={(e) =>
                          setDecreasingRateBasedOnIntervalPerHour(e.target.value === 'Yes')
                        }
                      />
                      Yes
                    </label>                    
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="enabledHour"
                        style={{ fontSize: "12px" }}
                        checked={!decreasingRateBasedOnIntervalPerHour}
                        onChange={(e) =>
                          setDecreasingRateBasedOnIntervalPerHour(e.target.value === 'Yes')
                        }
                      />
                      No
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="hourlyRate"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Hourly Rate ($)
                    <br />
                    <input
                      type="text"
                      name="hourlyRate"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={hourlyRate}
                      onChange={(e) => setHourlyRate(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="weeklyRate"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Weekly Rate ($)
                    <br />
                    <input
                      type="text"
                      name="weeklyRate"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={weeklyRate}
                      onChange={(e) => setWeeklyRate(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
              

              <div
                style={{
                  width: "50%",
                }}
              >
                 <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Decreasing Rate Based on Interval (Per Day)
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="enabledDay"
                        style={{ fontSize: "12px" }}
                        checked={decreasingRateBasedOnIntervalPerDay}
                        onChange={(e) =>
                          setDecreasingRateBasedOnIntervalPerDay(e.target.value === 'Yes')
                        }
                      />
                      Yes
                    </label>
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="enabledDay"
                        style={{ fontSize: "12px" }}
                        checked={!decreasingRateBasedOnIntervalPerDay}
                        onChange={(e) =>
                          setDecreasingRateBasedOnIntervalPerDay(e.target.value === 'Yes')
                        }
                      />
                      No
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="dailyRate"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Daily Rate ($)
                    <br />
                    <input
                      type="text"
                      name="dailyRate"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={dailyRate}
                      onChange={(e) => setDailyRate(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="monthlyRate"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Monthly Rate ($)
                    <br />
                    <input
                      type="text"
                      name="monthlyRate"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={monthilyRate}
                      onChange={(e) => setMonthilyRate(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
            </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
    );
  };    
  