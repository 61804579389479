import React, { useEffect, useRef, useState } from 'react'
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
import axios from 'axios';
import baseURL from '../../../configs/api';
import Select from "react-select";
import { getCountries } from '../../../services/country';

type Props = {
  companyName: string;
  setCompanyName: (value: string) => void;
  country: string;
  setCountry: (value: string) => void;
  smsSender: string;
  setSmsSender: (value: string) => void;
  timezone: string;
  setTimezone: (value: string) => void;
  language: string;
  setLanguage: (value: string) => void;
  supportedLanguagesOptions: any[];
  SupportedLanguages: any;
  setSupportedLanguages: (value: any) => void;
  supportedLanguagesArray: any[]
  setSupportedLanguagesArray: (value: any[]) => void;
  preferredDateFormat: string;
  setPreferredDateFormat: (value: string) => void;
  metricSystem: string;
  setMetricSystem: (value: string) => void;
  pleaseWaitPopup: boolean;
  setPleaseWaitPopup: (value: boolean) => void;
  filePath: string;
  setFilePath: (value: string) => void;
}

const preferredDateFormatOptions: any[] = [
  { value: 0, label: "Use System Default" },
  { value: 1, label: "2023-06-16 7:37 ()" },
  { value: 2, label: "2023-06-16 7:37 (iso)" },
  { value: 3, label: "16-06-2023 7:37 (eu)" },
  { value: 4, label: "06/16/2023 7:37 AM (us)" },
  { value: 5, label: "16/06/2023 7:37 AM (ve)" },
  { value: 6, label: "16.06.2023 7:37 AM (int)" },
  { value: 7, label: ">16-Jun-2023 7:37 AM (id)" },
  { value: 8, label: "16.06.2023 7:37 (ch)" }
]

const merticsSystemOptions: any[] = [
  { value: "Metric 2.480,12", label: "Metric 2.480,12" },
  { value: "Imperial 2,480.12", label: "Imperial 2,480.12" },
]

const timezoneOptions: any[] = [
  { value: 0, label: "America/Adak" },
  { value: 1, label: "America/Anchorage" },
  { value: 2, label: "America/Araguaina" },
  { value: 3, label: "America/Argentina/Buenos_Aires" },
  { value: 4, label: "America/Argentina/Catamarca" },
  { value: 5, label: "America/Argentina/Cordoba" },
  { value: 6, label: "America/Argentina/Jujuy" },
  { value: 7, label: "America/Argentina/La_Rioja" },
  { value: 8, label: "America/Argentina/Mendoza" },
  { value: 9, label: "America/Argentina/Rio_Gallegos" },
  { value: 10, label: "America/Argentina/Salta" },
  { value: 11, label: "America/Argentina/San_Juan" },
  { value: 12, label: "America/Argentina/San_Luis" },
  { value: 13, label: "America/Argentina/Tucuman" },
  { value: 14, label: "America/Argentina/Ushuaia" },
  { value: 15, label: "America/Asuncion" },
  { value: 16, label: "America/Bahia" },
  { value: 17, label: "America/Bahia_Banderas" },
  { value: 18, label: "America/Barbados" },
  { value: 19, label: "America/Belem" },
  { value: 20, label: "America/Belize" },
  { value: 21, label: "America/Boa_Vista" },
  { value: 22, label: "America/Bogota" },
  { value: 23, label: "America/Boise" },
  { value: 24, label: "America/Cambridge_Bay" },
  { value: 25, label: "America/Campo_Grande" },
  { value: 26, label: "America/Cancun" },
  { value: 27, label: "America/Caracas" },
  { value: 28, label: "America/Cayenne" },
  { value: 29, label: "America/Chicago" },
  { value: 30, label: "America/Chihuahua" },
  { value: 31, label: "America/Ciudad_Juarez" },
  { value: 32, label: "America/Costa_Rica" },
  { value: 33, label: "America/Cuiaba" },
  { value: 34, label: "America/Danmarkshavn" },
  { value: 35, label: "America/Dawson" },
  { value: 36, label: "America/Dawson_Creek" },
  { value: 37, label: "America/Denver" },
  { value: 38, label: "America/Detroit" },
  { value: 39, label: "America/Edmonton" },
  { value: 40, label: "America/Eirunepe" },
  { value: 41, label: "America/El_Salvador" },
  { value: 42, label: "America/Fort_Nelson" },
  { value: 43, label: "America/Fortaleza" },
  { value: 44, label: "America/Glace_Bay" },
  { value: 45, label: "America/Goose_Bay" },
  { value: 46, label: "America/Grand_Turk" },
  { value: 47, label: "America/Guatemala" },
  { value: 48, label: "America/Guayaquil" },
  { value: 49, label: "America/Guyana" },
  { value: 50, label: "America/Halifax" },
  { value: 51, label: "America/Havana" },
  { value: 52, label: "America/Hermosillo" },
  { value: 53, label: "America/Indiana/Indianapolis" },
  { value: 54, label: "America/Indiana/Knox" },
  { value: 55, label: "America/Indiana/Marengo" },
  { value: 56, label: "America/Indiana/Petersburg" },
  { value: 57, label: "America/Indiana/Tell_City" },
  { value: 58, label: "America/Indiana/Vevay" },
  { value: 59, label: "America/Indiana/Vincennes" },
  { value: 60, label: "America/Indiana/Winamac" },
  { value: 61, label: "America/Inuvik" },
  { value: 62, label: "America/Iqaluit" },
  { value: 63, label: "America/Jamaica" },
  { value: 64, label: "America/Juneau" },
  { value: 65, label: "America/Kentucky/Louisville" },
  { value: 66, label: "America/Kentucky/Monticello" },
  { value: 67, label: "America/La_Paz" },
  { value: 68, label: "America/Lima" },
  { value: 69, label: "America/Los_Angeles" },
  { value: 70, label: "America/Maceio" },
  { value: 71, label: "America/Managua" },
  { value: 72, label: "America/Manaus" },
  { value: 73, label: "America/Martinique" },
  { value: 74, label: "America/Matamoros" },
  { value: 75, label: "America/Mazatlan" },
  { value: 76, label: "America/Menominee" },
  { value: 77, label: "America/Merida" },
  { value: 78, label: "America/Metlakatla" },
  { value: 79, label: "America/Mexico_City" },
  { value: 80, label: "America/Miquelon" },
  { value: 81, label: "America/Moncton" },
  { value: 82, label: "America/Monterrey" },
  { value: 83, label: "America/Montevideo" },
  { value: 84, label: "America/New_York" },
  { value: 85, label: "America/Nome" },
  { value: 86, label: "America/Noronha" },
  { value: 87, label: "America/North_Dakota/Beulah" },
  { value: 88, label: "America/North_Dakota/Center" },
  { value: 89, label: "America/North_Dakota/New_Salem" },
  { value: 90, label: "America/Nuuk" },
  { value: 91, label: "America/Ojinaga" },
  { value: 92, label: "America/Panama" },
  { value: 93, label: "America/Paramaribo" },
  { value: 94, label: "America/Phoenix" },
  { value: 95, label: "America/Port-au-Prince" },
  { value: 96, label: "America/Porto_Velho" },
  { value: 97, label: "America/Puerto_Rico" },
  { value: 98, label: "America/Punta_Arenas" },
  { value: 99, label: "America/Rankin_Inlet" },
  { value: 100, label: "America/Recife" },
  { value: 101, label: "America/Regina" },
  { value: 102, label: "America/Resolute" },
  { value: 103, label: "America/Rio_Branco" },
  { value: 104, label: "America/Santarem" },
  { value: 105, label: "America/Santiago" },
  { value: 106, label: "America/Santo_Domingo" },
  { value: 107, label: "America/Sao_Paulo" },
  { value: 108, label: "America/Scoresbysund" },
  { value: 109, label: "America/Sitka" },
  { value: 110, label: "America/St_Johns" },
  { value: 111, label: "America/Swift_Current" },
  { value: 112, label: "America/Tegucigalpa" },
  { value: 113, label: "America/Thule" },
  { value: 114, label: "America/Tijuana" },
  { value: 115, label: "America/Toronto" },
  { value: 116, label: "America/Vancouver" },
  { value: 117, label: "America/Whitehorse" },
  { value: 118, label: "America/Winnipeg" },
  { value: 119, label: "America/Yakutat" }
]

const GeneralSettings = ({
  companyName,
  setCompanyName,
  country,
  setCountry,
  smsSender,
  setSmsSender,
  timezone,
  setTimezone,
  language,
  setLanguage,
  supportedLanguagesOptions,
  SupportedLanguages,
  setSupportedLanguages,
  supportedLanguagesArray,
  setSupportedLanguagesArray,
  preferredDateFormat,
  setPreferredDateFormat,
  metricSystem,
  setMetricSystem,
  pleaseWaitPopup,
  setPleaseWaitPopup,
  filePath,
  setFilePath,
}: Props) => {
  const [isGeneralSettings, setIsGeneralSetttings] = useState<boolean>(true);
  const [countryOptions, setCountryOptions] = useState<any[]>([]);

  useEffect(() => {
    getCountries()
      .then(data => {
        setCountryOptions(
          data.map((item: any) => {
            return { value: item.id, label: item.countryName }
          })
        );
      })
      .catch(error => console.error("Error:", error));
  }, [])

  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPleaseWaitPopup(true);
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}commonFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((response) => {
          setFilePath(response.data);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    } else {
      setPleaseWaitPopup(false);
    }
  };

  return (
    <>
      <h5
        style={{
          marginBlock: "0px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          cursor: "pointer",
          WebkitUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
        }}
        onClick={() => setIsGeneralSetttings(!isGeneralSettings)}
      >
        {isGeneralSettings ? (
          <ExpandMore sx={{ fontSize: "20px" }} />
        ) : (
          <KeyboardArrowRight sx={{ fontSize: "20px" }} />
        )}{" "}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: "50%" }}>General Settings</div>

          <div
            style={{
              width: "50%",
              fontSize: "10px",
              fontWeight: "normal",
              textAlign: "end",
              color: "gray",
            }}
          >
            General Settings for this Tenant
          </div>
        </div>
      </h5>
      <hr />

      {isGeneralSettings &&
        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        >

          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            marginTop: "10px"
          }}>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="companyName"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Company Name
                <br />
                <input
                  type="text"
                  name="companyName"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </label>
            </div>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="country"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Country
                <br />
                <Select
                  styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={false}
                  name="country"
                  value={countryOptions.find(
                    (item: any) => item.value === parseInt(country)
                  )}
                  onChange={(country: any) =>
                    setCountry(country ? country.value : "")
                  }
                  options={countryOptions}
                />
              </label>
            </div>

          </div>

          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            marginTop: "10px"
          }}>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="smsSender"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                SMS Sender
                <br />
                <input
                  type="text"
                  name="smsSender"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={smsSender}
                  onChange={(e) => setSmsSender(e.target.value)}
                />
              </label>
            </div>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="timezone"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Timezone
                <br />
                <Select
                  styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  name="timezone"
                  value={timezoneOptions.find(
                    (item: any) => item.value === parseInt(timezone)
                  )}
                  onChange={(timezone: any) =>
                    setTimezone(timezone ? timezone.value : "")
                  }
                  options={timezoneOptions}
                />
              </label>
            </div>

          </div>

          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            marginTop: "10px"
          }}>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="language"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Language
                <br />
                <select
                  name="language"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value={"English"}>English</option>
                </select>
              </label>
            </div>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="SupportedLanguages"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Supported Languages
                <br />
                <Select
                  isMulti
                  styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={false}
                  name="SupportedLanguages"
                  value={SupportedLanguages}
                  onChange={(value) => setSupportedLanguages(value)}
                  options={supportedLanguagesOptions}
                />
              </label>
            </div>

          </div>

          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            marginTop: "10px"
          }}>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="preferredDateFormat"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Preferred Date Format
                <br />
                <Select
                  styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={false}
                  name="preferredDateFormat"
                  value={preferredDateFormatOptions.find(
                    (item: any) => item.value === parseInt(preferredDateFormat)
                  )}
                  onChange={(preferredDateFormat: any) =>
                    setPreferredDateFormat(preferredDateFormat ? preferredDateFormat.value : "")
                  }
                  options={preferredDateFormatOptions}
                />
              </label>
            </div>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="metricSystem"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Metric System
                <br />
                <Select
                  styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={false}
                  name="metricSystem"
                  value={merticsSystemOptions.find(
                    (item: any) => item.value === metricSystem
                  )}
                  onChange={(metricSystem: any) =>
                    setMetricSystem(metricSystem ? metricSystem.value : "")
                  }
                  options={merticsSystemOptions}
                />
              </label>
            </div>

          </div>

          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginTop: "10px"
          }}>

            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "8px"
            }}>
              <label
                htmlFor="tenantLogo"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Tenant Logo

              </label>

              {!filePath ?
                <label
                  style={{
                    color: "blue",
                    fontSize: "12px",
                    cursor: "pointer"
                  }}
                  onClick={(e) => {
                    if (inputFileRef.current !== null) {
                      inputFileRef.current.click();
                    }
                  }}
                >
                  Add Logo

                </label>
                :
                <label
                  style={{
                    color: "blue",
                    fontSize: "12px",
                    cursor: "pointer"
                  }}
                  onClick={(e) => setFilePath("")}
                >
                  Delete
                </label>
              }

            </div>

            <div
              style={{
                maxWidth: "50%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {filePath &&
                <img
                  src={`${baseURL}commonFile/Get?name=${filePath}`}
                  width={200}
                  height={125}
                  alt="tenantLogo"
                />
              }
            </div>
            <input
              type="file"
              accept="image/*"
              name="tenantLogo"
              ref={inputFileRef}
              onChangeCapture={onFileChangeCapture}
              style={{ display: "none" }}
            />

          </div>

        </div>
      }
    </>
  )
}

export default GeneralSettings