import {
  Toolbar,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import baseURL from "../../../../configs/api";
import colorConfigs from "../../../../configs/colorConfigs";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Webcam from "react-webcam";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axois from "../../../../configs/axois";

const pages = ["Edit"];

export default function EditEmailTemplate() {
  const id = useLocation().state;
  const [firstTime, setFirstTime] = useState<String>(pages[0]);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const [label, setLabel] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const [isFilters, setIsFilters] = useState<boolean>(true);
  const [pickupLocations, setPickupLocations] = useState<string>("");
  const [pickupLocationsOptions, setPickupLocationsOptions] = useState([]);
  const [
    pickupLocationsOptionsOfParticular,
    setPickupLocationsOptionsOfParticular,
  ] = useState([]);
  const [returnLocations, setReturnLocations] = useState<string>("");
  const [returnLocationsOptions, setReturnLocationsOptions] = useState([]);
  const [
    returnLocationsOptionsOfParticular,
    setReturnLocationsOptionsOfParticular,
  ] = useState([]);
  const [branchId, setBranchId] = useState<string>("");
  const [vehicleClassId, setVehicleClassId] = useState<string>("");
  const [sendAfterXDays, setSendAfterXDays] = useState<string>("");
  const [repeatEveryXDays, setRepeatEveryXDays] = useState<string>("");
  const [attachments, setAttachments] = useState<string>("");
  const [itemTypeId, setItemTypeId] = useState<string>("");
  const [isAdvanced, setIsAdvanced] = useState<boolean>(true);
  const [isContent, setIsContent] = useState<boolean>(true);
  const [hourToSend, setHourToSend] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [cc, setCc] = useState<string>("");
  const [replyTo, setReplyTo] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [sendAsSms, setSendAsSms] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");
  const [filePath, setFilePath] = useState<any[]>([]);
  const [newFilePath, setNewFilePath] = useState<string[]>([])
  const [imagePopup, setImagePopup] = useState<boolean>(false);
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

  const [isImageSaved, setIsImageSaved] = useState<boolean>(false);

  const webRef = useRef(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPleaseWaitPopup(true);
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}commonFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((response) => {
            const newFilePath: any = {
                id: 0,
                fileName:
                  `${baseURL}commonFile/Get?name=` +
                  response.data,
              };
              setNewFilePath((prevPath: string[]) => [
                ...prevPath,
                newFilePath.fileName,
              ]);
              setFilePath((prevPath: any[]) => [...prevPath, newFilePath]);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setPleaseWaitPopup(false);
    }
    
  };

  const [itemTypeOptions, setItemTypeOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}emailTemplateType`)
      .then((response) => {
        setItemTypeOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );

        setItemTypeId(response.data[0].id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [branches, setBranches] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then((response) => {
        setBranches(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.branchName };
          })
        );

        setBranchId(response.data[0].id);
        setPickupLocations(response.data[0].defaultLocationId);
        setReturnLocations(response.data[0].defaultLocationId);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [vehicleClassOptions, setVehicleClassOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}vehicleClass`)
      .then((response) => {
        setVehicleClassOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );

        setVehicleClassId(response.data[0].id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setPleaseWaitPopup(true);

    if (newFilePath.length !== 0) {
      newFilePath.forEach((item: string, index: number) => {
        axios
          .post(
            `${baseURL}fileUploadData`,
            JSON.stringify({
              attributeName: "EmailTemplate",
              attributeId: id,
              fileName: item.replace(
                `${baseURL}commonFile/Get?name=`,
                ""
              ),
              tabName: "EmailTemplate",
              userId: 1,
              createdDate: new Date(),
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then((response) => {
            if (newFilePath.length - 1 === index) {
              setIsImageSaved(true);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
    } else {
      setIsImageSaved(true);
    }
  };

  useEffect(() => {
    if (isImageSaved) {
         axios
        .put(
          `${baseURL}emailTemplate`,
          JSON.stringify({
            id,
            branchId,
            name: label,
            emailTemplateTypeId: itemTypeId,
            sendAsSMS: sendAsSms,
            group,
            vehicleClassId,
            pickupLocationId: pickupLocations,
            returnLocationId: returnLocations,
            sendAfterXDays,
            hourToSend,
            attachments,
            startDate,
            repeatEvery: repeatEveryXDays,
            isActive: active,
            emailCC: cc,
            emailReplyTo: replyTo,
            emailSubject: subject,
            emailContent: content,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
         // console.log(response);
         navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      setIsImageSaved(false);
    }
  }, [isImageSaved]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);

  useEffect(() => {
    axios
      .get(`${baseURL}location`)
      .then((response) => {
        setPickupLocationsOptions(() =>
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.locationName,
              branchId: item.branchId,
            };
          })
        );

        setPickupLocationsOptionsOfParticular(() =>
          response.data
            .map((item: any) => {
              return {
                value: item.id,
                label: item.locationName,
                branchId: item.branchId,
              };
            })
            .filter((item: any) => item.branchId == branchId)
        );

        setReturnLocationsOptions(() =>
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.locationName,
              branchId: item.branchId,
            };
          })
        );

        setReturnLocationsOptionsOfParticular(() =>
          response.data
            .map((item: any) => {
              return {
                value: item.id,
                label: item.locationName,
                branchId: item.branchId,
              };
            })
            .filter((item: any) => item.branchId == branchId)
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (branchId && pickupLocationsOptions)
      setPickupLocationsOptionsOfParticular(() =>
        pickupLocationsOptions.filter((item: any) => item.branchId == branchId)
      );

    if (branchId && returnLocationsOptions)
      setReturnLocationsOptionsOfParticular(() =>
        returnLocationsOptions.filter((item: any) => item.branchId == branchId)
      );
  }, [branchId]);

  useEffect(() => {
    axois
      .get(`${baseURL}emailTemplate/${id}`)
      .then((response) => {
        setBranchId(response.data.branchId);
        setLabel(response.data.name);
        setItemTypeId(response.data.emailTemplateTypeId);
        setSendAsSms(response.data.sendAsSMS);
        setGroup(response.data.group);
        setVehicleClassId(response.data.vehicleClassId);
        setPickupLocations(response.data.pickupLoationId);
        setReturnLocations(response.data.returnLocationId);
        setSendAfterXDays(response.data.sendAfterXDays);
        setHourToSend(response.data.hourToSend);
        setAttachments(response.data.attachments);
        setStartDate(response.data.startDate.split("T")[0]);
        setRepeatEveryXDays(response.data.repeatEvery);
        setActive(response.data.isActive);
        setCc(response.data.emailCC);
        setReplyTo(response.data.emailReplyTo);
        setSubject(response.data.emailSubject);
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(response.data.emailContent).contentBlocks,
              convertFromHTML(response.data.emailContent).entityMap
            )
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [pickupLocationsOptions]);

  useEffect(() => {
    axois
      .get(`${baseURL}fileUploadData/EmailTemplate/${id}`)
      .then((response) => {
        setFilePath(() =>
          response.data.map((item: any) => {
            return {
              id: item.id,
              fileName: item.fileName.startsWith("data:image/png;base64,")
                ? item.fileName
                : `${baseURL}commonFile/Get?name=` +
                  item.fileName,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const deleteHandle = async () => {
    if (
      window.confirm("Are you sure you want to delete this Email Template?") ===
      true
    ) {
      await axios
        .delete(`${baseURL}emailTemplate?id=${id}`)
        .then(() => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <EmailIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Email Templates - Edit Email Template
          </Typography>
        </Box>

        <div>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => deleteHandle()}
          >
            Delete
          </Button>
        </div>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingBottom: "24px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
           General Settings
          </h5>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="label"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Label
                    <br />
                    <input
                      type="text"
                      name="label"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                      required
                    />
                  </label>

                  <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "10px", fontWeight: "bold" }}>
                      Send As SMS
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="sendAsSms"
                        style={{ fontSize: "12px" }}
                        checked={sendAsSms ? true : false}
                        onChange={(e) =>
                          setSendAsSms(e.target.value === "Yes" ? true : false)
                        }
                      />
                      Yes
                    </label>
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="sendAsSms"
                        style={{ fontSize: "12px" }}
                        checked={sendAsSms ? false : true}
                        onChange={(e) =>
                          setSendAsSms(e.target.value === "Yes" ? true : false)
                        }
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="itemType"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Item Type
                    <br />
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={false}
                      name="itemType"
                      value={itemTypeOptions.find(
                        (item: any) => item.value === itemTypeId
                      )}
                      onChange={(itemType: any) =>
                        setItemTypeId(itemType ? itemType.value : "")
                      }
                      options={itemTypeOptions}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="group"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Group
                    <br />
                    <input
                      type="text"
                      name="group"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={group}
                      onChange={(e) => setGroup(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
            </div>

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsFilters(!isFilters)}
            >
              {isFilters ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Filters
            </h5>
            <hr />

            {isFilters && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      marginRight: "3%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="branchId"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Branch
                        <br />
                        <select
                          name="branchId"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={branchId}
                          onChange={(e) => setBranchId(e.target.value)}
                          required
                        >
                          {branches.map((item: any) => {
                            return (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    </div>

                    <div style={{ marginTop: "15px" }}>
                      <label
                        htmlFor="pickupLocations"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Pickup Locations
                        <br />
                        <select
                          name="pickupLocations"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={pickupLocations}
                          onChange={(e) => setPickupLocations(e.target.value)}
                        >
                          {pickupLocationsOptionsOfParticular.map(
                            (item: any) => {
                              return (
                                <option key={item.value} value={item.value}>{item.label}</option>
                              );
                            }
                          )}
                        </select>
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="vehicleClass"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Vehicle Class
                        <br />
                        <Select
                          styles={{
                            menu: (base) => ({ ...base, fontSize: "12px" }),
                          }}
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={true}
                          isRtl={false}
                          isSearchable={false}
                          name="vehicle"
                          value={vehicleClassOptions.find(
                            (item: any) => item.value === parseInt(vehicleClassId)
                          )}
                          onChange={(vehicle: any) =>
                            setVehicleClassId(vehicle ? vehicle.value : "")
                          }
                          options={vehicleClassOptions}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="returnLocations"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Return Locations
                        <br />
                        <select
                          name="returnLocations"
                          id="cars"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={returnLocations}
                          onChange={(e) => setReturnLocations(e.target.value)}
                        >
                          {returnLocationsOptionsOfParticular.map(
                            (item: any) => {
                              return (
                                <option key={item.value} value={item.value}>{item.label}</option>
                              );
                            }
                          )}
                        </select>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsAdvanced(!isAdvanced)}
            >
              {isAdvanced ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Advanced
            </h5>
            <hr />

            {isAdvanced && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      marginRight: "3%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="sendAfterXDays"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Send After x Days
                        <br />
                        <input
                          type="number"
                          name="sendAfterXDays"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={sendAfterXDays}
                          onChange={(e) => setSendAfterXDays(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="attachments"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Attachments
                        <br />
                        <input
                          type="text"
                          name="attachments"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={attachments}
                          onChange={(e) => setAttachments(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="startDate"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        Start Date
                        <input
                          type="date"
                          id="startDate"
                          value={startDate}
                          onChange={(event) => setStartDate(event.target.value)}
                          style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingBlock: "3px",
                          }}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="repeatEveryXDays"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Repeat Every x Days
                        <br />
                        <input
                          type="number"
                          name="repeatEveryXDays"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={repeatEveryXDays}
                          onChange={(e) => setRepeatEveryXDays(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="hourToSend"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        Hour to Send
                        <input
                          type="time"
                          value={hourToSend}
                          onChange={(event) =>
                            setHourToSend(event.target.value)
                          }
                          style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingBlock: "3px",
                          }}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px", visibility: "hidden" }}>
                      <label
                        htmlFor="returnLocations"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Return Locations
                        <br />
                        <input
                          type="text"
                          name="returnLocations"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={returnLocations}
                          onChange={(e) => setReturnLocations(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px", visibility: "hidden" }}>
                      <label
                        htmlFor="returnLocations"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Return Locations
                        <br />
                        <input
                          type="text"
                          name="returnLocations"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={returnLocations}
                          onChange={(e) => setReturnLocations(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label style={{ fontSize: "10px", fontWeight: "bold" }}>
                        Active?
                      </label>{" "}
                      <br />
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="active"
                          style={{ fontSize: "12px" }}
                          checked={active ? true : false}
                          onChange={(e) =>
                            setActive(e.target.value === "Yes" ? true : false)
                          }
                        />
                        Yes
                      </label>
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="No"
                          name="active"
                          style={{ fontSize: "12px" }}
                          checked={active ? false : true}
                          onChange={(e) =>
                            setActive(e.target.value === "Yes" ? true : false)
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsContent(!isContent)}
            >
              {isContent ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Content
            </h5>
            <hr />

            {isContent && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      marginRight: "3%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="cc"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        CC
                        <br />
                        <input
                          type="text"
                          name="cc"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={cc}
                          onChange={(e) => setCc(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="replyTo"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Reply To
                        <br />
                        <input
                          type="text"
                          name="replyTo"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={replyTo}
                          onChange={(e) => setReplyTo(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="subject"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Subject
                    <br />
                    <input
                      type="text"
                      name="subject"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </label>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="content"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Content
                  </label>
                  <br />

                  <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={setEditorState}
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                  />
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="attachments"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Attachments
                    <br />
                    {filePath.length !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {filePath.map((item: any, outerIndex: number) => {
                          return (
                            <React.Fragment key={outerIndex}>
                              <img
                                src={item.fileName}
                                width={40}
                                height={40}
                                alt="repairOrder"
                                onClick={() => {
                                  setFileIndex(outerIndex);
                                  setImagePopup(true);
                                }}
                              />
                              <RemoveCircleIcon
                                sx={{
                                  fontSize: "14px",
                                  color: colorConfigs.topbar.bg,
                                  fontWeight: "bold",
                                  "&: hover": {
                                    color: "red",
                                  },
                                }}
                                onClick={() => {
                                    if (
                                        window.confirm(
                                          "Are you sure you want to delete this image?"
                                        )
                                      ) {
                                        let newFileSet: any[] = filePath.filter(
                                          (_, index: number) => index !== outerIndex
                                        );
      
                                        if (filePath[outerIndex].id === 0) {
                                          setFilePath(() => newFileSet);
                                        } else {
                                          axios
                                            .delete(
                                              `${baseURL}fileUploadData?id=${filePath[outerIndex].id}`
                                            )
                                            .then((response) => {
                                              setFilePath(() => newFileSet);
                                            })
                                            .catch((error) => {
                                              console.error("Error:", error);
                                            });
                                        }
                                      }
                                }}
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputFileRef}
                      onChangeCapture={onFileChangeCapture}
                      style={{ display: "none" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={(e) => {
                          if (inputFileRef.current !== null) {
                            inputFileRef.current.click();
                          }
                        }}
                      >
                        {" "}
                        <AddIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                        Add Image
                      </p>

                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={() => setShowWebcam(true)}
                      >
                        {" "}
                        <LocalSeeIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>

      <Dialog
        open={showWebcam}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-16%",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <Webcam
            width={480}
            height={320}
            ref={webRef}
            screenshotFormat="image/png"
            audio={false}
          />

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => setShowWebcam(false)}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (webRef.current !== null) {
                    //@ts-ignore
                    const imageSrc = webRef.current.getScreenshot();
                    setNewFilePath((prevFileString: string[]) => [
                      ...prevFileString,
                      imageSrc,
                    ]);

                    const newFile: any = {
                      id: 0,
                      fileName: imageSrc,
                    };
                    setFilePath((prevFileString: any[]) => [
                      ...prevFileString,
                      newFile,
                    ]);
                    setShowWebcam(false);
                  }
                }}
              >
                Capture an Image
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={imagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>Attachement</p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>
          {filePath.length !== 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  left: "0",
                  top: "50%",
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    cursor: fileIndex !== 0 ? "pointer" : "",
                    opacity: fileIndex === 0 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== 0) {
                      setFileIndex(fileIndex - 1);
                    }
                  }}
                />
              </div>
              <img
                width={700}
                height={350}
                src={filePath[fileIndex]}
                alt="repairOrder"
              />

              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  right: "0",
                  top: "50%",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    cursor: fileIndex !== filePath.length - 1 ? "pointer" : "",
                    opacity: fileIndex === filePath.length - 1 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== filePath.length - 1) {
                      setFileIndex(fileIndex + 1);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={pleaseWaitPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "32%",
          },
        }}
      >
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
              backgroundColor: "white",
              color: "black",
              textAlign: "center",
            }}
          >
            Please Wait...
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
