import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Menu, MenuItem } from "@mui/material";
import { ExpandMore, KeyboardArrowRight, Close, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import colorConfigs from "../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../configs/api";
import { format } from "date-fns";
import useAuth from "../../../components/hooks/useAuth";
import { getCreditCardOfCustomer } from "../../../services/creditCard";


type Props = {
  reservation: any;
  reservationUpdate: () => void;
  paymentList: any[];
  paymentUpdate: () => void;
  securityDepositAmountToPaid: string;
};

export default function AddPayments({
  reservation,
  reservationUpdate,
  paymentList,
  paymentUpdate,
  securityDepositAmountToPaid
}: Props) {
  const { auth }: any = useAuth();
  const [isPayments, setIsPayments] = useState<boolean>(true);

  const [processPaymentBox, setProcessPaymentBox] = useState<boolean>(false);
  const [processSecurityDeposit, setProcessSecurityDeposit] =
    useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const [offlinePaymentAmount, setOfflinePaymentAmount] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>("Payment");
  const [paymentMethod, setPaymentMethod] = useState<string>("Cash");
  const [offlinePaymentStatus, setOfflinePaymentStatus] =
    useState<string>("Approved");
  const [isSendConfirmationEmail, setIsSendConfirmationEmail] =
    useState<boolean>(false);
  const [offlineReference, setOfflineReference] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [paymentCheck, setPaymentCheck] = useState<boolean>(true);
  const [offlinePaymentDate, setOfflinePaymentDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );

  const [offlinePaymentPopup, setOfflinePaymentPopup] =
    useState<boolean>(false);
  const [creditCard, setCreditCard] = useState<string>("");
  const [cardHolderName, setCardHolderName] = useState<string>("");
  const [expirationDate, setExpirationDate] = useState<string>("");
  const [ccvCode, setCcvCode] = useState<string>("");

  const [isProcessPayment, setIsProcessPayment] = useState<boolean>(false);

  const [creditCardList, setCreditCardList] = useState<any[]>([]);
  const [creditReferrence, setCreditCardReferrence] = useState<string>("");
  const [isAuthorizeOpen, setIsAuthorizeOpen] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [sanchorEl, setSAnchorEl] = React.useState<null | HTMLElement>(null);
  const sopen = Boolean(anchorEl);
  const shandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSAnchorEl(event.currentTarget);
  };

  const shandleClose = () => {
    setSAnchorEl(null);
  };

  useEffect(() => {
    getCreditCardOfCustomer(reservation?.customerId)
      .then(data => setCreditCardList(data))
      .catch(error => console.error(error));
  }, [reservation])

  useEffect(() => {
    if (reservation) {
      setOfflinePaymentAmount(() => reservation?.outstandingBalance)
    }
  }, [reservation]);

  const handleOfflinePayment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}payment`,
        JSON.stringify({
          reservationId: reservation?.id,
          paymentType: paymentType,
          paymentMethod: paymentMethod,
          dateofPayment: offlinePaymentDate,
          amount: offlinePaymentAmount,
          reference: offlineReference,
          status: offlinePaymentStatus,
          createdAt: new Date(),
          createdBy: auth.givenname,
          syncedWithAccounting: false,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        updateTheTotalPrice(parseFloat(offlinePaymentAmount), true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateTheTotalPrice = async (amount: number, payment: boolean) => {
    if (payment) {
      const outstandingPayment = parseFloat(reservation?.outstandingBalance) - amount;
      const totalPaid = parseFloat(reservation?.totalPaid) + amount;

      await axios
        .put(
          `${baseURL}reservation`,
          {
            ...reservation,
            outstandingBalance: outstandingPayment,
            totalPaid: totalPaid,
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          setAmount("");
          setDescription("");
          setOfflinePaymentPopup(false);
          setIsAuthorizeOpen(false);
          reservationUpdate();
          paymentUpdate();
          setIsAuthorizeOpen(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      await axios
        .put(
          `${baseURL}reservation`,
          {
            ...reservation,
            securityDepositAmount: parseFloat(reservation?.securityDepositAmount) - amount
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          setOfflinePaymentPopup(false);
          reservationUpdate();
          paymentUpdate();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleCreditPayment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}creditCard`,
        JSON.stringify({
          creditCardNumber: creditCard,
          expirationDate: expirationDate,
          cardholderName: cardHolderName,
          ccvCode: ccvCode,
          cardZipCode: 0,
          customerId: reservation.customerId,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (isProcessPayment) {
          paymentWithCredit(parseFloat(amount), true);
        } else {
          setIsAuthorizeOpen(true);
          setCreditCardReferrence(cardHolderName + " - " + creditCard.substring(creditCard.length - 4, creditCard.length));
        }
        setProcessPaymentBox(false);
        setProcessSecurityDeposit(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const paymentWithCredit = async (amount: number, payment: boolean) => {
    await axios
      .post(
        `${baseURL}payment`,
        JSON.stringify({
          reservationId: reservation?.id,
          paymentType: payment ? "Payment" : "Security Deposit",
          paymentMethod: "Credit Card",
          dateofPayment: new Date(),
          amount: amount,
          reference: cardHolderName + " - " + creditCard.substring(creditCard.length - 4, creditCard.length),
          status: "Approved",
          createdAt: new Date(),
          createdBy: auth.givenname,
          syncedWithAccounting: false,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        updateTheTotalPrice(amount, payment);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const saveCredit = async () => {
    await axios
    .post(
      `${baseURL}creditCard`,
      JSON.stringify({
        creditCardNumber: creditCard,
        expirationDate: expirationDate,
        cardholderName: cardHolderName,
        ccvCode: ccvCode,
        cardZipCode: 0,
        customerId: reservation.customerId,
      }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    )
    .then((response) => {
      paymentWithCredit(parseFloat(securityDepositAmountToPaid), false);
      setProcessPaymentBox(false);
      setProcessSecurityDeposit(false);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  }

  const handleAuthorize = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}payment`,
        JSON.stringify({
          reservationId: reservation?.id,
          paymentType: isProcessPayment ? "Authorize" : "Security Deposit",
          paymentMethod: "Credit Card",
          dateofPayment: new Date(),
          amount: amount,
          reference: creditReferrence,
          status: "Approved",
          createdAt: new Date(),
          createdBy: auth.givenname,
          syncedWithAccounting: false,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (isProcessPayment) {
          updateTheTotalPrice(parseFloat(amount), true);
        } else {
          updateTheTotalPrice(parseFloat(amount), false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <>
      <h5
        style={{
          paddingBottom: "0px",
          marginBlock: "0px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          cursor: "pointer",
          WebkitUserSelect: "none" /* Safari */,
          msUserSelect: "none" /* IE 10 and IE 11 */,
          userSelect: "none" /* Standard syntax */,
        }}
        onClick={() => setIsPayments(!isPayments)}
      >
        {isPayments ? (
          <ExpandMore sx={{ fontSize: "20px" }} />
        ) : (
          <KeyboardArrowRight sx={{ fontSize: "20px" }} />
        )}{" "}
        Payments
      </h5>
      <hr />

      {isPayments && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "8%",
                marginBottom: "0px",
              }}
            >
              #
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "10%",
                marginBottom: "0px",
              }}
            >
              Payment Type
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "12%",
                marginBottom: "0px",
              }}
            >
              Payment Method
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "10%",
                marginBottom: "0px",
              }}
            >
              Date
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "10%",
                marginBottom: "0px",
              }}
            >
              Amount
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "10%",
                marginBottom: "0px",
              }}
            >
              Reference
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "10%",
                marginBottom: "0px",
              }}
            >
              Payment Status
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "10%",
                marginBottom: "0px",
              }}
            >
              Created at
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "10%",
                marginBottom: "0px",
              }}
            >
              Synced with Accounting?
            </p>
          </div>
          <hr style={{ marginBlock: "0px" }} />

          {paymentList.map((item: any) => {
            return (
              <React.Fragment key={item.id}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "8%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.id}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.paymentType}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "12%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.paymentMethod}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.dateofPayment
                      ? format(
                        new Date(
                          item.dateofPayment
                            .replaceAll("-", "/")
                            .split("T")[0]
                        ),
                        "MM/dd/yyyy "
                      )
                      : ""}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    ${parseFloat(item.amount).toFixed(2)}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    {item?.reference}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.status}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.createdAt
                      ? format(
                        new Date(
                          item.createdAt.replaceAll("-", "/").split("T")[0]
                        ),
                        "MM/dd/yyyy "
                      )
                      : ""}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.syncedWithAccounting ? "Yes" : "No"}
                  </p>
                </div>
                <hr style={{ marginBlock: "0px" }} />
              </React.Fragment>
            );
          })}

          <div>

            <div style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "3px",
            }}>

              <button
                style={{
                  marginBlock: "5px",
                  paddingBlock: "3px",
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  border: "none",
                  padding: "8px",
                  cursor: "pointer",
                }}
                onClick={() => setOfflinePaymentPopup(true)}
              >
                Add Offline Payment
              </button>


              {creditCardList.length > 0 &&
                <>
                  <button
                    style={{
                      marginBlock: "5px",
                      paddingBlock: "3px",
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                      border: "none",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                    onClick={handleClick}
                  >
                    Pay ${parseFloat(reservation?.outstandingBalance).toFixed(2)} with Authorize {open ? <ArrowDropUp sx={{ fontSize: "12px" }} /> : <ArrowDropDown sx={{ fontSize: "12px" }} />}
                  </button>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{ sx: { width: '164px', borderRadius: "0px" } }}
                  >
                    {
                      creditCardList.map((item: any) => {
                        return <MenuItem key={item.id} sx={{ fontSize: "12px" }} onClick={() => {
                          setIsAuthorizeOpen(true);
                          setIsProcessPayment(true);
                          setCreditCardReferrence(item.cardholderName + " - " + item.creditCardNumber.substr(-4));
                        }}>{item.cardholderName + " - " + item.creditCardNumber.substr(-4)}</MenuItem>
                      })
                    }

                  </Menu>

                  {
                    parseFloat(securityDepositAmountToPaid) > 0 &&
                    <>
                      <button
                        style={{
                          marginBlock: "5px",
                          paddingBlock: "3px",
                          backgroundColor: colorConfigs.topbar.bg,
                          color: "white",
                          border: "none",
                          padding: "8px",
                          cursor: "pointer",
                        }}
                        onClick={shandleClick}
                      >
                        Authorize ${parseFloat(securityDepositAmountToPaid).toFixed(2)} with Authorize {sopen ? <ArrowDropUp sx={{ fontSize: "12px" }} /> : <ArrowDropDown sx={{ fontSize: "12px" }} />}
                      </button>

                      <Menu
                        id="basic-menu"
                        anchorEl={sanchorEl}
                        open={sopen}
                        onClose={shandleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        PaperProps={{ sx: { width: '164px', borderRadius: "0px" } }}
                      >
                        {
                          creditCardList.map((item: any) => {
                            return <MenuItem key={item.id} sx={{ fontSize: "12px" }} onClick={() => {
                              setIsAuthorizeOpen(true);
                              setIsProcessPayment(false);
                              setCreditCardReferrence(item.cardholderName + " - " + item.creditCardNumber.substr(-4));
                            }}>{item.cardholderName + " - " + item.creditCardNumber.substr(-4)}</MenuItem>
                          })
                        }

                      </Menu>
                    </>
                  }
                </>
              }

            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <button
                style={{
                  marginBlock: "5px",
                  paddingBlock: "3px",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid gray",
                  padding: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsProcessPayment(true);
                  setPaymentCheck(true);
                  setProcessPaymentBox(true);
                }}
              >
                Process Payment
              </button>


              {
                parseFloat(securityDepositAmountToPaid) > 0 &&
                <button
                  style={{
                    marginBlock: "5px",
                    paddingBlock: "3px",
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid gray",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsProcessPayment(false);
                    setPaymentCheck(true);
                    setProcessSecurityDeposit(true);
                    setProcessPaymentBox(true);
                  }}
                >
                  Process Security Deposit
                </button>
              }

              <button
                style={{
                  marginBlock: "5px",
                  paddingBlock: "3px",
                  backgroundColor: "#66BB6A",
                  color: "white",
                  border: "none",
                  padding: "8px",
                }}

              >
                Request Payment
              </button>

              <button
                style={{
                  marginBlock: "5px",
                  paddingBlock: "3px",
                  backgroundColor: "#66BB6A",
                  color: "white",
                  border: "none",
                  padding: "8px",
                }}
             
              >
                Request Security Deposit
              </button>
            </div>
          </div>
        </>
      )}

      <Dialog
        open={processPaymentBox}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "40%",
            display: "flex",
            position: "absolute",
            top: "30px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              {processSecurityDeposit
                ? "Authorize Security Deposit"
                : "Charge Customer"}
            </p>
            <Close
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => {
                setProcessPaymentBox(false);
                setProcessSecurityDeposit(false);
              }}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <form onSubmit={(e) => handleCreditPayment(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {!processSecurityDeposit && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <label
                      htmlFor="amount"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Amount
                      <br />
                      <input
                        type="text"
                        name="amount"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </label>
                  </div>

                  <div style={{ width: "50%" }}>
                    <label
                      htmlFor="description"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Description
                      <br />
                      <input
                        type="text"
                        name="description"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              )}

              <p
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  fontSize: "12px",
                  marginBottom: "0px",
                }}
              >
                Payment
              </p>

              <hr style={{ marginLeft: "16px", marginRight: "16px" }} />

              <div
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <label htmlFor="payment" style={{ fontSize: "12px" }}>
                  <input
                    type="checkbox"
                    value="Yes"
                    name="payment"
                    style={{ fontSize: "12px", textAlign: "center" }}
                    defaultChecked={paymentCheck}
                    onChange={(e) =>
                      setPaymentCheck((paymentCheck) => !paymentCheck)
                    }
                  />{" "}
                  <i
                    style={{ fontSize: "26px", color: "blue" }}
                    className="bx bxl-visa"
                  ></i>
                  <i
                    style={{ fontSize: "26px", marginLeft: "5px" }}
                    className="bx bxl-mastercard"
                  ></i>
                </label>
              </div>

              {paymentCheck && (
                <>
                  <div
                    style={{
                      marginLeft: "16px",
                      marginRight: "16px",
                      marginBlock: "16px",
                      paddingBottom: "16px",
                      border: "1px solid gray",
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "8px 5px",
                        marginBlock: "0px",
                      }}
                    >
                      Authorization Details - <strong>USD</strong>
                    </p>
                    <hr style={{ marginTop: "0px" }} />

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}
                    >

                      <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                      }}>

                        <div style={{ width: "75%" }}>
                          <label
                            htmlFor="amount"
                            style={{ fontSize: "12px", fontWeight: "bold" }}
                          >
                            Credit Card Number
                            <br />
                            <input
                              type="number"
                              name="amount"
                              style={{
                                width: "100%",
                                fontSize: "14px",
                                paddingBlock: "5px",
                              }}
                              value={creditCard}
                              onChange={(e) => setCreditCard(e.target.value)}
                              required
                            />
                          </label>

                        </div>

                        <div style={{ width: "25%" }}>
                          <label
                            htmlFor="description"
                            style={{ fontSize: "12px", fontWeight: "bold" }}
                          >
                            Expiration Date
                            <br />
                            <input
                              type="text"
                              placeholder="MM/YY"
                              name="description"
                              style={{
                                width: "100%",
                                fontSize: "14px",
                                paddingBlock: "5px",
                              }}
                              value={expirationDate}
                              onChange={(e) => setExpirationDate(e.target.value)}
                              required
                            />
                          </label>

                        </div>

                      </div>

                      <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                      }}>

                        <div style={{ width: "75%" }}>
                          <label
                            htmlFor="amount"
                            style={{ fontSize: "12px", fontWeight: "bold" }}
                          >
                            Cardholder Name
                            <br />
                            <input
                              type="text"
                              name="amount"
                              style={{
                                width: "100%",
                                fontSize: "14px",
                                paddingBlock: "5px",
                              }}
                              value={cardHolderName}
                              onChange={(e) => setCardHolderName(e.target.value)}
                              required
                            />
                          </label>

                        </div>

                        <div style={{ width: "25%" }}>

                          <label
                            htmlFor="description"
                            style={{ fontSize: "12px", fontWeight: "bold" }}
                          >
                            CCV Code
                            <br />
                            <input
                              type="text"
                              placeholder="CV"
                              name="description"
                              style={{
                                width: "100%",
                                fontSize: "14px",
                                paddingBlock: "5px",
                              }}
                              value={ccvCode}
                              onChange={(e) => setCcvCode(e.target.value)}
                              required
                            />
                          </label>
                        </div>


                      </div>

                    </div>

                  </div>

                  {
                    !isProcessPayment &&
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginLeft: "16px",
                        marginRight: "16px",
                        backgroundColor: colorConfigs.topbar.bg,
                        padding: "5px 8px",
                        cursor: "pointer"
                      }}
                      onClick={() => saveCredit()}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          color: "white",
                          marginBlock: "0px",
                        }}
                      >
                        {reservation?.securityDeposit
                          ? "Final Authorization"
                          : "Payment"}
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          marginBlock: "0px",
                          color: "black",
                          backgroundColor: "white",
                          padding: "1px 5px",
                          borderRadius: "20px",
                        }}
                      >
                        ${parseFloat(securityDepositAmountToPaid).toFixed(2)}
                      </p>
                    </div>
                  }

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "8px 16px 16px 16px",
                      color: "white",
                      backgroundColor: "#66BB6A",
                      padding: "5px 8px",
                      cursor: "pointer",
                      fontSize: "12px",
                    }}
                  >
                    <button
                      style={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#66BB6A",
                        border: "none",
                        cursor: "pointer",
                      }}
                      type="submit"
                    >
                      {processSecurityDeposit
                        ? "Authorize with Credit Card"
                        : "Pay with Credit Card"}
                    </button>
                  </div>
                </>
              )}
            </div>
          </form>

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }} />

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => {
                  setProcessPaymentBox(false);
                  setProcessSecurityDeposit(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={offlinePaymentPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "40%",
            display: "flex",
            position: "absolute",
            top: "30px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>Add Payment</p>
            <Close
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => setOfflinePaymentPopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <form onSubmit={(e) => handleOfflinePayment(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  marginTop: "10px"
                }}
              >
                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="paymentType"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Payment Type
                    <br />
                    <div>
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="Payment"
                          name="paymentType"
                          style={{ fontSize: "12px" }}
                          checked={paymentType === "Payment" ? true : false}
                          onChange={(e) => setPaymentType(e.target.value)}
                        />{" "}
                        Payment
                      </label>
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="Authorization"
                          name="paymentType"
                          style={{ fontSize: "12px" }}
                          checked={
                            paymentType === "Authorization" ? true : false
                          }
                          onChange={(e) => setPaymentType(e.target.value)}
                        />{" "}
                        Authorization
                      </label>
                    </div>
                  </label>
                </div>

              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  marginTop: "10px"
                }}
              >

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="paymentMethod"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Payment Method
                    <br />
                    <select
                      name="paymentMethod"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "3px",
                      }}
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      required
                    >
                      <option value="Cash">Cash</option>
                      <option value="Check">Check</option>
                      <option value="Credit card">Credit card</option>
                      <option value="Debit card">Debit card</option>
                    </select>
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="date"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Date
                    <br />
                    <input
                      type="date"
                      name="date"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "1px",
                      }}
                      value={offlinePaymentDate}
                      onChange={(e) => setOfflinePaymentDate(e.target.value)}
                      required
                    />
                  </label>
                </div>

              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  marginTop: "10px"
                }}
              >
                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="amount"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Amount
                    <br />
                    <input
                      type="number"
                      name="amount"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "3px",
                      }}
                      value={offlinePaymentAmount}
                      onChange={(e) =>
                        setOfflinePaymentAmount(e.target.value)
                      }
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="reference"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Reference
                    <br />
                    <input
                      type="text"
                      name="reference"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "3px",
                      }}
                      value={offlineReference}
                      onChange={(e) => setOfflineReference(e.target.value)}
                    />
                  </label>
                </div>

              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  marginTop: "10px"
                }}
              >
                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="paymentStatus"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Payment Status
                    <br />
                    <select
                      name="paymentStatus"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "3px",
                      }}
                      value={offlinePaymentStatus}
                      onChange={(e) =>
                        setOfflinePaymentStatus(e.target.value)
                      }
                      required
                    >
                      <option value="Approved">Approved</option>
                      <option value="Denied">Denied</option>
                    </select>
                  </label>
                </div>

              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  marginTop: "10px"
                }}
              >
                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="paymentType"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Send Confirmation Email
                    <br />
                    <div>
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="sendConfirmationEmail"
                          style={{ fontSize: "12px" }}
                          checked={isSendConfirmationEmail ? true : false}
                          onChange={(e) =>
                            setIsSendConfirmationEmail(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        Yes
                      </label>
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="No"
                          name="sendConfirmationEmail"
                          style={{ fontSize: "12px" }}
                          checked={isSendConfirmationEmail ? false : true}
                          onChange={(e) =>
                            setIsSendConfirmationEmail(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        No
                      </label>
                    </div>
                  </label>
                </div>

              </div>

              <div
                style={{
                  width: "100%",
                  height: "50px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  paddingBlock: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  backgroundColor: "#eeeeee",
                }}
              >

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#e0dee0",
                      color: colorConfigs.sidebar.color,
                      borderRadius: "0",
                      border: "none",
                      textTransform: "capitalize",
                      fontSize: "12px",
                      "&: hover": {
                        backgroundColor: "#e0dee0",
                        color: colorConfigs.sidebar.color,
                      },
                    }}
                    onClick={() => setOfflinePaymentPopup(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                      borderRadius: "0",
                      border: "none",
                      textTransform: "capitalize",
                      fontSize: "12px",
                      "&: hover": {
                        backgroundColor: colorConfigs.topbar.bg,
                        color: "white",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isAuthorizeOpen}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "30%",
            display: "flex",
            position: "absolute",
            top: "30px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>{`Credit Card (${creditReferrence})`}</p>
            <Close
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => setIsAuthorizeOpen(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <form onSubmit={(e) => handleAuthorize(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                paddingLeft: "16px",
                paddingRight: "16px",
                marginBottom: "8px"
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="amount"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Amount
                  <br />
                  <input
                    type="text"
                    name="amount"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </label>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="description"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Description
                  <br />
                  <input
                    type="text"
                    name="description"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                paddingBlock: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                backgroundColor: "#eeeeee",
              }}
            >

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: "#e0dee0",
                      color: colorConfigs.sidebar.color,
                    },
                  }}
                  onClick={() => setIsAuthorizeOpen(false)}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                    },
                  }}
                  type="submit"
                >
                  Authorize
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

    </>
  );
}