import { Box, Menu, MenuItem, Stack, Toolbar, Typography, styled } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";

import SearchIcon from "@mui/icons-material/Search";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HelpIcon from '@mui/icons-material/Help';
import FlightIcon from '@mui/icons-material/Flight';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import useAuth from "../hooks/useAuth";
import Cookies from "js-cookie";
import React from "react";


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Topbar = () => {
  const { auth, setAuth }: any = useAuth();

  const logout = () => {
    setAuth(null);
    Cookies.remove("role");
    Cookies.remove("token-jj-rental-ui");
    Cookies.remove("roleId-jj-rental-ui");
    Cookies.remove("role-jj-rental-ui");
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
        marginBlock: "0"
      }}
    >
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src="images/logo/rd-rental-logo.jpeg" alt="Logo" style={{ marginRight: '8px', height: '32px' }} />
          <Typography variant="h6" sx={{ color: "#ffffff", fontSize: "18px" }}>
            RD Rental
          </Typography>
        </Box>

        <Stack direction="row" spacing={3}>
          <SearchIcon sx={{ color: "#ffffff", fontSize: "18px" }} />
          <CardGiftcardIcon sx={{ color: "#ffffff", fontSize: "18px" }} />
          <HelpIcon sx={{ color: "#ffffff", fontSize: "18px" }} />
          <FlightIcon sx={{ color: "#ffffff", fontSize: "18px" }} />
          <FlightIcon sx={{ color: "#ffffff", fontSize: "18px" }} />
          <ChatBubbleIcon sx={{ color: "#ffffff", fontSize: "18px" }} />
          <DirectionsCarIcon sx={{ color: "#ffffff", fontSize: "18px" }} />
          <MyLocationIcon sx={{ color: "#ffffff", fontSize: "18px" }} />
          <MyLocationIcon sx={{ color: "#ffffff", fontSize: "18px" }} />

          <div >
            <button
              style={{ backgroundColor: colorConfigs.topbar.bg, border: "none", cursor: "pointer" }}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <Stack direction="row" spacing={1} alignItems={"center"} justifyContent={"center"}>
                <PersonIcon sx={{ color: "#ffffff", fontSize: "18px" }} />
                <Typography sx={{ color: "#ffffff", fontSize: "14px", }}>{auth?.givenname}</Typography>
                {open ? <ArrowDropUpIcon sx={{ color: "#ffffff", fontSize: "18px" }} /> : <ArrowDropDownIcon sx={{ color: "#ffffff", fontSize: "18px" }} />}
              </Stack>
            </button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              PaperProps={{ sx: { width: '164px', borderRadius: "0px", marginLeft: "18px", marginTop: "26px" } }}
            >
              <MenuItem sx={{ fontSize: "12px", marginBlock: "0px" }}>
                <PersonIcon sx={{ color: "#000000", fontSize: "16px", marginRight: "2px", paddingTop: "2px" }} />Profile
              </MenuItem>
              <hr style={{ marginBlock: "0px" }} />
              <MenuItem sx={{ fontSize: "12px" }} onClick={() => logout()}>
                <LogoutOutlinedIcon sx={{ color: "#000000", fontSize: "16px", marginRight: "2px", paddingTop: "2px" }} />Logout
              </MenuItem>
            </Menu>
          </div>

        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
