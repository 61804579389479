import {
  Box,
  Button,
  Toolbar,
  Typography,
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import colorConfigs from "../../../../configs/colorConfigs";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import axios from "axios";
import axois from "../../../../configs/axois";
import baseURL from "../../../../configs/api";
import StorageIcon from '@mui/icons-material/Storage';
import { iconValues } from "../../../../utils/iconValues";

const pages = [
  "Edit"
];

const Option = (props: any) => (
  <components.Option {...props} className="top-bar-icon-option">
    <img src={`../../../../images/icons/svg/${props.data.icon}`} alt="logo" className="top-bar-icon-logo" />
    {props.data.label}
  </components.Option>
);

export const EditFeature = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const [label, setLabel] = useState<string>("");
  const [order, setOrder] = useState<string>("");
  const [menuBarIcon, setMenuBarIcon] = useState<any>();
  const [menuTopBarIcon, setMenuTopBarIcon] = useState<string>("");

  const handleMenuChange = (value: any) => {
    setMenuBarIcon(value);
    setMenuTopBarIcon(value.value);
  };

  const [firstTime, setFirstTime] = React.useState<String>(pages[0]);

  const SingleValue = ({ children, ...props }: any) => (
    <components.SingleValue {...props}>
      <img src={`../../../../images/icons/svg/${menuBarIcon?.icon}`} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const id = useLocation();
  useEffect(() => {
    axois
      .get(`${baseURL}Feature/${id.state}`)
      .then((response) => {
        setLabel(response.data.label);
        setOrder(response.data.order);
        setMenuTopBarIcon(response.data.icon);
        setMenuBarIcon(iconValues.find((item: any) => item.value === response.data.icon));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .put(
        `${baseURL}Feature`,
        JSON.stringify({
          id: id.state,
          label: label,
          order: order,
          icon: menuTopBarIcon,
          websiteLabel: label
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteTheItem = async () => {
    if (
      window.confirm("Are you sure you want to delete this Feature?") === true
    ) {
      await axios
        .delete(`${baseURL}Feature?id=${id.state}`)
        .then(() => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <StorageIcon
            sx={{ fontSize: "24px", marginRight: "5px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Feature / Edit Feature
          </Typography>
        </Box>
        <div>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => deleteTheItem()}
          >
            Delete
          </Button>
        </div>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>
      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingTop: "10px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px", fontSize: "18px" }}>
            Feature Overview
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="label"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Label
                    <br />
                    <input
                      type="text"
                      name="label"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                      required
                    />
                  </label>

                </div>
              </div>

              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="order"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Order
                    <br />
                    <input
                      type="text"
                      name="order"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="icon"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Icon
                    <br />
                    <Select
                      name="menu"
                      value={menuBarIcon}
                      options={iconValues}
                      onChange={handleMenuChange}
                      styles={{
                        singleValue: (base) => ({
                          ...base,
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                        }),
                      }}
                      components={{
                        Option,
                        SingleValue,
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};