import React, { useEffect, useState } from "react";
import colorConfigs from "../../configs/colorConfigs";
import baseURL from "../../configs/api";
import { useNavigate } from "react-router";
import QuickPickupSelectWrapper from "../../components/common/QuickPickupSelectWrapper";

export default function QuickPickup() {
  const [currentSearchTitle, setCurrentSearchTitle] = useState<number>(0);
  const [pageNo, setPage] = useState<number>(-1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isNextPageLoading, setNextPageLoading] = useState<boolean>(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);

  useEffect(() => {
    let timeOutId: NodeJS.Timeout;
    setHasNextPage(true);
    setNextPageLoading(false);
    setPage(-1)
    if (searchValue !== "") {
      timeOutId = setTimeout(() => {
        setIsSearching(false);
        setOptions([])
      }, 1000)
    }
    else {
      setOptions([]);
      setIsSearching(false);
    }

    return () => clearTimeout(timeOutId);
  }, [searchValue])



  const loadNextPage = () => {
    if (searchValue === "") {
      loadWithOutSearchOptions(pageNo + 1);
    }
    else {
      loadWithSearchOptions(pageNo + 1);
    }

  };

  const loadWithOutSearchOptions = (page: number) => {
    try {
      const pagination = {
        page: page,
        size: 15
      }
      setNextPageLoading(true);
      if (currentSearchTitle === 0) {
        fetch(`${baseURL}reservation/GetReservationsReportPaging/${pagination.page},${pagination.size}`)
          .then((res) => res.json())
          .then((data) => {
            const dataOptions = data.reservationReport.map((item: any) => {
              return {
                value: item.id,
                label: item.bookingNumber,
              }
            })

            const itemsData = options.concat(dataOptions);
            setOptions(itemsData);
            setNextPageLoading(false);
            setHasNextPage(itemsData.length < data.count);
            setPage(page);
          })
          .catch((error) => {
            console.error(error)
          })
      }
      else if (currentSearchTitle === 1) {
        fetch(`${baseURL}vehicles/GetVehicleReportPaging/${pagination.page},${pagination.size}`)
          .then((res) => res.json())
          .then((data) => {
            const dataOptions = data.vehicleReports.map((item: any) => {
              return {
                value: item.id,
                label: item.licensePlate,
              }
            })

            const itemsData = options.concat(dataOptions);
            setOptions(itemsData);
            setNextPageLoading(false);
            setHasNextPage(itemsData.length < data.count);
            setPage(page);
          })
          .catch((error) => {
            console.error(error)
          })
      }
      else {
        fetch(`${baseURL}Customer/GetCustomersPaging/${pagination.page},${pagination.size}`)
          .then((res) => res.json())
          .then((data) => {
            const dataOptions = data.customers.map((item: any) => {
              return {
                value: item.id,
                label: item.firstName + " " + item.lastName,
              }
            })

            const itemsData = options.concat(dataOptions);
            setOptions(itemsData);
            setNextPageLoading(false);
            setHasNextPage(itemsData.length < data.count);
            setPage(page);
          })
          .catch((error) => {
            console.error(error)
          })
      }

    }
    catch (error) {
      console.error(error);
    }
  }

  const loadWithSearchOptions = (page: number) => {
    try {
      const pagination = {
        page: page,
        size: 15
      }
      setNextPageLoading(true);
      if (currentSearchTitle === 0) {
        fetch(`${baseURL}reservation/GetReservationsReportSearchWithBookingNumber/${searchValue},${pagination.page},${pagination.size}`)
          .then((res) => res.json())
          .then((data) => {
            const dataOptions = data.reservationReport.map((item: any) => {
              return {
                value: item.id,
                label: item.bookingNumber,
              }
            })
            const itemsData = options.concat(dataOptions);
            setOptions(itemsData);
            setNextPageLoading(false);
            setHasNextPage(itemsData.length < data.count);
            setPage(page);
          })
          .catch((error) => {
            console.error(error)
          })
      }
      else if (currentSearchTitle === 1) {
        fetch(`${baseURL}vehicles/GetVehicleReportSearchWithLicensePlate/${searchValue},${pagination.page},${pagination.size}`)
          .then((res) => res.json())
          .then((data) => {
            const dataOptions = data.vehicleReports.map((item: any) => {
              return {
                value: item.id,
                label: item.licensePlate,
              }
            })

            const itemsData = options.concat(dataOptions);
            setOptions(itemsData);
            setNextPageLoading(false);
            setHasNextPage(itemsData.length < data.count);
            setPage(page);
          })
          .catch((error) => {
            console.error(error)
          })
      }
      else {
        fetch(`${baseURL}Customer/GetCustomersSearchWithCustomerNamePaging/${searchValue},${pagination.page},${pagination.size}`)
          .then((res) => res.json())
          .then((data) => {
            const dataOptions = data.customers.map((item: any) => {
              return {
                value: item.id,
                label: item.firstName + " " + item.lastName,
              }
            })

            const itemsData = options.concat(dataOptions);
            setOptions(itemsData);
            setNextPageLoading(false);
            setHasNextPage(itemsData.length < data.count);
            setPage(page);
          })
          .catch((error) => {
            console.error(error)
          })
      }

    }
    catch (error) {
      console.error(error);
    }
  }


  const navigate = useNavigate();

  const navigateToSelectDestination = (id: number) => {
    switch (currentSearchTitle) {
      case 0:
        navigate("/carrental/reservations/editreservation", {
          state: id,
        });
        break;
      case 1:
        navigate("/fleet/vehicle/editvehicles", { state: id });
        break;
      case 2:
        navigate("/contacts/customers/editcustomer", { state: id });
        break;
    }
  };

  return (
    <div
      style={{
        margin: "10px",
        border: "2px solid",
        borderBlockColor: colorConfigs.topbar.bg,
        borderRadius: "5px",
      }}
    >
      <nav
        style={{
          background: colorConfigs.topbar.bg,
          color: "white",
          display: "flex",
          alignItems: "center",
          padding: "8px 16px",
          fontSize: "12px",
        }}
      >
        Quick Pickup
      </nav>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "16px 16px 8px 16px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "80px",
            borderStyle:
              currentSearchTitle === 0
                ? "solid solid none solid"
                : "none none solid none",
            borderWidth: "1px",
            borderColor: "gray",
            textAlign: "center",
            padding: "5px 8px 0px 8px",
            fontSize: "12px",
            cursor: currentSearchTitle !== 0 ? "pointer" : "",
            color: currentSearchTitle !== 0 ? "blue" : "black",
          }}
          onClick={() => {
            setCurrentSearchTitle(0)
            setOptions([]);
            setPage(-1);
            setHasNextPage(true);
            setIsSearching(false);
          }
          }
        >
          Reservation
        </div>

        <div
          style={{
            display: "flex",
            width: "80px",
            borderStyle:
              currentSearchTitle === 1
                ? "solid solid none solid"
                : "none none solid none",
            borderWidth: "1px",
            borderColor: "gray",
            textAlign: "center",
            padding: "5px 8px 0px 8px",
            fontSize: "12px",
            cursor: currentSearchTitle !== 1 ? "pointer" : "",
            color: currentSearchTitle !== 1 ? "blue" : "black",
          }}
          onClick={() => {
            setCurrentSearchTitle(1)
            setOptions([]);
            setPage(-1);
            setHasNextPage(true);
            setIsSearching(false);
          }
          }
        >
          Vehicle
        </div>

        <div
          style={{
            display: "flex",
            width: "80px",
            borderStyle:
              currentSearchTitle === 2
                ? "solid solid none solid"
                : "none none solid none",
            borderWidth: "1px",
            borderColor: "gray",
            textAlign: "center",
            padding: "5px 8px 0px 8px",
            fontSize: "12px",
            cursor: currentSearchTitle !== 2 ? "pointer" : "",
            color: currentSearchTitle !== 2 ? "blue" : "black",
          }}
          onClick={() => {
            setCurrentSearchTitle(2)
            setOptions([]);
            setPage(-1);
            setHasNextPage(true);
            setIsSearching(false);
          }
          }
        >
          Customer
        </div>

        <div
          style={{
            width: "100%",
            borderStyle: "none none solid none",
            borderWidth: "1px",
            borderColor: "gray",
          }}
        />
      </div>

      <div style={{ padding: "8px 16px 16px 16px" }}>
        <QuickPickupSelectWrapper
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          setSearchValue={setSearchValue}
          options={options}
          setPage={setPage}
          loadNextPage={loadNextPage}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          onChange={(searchValue: any) =>
            navigateToSelectDestination(searchValue?.value)
          }

        />
      </div>
    </div>
  );
}
