
type Props = {
    state: string;
    setState: (value: string) => void;
    zip: string;
    setZip: (value: string) => void;
}

export default function TextSettings({ state, setState, zip, setZip }: Props) {

    return (
        <div>

            <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    padding: "24px 26px",
                }}
            >
                <div
                    style={{
                        width: "85%",
                        marginRight: "3%",
                    }}
                >
                    <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
                        Text Settings
                    </h5>
                    <hr />

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            padding: "16px 16px 0px 16px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "70px",
                                borderStyle: "solid solid none solid",
                                borderWidth: "1px",
                                borderColor: "gray",
                                padding: "5px 5px 0px 5px",
                                fontSize: "12px",
                                justifyContent: "center",
                            }}
                        >
                            English
                        </div>

                        <div
                            style={{
                                width: "100%",
                                borderStyle: "none none solid none",
                                borderWidth: "1px",
                                borderColor: "gray",
                            }}
                        />
                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div
                            style={{
                                width: "50%",
                                marginRight: "3%",
                                paddingLeft: "16px",
                            }}
                        >
                            <div style={{ marginTop: "10px" }}>
                                <label
                                    htmlFor="state"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    State
                                    <br />
                                    <input
                                        type="text"
                                        name="state"
                                        style={{
                                            width: "100%",
                                            fontSize: "14px",
                                            paddingBlock: "5px",
                                        }}
                                        value={state}
                                        onChange={(e) =>
                                            setState(e.target.value)
                                        }
                                    />
                                </label>
                            </div>

                        </div>

                        <div
                            style={{
                                width: "50%",
                            }}
                        >
                            <div style={{ marginTop: "10px" }}>
                                <label
                                    htmlFor="zip"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Zip
                                    <br />
                                    <input
                                        type="text"
                                        name="zip"
                                        style={{
                                            width: "100%",
                                            fontSize: "14px",
                                            paddingBlock: "5px",
                                        }}
                                        value={zip}
                                        onChange={(e) =>
                                            setZip(e.target.value)
                                        }
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
