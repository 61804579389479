import { Box, Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import baseURL from "../../../../configs/api";
import { GridColDef, DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import CloseIcon from "@mui/icons-material/Close";
import TransactionInfo from "./TransactionInfo";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },

    width: "100%",
    paddingBottom: "0px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    paddingBottom: "0px",
}));

type AllTransactionsProps = {
    paginationModel: any;
    setPaginationModel: (value: any) => void;
    totalLength: number;
    setSearchTableData: (value: any[]) => void;
    searchTableData: any[];
    setIsDataLoaded: (value: boolean) => void;
    isDataLoaded: boolean;
    searchValue: string;
    setSearchValue: (value: string) => void;
    oldPage: number;
    setOldPage: (value: number) => void;
    setTotalLength: (value: number) => void;
}

export const AllTransactions = ({
    paginationModel,
    setPaginationModel,
    totalLength,
    setSearchTableData,
    searchTableData,
    setIsDataLoaded,
    searchValue,
    setSearchValue,
    isDataLoaded,
    oldPage,
    setOldPage,
    setTotalLength }: AllTransactionsProps) => {
    const [displayFilter, setDisplayFilter] = useState<boolean>(false);
    const [transactionsFilterValue, setTransactionsFilterValue] = useState(0);
    const [operatorFilter, setOpertorFilter] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState<string>("External Transaction ID");
    const [particularTransactionDetails, setParticularTransactionDetails] = useState<any>();
    const [editPopup, setEditPopup] = useState<boolean>(false);

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "#",
            width: 80,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <p
                        style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setEditPopup(true);
                            setParticularTransactionDetails(params.row);
                        }}
                    >
                        {params.value}
                    </p>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 80,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                let color: string = "#9E9E9E";
                switch (params.value.toLowerCase()) {
                    case "quote":
                        color = "#bdbdbd";
                        break;
                    case "open":
                        color = "#f0ad4e";
                        break;
                    case "rental":
                        color = "#66BB6A";
                        break;
                    case "completed":
                        color = "#253455";
                        break;
                    case "cancel":
                        color = "#ff0000";
                        break;
                    case "pending":
                        color = "#808080";
                        break;
                    case "no Show":
                        color = "#e0e0e0";
                        break;
                    default:
                        color = "#9E9E9E";
                        break;
                }

                return (
                    <p
                        style={{
                            width: params.value === "no Show" ? "50px" : params.value === "completed" ? "60px" : "45px",
                            fontSize: "10px",
                            backgroundColor: color,
                            color: "#fff",
                            padding: "2px 4px",
                            borderRadius: "2px",
                            textAlign: "center",
                            margin: "0px",
                        }}
                    >
                        {params.value}
                    </p>
                );
            },
        },
        {
            field: "externalTransactionID",
            headerName: "External Transaction ID",
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "item",
            headerName: "Item Type",
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "contact",
            headerName: "Contact",
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 100,
            editable: false,
            sortable: false,
        },
        {
            field: "refundedAmount",
            headerName: "Refunded Amount",
            width: 100,
            editable: false,
            sortable: false,
        },
        {
            field: "gateway",
            headerName: "Gateway",
            width: 90,
            editable: false,
            sortable: false,
        },
        {
            field: "brand",
            headerName: "Brand",
            width: 100,
            editable: false,
            sortable: false,
        },
        {
            field: "cardholderName",
            headerName: "Cardholder Name",
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "last4Digits",
            headerName: "Last 4 Digits",
            width: 90,
            editable: false,
            sortable: false,
        },
    ];


    useEffect(() => {

        let timeOutId: NodeJS.Timeout;

        if (searchValue !== "") {
            timeOutId = setTimeout(() => {
                const newPaginationModel = {
                    page: 0,
                    pageSize: paginationModel.pageSize,
                };

                setPaginationModel(newPaginationModel);

            }, 1000);

        }

        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (searchValue !== "") {
            apiCall(paginationModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel])

    const apiCall = (newPaginationModel: any) => {
        let filterData: any[] = [];
        setIsDataLoaded(true);
        switch (transactionsFilterValue) {
            case 0:
                fetch(`${baseURL}transaction/GetTransactionsReportSearchWithExternalTransactionID/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
                    .then((res) => res.json())
                    .then((data) => {

                        filterData = data.transaction.map((item: any) => {
                            return {
                                ...item,
                            };
                        });

                        let newArray = [];
                        if (paginationModel.page === 0) {
                            newArray = filterData;
                        } else if (paginationModel.page > oldPage) {
                            newArray = searchTableData.concat(filterData);
                        } else {
                            newArray = searchTableData;
                        }

                        setSearchTableData(newArray);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                        if (paginationModel.page === 0) {
                            setOldPage(-1);
                        } else {
                            setOldPage(paginationModel.page - 1);
                        }
                    });
                break;
            case 1:
                fetch(`${baseURL}transaction/GetTransactionsReportSearchWithCardHolderName/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
                    .then((res) => res.json())
                    .then((data) => {

                        filterData = data.transaction.map((item: any) => {
                            return {
                                ...item,
                            };
                        });

                        let newArray = [];
                        if (paginationModel.page === 0) {
                            newArray = filterData;
                        } else if (paginationModel.page > oldPage) {
                            newArray = searchTableData.concat(filterData);
                        } else {
                            newArray = searchTableData;
                        }

                        setSearchTableData(newArray);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                        if (paginationModel.page === 0) {
                            setOldPage(-1);
                        } else {
                            setOldPage(paginationModel.page - 1);
                        }
                    });
                break;
            case 2:
                fetch(`${baseURL}transaction/GetTransactionsReportSearchWithLast4Digits/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
                    .then((res) => res.json())
                    .then((data) => {

                        filterData = data.transaction.map((item: any) => {
                            return {
                                ...item,
                            };
                        });

                        let newArray = [];
                        if (paginationModel.page === 0) {
                            newArray = filterData;
                        } else if (paginationModel.page > oldPage) {
                            newArray = searchTableData.concat(filterData);
                        } else {
                            newArray = searchTableData;
                        }

                        setSearchTableData(newArray);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                        if (paginationModel.page === 0) {
                            setOldPage(-1);
                        } else {
                            setOldPage(paginationModel.page - 1);
                        }
                    });
                break;

        }
    }


    const applyFilter = () => {
        setSelectedFilter(() =>
            transactionsFilterValue === 0 ? "External Transaction ID" :
                transactionsFilterValue === 1 ? "Card Holder Name" : "Last Four Digits"
        );
        setSearchValue("");
        setDisplayFilter(false);
    };

    const clearFilter = () => {
        setSearchValue("");
        setDisplayFilter(false);
    };


    return (
        <>
            <Box sx={{ padding: "24px" }}>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ border: "1px solid black", alignItems: "center" }}
                >
                    <div style={{ position: "relative", width: "150px" }}>
                        <Button
                            variant="contained"
                            endIcon={<ArrowDropDownIcon sx={{ color: "#bdbdbd" }} />}
                            sx={{
                                color: "black",
                                backgroundColor: "#e0dee0",
                                textTransform: "unset",
                                fontSize: "12px",
                                border: "none",
                                borderRadius: "0px",

                                "&: hover": {
                                    backgroundColor: "white",
                                },
                            }}
                            onClick={() => setDisplayFilter(() => !displayFilter)}
                        >
                            {selectedFilter}
                        </Button>

                        <div
                            style={{
                                display: displayFilter ? "block" : "none",
                                width: "170px",
                                position: "absolute",
                                zIndex: "100",
                                top: "-100%",
                                left: "100%",
                                background: "white",
                                border: "1px solid black",
                                padding: "8px",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <label
                                        htmlFor="fields"
                                        style={{ fontSize: "10px", fontWeight: "bold" }}
                                    >
                                        Fields
                                        <br />
                                    </label>

                                    <CloseIcon
                                        sx={{
                                            width: "18px",
                                            fontSize: "10px",
                                            cursor: "pointer",
                                            background: "#e0e0e0",
                                        }}
                                        onClick={() => setDisplayFilter(false)}
                                    />
                                </div>

                                <select
                                    name="fields"
                                    style={{
                                        width: "100%",
                                        fontSize: "10px",
                                        paddingBlock: "3px",
                                    }}
                                    value={transactionsFilterValue}
                                    onChange={(e) =>
                                        setTransactionsFilterValue(parseInt(e.target.value))
                                    }
                                >
                                    <option value="0">External Transaction ID</option>
                                    <option value="1">Card Holder Name</option>
                                    <option value="2">Last Four Digits</option>
                                </select>
                            </div>

                            <div>
                                <label
                                    htmlFor="operator"
                                    style={{ fontSize: "10px", fontWeight: "bold" }}
                                >
                                    Operator
                                    <br />
                                    <select
                                        name="operator"
                                        style={{
                                            width: "100%",
                                            fontSize: "10px",
                                            paddingBlock: "3px",
                                        }}
                                        value={operatorFilter}
                                        onChange={(e) => setOpertorFilter(parseInt(e.target.value))}
                                    >
                                        <option value="0">In a list</option>
                                    </select>
                                </label>
                            </div>


                            <div
                                style={{
                                    display: "flex",

                                    marginTop: "7px",
                                    justifyContent: "space-between",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <button
                                    className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                                    onClick={() => applyFilter()}
                                >
                                    Apply Filter
                                </button>

                                <button
                                    className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                                    onClick={() => clearFilter()}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>

                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ "aria-label": "search" }}
                            value={searchValue}
                            onChange={(e) => {
                                setSearchValue(e.target.value);

                            }}
                        />
                    </Search>
                </Stack>


                <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
                    {!isDataLoaded ? searchTableData.length > 0 ? (
                        <DataGrid
                            rows={searchTableData}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                            rowCount={totalLength}
                            paginationModel={paginationModel}
                            onPaginationModelChange={(model: GridPaginationModel) => {
                                if (model.pageSize !== paginationModel.pageSize) {
                                    setPaginationModel({
                                        page: 0,
                                        pageSize: model.pageSize,
                                    });
                                    return;
                                }

                                setPaginationModel({
                                    page: model.page,
                                    pageSize: model.pageSize,
                                });
                            }}
                            disableRowSelectionOnClick
                            disableColumnMenu
                            checkboxSelection
                            showCellVerticalBorder={false}
                            sx={{
                                border: "none",
                                fontSize: "12px",
                                wordWrap: "break-word",
                                overflow: "visible",
                            }}
                        />
                    ) : (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <p>No Data Found</p>

                        </div>
                    ) : (
                        <div className="loading">
                            <p>Please wait</p>
                            <span>
                                <i></i>
                                <i></i>
                            </span>
                        </div>
                    )}
                </Box>
            </Box>

            <TransactionInfo
                editPopup={editPopup}
                hidePopup={() => setEditPopup(false)}
                particularTransactionDetails={particularTransactionDetails}
            />
        </>
    );
};