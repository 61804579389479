import React, { useState } from 'react'
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";

type Props = {
    mailChimpKey: string;
    setMailChimpKey: (value: string) => void;
    mailgunSender: string;
    setMailgunSender: (value: string) => void;
    mailgunDomain: string;
    setMailgunDomain: (value: string) => void;
    googleAnalyticsKey: string;
    setGoogleAnalyticsKey: (value: string) => void;
    extraCcUsersForSupportTickets: string;
    setExtraCcUsersForSupportTickets: (value: string) => void;
}

const Integration = ({
    mailChimpKey,
    setMailChimpKey,
    mailgunSender,
    setMailgunSender,
    mailgunDomain,
    setMailgunDomain,
    googleAnalyticsKey,
    setGoogleAnalyticsKey,
    extraCcUsersForSupportTickets,
    setExtraCcUsersForSupportTickets,
}: Props) => {
    const [isIntegrations, setIsIntegrations] = useState<boolean>(true);

    return (
        <>
            <h5
                style={{
                    marginBlock: "0px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    cursor: "pointer",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                }}
                onClick={() => setIsIntegrations(!isIntegrations)}
            >
                {isIntegrations ? (
                    <ExpandMore sx={{ fontSize: "20px" }} />
                ) : (
                    <KeyboardArrowRight sx={{ fontSize: "20px" }} />
                )}{" "}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div style={{ width: "50%" }}>Integrations</div>

                    <div
                        style={{
                            width: "50%",
                            fontSize: "10px",
                            fontWeight: "normal",
                            textAlign: "end",
                            color: "gray",
                        }}
                    >
                        General Settings for this Tenant
                    </div>
                </div>
            </h5>
            <hr />

            {isIntegrations &&
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
                >

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="mailChimpKey"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                MailChimp Key
                                <br />
                                <input
                                    type="text"
                                    name="mailChimpKey"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={mailChimpKey}
                                    onChange={(e) => setMailChimpKey(e.target.value)}
                                />
                            </label>
                        </div>

                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="mailgunSender"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Mailgun Sender
                                <br />
                                <input
                                    type="email"
                                    name="mailgunSender"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={mailgunSender}
                                    onChange={(e) => setMailgunSender(e.target.value)}
                                />
                            </label>
                        </div>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="mailgunDomain"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Mailgun Domain
                                <br />
                                <input
                                    type="text"
                                    name="mailgunDomain"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={mailgunDomain}
                                    onChange={(e) => setMailgunDomain(e.target.value)}
                                />
                            </label>
                        </div>

                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="googleAnalyticsKey"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Google Analytics Key
                                <br />
                                <input
                                    type="text"
                                    name="googleAnalyticsKey"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={googleAnalyticsKey}
                                    onChange={(e) => setGoogleAnalyticsKey(e.target.value)}
                                />
                            </label>
                        </div>

                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="extraCcUsersForSupportTickets"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Extra CC Users For Support Tickets
                                <br />
                                <input
                                    type="text"
                                    name="extraCcUsersForSupportTickets"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={extraCcUsersForSupportTickets}
                                    onChange={(e) => setExtraCcUsersForSupportTickets(e.target.value)}
                                />
                            </label>
                        </div>

                    </div>

                </div>
            }
        </>
    )
}

export default Integration