import React, { useEffect, useState } from "react";
import {
  Box,
  Toolbar,
  Typography,
  Stack,
  Button,
  AppBar,
  Container,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import colorConfigs from "../../../configs/colorConfigs";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import AllVehicles from "./AllVehicles";
import baseURL from "../../../configs/api";
import axios from "axios";


const Vehicles = () => {
  const [pages, setPages] = React.useState<any[]>([
    { id: 0, name: "All" },
  ]);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [currentPage, setCurrentPage] = React.useState<any>({
    pages: pages[0],
    ind: 0
  }
  );
  const [isBranchDataLoaded, setIsBranchDataLoaded] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [totalLength, setTotalLength] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [oldPage, setOldPage] = useState<number>(-1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchTableData, setSearchTableData] = useState<any[]>([]);
  const [isFristTime, setIsFristTime] = useState<boolean>(true);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (pages: string, ind: number) => {
    setAnchorElNav(null);
    setCurrentPage({
      pages,
      ind
    }
    );
    setSearchValue("");
    setPaginationModel({
      page: 0,
      pageSize: 10,
    })
  };

  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then((response) => {
        response.data.forEach((item: any) => {
          setPages((pages) => [
            ...pages,
            {
              id: item.id,
              name: "JJ Van Rental-" + item.branchName,
            },
          ]);
          setIsBranchDataLoaded(true);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (isBranchDataLoaded) {
      fetch(`${baseURL}vehicleStatus`)
        .then((res) => res.json())
        .then((data) => {
          data.forEach((item: any) => {
            if (item.statusName === 'Available' || item.statusName === 'Dirty') {
              setPages((pages) => [
                ...pages,
                {
                  id: item.id,
                  name: item.statusName
                }

              ]);
            }
          })
          setIsBranchDataLoaded(false);
        }).catch((err) => {
          console.error(err);
        })
    }
  }, [isBranchDataLoaded]);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchValue === "" && !isFristTime) {
      const newPaginationModel = {
        page: 0,
        pageSize: paginationModel.pageSize,
      };

      setPaginationModel(newPaginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setIsFristTime(false);
      apiCall(paginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const apiCall = (newPaginationModel: any) => {
    if (currentPage?.pages?.name === 'All') {
      setIsDataLoaded(true);
      fetch(`${baseURL}vehicles/GetVehicleReportPaging/${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.vehicleReports.map((item: any) => {
            return {
              ...item,
              availableDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.availableDate)
              ),
              availableUntil: new Intl.DateTimeFormat("en-US").format(
                new Date(item.availableUntil)
              ),
              lastMaintenanceDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.lastMaintenanceDate)
              ),
              lastWheelRotationDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.lastWheelRotationDate)
              ),
              lastWheelAlignmentDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.lastWheelAlignmentDate)
              ),
            };
          });
          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
    else if (currentPage?.pages?.name === 'Available' || currentPage?.pages?.name === 'Dirty') {
      setIsDataLoaded(true);
      fetch(`${baseURL}vehicles/GetVehicleReportPagingWithStatusId/${currentPage?.pages?.id},${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.vehicleReports.map((item: any) => {
            return {
              ...item,
              availableDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.availableDate)
              ),
              availableUntil: new Intl.DateTimeFormat("en-US").format(
                new Date(item.availableUntil)
              ),
              lastMaintenanceDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.lastMaintenanceDate)
              ),
              lastWheelRotationDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.lastWheelRotationDate)
              ),
              lastWheelAlignmentDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.lastWheelAlignmentDate)
              ),
            };
          });
          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
    else {
      console.log(currentPage)
      setIsDataLoaded(true);
      fetch(`${baseURL}vehicles/GetVehicleReportPagingWithBranchId/${currentPage?.pages?.id},${newPaginationModel.page},${newPaginationModel.pageSize}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.vehicleReports.map((item: any) => {
            return {
              ...item,
              availableDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.availableDate)
              ),
              availableUntil: new Intl.DateTimeFormat("en-US").format(
                new Date(item.availableUntil)
              ),
              lastMaintenanceDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.lastMaintenanceDate)
              ),
              lastWheelRotationDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.lastWheelRotationDate)
              ),
              lastWheelAlignmentDate: new Intl.DateTimeFormat("en-US").format(
                new Date(item.lastWheelAlignmentDate)
              ),
            };
          });
          let newArray = [];
          if (paginationModel.page === 0) {
            newArray = result;
          } else if (paginationModel.page > oldPage) {
            newArray = searchTableData.concat(result);
          } else {
            newArray = searchTableData;
          }

          setSearchTableData(newArray);
          setTotalLength(data.count);
          setIsDataLoaded(false);

          if (paginationModel.page === 0) {
            setOldPage(-1);
          } else {
            setOldPage(() => paginationModel.page - 1);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoaded(false);
        });
    }
  }


  return (
    <Box>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <DirectionsCarIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Vehicles
          </Typography>
        </Box>

        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs.topbar.bg,
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => {
              navigate("/fleet/vehicle/addvehicles");
            }}
          >
            Add Vehicle
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#e0dee0",
              color: "black",
              borderRadius: "0",
              border: "none",
            }}
          >
            <MenuIcon />
          </Button>
        </Stack>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page: any, ind: number) => {
                if (currentPage.index === ind) {
                  return (
                    <MenuItem
                      key={page.id}
                      onClick={() => handleOnClick(page, ind)}
                      sx={{
                        backgroundColor: colorConfigs.sidebar.bg,
                        color: colorConfigs.sidebar.color,
                      }}
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={page.id} onClick={() => handleOnClick(page, ind)}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page, ind) => {
              if (currentPage.ind === ind) {
                return (
                  <Button
                    disableElevation={false}
                    key={ind}
                    onClick={() => handleOnClick(page, ind)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={ind}
                    onClick={() => handleOnClick(page, ind)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>

      <AllVehicles
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        totalLength={totalLength}
        setTotalLength={setTotalLength}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setSearchTableData={setSearchTableData}
        oldPage={oldPage}
        setOldPage={setOldPage}
        searchTableData={searchTableData}
        isDataLoaded={isDataLoaded}
        setIsDataLoaded={setIsDataLoaded}
        currentPage={currentPage}
      />
    </Box>
  );
};

export default Vehicles;
