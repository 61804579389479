import React, { useState, useEffect, useRef } from 'react';
import { getUsername } from '../services/UsernameChecking';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface UsernameValidatedComponentProps {
    userName: string;
    setUserName: (value: string) => void;
    className?: any;
    touched: boolean;
    setTouched: (touched: boolean) => void;
}

const UsernameValidatedComponent = ({
    userName,
    setUserName,
    className = {
        parentDiv: {
            marginBottom: "5px"
        },
        label: {
            fontSize: "12px", fontWeight: "bold",
        },
        input: {
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
        },
        successMessage: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '0.25rem',
            fontSize: '0.75rem',
            color: '#00b300',
        },
        errorMessage: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '0.25rem',
            fontSize: '0.75rem',
            color: '#ff3333',
        },
    },
    touched,
    setTouched
}: UsernameValidatedComponentProps) => {
    const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean | null>(null);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [initialUsername, setInitialUsername] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setInitialUsername(userName);
    }, [userName]);

    useEffect(() => {
        let debounceTimer: NodeJS.Timeout;

        if (touched && userName.trim() !== '' && userName.trim() !== initialUsername) {
            debounceTimer = setTimeout(() => {
                checkUsernameAvailability(userName.trim());
            }, 1000);
        } else {
            setIsUsernameAvailable(null);
            setShowErrorMessage(false);
        }

        return () => clearTimeout(debounceTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName, touched]);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setUserName(value);
        setTouched(true);
        setIsUsernameAvailable(null);
        setShowErrorMessage(false);
        setShowSuccessMessage(false);
        e.target.setCustomValidity("");
    };

    const checkUsernameAvailability = (username: string) => {
        getUsername(username)
            .then((response: boolean) => {
                setIsUsernameAvailable(!response);
                setShowErrorMessage(response);
                if (!response) {
                    setShowSuccessMessage(true);
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 3000);
                }
                if (inputRef.current) {
                    inputRef.current.setCustomValidity(response ? "Username is already exist" : "");
                }
            })
            .catch((error: Error) => {
                console.error('Error checking username availability:', error);
                setIsUsernameAvailable(null);
                setShowErrorMessage(false);
                setShowSuccessMessage(false);
            });
    };

    return (
        <div style={className.parentDiv}>
            <label style={className.label}>Username</label>
            <input
                ref={inputRef}
                name="username"
                type="text"
                required
                style={className.input}
                value={userName}
                onChange={handleUsernameChange}
                autoComplete="new-username"
            />
            {showSuccessMessage && isUsernameAvailable === true && (
                <div style={className.successMessage}>
                    <CheckCircleIcon style={{ marginRight: '0.25rem', fontSize: '1rem' }} />
                    <span>Username is available</span>
                </div>
            )}
            {showErrorMessage && isUsernameAvailable === false && (
                <div style={className.errorMessage}>
                    <ErrorOutlineIcon style={{ marginRight: '0.25rem', fontSize: '1rem' }} />
                    <span>Username is already exist</span>
                </div>
            )}
        </div>
    );
};

export default UsernameValidatedComponent;