import React, { useState, useEffect, useRef } from "react";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface EmailAlreadyExistCheckingProps {
    emailAddress: string;
    setEmailAddress: (value: string) => void;
    className?: any;
    getEmailChecking: (email: string) => Promise<boolean>;
    touched: boolean;
    setTouched: (touched: boolean) => void;
}

const EmailAlreadyExistChecking = ({
    emailAddress,
    setEmailAddress,
    getEmailChecking,
    className = {
        parentDiv: {
            marginBottom: "5px"
        },
        label: {
            fontSize: "12px",
            fontWeight: "bold",
        },
        input: {
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
        },
        successMessage: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '0.25rem',
            fontSize: '0.75rem',
            color: '#00b300',
        },
        errorMessage: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '0.25rem',
            fontSize: '0.75rem',
            color: '#ff3333',
        },
    },
    touched,
    setTouched
}: EmailAlreadyExistCheckingProps) => {
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [isEmailAvailable, setIsEmailAvailable] = useState<boolean | null>(null);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        let debounceTimer: NodeJS.Timeout;

        if (touched && emailAddress.trim() !== '') {
            debounceTimer = setTimeout(() => {
                const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/gm.test(emailAddress.trim());
                setIsEmailValid(isValid);
                if (isValid) {
                    checkEmailAvailability(emailAddress.trim());
                } else {
                    setIsEmailAvailable(false);
                    setShowErrorMessage(false);
                    setShowSuccessMessage(false);
                }
            }, 1000);
        } else {
            setIsEmailValid(true);
            setIsEmailAvailable(false);
            setShowErrorMessage(false);
            setShowSuccessMessage(false);
        }

        return () => clearTimeout(debounceTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailAddress, touched]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmailAddress(value);
        setTouched(true);
        setIsEmailValid(true);
        setIsEmailAvailable(false);
        setShowErrorMessage(false);
        setShowSuccessMessage(false);
        e.target.setCustomValidity("");
    };

    const checkEmailAvailability = (email: string) => {
        getEmailChecking(email)
            .then((response: boolean) => {
                setIsEmailAvailable(response);
                setShowErrorMessage(response);
                setShowSuccessMessage(!response);

                if (!response) {
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 3000);
                }

                if (inputRef.current) {
                    inputRef.current.setCustomValidity(response ? "Email is already exist" : "");
                }
            })
            .catch((error: Error) => {
                console.error('Error checking email availability:', error);
                setIsEmailAvailable(null);
                setShowErrorMessage(false);
                setShowSuccessMessage(false);
            });
    };

    return (
        <div style={className.parentDiv}>
            <label style={className.label}>Email</label>
            <input
                ref={inputRef}
                type="email"
                style={className.input}
                value={emailAddress}
                onChange={handleChange}
            />
            {!isEmailValid && (
                <div style={className.errorMessage}>
                    <ErrorOutlineIcon style={{ marginRight: '0.25rem', fontSize: '1rem' }} />
                    <span>Please enter a valid email address</span>
                </div>
            )}
            {showSuccessMessage && !isEmailAvailable && (
                <div style={className.successMessage}>
                    <CheckCircleIcon style={{ marginRight: '0.25rem', fontSize: '1rem' }} />
                    <span>Email is available</span>
                </div>
            )}
            {showErrorMessage && isEmailAvailable && (
                <div style={className.errorMessage}>
                    <ErrorOutlineIcon style={{ marginRight: '0.25rem', fontSize: '1rem' }} />
                    <span>Email is already exist</span>
                </div>
            )}
        </div>
    );
};

export default EmailAlreadyExistChecking;