import { Box, Button, Stack } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

import baseURL from "../../../../configs/api";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  width: "100%",
  paddingBottom: "0px",
  // [theme.breakpoints.up("sm")]: {
  //   width: "80.05%",
  // },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  paddingLeft: `calc(1em + ${theme.spacing(2)})`,
  paddingBottom: "0px",
  // '& .MuiInputBase-input': {
  // padding: theme.spacing(1, 1, 1, 0),
  // vertical padding + font size from searchIcon
  // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  // transition: theme.transitions.create('width'),
  // width: '100%',
  // [theme.breakpoints.up('sm')]: {
  //   width: '100%',
  // '&:focus': {
  //   width: '100%',
  // },
  // },
  // },
}));

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    editable: false,
    sortable: false,
  },
  {
    field: "securityDepositName",
    headerName: "Name",
    width: 180,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <Link
          to="/admin/carrental/securitydeposits/editsecuritydeposit"
          state={params.id}
        >
          {params.value}
        </Link>
      );
    },
  },
  {
    field: "isActive",
    headerName: "Active?",
    width: 65,
    editable: false,
    sortable: false,
  },
  {
    field: "withBranch",
    headerName: "With Branch?",
    width: 100,
    editable: false,
    sortable: false,
  },
  {
    field: "branchName",
    headerName: "Branch",
    width: 180,
    editable: false,
    sortable: false,
  },
  {
    field: "withAdditionalCharge",
    headerName: "With Additional Charge?",
    width: 140,
    editable: false,
    sortable: false,
  },
  {
    field: "additionalChargeName",
    headerName: "Additional Charge",
    editable: false,
    sortable: false,
    width: 140,
  },
  {
    field: "withVehicleClass",
    headerName: "With Vehicle Class?",
    width: 140,
    editable: false,
    sortable: false,
  },
  {
    field: "vehicleClassName",
    headerName: "Vehicle Classes",
    width: 140,
    editable: false,
    sortable: false,
  },
  {
    field: "withPickupLocation",
    headerName: "With Pickup Location?",
    width: 140,
    editable: false,
    sortable: false,
  },
  {
    field: "pickupLocationName",
    headerName: "Pickup Location",
    width: 140,
    editable: false,
    sortable: false,
  },
  {
    field: "withReturnLocation",
    headerName: "With Return Location?",
    width: 140,
    editable: false,
    sortable: false,
  },
  {
    field: "returnLocationName",
    headerName: "Return Location",
    width: 140,
    editable: false,
    sortable: false,
  },
  {
    field: "depositAmount",
    headerName: "Deposit Amount",
    width: 120,
    editable: false,
    sortable: false,
  },
];

type Props = {
  change: number;
  branchId: number;
  paginationModel: any;
  setPaginationModel: (value: any) => void;
  totalLength: number;
  setSearchTableData: (value: any[]) => void;
  searchTableData: any[];
  setIsDataLoaded: (value: boolean) => void;
  isDataLoaded: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  oldPage: number;
  setOldPage: (value: number) => void;
  setTotalLength: (value: number) => void;
};

export default function AllSecurityDeposits({ change, branchId,
  paginationModel,
  setPaginationModel,
  totalLength,
  setSearchTableData,
  searchTableData,
  setIsDataLoaded,
  searchValue,
  setSearchValue,
  isDataLoaded,
  oldPage,
  setOldPage,
  setTotalLength }: Props) {
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [securityDeposityFilterValue, setSecurityDeposityFilterValue] = useState(0);
  const [operatorFilter, setOpertorFilter] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState<string>("Security Deposit");


  useEffect(() => {
    setSecurityDeposityFilterValue(0);
    setSelectedFilter("Security Deposit");
  }, [branchId])

  useEffect(() => {

    let timeOutId: NodeJS.Timeout;

    if (searchValue !== "") {
      timeOutId = setTimeout(() => {
        const newPaginationModel = {
          page: 0,
          pageSize: paginationModel.pageSize,
        };

        setPaginationModel(newPaginationModel);

      }, 1000);

    }

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue !== "") {
      apiCall(paginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const apiCall = (newPaginationModel: any) => {
    let filterData: any[] = [];
    setIsDataLoaded(true);
    if (branchId === 0) {
      switch (securityDeposityFilterValue) {
        case 0:
          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithSecurityDepositName/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);

              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;
        case 1:

          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithAdditionalChargeName/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);
              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;
        case 2:

          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithVehicleClassName/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);
              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;

        case 3:
          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithPickupLocation/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);
              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;

        case 4:

          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithReturnLocation/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);
              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;

        case 5:

          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithBranchName/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);
              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;

      }
    }
    else {
      switch (securityDeposityFilterValue) {
        case 0:
          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithSecurityDepositNameAndBranchId/${searchValue},${branchId},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);

              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;
        case 1:
          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithAdditionalChargeNameAndBranchId/${searchValue},${branchId},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);

              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;
        case 2:
          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithVehicleClassNameAndBranchId/${searchValue},${branchId},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);

              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;
        case 3:
          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithPickupLocationAndBranchId/${searchValue},${branchId},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);

              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;
        case 4:
          fetch(`${baseURL}securityDeposit/GetSecurityDepositsReportSearchWithReturnLocationAndBranchId/${searchValue},${branchId},${newPaginationModel.page},${newPaginationModel.pageSize}`)
            .then((res) => res.json())
            .then((data) => {

              filterData = data.securityDepositReportsDto.map((item: any) => {
                return {
                  ...item,
                  effectiveFrom: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveFrom)
                  ),
                  effectiveUntil: new Intl.DateTimeFormat("en-US").format(
                    new Date(item.effectiveUntil)
                  ),
                  withBranch: item.withBranch ? "Yes" : "No",
                  withAdditionalCharge: item.withAdditionalCharge ? "Yes" : "No",
                  withVehicleClass: item.withVehicleClass ? "Yes" : "No",
                  withPickupLocation: item.withPickupLocation ? "Yes" : "No",
                  cumulative: item.cumulative ? "Yes" : "No",
                  includeQuantities: item.includeQuantities ? "Yes" : "No",
                  isActive: item.isActive ? "Yes" : "No",
                };
              })

              let newArray = [];
              if (paginationModel.page === 0) {
                newArray = filterData;
              } else if (paginationModel.page > oldPage) {
                newArray = searchTableData.concat(filterData);
              } else {
                newArray = searchTableData;
              }

              setSearchTableData(newArray);
              setTotalLength(data.count);
              setIsDataLoaded(false);

              if (paginationModel.page === 0) {
                setOldPage(-1);
              } else {
                setOldPage(paginationModel.page - 1);
              }
            });
          break;

      }
    }
  }



  const applyFilter = () => {
    setSelectedFilter(() =>
      branchId === 0 ?
        securityDeposityFilterValue === 0 ? "Security Deposit" : securityDeposityFilterValue === 1 ? "Additional Charge" : securityDeposityFilterValue === 2 ? "Vehicle Name" :
          securityDeposityFilterValue === 3 ? "Pickup Location" : securityDeposityFilterValue === 4 ? "Return Location" : "Branch Name"
        : securityDeposityFilterValue === 0 ? "Security Deposit" : securityDeposityFilterValue === 1 ? "Additional Charge" : securityDeposityFilterValue === 2 ? "Vehicle Name" :
          securityDeposityFilterValue === 3 ? "Pickup Location" : "Return Location"
    );
    setSearchValue("");
    setDisplayFilter(false);
  };

  const clearFilter = () => {
    setSearchValue("");
    setDisplayFilter(false);
  };



  return (
    <>
      <Box sx={{ padding: "24px" }}>
        <Stack
          direction="row"
          spacing={3}
          sx={{ border: "1px solid black", alignItems: "center" }}
        >
          <div style={{ position: "relative", width: "150px" }}>
            <Button
              variant="contained"
              endIcon={<ArrowDropDownIcon sx={{ color: "#bdbdbd" }} />}
              sx={{
                color: "black",
                backgroundColor: "#e0dee0",
                textTransform: "unset",
                fontSize: "12px",
                border: "none",
                borderRadius: "0px",

                "&: hover": {
                  backgroundColor: "white",
                },
              }}
              onClick={() => setDisplayFilter(() => !displayFilter)}
            >
              {selectedFilter}
            </Button>

            <div
              style={{
                display: displayFilter ? "block" : "none",
                width: "170px",
                position: "absolute",
                zIndex: "100",
                top: "-100%",
                left: "100%",
                background: "white",
                border: "1px solid black",
                padding: "8px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="fields"
                    style={{ fontSize: "10px", fontWeight: "bold" }}
                  >
                    Fields
                    <br />
                  </label>

                  <CloseIcon
                    sx={{
                      width: "18px",
                      fontSize: "10px",
                      cursor: "pointer",
                      background: "#e0e0e0",
                    }}
                    onClick={() => setDisplayFilter(false)}
                  />
                </div>

                <select
                  name="fields"
                  style={{
                    width: "100%",
                    fontSize: "10px",
                    paddingBlock: "3px",
                  }}
                  value={securityDeposityFilterValue}
                  onChange={(e) =>
                    setSecurityDeposityFilterValue(parseInt(e.target.value))
                  }
                >
                  {
                    branchId === 0 ? (
                      <>
                        <option value="0">Security Deposit</option>
                        <option value="1">Additional Charge</option>
                        <option value="2">Vehicle Name</option>
                        <option value="3">Pickup Location</option>
                        <option value="4">Return Location</option>
                        <option value="5">Branch Name</option>
                      </>
                    ) :
                      (<>
                        <option value="0">Security Deposit</option>
                        <option value="1">Additional Charge</option>
                        <option value="2">Vehicle Name</option>
                        <option value="3">Pickup Location</option>
                        <option value="4">Return Location</option>
                      </>
                      )
                  }
                </select>
              </div>

              <div>
                <label
                  htmlFor="operator"
                  style={{ fontSize: "10px", fontWeight: "bold" }}
                >
                  Operator
                  <br />
                  <select
                    name="operator"
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      paddingBlock: "3px",
                    }}
                    value={operatorFilter}
                    onChange={(e) => setOpertorFilter(parseInt(e.target.value))}
                  >
                    <option value="0">In a list</option>
                  </select>
                </label>
              </div>


              <div
                style={{
                  display: "flex",

                  marginTop: "7px",
                  justifyContent: "space-between",
                  whiteSpace: "nowrap",
                }}
              >
                <button
                  className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                  onClick={() => applyFilter()}
                >
                  Apply Filter
                </button>

                <button
                  className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                  onClick={() => clearFilter()}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </Search>
        </Stack>


        <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
          {!isDataLoaded ? searchTableData.length > 0 ? (
            <DataGrid
              rows={searchTableData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
              rowCount={totalLength}
              paginationModel={paginationModel}
              onPaginationModelChange={(model: GridPaginationModel) => {
                if (model.pageSize !== paginationModel.pageSize) {
                  setPaginationModel({
                    page: 0,
                    pageSize: model.pageSize,
                  });
                  return;
                }

                setPaginationModel({
                  page: model.page,
                  pageSize: model.pageSize,
                });
              }}
              disableRowSelectionOnClick
              disableColumnMenu
              checkboxSelection
              showCellVerticalBorder={false}
              sx={{
                border: "none",
                fontSize: "12px",
                wordWrap: "break-word",
                overflow: "visible",
              }}
            />
          ) : (
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <p>No Data Found</p>
            </div>
          ) : (
            <div className="loading">
              <p>Please wait</p>
              <span>
                <i></i>
                <i></i>
              </span>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};
