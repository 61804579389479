
const colorConfigs = {
  sidebar: {
    bg: "#f2f7fa",
    color: "#58707b",
    hoverBg: "#eeeeee",
    activeBg: "#295B7E"
  },
  topbar: {
    bg: "#295B7E",
    color: "#92A2AB"
  },
  jumper: {
    bg: "#295B7E",
  },
  mainBg: "#ffffff"
};

export default colorConfigs;