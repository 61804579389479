import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import baseURL from "../../../../../configs/api";
import AddMaintenanceTypes from "./AddMaintenanceTypes";
import EditMaintenanceTypes from "./EditMaintenanceTypes";

type Props = {
  addMaintenanceTypesPopup: boolean;
  hidePopup: () => void;
};

export default function MaintenanceTypes({
  addMaintenanceTypesPopup,
  hidePopup,
}: Props) {
  const [tableData, setTableData] = useState<any[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [primaryKey, setPrimaryKey] = useState<string>("");
  const [editPopup, setEditPopup] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: "maintenanceName",
      headerName: "Name",
      width: 300,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <p
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setEditPopup(true);
              setPrimaryKey(params.id.toString());
            }}
          >
            {params.value}
          </p>
        );
      },
    },
    {
      field: "isRecurring",
      headerName: "Is Recurring",
      width: 180,
      editable: false,
      sortable: false,
    },
    {
      field: "defaultTotalInParts",
      headerName: "Default Total in Parts",
      width: 180,
      editable: false,
      sortable: false,
    },
    {
      field: "defaultTotalInLabor",
      headerName: "Default Total in Labor",
      width: 180,
      editable: false,
      sortable: false,
    },
    {
      field: "isActive",
      headerName: "Active?",
      width: 180,
      editable: false,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (isSaved) {
      fetch(`${baseURL}maintenanceType`)
        .then((res) => res.json())
        .then((data) => {
          setTableData(
            data.map((item: any) => {
              return {
                ...item,
                isRecurring: item.isRecurring ? "Yes" : "No",
                isActive: item.isActive ? "Yes" : "No",
              };
            })
          );
          setIsDataLoaded(true);
          setIsSaved(false);
        });
    }
  }, [isSaved]);

  return (
    <>
      <Box sx={{ padding: "24px" }}>
        <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
          {isDataLoaded ? (
            <>
              <hr style={{ marginBlock: "0px" }} />
              <DataGrid
                rows={tableData}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                disableRowSelectionOnClick
                disableColumnMenu
                showColumnVerticalBorder
                showCellVerticalBorder={true}
                sx={{
                  border: "none",
                  fontSize: "12px",
                  wordWrap: "break-word",
                  overflow: "visible",
                }}
              />
            </>
          ) : (
            <div className="loading">
              <p>Please wait</p>
              <span>
                <i></i>
                <i></i>
              </span>
            </div>
          )}
        </Box>

        <AddMaintenanceTypes
          popup={addMaintenanceTypesPopup}
          hidePopup={() => hidePopup()}
          isSaved={() => {
            setIsSaved(true);
            hidePopup();
          }}
        />

        <EditMaintenanceTypes
          primaryKey={primaryKey}
          popup={editPopup}
          hidePopup={() => setEditPopup(false)}
          isSaved={() => {
            setIsSaved(true);
            setEditPopup(false);
          }}
        />
      </Box>
    </>
  );
}
