import {
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../../configs/colorConfigs";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AddLabel } from "./AddLabel";
import baseURL from "../../../configs/api";
import axios from "axios";

export const UserRoles = () => {
  const [tableData, setTableData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isAdded, setAdded] = useState(false);
  const [id, setId] = useState<number>();
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);


  useEffect(() => {
    if (!isProcessing && !isDataLoaded) {
      fetch(`${baseURL}roles`)
        .then((res) => res.json())
        .then((data) => {
          setIsDataLoaded(true);
          setTableData(data);
        });
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing]);

  const deletingLinks = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this item?") === true) {
      await axios
        .delete(`${baseURL}roles?id=${id}`)
        .then(() => {
          setTableData(() => tableData.filter((_: any) => _.id !== id));
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const ChangeHandle = async (id: number, roleName: string, roleDescription: string, isActive: boolean) => {
    setId(id);
    setRoleName(roleName);
    setRoleDescription(roleDescription);
    setIsActive(isActive);
  }

  const addNewRow = () => {
    setId(0);
    setRoleName("");
    setRoleDescription("");
    setIsActive(true);
    setAdded(true)
  }

  const saveNewRow = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isProcessing) {
      setIsProcessing(true);
      if (!isAdded) {
        axios
          .put(
            `${baseURL}roles`,
            JSON.stringify({
              id,
              roleName,
              roleDescription,
              isActive,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            setIsProcessing(false);
            setIsDataLoaded(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        await axios
          .post(
            `${baseURL}roles`,
            JSON.stringify({
              roleName,
              roleDescription,
              isActive,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            setAdded(false);
            setIsProcessing(false);
            setIsDataLoaded(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  return (
    <Box>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <AssignmentIndIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Roles
          </Typography>
        </Box>
      </Toolbar>

      <hr />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <div style={{ width: "100%" }}>Label</div>

        <div style={{ width: "40%" }}>Handle</div>

        <div style={{ width: "17%" }}>Active?</div>

        <div style={{ width: "7%" }}>
          <DeleteForeverIcon sx={{ color: "red" }} />
        </div>
      </div>

      <hr style={{ marginLeft: "14px", marginRight: "14px", marginTop: "0" }} />

      {isDataLoaded ? (
        tableData.map((item: any) => (
          <AddLabel
            key={item.id}
            data={item}
            handleDelete={(id) => deletingLinks(id)}
            handleTheChange={(id, roleName, roleDescription, isActive) => ChangeHandle(id, roleName, roleDescription, isActive)} />
        ))
      ) : (
        <div className="loading">
          <p>Please wait</p>
          <span>
            <i></i>
            <i></i>
          </span>
        </div>
      )}

      {isDataLoaded && (
        <form onSubmit={(e) => saveNewRow(e)}>
          {isAdded && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "24px",
                paddingRight: "24px",
                backgroundColor: colorConfigs.sidebar.hoverBg,
              }}
            >
              <div style={{ width: "45%", marginLeft: "10px", marginRight: "10px" }}>
                <input
                  type="text"
                  name="label"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                  required
                />
              </div>

              <div style={{ width: "35%", marginLeft: "10px", marginRight: "10px" }}>
                <input
                  type="text"
                  name="handle"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={roleDescription}
                  onChange={(e) => setRoleDescription(e.target.value)}
                  required
                />
              </div>

              <div style={{ width: "10%", marginLeft: "10px", marginRight: "10px" }}>
                <select
                  name="active"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={isActive ? "Yes" : "No"}
                  onChange={(e) =>
                    setIsActive(e.target.value === "Yes" ? true : false)
                  }
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div style={{ width: "5%" }}>
                <IconButton
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this item?"
                      ) === true
                    )
                      setAdded(false);
                  }}
                >
                  <DeleteForeverIcon sx={{ color: "red" }} />
                </IconButton>
              </div>
            </div>
          )}
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "2",
              alignItems: "center",
              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              paddingLeft: "32px",
            }}
          >

            <Button
              variant="contained"
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                borderRadius: "0",
                textTransform: "capitalize",
                marginRight: "8px",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              onClick={addNewRow}
              disabled={isAdded || isProcessing}
            >
              Add Row
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                borderRadius: "0",
                textTransform: "capitalize",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              type="submit"
            >
              {isProcessing ? "Processing..." : "Save"}
            </Button>
          </div>
        </form>
      )}
    </Box>
  );
};

