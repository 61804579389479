import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import colorConfigs from "../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../configs/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import { AddNewCustomer } from "./AddNewCustomer";

type Props = {
  reservation: any;
  handlePleaseWait: (pleaseWaitPopup: boolean) => void;
  reservationUpdate: () => void;
};

export default function AddDriver({
  reservation,
  handlePleaseWait,
  reservationUpdate,
}: Props) {
  const [customers, setCustomers] = useState<any[]>([]);
  const [customerId, setCustomerId] = useState<string>("");
  const [extraInfoCustomers, setExtraInfoCustomers] = useState<any[]>([]);

  const [newDriverAdded, setNewDriverAdded] = useState<boolean>(true);
  const [addNewDiver, setAddNewDriver] = useState<boolean>(false);
  const [isDrivers, setIsDrivers] = useState<boolean>(true);
  const [drivers, setDrivers] = useState<any[]>([]);
  const [driverId, setDriverId] = useState<string>("0");
  const [isPrimaryDriver, setIsPrimaryDriver] = useState<boolean>(false);

  const [idTypes, setIdTypes] = useState<any[]>([]);

  useEffect(() => {
    axios
      .get(`${baseURL}Customer`)
      .then((response) => {
        setCustomers(
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
              dob: item.birthday,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [customerId]);

  useEffect(() => {
    axios
      .get(`${baseURL}customerExtraInformation`)
      .then((response) => {
        setExtraInfoCustomers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [customerId]);

  useEffect(() => {
    axios
      .get(`${baseURL}IdType`)
      .then((response) => {
        setIdTypes(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (reservation && newDriverAdded) {
      axios
        .get(`${baseURL}reservationAdditionalDriver`)
        .then((response) => {
          setDrivers(response.data);

          setDrivers((drivers) =>
            drivers.filter((item: any) => item.reservationId === reservation?.id)
          );
          setNewDriverAdded(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [reservation, newDriverAdded]);

  const calculateAge = (date: string) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const updateTheDriver = (updateCustomerId: string) => {
    handlePleaseWait(true);
    setCustomerId(updateCustomerId);
    if (driverId === "0") {
      axios
        .post(
          `${baseURL}reservationAdditionalDriver`,
          JSON.stringify({
            reservationId: reservation?.id,
            customerId: updateCustomerId,
            primaryDriver: isPrimaryDriver,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          updateAllDriver(updateCustomerId);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      axios
        .put(
          `${baseURL}reservationAdditionalDriver`,
          JSON.stringify({
            id: driverId,
            reservationId: reservation?.id,
            customerId: updateCustomerId,
            primaryDriver: isPrimaryDriver,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          updateAllDriver(updateCustomerId);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const updateAllDriver = (updateCustomerId: string) => {
    if (isPrimaryDriver) {
      updateReservationCustomer(updateCustomerId);
      drivers.forEach((item: any, index: number) => {
        if (item.customerId !== updateCustomerId) {
          axios
            .put(
              `${baseURL}reservationAdditionalDriver`,
              JSON.stringify({
                id: item.id,
                reservationId: item.reservationId,
                customerId: item.customerId,
                primaryDriver: false,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then((response) => {
              if (drivers.length - 1 === index) {
                handlePleaseWait(false);
              }
              setNewDriverAdded(true);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          if (drivers.length - 1 === index) {
            handlePleaseWait(false);
          }
          setNewDriverAdded(true);
        }
      });
    } else {
      handlePleaseWait(false);
      setNewDriverAdded(true);
    }
  };

  const updateReservationCustomer = (updateCustomerId: String) => {
    axios
      .put(
        `${baseURL}reservation`,
        {
          ...reservation,
          customerId: updateCustomerId,
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        reservationUpdate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <h5
        style={{
          paddingBottom: "0px",
          marginBottom: "0px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setIsDrivers(!isDrivers)}
      >
        {isDrivers ? (
          <ExpandMoreIcon sx={{ fontSize: "20px" }} />
        ) : (
          <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
        )}{" "}
        Drivers
      </h5>
      <hr />

      {isDrivers && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "5%",
                marginBottom: "0px",
              }}
            >
              #
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "30%",
                marginBottom: "0px",
              }}
            >
              Name
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "40%",
                marginBottom: "0px",
              }}
            >
              Driver License
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "5%",
                marginBottom: "0px",
              }}
            >
              Age
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "20%",
                marginBottom: "0px",
              }}
            >
              Primary Driver ?
            </p>
          </div>
          <hr style={{ marginBlock: "0px" }} />

          {drivers.map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "5%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.customerId}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "30%",
                      marginBottom: "0px",
                      color: "blue",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      setCustomerId(item.customerId);
                      setDriverId(item.id);
                      setAddNewDriver(true);
                      setIsPrimaryDriver(item.primaryDriver);
                    }}
                  >
                    {
                      customers.find(
                        (customer: any) => customer.value === item.customerId
                      )?.label
                    }
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "40%",
                      marginBottom: "0px",
                    }}
                  >
                    {idTypes.find(
                      (idType: any) =>
                        idType.id ===
                        extraInfoCustomers.find(
                          (customer: any) =>
                            customer.customerId === item.customerId
                        )?.idTypeId
                    )?.name !== "undefined"
                      ? idTypes.find(
                          (idType: any) =>
                            idType.id ===
                            extraInfoCustomers.find(
                              (customer: any) =>
                                customer.customerId === item.customerId
                            )?.idTypeId
                        )?.name
                      : "" +
                        " " +
                        extraInfoCustomers.find(
                          (customer: any) =>
                            customer.customerId === item.customerId
                        )?.idNumber +
                        " " +
                        extraInfoCustomers
                          .find(
                            (customer: any) =>
                              customer.customerId == item.customerId
                          )
                          ?.exprationDate.replaceAll("-", "/")
                          .split("T")[0]}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "5%",
                      marginBottom: "0px",
                    }}
                  >
                    {calculateAge(
                      customers.find(
                        (customer: any) => customer.value === item.customerId
                      )?.dob
                    )}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "20%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.primaryDriver ? "Yes" : "No"}
                  </p>
                </div>
                <hr style={{ marginBlock: "0px" }} />
              </React.Fragment>
            );
          })}

          <Button
            variant="contained"
            sx={{
              marginBlock: "16px",
              backgroundColor: colorConfigs.topbar.bg,
              color: "white",
              border: "none",
              borderRadius: "0px",
              fontSize: "12px",
              textTransform: "capitalize",
              "&: hover": {
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
              },
            }}
            onClick={() => {
              setAddNewDriver(true);
              setCustomerId("");
              setDriverId("0");
              setIsPrimaryDriver(false);
            }}
          >
            Add Driver
          </Button>
        </>
      )}

      <Dialog
        open={addNewDiver}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "40%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              {customerId ? "Edit Driver" : "Add Driver"}
            </p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => setAddNewDriver(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
            }}
          >
            <div style={{ marginBlock: "10px" }}>
              <label
                htmlFor="localAddress"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginBottom: "0px",
                }}
              >
                Existing Driver
              </label>
              <hr />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "starrt",
                  alignItems: "center",
                  gap: "15px",
                  marginTop: "0px",
                }}
              >
                <select
                  name="branch"
                  style={{
                    width: "50%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={customerId}
                  onChange={(e) => setCustomerId(e.target.value)}
                >
                  <option value={""}></option>
                  {customers.map((item: any) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>

                <p
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => {
                    setCustomerId("");
                  }}
                >
                  or create a new one
                </p>
              </div>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <label
                htmlFor="timeOut"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Primary Driver?
                <br />
                <div>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="isPrimaryDriver"
                      style={{ fontSize: "12px" }}
                      checked={isPrimaryDriver ? true : false}
                      onChange={(e) =>
                        setIsPrimaryDriver(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />{" "}
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="isPrimaryDriver"
                      style={{ fontSize: "12px" }}
                      checked={isPrimaryDriver ? false : true}
                      onChange={(e) =>
                        setIsPrimaryDriver(
                          e.target.value === "Yes" ? true : false
                        )
                      }
                    />{" "}
                    No
                  </label>
                </div>
              </label>
            </div>

            <AddNewCustomer
              handleNewCustomer={(updateCustomerId) =>
                updateTheDriver(updateCustomerId)
              }
              closeNewCustomer={() => setAddNewDriver(false)}
              customerId={customerId}
              isFromDriver={true}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
