import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../configs/colorConfigs";
import { RouteType } from "../../routes/config";
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";


type Props = {
  item: RouteType,
};

const SidebarItemCollapse = ({ item }: Props) => {
  const [open, setOpen] = useState(false);

  const { appState } = useSelector((state: RootState) => state.appState);

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true);
    }
  }, [appState, item]);

  return (
    item.sidebarProps ? (
      <>
        <ListItemButton
          onClick={() => setOpen(!open)}
          sx={{
            "&: hover": {
              backgroundColor: appState === item.state ? colorConfigs.sidebar.activeBg : colorConfigs.sidebar.hoverBg
            },
            backgroundColor: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
            paddingY: "5px",
            paddingX: "24px",
            fontSize: "14px",
          }}
        >
          <ListItemIcon sx={{
            color: appState === item.state ? "#FFFFFF" : colorConfigs.sidebar.color,
          }}>
            {item.sidebarProps.icon && item.sidebarProps.icon}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography fontSize={12}>
                {item.sidebarProps.displayText}
              </Typography>
            }
          />
          {open ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
        </ListItemButton>

        
        <Collapse in={open} timeout="auto">
          <List>
            {item.child?.map((route, index) => (
              route.sidebarProps && 
                route.child ? (
                  <SidebarItemCollapse item={route} key={index} />
                ) : (
                  <SidebarItem item={route} key={index} />
                )
            ))}
          </List>
        </Collapse>
      </>
    ) : null
  );
};

export default SidebarItemCollapse;