import axios from "axios";
import React, { useEffect, useState } from "react";
import baseURL from "../../../configs/api";
import { format } from "date-fns";
import { Button } from "@mui/material";
import DateInput from "../../../components/common/DateInput";
import TimeInput from "../../../components/common/TimeInput";
import PleaseWaitPopup from "../../PleaseWaitPopup";
import colorConfigs from "../../../configs/colorConfigs";

export const BlockedDays = () => {
  const [label, setLabel] = useState<string>("");
  const [color, setColor] = useState("#000000");
  const [startDate, setStartDate] = useState<string>("");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [isAllDay, setIsAllDay] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);
  const [timeSlot, setTimeSlot] = useState<string>("09-10");
  const [endRepeat, setEndRepeat] = useState<string>("After");
  const [tableData, setTableData] = useState<any[]>([]);
  const [previewRow, setPreviewRow] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(true);
  const [deleteItem, setDeleteItem] = useState<string[]>([]);
  const [deleteAllItem, setAllDeleteItem] = useState<boolean>(false);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [id, setId] = useState<string>("");
  const [repeatOccur, setRepeatOccur] = useState<string>("Every Year");
  const [afterOccurrences, setAfterOccurrences] = useState<string>("");
  const [ondate, setOndate] = useState<string>("");
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(true);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (id) {
      await axios
        .put(
          `${baseURL}blockedDay`,
          JSON.stringify({
            id,
            name: label,
            color,
            startDate,
            isAllDay,
            timeSlot: isAllDay ? "" : timeSlot,
            startTime: isAllDay ? "" : startTime,
            endTIme: isAllDay ? "" : endTime,
            repeat: isRepeat,
            repeatOccur: isRepeat ? repeatOccur : "",
            endrepeat: endRepeat,
            afterOccurrences: isRepeat ? afterOccurrences : "",
            ondate: isRepeat ? ondate : "",
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          setId("");
          setLabel("");
          setColor("#000000");
          setStartDate("");
          setStartTime("");
          setEndTime("");
          setIsAllDay(false);
          setIsRepeat(false);
          setTimeSlot("");
          setRepeatOccur("Every Year");
          setEndRepeat("");
          setAfterOccurrences("After");
          setOndate("");
          setIsChanged(true);
          setAllDeleteItem(false);
          setPreviewRow(false);
          setPleaseWaitPopup(true);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      await axios
        .post(
          `${baseURL}blockedDay`,
          JSON.stringify({
            name: label,
            color,
            startDate,
            isAllDay,
            timeSlot: isAllDay ? "" : timeSlot,
            startTime: isAllDay ? "" : startTime,
            endTIme: isAllDay ? "" : endTime,
            repeat: isRepeat,
            repeatOccur: repeatOccur,
            endrepeat: endRepeat,
            afterOccurrences: isRepeat ? afterOccurrences : "",
            ondate: isRepeat ? ondate : "",
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          setLabel("");
          setColor("#000000");
          setStartDate("");
          setStartTime("");
          setEndTime("");
          setIsAllDay(false);
          setIsRepeat(false);
          setTimeSlot("");
          setRepeatOccur("Every Year");
          setEndRepeat("");
          setAfterOccurrences("After");
          setOndate("");
          setIsChanged(true);
          setAllDeleteItem(false);
          setPreviewRow(false);
          setPleaseWaitPopup(true);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  useEffect(() => {
    if (isChanged) {
      axios
        .get(`${baseURL}blockedDay`)
        .then((response) => {
          setTableData(() => response.data);
          setIsChanged(false);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [isChanged]);

  useEffect(() => {
    if (!firstTime) {
      if (!deleteAllItem) {
        setDeleteItem([]);
      } else {
        setDeleteItem(() =>
          tableData.map((item: any) => {
            return item.id;
          })
        );
      }
    }

    setFirstTime(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAllItem]);

  const deleteSelectedItem = async () => {
    if (
      window.confirm("Are you sure you want to delete this Blocked Day?") ===
      true
    ) {
      deleteItem.forEach((item: string) => {
        axios
          .delete(`${baseURL}blockedDay?id=${item}`)
          .then(() => {
            setIsChanged(true);
            setAllDeleteItem(false);
            setPleaseWaitPopup(true);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
    }
  };

  const editTheData = (primaryKey: string) => {
    setId(primaryKey);

    axios
      .get(`${baseURL}blockedDay/${primaryKey}`)
      .then((response) => {
        setLabel(response.data.name);
        setColor(response.data.color);
        setStartDate(response.data.startDate.split("T")[0]);
        setIsAllDay(response.data.isAllDay);
        setTimeSlot(response.data.timeSlot);
        setStartTime(response.data.startTime);
        setEndTime(response.data.endTIme);
        setIsRepeat(response.data.repeat);
        setEndRepeat(response.data.endRepeat);
        setRepeatOccur(response.data.repeatOccur);
        setAfterOccurrences(response.data.afterOccurrences);
        setOndate(response.data.ondate.split("T")[0]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onCancel = () => {
    setId("");
    setLabel("");
    setColor("#000000");
    setStartDate("");
    setStartTime("");
    setEndTime("");
    setIsAllDay(false);
    setIsRepeat(false);
    setTimeSlot("");
    setRepeatOccur("Every Year");
    setEndRepeat("");
    setAfterOccurrences("After");
    setOndate("");
    setAllDeleteItem(false);
  };

  return (
    <>
      <div style={{ padding: "10px 20px 15px 30px" }}>
        <div style={{ padding: "9px" }}>Blocked Days</div>
        <hr style={{ marginTop: "0px" }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingTop: "10px",
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          <div style={{ width: "40%", marginRight: "3%" }}>
            <h2
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: "14px",
                marginBlock: "0px",
              }}
            >
              {id ? "Edit" : "Add New"} Scheduling
            </h2>
            <hr />

            <form
              onSubmit={(e) => handleFormSubmit(e)}
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <label
                  htmlFor="label"
                  style={{ fontSize: "10px", fontWeight: "bold" }}
                >
                  Label
                  <input
                    type="text"
                    id="label"
                    value={label}
                    onChange={(event) => setLabel(event.target.value)}
                    style={{
                      width: "100%",
                      fontSize: "12px",
                      paddingBlock: "3px",
                    }}
                    required
                  />
                </label>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="startDate"
                    style={{ fontSize: "10px", fontWeight: "bold" }}
                  >
                    Start Date
                    <DateInput
                      name={"startDate"}
                      value={startDate}
                      setValue={setStartDate}
                      required={true} />
                  </label>
                </div>

                {!isAllDay && (
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="timeSlots"
                      style={{ fontSize: "10px", fontWeight: "bold" }}
                    >
                      Time Slot
                      <select
                        name="cars"
                        id="cars"
                        style={{
                          width: "100%",
                          fontSize: "12px",
                          paddingBlock: "3px",
                        }}
                        value={timeSlot}
                        onChange={(e) => setTimeSlot(e.target.value)}
                      >
                        <option value="09-10">09-10</option>
                        <option value="10-11">10-11</option>
                        <option value="11-12">11-12</option>
                        <option value="12-13">12-13</option>
                        <option value="13-14">13-14</option>
                        <option value="14-15">14-15</option>
                        <option value="15-16">15-16</option>
                        <option value="16-17">16-17</option>
                        <option value="17-18">17-18</option>
                        <option value="18-19">18-19</option>
                        <option value="19-20">19-20</option>
                      </select>
                    </label>
                  </div>
                )}

                {!isAllDay && (
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="startTime"
                      style={{ fontSize: "10px", fontWeight: "bold" }}
                    >
                      Start Time


                      <TimeInput
                        name={"startTime"}
                        value={startTime}
                        setValue={setStartTime}
                        required={true} />
                    </label>
                  </div>
                )}

                <div style={{ marginTop: "8px" }}>
                  <label style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Repeat?
                  </label>
                  <div>
                    <label
                      htmlFor="repeatYes"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <input
                        type="radio"
                        id="repeatYes"
                        value="Yes"
                        checked={isRepeat ? true : false}
                        onChange={() => setIsRepeat(true)}
                      />
                      Yes
                    </label>
                    <label
                      htmlFor="repeatNo"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <input
                        type="radio"
                        id="repeatNo"
                        value="No"
                        checked={isRepeat ? false : true}
                        onChange={() => setIsRepeat(false)}
                      />
                      No
                    </label>
                  </div>
                </div>

                {isRepeat && (
                  <>
                    <div style={{ marginTop: "7px" }}>
                      <label
                        htmlFor="repeats"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        Repeats
                        <select
                          name="cars"
                          id="cars"
                          style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingBlock: "3px",
                          }}
                          value={repeatOccur}
                          onChange={(e) => setRepeatOccur(e.target.value)}
                        >
                          <option value="Every Year">Every Year</option>
                          <option value="Every Month">Every Month</option>
                          <option value="Every Week">Every Week</option>
                          <option value="Every Day">Every Day</option>
                        </select>
                      </label>
                    </div>

                    <div style={{ marginTop: "7px" }}>
                      <label
                        htmlFor="endrepeat"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        End Repeat
                        <select
                          name="cars"
                          id="cars"
                          style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingBlock: "3px",
                          }}
                          value={endRepeat}
                          onChange={(e) => setEndRepeat(e.target.value)}
                        >
                          <option value="After">After</option>
                          <option value="On Date">On Date</option>
                        </select>
                      </label>
                    </div>
                  </>
                )}

                <div style={{ marginTop: "16px" }}>
                  {id ? (
                    <Button
                      variant="outlined"
                      sx={{
                        marginRight: "10px",
                        backgroundColor: "#E0DEE0",
                        border: "none",
                        color: "black",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        "&: hover": {
                          backgroundColor: "#E0DEE0",
                          color: "black",
                          border: "none",
                        },
                      }}
                      onClick={() => onCancel()}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{
                        marginRight: "10px",
                        backgroundColor: "#E0DEE0",
                        border: "none",
                        color: "black",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        "&: hover": {
                          backgroundColor: "#E0DEE0",
                          color: "black",
                          border: "none",
                        },
                      }}
                      onClick={() => setPreviewRow(!previewRow)}
                    >
                      Preview
                    </Button>
                  )}

                  <Button
                    variant="outlined"
                    sx={{
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                      border: "none",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      "&: hover": {
                        backgroundColor: colorConfigs.topbar.bg,
                        color: "white",
                        border: "none",
                      },
                    }}
                    type="submit"
                  >
                    {id ? "Update" : "Save"}
                  </Button>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div>
                  <label
                    htmlFor="color"
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                      marginRight: "2px",
                    }}
                  >
                    Color
                  </label>
                  <input
                    type="color"
                    id="color"
                    style={{
                      width: "30px",
                      fontSize: "12px",
                      paddingBlock: "3px",
                    }}
                    value={color}
                    onChange={(event) => setColor(event.target.value)}
                  />
                </div>

                <div style={{ marginTop: "30px" }}>
                  <label style={{ fontSize: "10px", fontWeight: "bold" }}>
                    All Day?
                  </label>
                  <div>
                    <label
                      htmlFor="allDayYes"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <input
                        type="radio"
                        id="allDayYes"
                        value="yes"
                        checked={isAllDay ? true : false}
                        onChange={() => setIsAllDay(true)}
                      />
                      Yes
                    </label>
                    <label
                      htmlFor="allDayNo"
                      style={{ fontSize: "10px", fontWeight: "bold" }}
                    >
                      <input
                        type="radio"
                        id="allDayNo"
                        value="no"
                        checked={isAllDay ? false : true}
                        onChange={() => setIsAllDay(false)}
                      />
                      No
                    </label>
                  </div>
                </div>
                {!isAllDay && (
                  <>
                    <div style={{ visibility: "hidden", marginTop: "10px" }}>
                      <label
                        htmlFor="startDate"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        Start Date

                        <DateInput
                          name={"startDate"}
                          value={startDate}
                          setValue={setStartDate}
                          required={true} />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="endTime"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        End Time
                        <TimeInput
                          name={"endTime"}
                          value={endTime}
                          setValue={setEndTime}
                          required={true} />
                      </label>
                    </div>
                  </>
                )}

                <div style={{ marginTop: "8px", visibility: "hidden" }}>
                  <label style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Repeat?
                  </label>
                  <div>
                    <label
                      htmlFor="repeatYes"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <input type="radio" id="repeatYes" value="Yes" />
                      Yes
                    </label>
                    <label
                      htmlFor="repeatNo"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <input type="radio" id="repeatNo" value="No" />
                      No
                    </label>
                  </div>
                </div>

                {isRepeat && (
                  <>
                    <div style={{ marginTop: "7px", visibility: "hidden" }}>
                      <label
                        htmlFor="repeats"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        Repeats
                        <select
                          name="cars"
                          id="cars"
                          style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingBlock: "3px",
                          }}
                        >
                          <option value="Every Year">Every Year</option>
                          <option value="Every Month">Every Month</option>
                          <option value="Every Week">Every Week</option>
                          <option value="Every Day">Every Day</option>
                        </select>
                      </label>
                    </div>

                    {endRepeat === "After" ? (
                      <div style={{ marginTop: "7px" }}>
                        <label
                          htmlFor="onDates"
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          After Occurrences
                          <input
                            type="text"
                            id="onDates"
                            style={{
                              width: "100%",
                              fontSize: "12px",
                              paddingBlock: "3px",
                            }}
                            value={afterOccurrences}
                            onChange={(e) => setAfterOccurrences(e.target.value)}
                          />
                        </label>
                      </div>
                    ) : (
                      <div style={{ marginTop: "7px" }}>
                        <label
                          htmlFor="onDates"
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          On Dates
                          <DateInput
                            name={"onDates"}
                            value={ondate}
                            setValue={setOndate} />
                        </label>
                      </div>
                    )}
                  </>
                )}
              </div>
            </form>
          </div>
          <div style={{ width: "60%" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2 style={{ fontSize: "14px", marginBlock: "0px" }}>
                  Blocked Dates
                </h2>
                <p
                  style={{
                    fontSize: "12px",
                    marginBlock: "0px",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteSelectedItem()}
                >
                  Delete Selected
                </p>
              </div>

              <hr />
              <table>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={deleteAllItem}
                        onChange={() => setAllDeleteItem(() => !deleteAllItem)}
                      />
                    </th>
                    <th>Label</th>
                    <th>Color</th>
                    <th>Starts At</th>
                    <th>Ends At</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item: any) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={
                              deleteItem.find(
                                (deleted: string) => deleted === item.id
                              )
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              const findId = deleteItem.find(
                                (id: string) => id === item.id
                              );
                              if (findId) {
                                const newDeleteItem: any[] = deleteItem
                                  .map((deleteId: string) => {
                                    if (deleteId === item.id) {
                                      return "";
                                    } else {
                                      return deleteId;
                                    }
                                  })
                                  .filter((id: string) => id !== "");

                                setDeleteItem(() => newDeleteItem);
                              } else {
                                setDeleteItem((prevItem: string[]) => [
                                  ...prevItem,
                                  item.id,
                                ]);
                              }
                            }}
                          />
                        </td>
                        <td>{item.name}</td>
                        <td><div style={{ backgroundColor: item.color, padding: "10px 8px", borderRadius: "2px" }} /></td>
                        <td
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => editTheData(item.id)}
                        >
                          {format(
                            new Date(
                              item.startTime
                                ? item.startDate.split("T")[0] +
                                "T" +
                                item.startTime
                                : new Date(item.startDate)
                            ),
                            "MM/dd/yyyy KK:mm a"
                          )}
                        </td>
                        <td>
                          {format(
                            new Date(
                              item.endTIme
                                ? item.startDate.split("T")[0] +
                                "T" +
                                item.endTIme
                                : new Date(item.startDate)
                            ),
                            "MM/dd/yyyy KK:mm a"
                          )}
                        </td>
                        <td>Closed</td>
                      </tr>
                    );
                  })}

                  {previewRow && (
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>{label}</td>
                      <td><div style={{ backgroundColor: color, padding: "10px 8px", borderRadius: "2px" }} /></td>
                      <td>
                        {startTime &&
                          format(
                            startTime
                              ? new Date(startDate + "T" + startTime)
                              : new Date(startDate),
                            "MM/dd/yyyy KK:mm a"
                          )}
                      </td>
                      <td>
                        {endTime &&
                          format(
                            endTime
                              ? new Date(startDate + "T" + endTime)
                              : new Date(startDate),
                            "MM/dd/yyyy KK:mm a"
                          )}
                      </td>
                      <td>Closed</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
    </>
  );
};
