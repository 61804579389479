import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../configs/api";
import { saveAs } from "file-saver";
import { format } from "date-fns";

type Props = {
  handlePopup: () => void;
  showPopup: boolean;
  fileName: FileList | null;
  fileSize: number;
  uploadedAt: string;
  uploadedBy: string;
  setFileName: (name: string) => void;
  filePath: string;
  saved: boolean;
};

export default function ImagePopup({
  showPopup,
  handlePopup,
  fileName,
  fileSize,
  uploadedAt,
  uploadedBy,
  setFileName,
  filePath,
  saved,
}: Props) {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isSaved, setSaved] = useState<boolean>(saved);
  const [newFileName, setNewFileName] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const saveTheFile = () => {
    if (fileName) {
      if (!isProcessing) {
        setIsProcessing(true);
        const formData = new FormData();
        formData.append("ImageFile", fileName[0]);

        axios
          .post(`${baseURL}customerFile/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          })
          .then((response) => {
            setSaved(true);
            setFileName(response.data);
            setNewFileName(response.data);
            handlePopup();
            setIsProcessing(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  const downloadTheImage = () => {
    saveAs(
      `${baseURL}customerFile/download?name=${!saved ? newFileName : filePath}`,
      `${!saved ? newFileName : filePath}.png`
    );
  };

  return (
    <Dialog
      open={showPopup}
      maxWidth="md"
      fullScreen={isFullScreen}
      PaperProps={{
        style: {
          width: isFullScreen ? "" : "35%",
          marginTop: isFullScreen ? "" : "-80px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", width: "100%" }}>
        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 16px 8px 16px",
          }}
        >
          <p style={{ fontSize: "14px", marginLeft: "5px" }}>
            {!saved ? fileName && fileName[0].name : filePath}
          </p>
          <CloseIcon
            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
            onClick={() => {
              setIsFullScreen(false);
              handlePopup();
            }}
          />
        </div>
        <hr style={{ marginBlock: "0px" }} />
        <div
          style={{
            width: "100%",
            height: isFullScreen ? "28%" : "5%",
            backgroundColor: colorConfigs.sidebar.bg,
            paddingLeft: "8px",
            fontSize: "14px",
          }}
        >
          Preview
        </div>
        <hr style={{ marginBlock: "0px" }} />
      </DialogTitle>
      <DialogContent sx={{ padding: "0px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "16px",
            gap: "2px"
          }}
        >
          <div style={{ width: "50%" }}>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                marginBlock: "0px",
                fontWeight: "bold",
              }}
            >
              Filename
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                marginBlock: "0px",
              }}
            >
              {!saved ? fileName && fileName[0].name : filePath}
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                marginBottom: "0px",
                fontWeight: "bold",
              }}
            >
              Uploaded At
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                marginBlock: "0px",
              }}
            >
              {uploadedAt !== null ? format(new Date(uploadedAt), "MM/dd/yyyy hh:mm a"): ""}
            </p>
          </div>

          <div style={{ width: "50%" }}>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                marginBlock: "0px",
                fontWeight: "bold",
              }}
            >
              Size
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                marginBlock: "0px",
              }}
            >
              {fileSize === 0 ? 0 : (fileSize / 1024).toFixed(2)} KB
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                marginBottom: "0px",
                fontWeight: "bold",
              }}
            >
              Uploaded By
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                marginBlock: "0px",
              }}
            >
              {uploadedBy !== null ? uploadedBy : "N/A"}
            </p>
          </div>
        </div>

        {fileName ? (
          <img
            src={
              !isSaved
                ? fileName && URL.createObjectURL(fileName[0])
                : `${baseURL}/customerFile/Get?name=${filePath}`
            }
            style={{
              width: "100%",
              height: isFullScreen ? "calc(100% - 175px)" : "300px",
              padding: "16px",
            }}
          />
        ) : (
          <img
            src={!isSaved ? "" : `${baseURL}/customerFile/Get?name=${filePath}`}
            style={{
              width: "100%",
              height: isFullScreen ? "calc(100% - 175px)" : "300px",
              padding: "16px",
            }}
          />
        )}

        <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBlock: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
            backgroundColor: "#eeeeee",
          }}
        >
          <div style={{ width: "50%" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#e0dee0",
                color: colorConfigs.sidebar.color,
                borderRadius: "0",
                border: "none",
                textTransform: "capitalize",
                fontSize: "12px",
                "&: hover": {
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                },
              }}
              onClick={() => setIsFullScreen(!isFullScreen)}
            >
              {isFullScreen && "Exit "} Full Screen
            </Button>
          </div>

          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {isSaved ? (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#66BB6A",
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#66BB6A",
                    color: "white",
                  },
                }}
                onClick={() => downloadTheImage()}
              >
                Download
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => saveTheFile()}
              >
                {isProcessing ? "Processing..." : "Save"}
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
