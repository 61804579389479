import React, { useState, useEffect } from "react";
import baseURL from "../../configs/api";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const QuickLinks = () => {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    fetch(`${baseURL}QuickLinks`)
      .then((response) => response.json())
      .then((data) => setLinks(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div
      style={{
        margin: "10px",
        border: "2px solid #f2f7fa",
        borderRadius: "5px",
        paddingBottom: "8px",
      }}
    >
      <table>
        <thead>
          <tr>
            <th
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "normal",
                padding: "8px 16px",
                backgroundColor: "#f2f7fa",
                borderColor: "#f2f7fa",
              }}
            >
              Quick Links
            </th>
          </tr>
        </thead>
        <tbody>
          {links.map((item: any, index: number) => (
            <tr key={index}>
              <td
                style={{
                  border: "none",
                  paddingBlock: "2px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  marginBlock: "4px",
                  backgroundColor: "white",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "normal"
                }}
              >
                <ArrowCircleRightIcon
                  style={{
                    fontSize: "16px",
                    marginRight: "2px",
                    color: "#404040",
                  }}
                />
                <a
                  href={"https://" + item.link}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "blue" }}
                >
                  {item.label}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuickLinks;
