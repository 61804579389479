export const iconValues = [
    {
        value: "baguette",
        label: "Baguette",
        icon: "bx-baguette.svg",
    },
    {
        value: "bowlHot",
        label: "Bowl Hot",
        icon: "bx-bowl-hot.svg",
    },
    {
        value: "bowlRice",
        label: "Bowl Rice",
        icon: "bx-bowl-rice.svg",
    },
    {
        value: "cableCar",
        label: "Cable Car",
        icon: "bx-cable-car.svg",
    },
    {
        value: "candles",
        label: "Candles",
        icon: "bx-candles.svg",
    },
    {
        value: "cartAdd",
        label: "Cart Add",
        icon: "bx-cart-add.svg",
    },
    {
        value: "cartDownload",
        label: "Cart Download",
        icon: "bx-cart-download.svg",
    },
    {
        value: "cheese",
        label: "Cheese",
        icon: "bx-cheese.svg",
    },
    {
        value: "child",
        label: "Child",
        icon: "bx-child.svg",
      },
      {
        value: "circleHalf",
        label: "Circle Half",
        icon: "bx-circle-half.svg",
      },
      {
        value: "circleQuarter",
        label: "Circle Quarter",
        icon: "bx-circle-quarter.svg",
      },
      {
        value: "circleThreeQuarter",
        label: "Circle Three Quarter",
        icon: "bx-circle-three-quarter.svg",
      },
      {
        value: "collapseAlt",
        label: "Collapse Alt",
        icon: "bx-collapse-alt.svg",
      },
      {
        value: "collapseAlt",
        label: "Collapse Alt",
        icon: "bx-collapse-alt.svg",
      },
      {
        value: "collapseHorizontal",
        label: "Collapse Horizontal",
        icon: "bx-collapse-horizontal.svg",
      },
      {
        value: "collapseVertical",
        label: "Collapse Vertical",
        icon: "bx-collapse-vertical.svg",
      },
      {
        value: "color",
        label: "color",
        icon: "bx-color.svg",
      },
      {
        value: "cricketBall",
        label: "Cricket Ball",
        icon: "bx-cricket-ball.svg",
      },
      {
        value: "cross",
        label: "Cross",
        icon: "bx-cross.svg",
      },
      {
        value: "expandHorizontal",
        label: "Expand Horizontal",
        icon: "bx-expand-horizontal.svg",
      },
      {
        value: "expandVertical",
        label: "Expand Vertical",
        icon: "bx-expand-vertical.svg",
      },
      {
        value: "fork",
        label: "Fork",
        icon: "bx-fork.svg",
      },
      {
        value: "hardHat",
        label: "Hard Hat",
        icon: "bx-hard-hat.svg",
      },
      {
        value: "homeAlt",
        label: "Home Alt",
        icon: "bx-home-alt-2.svg",
      }, 
      {
        value: "horizontalLeft",
        label: "Horizontal Left",
        icon: "bx-horizontal-left.svg",
      },
      {
        value: "horizontalRight",
        label: "Horizontal Right",
        icon: "bx-horizontal-right.svg",
      },
      {
        value: "Injection",
        label: "injection",
        icon: "bx-injection.svg",
      },
      {
        value: "knife",
        label: "Knife",
        icon: "bx-knife.svg",
      },
      {
        value: "leaf",
        label: "leaf",
        icon: "bx-leaf.svg",
      },
      {
        value: "lemon",
        label: "Lemon",
        icon: "bx-lemon.svg",
      },
      {
        value: "maleFemale",
        label: "Male Female",
        icon: "bx-male-female.svg",
      },
      {
        value: "math",
        label: "Math",
        icon: "bx-math.svg",
      },
      {
        value: "moneyWithdraw",
        label: "Money Withdraw",
        icon: "bx-money-withdraw.svg",
      },
      {
        value: "noSignal",
        label: "No Signal",
        icon: "bx-no-signal.svg",
      },
      {
        value: "objectsHorizontalCenter",
        label: "Objects Horizontal Center",
        icon: "bx-objects-horizontal-center.svg",
      },
      {
        value: "objectsHorizontalLeft",
        label: "Objects Horizontal Left",
        icon: "bx-objects-horizontal-left.svg",
      },
      {
        value: "objectsHorizontalRight",
        label: "Objects Horizontal Right",
        icon: "bx-objects-horizontal-right.svg",
      },
      {
        value: "objectsVerticalBottom",
        label: "Objects Vertical Bottom",
        icon: "bx-objects-vertical-bottom.svg",
      },
      {
        value: "objectsVerticalCenter",
        label: "Objects Vertical Center",
        icon: "bx-objects-vertical-center.svg",
      },
      {
        value: "objectsVerticalTop",
        label: "Objects Vertical Top",
        icon: "bx-objects-vertical-top.svg",
      },
      {
        value: "party",
        label: "party",
        icon: "bx-party.svg",
      },
      {
        value: "popsicle",
        label: "Popsicle",
        icon: "bx-popsicle.svg",
      },
      {
        value: "reflectHorizontal",
        label: "Reflect Horizontal",
        icon: "bx-reflect-horizontal.svg",
      },
      {
        value: "reflectVertical",
        label: "Reflect Vertical",
        icon: "bx-reflect-vertical.svg",
      },
      {
        value: "rfid",
        label: "Rfid",
        icon: "bx-rfid.svg",
      },
      {
        value: "scatterChart",
        label: "Scatter Chart",
        icon: "bx-scatter-chart.svg",
      },
      {
        value: "shieldMinus",
        label: "Shield Minus",
        icon: "bx-shield-minus.svg",
      },
      {
        value: "shieldPlus",
        label: "Shield Plus",
        icon: "bx-shield-plus.svg",
      },
      {
        value: "shower",
        label: "shower",
        icon: "bx-shower.svg",
      },
      {
        value: "Signal",
        label: "signal",
        icon: "bx-signal-1.svg",
      },
      {
        value: "sushi",
        label: "Sushi",
        icon: "bx-sushi.svg",
      },
      {
        value: "universalAccess",
        label: "Universal Access",
        icon: "bx-universal-access.svg",
      },
      {
        value: "verticalBottom",
        label: "verticalBottom",
        icon: "bx-vertical-bottom.svg",
      },
      {
        value: "verticalTop",
        label: "Vertical Top",
        icon: "bx-vertical-top.svg",
      },
      {
        value: "deezer",
        label: "Deezer",
        icon: "bxl-deezer.svg",
      },
      {
        value: "gmail",
        label: "Gmail",
        icon: "bxl-gmail.svg",
      },
      {
        value: "graphql",
        label: "Graphql",
        icon: "bxl-graphql.svg",
      },
      {
        value: "meta",
        label: "Meta",
        icon: "bxl-meta.svg",
      },
      {
        value: "mongodb",
        label: "Mongodb",
        icon: "bxl-mongodb.svg",
      },
      {
        value: "netlify",
        label: "Netlify",
        icon: "bxl-netlify.svg",
      },
      {
        value: "postgresql",
        label: "Postgresql",
        icon: "bxl-postgresql.svg",
      },
      {
        value: "upwork",
        label: "Upwork",
        icon: "bxl-upwork.svg",
      },
      {
        value: "xing",
        label: "Xing",
        icon: "bxl-xing.svg",
      },
      {
        value: "sbaguette",
        label: "Baguette",
        icon: "bxs-baguette.svg",
      },
      {
        value: "balloon",
        label: "Balloon",
        icon: "bxs-balloon.svg",
      },
      {
        value: "bowlHot",
        label: "Bowl Hot",
        icon: "bxs-bowl-hot.svg",
      },
      {
        value: "bowlRice",
        label: "Bowl Rice",
        icon: "bxs-bowl-rice.svg",
      },
      {
        value: "scableCar",
        label: "Cable Car",
        icon: "bxs-cable-car.svg",
      },
      {
        value: "castle",
        label: "Castle",
        icon: "bxs-bowl-hot.svg",
      },
      {
        value: "scat",
        label: "Cat",
        icon: "bxs-cat.svg",
      },
      {
        value: "scheese",
        label: "Cheese",
        icon: "bxs-cheese.svg",
      },
      {
        value: "scircleHalf",
        label: "Circle Half",
        icon: "bxs-circle-half.svg",
      },
      {
        value: "scircleQuarter",
        label: "Circle Quarter",
        icon: "bxs-circle-quarter.svg",
      },
      {
        value: "scircleThreeQuarter",
        label: "Circle Three Quarter",
        icon: "bxs-circle-three-quarter.svg",
      },
      {
        value: "coffeBean",
        label: "Coffee Bean",
        icon: "bxs-coffee-bean.svg",
      },
      {
        value: "scolor",
        label: "color",
        icon: "bxs-color.svg",
      },
      {
        value: "scricketBall",
        label: "Cricket Ball",
        icon: "bxs-cricket-ball.svg",
      },
      {
        value: "dog",
        label: "Dog",
        icon: "bxs-dog.svg",
      },
      {
        value: "invader",
        label: "Invader",
        icon: "bxs-invader.svg",
      },
      {
        value: "sleaf",
        label: "Leaf",
        icon: "bxs-leaf.svg",
      },
      {
        value: "lemon",
        label: "Lemon",
        icon: "bxs-lemon.svg",
      },
      {
        value: "sparty",
        label: "Party",
        icon: "bxs-party.svg",
      },
      {
        value: "pear",
        label: "Pear",
        icon: "bxs-pear.svg",
      },
      {
        value: "treeAlt",
        label: "Tree Alt",
        icon: "bxs-tree-alt.svg",
      }
];
