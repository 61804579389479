import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import colorConfigs from "../../../../configs/colorConfigs";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../../configs/api";
import SecurityIcon from "@mui/icons-material/Security";
import MenuIcon from "@mui/icons-material/Menu";

const pages = ["Edit"];

export default function EditSecurityDeposits() {
  const id = useLocation().state;

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const [firstTime, setFirstTime] = React.useState<String>(pages[0]);

  const [name, setName] = useState<string>("");
  const [reservationType, setReservationType] = useState<string>("");
  const [withBranch, setWithBranch] = useState<boolean>(false);
  const [branchId, setBranchId] = useState<string>("");
  const [withAdditionalCharge, setWithAdditionalCharge] =
    useState<boolean>(false);
  const [additionalChargeId, setAdditionalChargeId] = useState<string>("");
  const [withVehicleClass, setWithVehicleClass] = useState<boolean>(false);
  const [vehicleClassId, setVehicleClassId] = useState<string>("");
  const [withPickupLocation, setWithPickupLocation] = useState<boolean>(false);
  const [pickupLocationId, setPickupLocationId] = useState<string>("");
  const [withReturnLocation, setWithReturnLocation] = useState<boolean>(false);
  const [returnLocationId, setReturnLocationId] = useState<string>("");
  const [cumulative, setCumulative] = useState<boolean>(false);
  const [includeQuantities, setIncludeQuantities] = useState<boolean>(false);
  const [depositAmount, setDepositAmount] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [effectiveFrom, setEffectiveFrom] = useState<string>("");
  const [effectiveUntil, setEffectiveUntil] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [label, setLabel] = useState<string>("");

  const [branches, setBranches] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then((response) => {
        setBranches(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.branchName };
          })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [additionalOptions, setAdditionalOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}additionalCharge`)
      .then((response) => {
        setAdditionalOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [locationOptions, setLocationOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}vehicleClass`)
      .then((response) => {
        setVehicleClassesOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [vehicleClassesOptions, setVehicleClassesOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}location`)
      .then((response) => {
        setLocationOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.locationName };
          })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL}securityDeposit/${id}`)
      .then((response) => {
        setName(response.data.name);
        setReservationType(response.data.reservationType);
        setWithBranch(response.data.withBranch);
        setBranchId(response.data.branchId);
        setWithAdditionalCharge(response.data.withAdditionalCharge);
        setAdditionalChargeId(response.data.additionalChargeId);
        setWithVehicleClass(response.data.withVehicleClass);
        setVehicleClassId(response.data.vehicleClassId);
        setWithPickupLocation(response.data.withPickupLocation);
        setPickupLocationId(response.data.pickupLocationId);
        setCumulative(response.data.cumulative);
        setIncludeQuantities(response.data.includeQuantities);
        setDepositAmount(response.data.depositAmount);
        setCurrency(response.data.currency);
        setEffectiveFrom(response.data.effectiveFrom.split("T")[0]);
        setEffectiveUntil(response.data.effectiveUntil.split("T")[0]);
        setIsActive(response.data.isActive);
        setLabel(response.data.label);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .put(
        `${baseURL}securityDeposit`,
        JSON.stringify({
          id,
          name,
          reservationType,
          withBranch,
          branchId,
          withAdditionalCharge,
          additionalChargeId,
          withVehicleClass,
          vehicleClassId,
          withPickupLocation,
          pickupLocationId,
          withReturnLocation,
          returnLocationId,
          cumulative,
          includeQuantities,
          depositAmount,
          currency,
          effectiveFrom,
          effectiveUntil,
          isActive,
          label,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  const deleteTheItem = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this security deposit?"
      ) === true
    ) {
      await axios
        .delete(`${baseURL}securityDeposit?id=${id}`)
        .then((response) => {
          // console.log(response);
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <SecurityIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Security Deposits / Edit Security Deposit
          </Typography>
        </Box>
        <div>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              borderRadius: "0",
              textTransform: "capitalize",
              "&: hover": {
                backgroundColor: "red",
              }
            }}
            onClick={() => deleteTheItem()}
          >
            Delete
          </Button>
        </div>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h3
            style={{
              paddingBottom: "0px",
              marginBlock: "0px",
              fontSize: "12px",
              color: "gray",
            }}
          >
            Rule Overview
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                paddingLeft: "16px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="name"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Name
                    <br />
                    <input
                      type="text"
                      name="name"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    With Branch?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="withBranch"
                      style={{ fontSize: "12px" }}
                      checked={withBranch ? true : false}
                      onChange={(e) =>
                        setWithBranch(e.target.value == "Yes" ? true : false)
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="withBranch"
                      style={{ fontSize: "12px" }}
                      checked={withBranch ? false : true}
                      onChange={(e) =>
                        setWithBranch(e.target.value == "Yes" ? true : false)
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    With Additional Charge?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="withAdditionalCharge"
                      style={{ fontSize: "12px" }}
                      checked={withAdditionalCharge ? true : false}
                      onChange={(e) =>
                        setWithAdditionalCharge(
                          e.target.value == "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="withAdditionalCharge"
                      style={{ fontSize: "12px" }}
                      checked={withAdditionalCharge ? false : true}
                      onChange={(e) =>
                        setWithAdditionalCharge(
                          e.target.value == "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    With Vehicle Class?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="withVehicleClass"
                      style={{ fontSize: "12px" }}
                      checked={withVehicleClass ? true : false}
                      onChange={(e) =>
                        setWithVehicleClass(
                          e.target.value == "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="withVehicleClass"
                      style={{ fontSize: "12px" }}
                      checked={withVehicleClass ? false : true}
                      onChange={(e) =>
                        setWithVehicleClass(
                          e.target.value == "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    With Pickup Location?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="withPickupLocation"
                      style={{ fontSize: "12px" }}
                      checked={withPickupLocation ? true : false}
                      onChange={(e) =>
                        setWithPickupLocation(
                          e.target.value == "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="withPickupLocation"
                      style={{ fontSize: "12px" }}
                      checked={withPickupLocation ? false : true}
                      onChange={(e) =>
                        setWithPickupLocation(
                          e.target.value == "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    With Return Location?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="withReturnLocation"
                      style={{ fontSize: "12px" }}
                      checked={withReturnLocation ? true : false}
                      onChange={(e) =>
                        setWithReturnLocation(
                          e.target.value == "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="withReturnLocation"
                      style={{ fontSize: "12px" }}
                      checked={withReturnLocation ? false : true}
                      onChange={(e) =>
                        setWithReturnLocation(
                          e.target.value == "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    With Cumulative?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="cumulative"
                      style={{ fontSize: "12px" }}
                      checked={cumulative ? true : false}
                      onChange={(e) =>
                        setCumulative(e.target.value == "Yes" ? true : false)
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="cumulative"
                      style={{ fontSize: "12px" }}
                      checked={cumulative ? false : true}
                      onChange={(e) =>
                        setCumulative(e.target.value == "Yes" ? true : false)
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="depositAmount"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Deposit Amount
                    <br />
                    <input
                      type="number"
                      name="depositAmount"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={depositAmount}
                      onChange={(e) => setDepositAmount(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="effectiveFrom"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Effective From
                    <br />
                    <input
                      type="date"
                      name="effectiveFrom"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={effectiveFrom}
                      onChange={(e) => setEffectiveFrom(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    With Active?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="isActive"
                      style={{ fontSize: "12px" }}
                      checked={isActive ? true : false}
                      onChange={(e) =>
                        setIsActive(e.target.value == "Yes" ? true : false)
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="isActive"
                      style={{ fontSize: "12px" }}
                      checked={isActive ? false : true}
                      onChange={(e) =>
                        setIsActive(e.target.value == "Yes" ? true : false)
                      }
                    />
                    No
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="reservationType"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Reservation Type
                    <br />
                    <select
                      name="reservationType"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={reservationType}
                      onChange={(e) => setReservationType(e.target.value)}
                      required
                    >
                      <option value="Reservations">Reservations</option>
                    </select>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="branchId"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Branch
                    <br />
                    <select
                      name="branchId"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={branchId}
                      onChange={(e) => setBranchId(e.target.value)}
                      required
                    >
                      {branches.map((item: any) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="additionalChargeId"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Additional Charge
                    <br />
                    <select
                      name="additionalChargeId"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={additionalChargeId}
                      onChange={(e) => setAdditionalChargeId(e.target.value)}
                      required
                    >
                      {additionalOptions.map((item: any) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="vehicleClassId"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Vehicle Classess
                    <br />
                    <select
                      name="vehicleClassId"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={vehicleClassId}
                      onChange={(e) => setVehicleClassId(e.target.value)}
                      required
                    >
                      {vehicleClassesOptions.map((item: any) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="pickupLocationId"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Pickup Location
                    <br />
                    <select
                      name="pickupLocationId"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={pickupLocationId}
                      onChange={(e) => setPickupLocationId(e.target.value)}
                      required
                    >
                      {locationOptions.map((item: any) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="returnLocationId"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Return Location
                    <br />
                    <select
                      name="returnLocationId"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={returnLocationId}
                      onChange={(e) => setReturnLocationId(e.target.value)}
                      required
                    >
                      {locationOptions.map((item: any) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Include Quantities?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="includeQuantities"
                      style={{ fontSize: "12px" }}
                      checked={includeQuantities ? true : false}
                      onChange={(e) =>
                        setIncludeQuantities(
                          e.target.value == "Yes" ? true : false
                        )
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="includeQuantities"
                      style={{ fontSize: "12px" }}
                      checked={includeQuantities ? false : true}
                      onChange={(e) =>
                        setIncludeQuantities(
                          e.target.value == "Yes" ? true : false
                        )
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="currency"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Currency
                    <br />
                    <input
                      type="text"
                      name="currency"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="effectiveUntil"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Effective Until
                    <br />
                    <input
                      type="date"
                      name="effectiveUntil"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={effectiveUntil}
                      onChange={(e) => setEffectiveUntil(e.target.value)}
                      required
                    />
                  </label>
                </div>

              </div>
            </div>

            <div
              style={{
                width: "100%",
              }}
            >
              <h3
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  fontSize: "12px",
                  color: "gray",
                }}
              >
                Website
              </h3>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  padding: "16px 16px 0px 16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "62px",
                    borderStyle: "solid solid none solid",
                    borderWidth: "1px",
                    borderColor: "gray",
                    textAlign: "center",
                    padding: "5px 5px 0px 5px",
                  }}
                >
                  English
                </div>

                <div
                  style={{
                    width: "100%",
                    borderStyle: "none none solid none",
                    borderWidth: "1px",
                    borderColor: "gray",
                  }}
                />
              </div>

              <div
                style={{
                  marginTop: "10px",
                  width: "100%",
                  paddingLeft: "16px",
                }}
              >
                <label
                  htmlFor="label"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Label
                  <br />
                  <input
                    type="label"
                    name="name"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
