import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import baseURL from "../../../../configs/api";

import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { getActiveElement } from "@mui/x-data-grid/internals";
import AddCreditCard from "./AddCreditCard";
import DeleteCreditCard from "./DeleteCreditCard";

type Props = {
  customerId: string;
  customerName: string;
  addCreditCardPopup: boolean;
  setAddCreditCardPopup: () => void;
};

export default function CreditCards({ customerId, customerName, addCreditCardPopup, setAddCreditCardPopup }: Props) {
  const [tableData, setTableData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [particularCreditDetails, setParticularCreditDetails] = useState<any>();
  const [editPopup, setEditPopup] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 120,
      editable: false,
      sortable: false,
    },
    {
      field: "paymentGateway",
      headerName: "Payment Gateway",
      width: 170,
      editable: false,
      sortable: false,
    },
    {
      field: "cardholderName",
      headerName: "Cardholder's Name",
      width: 220,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <p
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setEditPopup(true);
              setParticularCreditDetails(params.row);
            }}
          >
            {params.value}
          </p>
        );
      },
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 170,
      editable: false,
      sortable: false,
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      editable: false,
      sortable: false,
      width: 160,
    },
    {
      field: "creditCardNumber",
      headerName: "Last 4 Digits",
      width: 180,
      editable: false,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (isSaved) {
      fetch(`${baseURL}creditCard?customerId=${customerId}`)
        .then((res) => res.json())
        .then((data) => {
          setTableData(() =>
            data.map((item: any) => {
              return {
                ...item,
                creditCardNumber: item.creditCardNumber.toString().substr(-4)
              }
            })
          );
          setIsDataLoaded(true);
          setIsSaved(false);
        });
    }
  }, [isSaved]);

  const handleOnClick = (data: unknown) => {
    console.log(data);
  };

  return (
    <>
      <Box sx={{ padding: "24px" }}>
        <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
          {isDataLoaded ? (
            <>
              <hr style={{ marginBlock: "0px" }} />
              <DataGrid
                rows={tableData}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                disableRowSelectionOnClick
                disableColumnMenu
                showColumnVerticalBorder
                onRowClick={() => handleOnClick(getActiveElement)}
                showCellVerticalBorder={true}
                sx={{
                  border: "none",
                  fontSize: "12px",
                  wordWrap: "break-word",
                  overflow: "visible",
                }}
              />
            </>
          ) : (
            <div className="loading">
              <p>Please wait</p>
              <span>
                <i></i>
                <i></i>
              </span>
            </div>
          )}
        </Box>

      </Box>

      <AddCreditCard
        customerId={customerId}
        addCreditCardPopup={addCreditCardPopup}
        setAddCreditCardPopup={() => {
          setAddCreditCardPopup();
          setIsSaved(true);
        }} />

      <DeleteCreditCard
        customerName={customerName}
        editPopup={editPopup}
        hidePopup={() => setEditPopup(false)}
        deleteCreditCard={() => {
          setEditPopup(false);
          setIsSaved(true);
        }}
        particularCreditDetails={particularCreditDetails}
      />
    </>
  );
};