import axios from "axios"
import baseURL from "../configs/api"

export const put_FleetSettings = (data: any) => {
    return axios({
        url: `${baseURL}fleetSetting`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const getFleetSettings = () => {
    return getFetch("fleetSetting")
}

export const getFleetSetting = (primaryKey: string) => {
    return getFetch(`fleetSetting/${primaryKey}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}