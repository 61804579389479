import {
    InputBase,
    Box,
    Stack,
    Button,
} from "@mui/material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import baseURL from "../../../configs/api";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import { getLocations } from "../../../services/location";
import { getBranches } from "../../../services/branch";
import { getCustomers } from "../../../services/customer";
import QuotesDetailsPopup from "../../carrental/quotes/QuotesDetailsPopup";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },

    width: "100%",
    paddingBottom: "0px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    paddingBottom: "0px",
}));

type Props = {
    customerId: string;
};

export default function CustomerQuotes({ customerId }: Props) {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [particularQuotes, setParticularQuotes] = useState<any>();

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "#",
            width: 80,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <p style={{ color: "blue", cursor: "pointer" }} onClick={() => {
                        setShowPopup(true);
                        setParticularQuotes(params.row);
                    }}
                    >
                        {params.value}
                    </p>
                );
            },
        },
        {
            field: "reservationType",
            headerName: "Reservation Type",
            headerAlign: "center",
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "customerName",
            headerName: "Customer Name",
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "customerEmail",
            headerName: "Customer Email",
            headerAlign: "center",
            type: "number",
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "pickupDate",
            headerName: "Pickup Date",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
        {
            field: "returnDate",
            headerName: "Return Date",
            type: "number",
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "vehicleClassName",
            headerName: "Vehicle Class",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
        {
            field: "commissionPartner",
            headerName: "Commission Patner",
            type: "number",
            width: 120,
            editable: false,
            sortable: false,
        },
        {
            field: "clientPay",
            headerName: "Client pays at Commission Patner",
            headerAlign: "right",
            type: "string",
            width: 120,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <p
                        style={{
                            width: "30px",
                            color: "white",
                            background: params.value === "Yes" ? "#66BB6A" : "#ff0000",
                            padding: "1px 3px",
                            textAlign: "center",
                            borderRadius: "2px",
                            fontSize: "10px",
                        }}
                    >
                        {params.value}
                    </p>
                );
            },
        },
        {
            field: "rackPrice",
            headerName: "Rack Price",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
        {
            field: "totalPrice",
            headerName: "Total Price",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
        {
            field: "status",
            headerName: "Status",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                let color = "#bdbdbd";
                switch (params.value) {
                    case "Quote":
                        color = "#bdbdbd";
                        break;
                    case "Open":
                        color = "#f0ad4e";
                        break;
                    case "Rental":
                        color = "#66BB6A";
                        break;
                    case "Completed":
                        color = "#253455";
                        break;
                    case "Cancel":
                        color = "#ff0000";
                        break;
                    case "Pending":
                        color = "#808080";
                        break;
                    case "No Show":
                        color = "#e0e0e0";
                        break;
                }

                return (
                    <p
                        style={{
                            width: "60px",
                            color: "white",
                            background: color,
                            padding: "1px 3px",
                            textAlign: "center",
                            borderRadius: "2px",
                            fontSize: "10px",
                        }}
                    >
                        {params.value}
                    </p>
                );
            },
        },
        {
            field: "discount",
            headerName: "Discount",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
        {
            field: "tag",
            headerName: "Tags",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },

    ];

    const [tableData, setTableData] = useState([]);
    const [searchTableData, setSearchTableData] = useState<any[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [branches, setBranches] = useState<any[]>([]);
    const [branchDataIsLoaded, setBranchDataIsLoaded] = useState<boolean>(false);
    const [locations, setLocations] = useState<any[]>([]);
    const [locationDataIsLoaded, setLocationDataIsLoaded] = useState<boolean>(false);
    const [customers, setCustomers] = useState<any[]>([]);
    const [customerDataIsLoaded, setCustomerDataIsLoaded] =
        useState<boolean>(false);
    const [vehicleClasses, setVehicleClasses] = useState<any[]>([]);
    const [vehicleClassDataIsLoaded, setVehicleClassDataIsLoaded] =
        useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [displayFilter, setDisplayFilter] = useState<boolean>(false);
    const [customerFilter, setCustomerFilter] = useState(0);
    const [customerFilterValue, setCustomerFilterValue] = useState(0);
    const [operatorFilter, setOpertorFilter] = useState(0);
    const [valueFilter, setValueFilter] = useState("");
    const [valueFilterPlaceholder, setValueFilterPlaceholder] =
        useState("Select a customer");
    const [filterOption, setFilterOption] = useState<any[]>([]);

    useEffect(() => {
       getBranches()
            .then(data => {
                setBranches(
                    data.map((item: any) => {
                        return {
                            id: item.id,
                            branchName: item.branchName
                        };
                    })
                );
                setBranchDataIsLoaded(true);
            });
    }, []);

    useEffect(() => {
       getLocations()
            .then(data => {
                setLocations(
                    data.map((item: any) => {
                        return {
                            id: item.id,
                            locationName: item.locationName
                        };
                    })
                );
                setLocationDataIsLoaded(true);
            });
    }, []);

    useEffect(() => {
        getCustomers()
            .then(data => {
                setCustomers(
                    data.map((item: any) => {
                        return {
                            id: item.id,
                            customerName: item.firstName + " " + item.lastName,
                            customerEmail: item.emailAddress,
                            phoneNumber: item.phoneNumber
                        };
                    })
                );
                setCustomerDataIsLoaded(true);
            });
    }, []);

    useEffect(() => {
        fetch(`${baseURL}vehicleClass`)
            .then((res) => res.json())
            .then((data) => {
                setVehicleClasses(() =>
                    data.map((item: any) => {
                        return {
                            id: item.id,
                            name: item.name,
                        };
                    })
                );
                setVehicleClassDataIsLoaded(true);
            });
    }, []);


    useEffect(() => {
        if (
            customerDataIsLoaded &&
            vehicleClassDataIsLoaded &&
            branchDataIsLoaded &&
            locationDataIsLoaded
        ) {
            fetch(`${baseURL}quote`)
                .then((res) => res.json())
                .then((data) => {
                    setTableData(
                        data
                            .filter((data: any) => data.customerId === parseInt(customerId))
                            .map((item: any) => {
                                return {
                                    ...item,
                                    pickupDate: format(
                                        new Date(item.pickupDate),
                                        "MM/dd/yyyy KK:mm a"
                                    ),
                                    returnDate: format(
                                        new Date(item.returenDate),
                                        "MM/dd/yyyy KK:mm a"
                                    ),
                                    totalPrice: "$" + item.totalPrice?.toFixed(2),
                                    rackPrice: "$" + item.rackPrice?.toFixed(2),
                                    securityDepositAmount: "$" + item.securityDepositAmount?.toFixed(2),
                                    discount: "$" + item.discount?.toFixed(2),
                                    customerName: customers.find(
                                        (customer: any) => customer.id === item.customerId
                                    )?.customerName,
                                    customerEmail: customers.find(
                                        (customer: any) => customer.id === item.customerId
                                    )?.customerEmail,
                                    phoneNumber: customers.find(
                                        (customer: any) => customer.id === item.customerId
                                    )?.phoneNumber,
                                    vehicleClassName: vehicleClasses.find(
                                        (vehicleClass: any) => vehicleClass.id === item.vehicleClassId
                                    )?.name,
                                    branchName: branches.find(
                                        (branch: any) => branch.id === item.branchId
                                    )?.branchName,
                                    pickupLocation: locations.find(
                                        (location: any) => location.id === item.pickupLoationId
                                    )?.locationName,
                                    returnLocation: locations.find(
                                        (location: any) => location.id === item.returenLoationId
                                    )?.locationName,
                                    reservatoin: "",
                                    clientPay: item.clientPaysatCommissionPartner ? "Yes" : "No",
                                };
                            })
                    );

                    setSearchTableData(
                        data
                            .filter((data: any) => data.customerId === parseInt(customerId))
                            .map((item: any) => {
                                return {
                                    ...item,
                                    pickupDatee: item.pickupDate,
                                    pickupDate: format(
                                        new Date(item.pickupDate),
                                        "MM/dd/yyyy KK:mm a"
                                    ),
                                    returnDate: format(
                                        new Date(item.returenDate),
                                        "MM/dd/yyyy KK:mm a"
                                    ),
                                    totalPrice: "$" + item.totalPrice?.toFixed(2),
                                    rackPrice: "$" + item.rackPrice?.toFixed(2),
                                    securityDepositAmount: "$" + item.securityDepositAmount?.toFixed(2),
                                    discount: "$" + item.discount?.toFixed(2),
                                    customerName: customers.find(
                                        (customer: any) => customer.id === item.customerId
                                    )?.customerName,
                                    customerEmail: customers.find(
                                        (customer: any) => customer.id === item.customerId
                                    )?.customerEmail,
                                    phoneNumber: customers.find(
                                        (customer: any) => customer.id === item.customerId
                                    )?.phoneNumber,
                                    vehicleClassName: vehicleClasses.find(
                                        (vehicleClass: any) => vehicleClass.id === item.vehicleClassId
                                    )?.name,
                                    branchName: branches.find(
                                        (branch: any) => branch.id === item.branchId
                                    )?.branchName,
                                    pickupLocation: locations.find(
                                        (location: any) => location.id === item.pickupLoationId
                                    )?.locationName,
                                    returnLocation: locations.find(
                                        (location: any) => location.id === item.returenLoationId
                                    )?.locationName,
                                    reservatoin: "",
                                    clientPay: item.clientPaysatCommissionPartner ? "Yes" : "No",
                                };
                            })
                    );

                    setCustomerDataIsLoaded(false);
                    setVehicleClassDataIsLoaded(false);
                    setBranchDataIsLoaded(false);
                    setLocationDataIsLoaded(false);
                    setIsDataLoaded(true);
                });
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerDataIsLoaded, vehicleClassDataIsLoaded, branchDataIsLoaded, locationDataIsLoaded]);

    useEffect(() => {
        switch (customerFilter) {
            case 0:
                setSearchTableData(() =>
                    tableData.filter((item: any) =>
                        item.customerName.toLowerCase().includes(searchValue.toLowerCase())
                    )
                );
                break;
            case 1:
                setSearchTableData(() =>
                    tableData.filter((item: any) =>
                        item.vehicleClassName.toLowerCase().includes(searchValue.toLowerCase())
                    )
                );
                break;
        }

        setValueFilter("");
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (customers) {
            setFilterOption(() =>
                customers.map((item: any) => {
                    return { value: item.id, label: item.customerName };
                })
            );
        }
    }, [customers]);

    useEffect(() => {
        switch (customerFilterValue) {
            case 0:
                setValueFilterPlaceholder("Select a customer");
                setFilterOption(() =>
                    customers.map((item: any) => {
                        return { value: item.id, label: item.customerName };
                    })
                );
                break;
            case 1:
                setValueFilterPlaceholder("Select a vehicle Class");
                setFilterOption(() =>
                    vehicleClasses.map((item: any) => {
                        return { value: item.id, label: item.name };
                    })
                );
                break;
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerFilterValue]);

    const applyFilter = () => {
        setCustomerFilter(() => customerFilterValue);

        if (valueFilter) {
            switch (customerFilterValue) {
                case 0:
                    setSearchTableData(() =>
                        tableData.filter(
                            (item: any) => item.customerId === parseInt(valueFilter)
                        )
                    );
                    break;
                case 1:
                    setSearchTableData(() =>
                        tableData.filter((item: any) => item.vehicleClassId === parseInt(valueFilter))
                    );
                    break;
            }
        }

        setDisplayFilter(false);
    };

    return (
        <>
            <Box sx={{ padding: "24px" }}>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ border: "1px solid black", alignItems: "center" }}
                >
                    <div style={{ position: "relative" }}>
                        <Button
                            variant="contained"
                            endIcon={<ArrowDropDownIcon sx={{ color: "#bdbdbd" }} />}
                            sx={{
                                color: "black",
                                backgroundColor: "#e0dee0",
                                textTransform: "unset",
                                fontSize: "12px",
                                border: "none",
                                borderRadius: "0px",

                                "&: hover": {
                                    backgroundColor: "white",
                                },
                            }}
                            onClick={() => setDisplayFilter(() => !displayFilter)}
                        >
                            Filter
                        </Button>

                        <div
                            style={{
                                display: displayFilter ? "block" : "none",
                                width: "120px",
                                position: "absolute",
                                zIndex: "100",
                                top: "-100%",
                                left: "100%",
                                background: "white",
                                border: "1px solid black",
                                padding: "8px",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <label
                                        htmlFor="fields"
                                        style={{ fontSize: "10px", fontWeight: "bold" }}
                                    >
                                        Fields
                                        <br />
                                    </label>

                                    <CloseIcon
                                        sx={{
                                            width: "18px",
                                            fontSize: "10px",
                                            cursor: "pointer",
                                            background: "#e0e0e0",
                                        }}
                                        onClick={() => setDisplayFilter(false)}
                                    />
                                </div>

                                <select
                                    name="fields"
                                    style={{
                                        width: "100%",
                                        fontSize: "10px",
                                        paddingBlock: "3px",
                                    }}
                                    value={customerFilterValue}
                                    onChange={(e) =>
                                        setCustomerFilterValue(parseInt(e.target.value))
                                    }
                                >
                                    <option value="0">Customer</option>
                                    <option value="1">Booking Number</option>
                                </select>
                            </div>

                            <div>
                                <label
                                    htmlFor="operator"
                                    style={{ fontSize: "10px", fontWeight: "bold" }}
                                >
                                    Operator
                                    <br />
                                    <select
                                        name="operator"
                                        style={{
                                            width: "100%",
                                            fontSize: "10px",
                                            paddingBlock: "3px",
                                        }}
                                    value={operatorFilter}
                                    onChange={(e) => setOpertorFilter(parseInt(e.target.value))}
                                    >
                                        <option value="0">In a list</option>
                                    </select>
                                </label>
                            </div>

                            <div>
                                <label
                                    htmlFor="value"
                                    style={{ fontSize: "10px", fontWeight: "bold" }}
                                >
                                    Value
                                    <br />
                                    <select
                                        name="value"
                                        style={{
                                            width: "100%",
                                            fontSize: "10px",
                                            paddingBlock: "3px",
                                        }}
                                        value={valueFilter}
                                        onChange={(e) => setValueFilter(e.target.value)}
                                    >
                                        <option value={""}>{valueFilterPlaceholder}</option>
                                        {filterOption.map((item: any) => {
                                            return (
                                                <option key={item.value} value={item.value}>
                                                    {item.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </label>
                            </div>

                            <button
                                style={{ fontSize: "10px" }}
                                onClick={() => applyFilter()}
                            >
                                Apply Filter
                            </button>
                        </div>
                    </div>

                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ "aria-label": "search" }}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </Search>
                    <Button
                        variant="text"
                        sx={{
                            width: "12%",
                            color: "black",
                            backgroundColor: "#e0dee0",
                            textTransform: "unset",
                            fontSize: "12px",
                            border: "none",
                            borderRadius: "0px",
                        }}
                    >
                        Save this search
                    </Button>
                </Stack>

                <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
                    {isDataLoaded ? (
                        <DataGrid
                            rows={searchTableData}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                            disableRowSelectionOnClick
                            disableColumnMenu
                            checkboxSelection
                            showCellVerticalBorder={false}
                            sx={{
                                border: "none",
                                fontSize: "12px",
                                wordWrap: "break-word",
                                overflow: "visible",
                            }}
                        />
                    ) : (
                        <div className="loading">
                            <p>Please wait</p>
                            <span>
                                <i></i>
                                <i></i>
                            </span>
                        </div>
                    )}
                </Box>
            </Box>

          <QuotesDetailsPopup
                particularQuotes={particularQuotes}
                showPopup={showPopup}
                hidePopup={() => setShowPopup(false)}
                deleteQuote={(id: number) => {
                    setTableData(() => tableData.filter((item: any) => item.id !== id));
                    setSearchTableData(() => searchTableData.filter((item: any) => item.id !== id))
                    setShowPopup(false);
                }}
            />
        </>
    );
}