import { Box, 
    Button, 
    Toolbar, 
    Typography, 
} from "@mui/material";
import React, { useState } from "react";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import colorConfigs from "../../../../configs/colorConfigs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../../configs/api";


export const AddVehicleMake = () => {  
  const [label, setLabel] = useState<string>("");     

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}vehicleMake`,
        JSON.stringify({
          makeName: label,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <LocalOfferIcon
            sx={{ fontSize: "24px", marginRight: "5px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Vehicle Make / Add Vehicle Make
          </Typography>
        </Box>
      </Toolbar>
      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingTop: "10px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px",fontSize: "18px" }}>
           Vehicle Make Overview
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                <label
                    htmlFor="label"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Make
                    <br />
                    <input
                      type="text"
                      name="label"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
                </div>
                

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
      
    </>
  );
};
