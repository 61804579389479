import React from "react";

interface EmailValidatedComponentProps {
  text: string;
  setText: (value: string) => void;
  required?: boolean;
  className?: any;
}

const EmailValidatedComponent = ({
  text,
  setText,
  required = false,
  className = {
    parentDiv: {},
    label: {
      fontWeight: "600",
      fontSize: "0.875rem"
    },
    input: {
      width: "100%",
      borderRadius: "0.25rem",
      padding: "0.5rem 1rem",
      marginTop: "0.5rem",
      border: "2px solid #ddd",
      fontSize: "0.875rem",
      color: "black",
      outline: "none",
      transition: "border-color 0.3s"
    }
  }
}: EmailValidatedComponentProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setText(value);
    const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/gm.test(value);
    e.target.setCustomValidity(isValid ? "" : "Please enter a valid email");
  };

  return (
    <div style={className.parentDiv}>
      <label style={className.label}>Email</label>
      <input
        type="email"
        placeholder="Enter Email"
        style={className.input}
        value={text}
        onChange={handleChange}
        required={required}
      />
    </div>
  );
};

export default EmailValidatedComponent;