import axios from "axios"
import baseURL from "../configs/api";

export const getUsername = (username: string) => {
    return getFetch(`users/DoesUserNameExist/${username}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}