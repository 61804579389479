import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

type QuickPickupSelectWrapperProps = {
    hasNextPage: boolean,
    isNextPageLoading: boolean,
    options: any[],
    loadNextPage: () => void,
    onChange: (value: number) => void,
    setSearchValue: (value: string) => void,
    setPage: (value: number) => void,
    isSearching: boolean,
    setIsSearching: (value: boolean) => void,

}
const QuickPickupSelectWrapper = ({ hasNextPage, isNextPageLoading, options, loadNextPage, onChange, setSearchValue, isSearching, setIsSearching }: QuickPickupSelectWrapperProps) => {
    const itemCount = hasNextPage ? options.length + 1 : options.length;
    const loadMoreItems = isNextPageLoading ? () => { } : loadNextPage;
    const isItemLoaded = (index: any) => !hasNextPage || index < options.length;
    const listRef = useRef<any>(null);
    const scrollPosRef = useRef<number>(0);

    const handleScroll = (e: any) => {
        if (options.length > 0) {
            scrollPosRef.current = e.scrollOffset;
        }
        else {
            scrollPosRef.current = 0;
        }
    };

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTo(scrollPosRef.current);
        }
    }, [options]);

    const MenuList = ({ children }: any) => {
        const childrenArray = React.Children.toArray(children);
        // Render an item or a loading indicator.
        const Item = ({ index, style, ...rest }: any) => {
            const child = childrenArray[index];

            return (
                <div
                    className="drop-down-list"
                    style={{
                        borderBottom: '1px solid rgb(243 234 234 / 72%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...style,
                    }}
                    onClick={() => handleChange(options[index])}
                    {...rest}
                >
                    {isItemLoaded(index) && child ? child : `Loading...`}
                </div>
            );
        };
        return (
            <AutoSizer disableHeight style={{
                width: "100%"
            }}>
                {(width: any) => (
                    <InfiniteLoader
                        isItemLoaded={(index) => index < options.length}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                        threshold={2}

                    >
                        {({ onItemsRendered, ref }) => (
                            <List
                                className="List"
                                height={options.length > 0 && !isSearching ? 250 : 50}
                                itemCount={isSearching ? 0 : itemCount}
                                onScroll={handleScroll}
                                itemSize={35}
                                onItemsRendered={onItemsRendered}
                                initialScrollOffset={scrollPosRef.current}
                                ref={ref}
                                width={width}
                            >
                                {Item}
                            </List>
                        )}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        );
    };

    const handleChange = (selected: any) => {
        onChange(selected);
    };


    return (
        <Select
            styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
            className="basic-single"
            classNamePrefix="select"
            isSearchable={true}
            onInputChange={(value: string) => {
                setSearchValue(value)
                if (value !== "") {
                    setIsSearching(true)
                }
            }}
            options={options}
            onChange={(selected: any) => onChange(selected)}
            components={(options.length > 7 || options.length === 0) && hasNextPage ? { MenuList } : {}}
            required
        />
    )
}

export default QuickPickupSelectWrapper;