import React, { useEffect, useState } from "react";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import axios from "axios";
import baseURL from "../../configs/api";
import { Link } from "react-router-dom";

type Props = {
  date: Date;
};

const PendingMaintenance = ({ date }: Props) => {
  const [data, setData] = useState<any[]>([]);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (date) {
      axios
        .get(`${baseURL}dailyManifest/PendingMaintenance/${date.toISOString()}`)
        .then((response) => {
          setData(response.data);
          setFilterData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [date]);

  useEffect(() => {
    setFilterData(data.filter((_: any) => _?.vehicle?.toLowerCase()?.includes(searchValue?.toLowerCase())));
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])


  return (
    <>
      {data.length === 0 ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "12px 8px",
            background: "#f2f7fa",
            fontWeight: "bold",
            fontSize: "14px",
            margin: "10px 10px 40px 10px"
          }}
        >
          No Pending Maintenance
        </div>
      ) : (
        <div style={{ width: "100%", margin: "10px 10px 40px 10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              background: "#f2f7fa"
            }}
          >
            <div>
              <h2 style={{ fontSize: "14px", marginBlock: "0px" }}>
                Pending Maintenance ({data.length})
              </h2>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
           
              <input
                type="text"
                placeholder="Search..."
                style={{ marginRight: "10px", padding: "5px" }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)} />
              <PictureAsPdfOutlinedIcon />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th style={{ width: "200px" }}>Vehicle Make</th>
                <th style={{ width: "250px" }}>Vehicle Model</th>
                <th style={{ width: "400px" }}>Vehicle Class</th>
                <th style={{ width: "180px" }}>Vehicle</th>
                <th style={{ width: "200px" }}>Maintenance Type</th>
                <th style={{ width: "150px" }}>Planned Start Date</th>
                <th style={{ width: "250px" }}>Odometer at Maintenance Due Date</th>
                <th style={{ width: "250px" }}>Current Odometer</th>
              </tr>
            </thead>
            <tbody style={{ background: "#f5f5f5" }}>
              {filterData.map((item, index) => (
                <tr key={index}>
                  <td>{item.makeName}</td>
                  <td>{item.modelName}</td>
                  <td>{item.vehicleClassName}</td>
                  <td>
                    <Link
                      to="/fleet/vehicles/Editvehicles"
                      state={item.maintenancId}
                    >
                      {item.vehicle}
                    </Link>
                  </td>
                  <td>{item.maintenanceType}</td>
                  <td>{item.plannedStartDate}</td>
                  <td>{item.odometerAtMaintenanceDueDate}</td>
                  <td>{item.currentOdometer}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
      }
    </>
  );
};

export default PendingMaintenance;