import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post_ForgotPassword } from '../../services/ForgotPassword';
import EmailValidatedComponent from '../EmailValidatedComponent';
import colorConfigs from '../../configs/colorConfigs';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any>({
        emailAddress: "",
    });
    const [message, setMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsProcessing(true);
        setMessage('');
        setErrorMessage('');

        post_ForgotPassword(userData.emailAddress)
            .then((res) => {
                if (res === userData.emailAddress) {
                    setMessage(`Please check your email for password has been sent to ${userData.emailAddress}.`);
                    setIsSuccess(true);
                } else {
                    setErrorMessage('Your email address does not exist.');
                }
                setIsProcessing(false);
                setUserData({ emailAddress: '' });
            })
            .catch((error) => {
                if (error.response && error.response.status === 500) {
                    setErrorMessage('There is some problem occurring. Please try again later.');
                } else {
                    setErrorMessage('Your email address does not exist.');
                }
                console.error(error);
                setIsProcessing(false);
            });
    };

    return (
        <div style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f7fafc"
        }}>
            <div style={{
                backgroundColor: "white",
                padding: "24px",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                width: "100%",
                maxWidth: "400px"
            }}>
                <h2 style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginBottom: "24px",
                    textAlign: "center"
                }}>Forgot Password</h2>
                {!isSuccess ? (
                    <form onSubmit={handleSubmit}>
                        <div style={{ marginBottom: "16px" }}>
                            <EmailValidatedComponent
                                text={userData.emailAddress}
                                setText={(value) =>
                                    setUserData({ ...userData, emailAddress: value })
                                }
                                required={true}
                            />
                        </div>
                        <button
                            type="submit"
                            disabled={isProcessing}
                            style={{
                                width: "100%",
                                backgroundColor: isProcessing ? "#a0aec0" : colorConfigs.topbar.bg,
                                color: "white",
                                fontWeight: "bold",
                                padding: "8px 16px",
                                borderRadius: "4px",
                                cursor: isProcessing ? "not-allowed" : "pointer",
                                outline: "none",
                                boxShadow: isProcessing ? "none" : "0 2px 4px rgba(0, 0, 0, 0.1)",
                                transition: "background-color 0.3s"
                            }}
                        >
                            {isProcessing ? 'Processing...' : 'Reset Password'}
                        </button>
                    </form>
                ) : (
                    <div style={{ textAlign: "center" }}>
                        <div style={{ marginTop: "16px", color: "#38a169" }}>{message}</div>
                        <button onClick={() => navigate("/login")}
                            style={{
                                marginTop: "16px",
                                backgroundColor: colorConfigs.topbar.bg,
                                color: "white",
                                fontWeight: "bold",
                                padding: "8px 16px",
                                borderRadius: "4px",
                                cursor: "pointer",
                                outline: "none",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                transition: "background-color 0.3s"
                            }}
                        >
                            Go back to sign in
                        </button>
                    </div>
                )}
                {errorMessage && (
                    <div style={{
                        marginTop: "16px",
                        textAlign: "center",
                        color: "#e53e3e"
                    }}>
                        {errorMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ForgotPassword;