import { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton } from "@mui/material";


type Props = {
  data: {
    link: string | number | readonly string[] | undefined;
    id: number;
    roleName: string;
    roleDescription: string;
    isActive: boolean;

  };
  handleDelete: (id: number) => void;
  handleTheChange: (
    id: number,
    roleName: string,
    roleDescription: string,
    isActive: boolean
  ) => void;
};


export const AddLabel = (props: Props) => {
  const [roleName, setRoleName] = useState(props.data.roleName);
  const [roleDescription, setRoleDescription] = useState(props.data.roleDescription);
  const [isActive, setIsActive] = useState(props.data.isActive);

  useEffect(() => {
    props.handleTheChange(props.data.id, roleName, roleDescription, isActive);
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleName, roleDescription, isActive]);


  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
    >
      <div style={{ width: "45%", marginLeft: "10px", marginRight: "10px" }}>
        <input
          type="text"
          name="roleName"
          style={{
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
          }}
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          required
        />
      </div>

      <div style={{ width: "35%", marginLeft: "10px", marginRight: "10px" }}>
        <input
          type="text"
          name="roleDescription"
          style={{
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
          }}
          value={roleDescription}
          onChange={(e) => setRoleDescription(e.target.value)}
          required
        />
      </div>

      <div style={{ width: "10%", marginLeft: "10px", marginRight: "10px" }}>
        <select
          name="active"
          style={{
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
          }}
          value={isActive ? "Yes" : "No"}
          onChange={(e) => setIsActive(e.target.value === "Yes" ? true : false)}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>

      <div style={{ width: "5%" }}>
        <IconButton onClick={() => props.handleDelete(props.data.id)}>
          <DeleteForeverIcon sx={{ color: "purple" }} />
        </IconButton>
      </div>
    </div>
  );
};
