import { InputBase, Box, Stack, Button } from "@mui/material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import baseURL from "../../../configs/api";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import { format } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  width: "100%",
  paddingBottom: "0px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  paddingLeft: `calc(1em + ${theme.spacing(2)})`,
  paddingBottom: "0px",
}));

const columns: GridColDef[] = [
  {
    field: "bookingNumber",
    headerName: "#",
    width: 120,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <Link to="/carrental/reservations/editreservation" state={params.id}>
          {params.value}
        </Link>
      );
    },
  },
  {
    field: "customerName",
    headerName: "Customer",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "pickupDate",
    headerName: "Pickup Date",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "returnDate",
    headerName: "Return Date",
    type: "number",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "locationName",
    headerName: "Pickup Location",
    editable: false,
    sortable: false,
    width: 160,
  },
  {
    field: "vehicleClassName",
    headerName: "Vehicle Class",
    width: 200,
    editable: false,
    sortable: false,
  },
  {
    field: "vehicleName",
    headerName: "Vehicle",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "localAddress",
    headerName: "Local Address",
    type: "number",
    width: 110,
    editable: false,
    sortable: false,
  },
  {
    field: "totalPrice",
    headerName: "Total Price",
    type: "number",
    width: 110,
    editable: false,
    sortable: false,
  },
  {
    field: "totalRevenue",
    headerName: "Total Revenue",
    type: "number",
    width: 110,
    editable: false,
    sortable: false,
  },
  {
    field: "totalPaid",
    headerName: "Total Paid",
    type: "number",
    width: 110,
    editable: false,
    sortable: false,
  },
  {
    field: "totalRefunded",
    headerName: "Total Refunded",
    type: "number",
    width: 110,
    editable: false,
    sortable: false,
  },
  {
    field: "outstandingBalance",
    headerName: "Outstanding Balance",
    type: "number",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 110,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      let color: string = "#9E9E9E";
      switch (params.value) {
        case "Quote":
          color = "#bdbdbd";
          break;
        case "Open":
          color = "#f0ad4e";
          break;
        case "Rental":
          color = "#66BB6A";
          break;
        case "Completed":
          color = "#253455";
          break;
        case "Cancel":
          color = "#ff0000";
          break;
        case "Pending":
          color = "#808080";
          break;
        case "No Show":
          color = "#e0e0e0";
          break;
        default:
          color = "#9E9E9E";
          break;
      }

      return (
        <p
          style={{
            width: params.value === "No Show" ? "50px" : params.value === "Completed" ? "60px" : "45px",
            fontSize: "10px",
            backgroundColor: color,
            color: "#fff",
            padding: "2px 4px",
            borderRadius: "2px",
            textAlign: "center",
            margin: "0px",
          }}
        >
          {params.value}
        </p>
      );
    },
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 120,
    editable: false,
    sortable: false,
  },
  {
    field: "branchName",
    headerName: "Branch",
    width: 120,
    editable: false,
    sortable: false,
  },
  {
    field: "walkInCustomer",
    headerName: "Walk in Customer?",
    width: 130,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      let color: string = "#9E9E9E";
      switch (params.value) {
        case "Yes":
          color = "#66BB6A";
          break;
        case "No":
          color = "#ff0000";
          break;
        default:
          color = "#9E9E9E";
          break;
      }

      return (
        <p
          style={{
            width: "40px",
            fontSize: "10px",
            backgroundColor: color,
            color: "#fff",
            padding: "2px 4px",
            borderRadius: "2px",
            textAlign: "center",
            margin: "0px",
          }}
        >
          {params.value}
        </p>
      );
    },
  },
  {
    field: "totalDays",
    headerName: "Total Days",
    type: "number",
    width: 100,
    editable: false,
    sortable: false,
  },
  {
    field: "dailyRate",
    headerName: "Daily Rate",
    type: "number",
    width: 100,
    editable: false,
    sortable: false,
  },
];

type Props = {
  customerId: string;
};

export default function CustomerReservations({ customerId }: Props) {
  const [tableData, setTableData] = useState([]);
  const [searchTableData, setSearchTableData] = useState<any[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [customerDataIsLoaded, setCustomerDataIsLoaded] =
    useState<boolean>(false);
  const [vehicleClasses, setVehicleClasses] = useState<any[]>([]);
  const [vehicleClassDataIsLoaded, setVehicleClassDataIsLoaded] =
    useState<boolean>(false);
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [vehiclesDataIsLoaded, setVehiclesDataIsLoaded] =
    useState<boolean>(false);
  const [branchDataIsLoaded, setBranchDataIsLoaded] = useState<boolean>(false);
  const [locationDataIsLoaded, setLocationDataIsLoaded] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [customerFilter, setCustomerFilter] = useState(0);
  const [customerFilterValue, setCustomerFilterValue] = useState(0);
  const [valueFilter, setValueFilter] = useState("");
  const [valueFilterPlaceholder, setValueFilterPlaceholder] =
    useState("Select a customer");
  const [filterOption, setFilterOption] = useState<any[]>([]);
  const [operatorFilter, setOperatorFilter] = useState<number>(0);

  useEffect(() => {
    fetch(`${baseURL}Customer`)
      .then((res) => res.json())
      .then((data) => {
        setCustomers(
          data.map((item: any) => {
            return {
              id: item.id,
              customerName: item.firstName + " " + item.lastName,
            };
          })
        );
        setCustomerDataIsLoaded(true);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseURL}vehicleClass`)
      .then((res) => res.json())
      .then((data) => {
        setVehicleClasses(() =>
          data.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
            };
          })
        );
        setVehicleClassDataIsLoaded(true);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseURL}vehicles`)
      .then((res) => res.json())
      .then((data) => {
        setVehicles(() =>
          data.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
            };
          })
        );
        setVehiclesDataIsLoaded(true);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseURL}branch`)
      .then((res) => res.json())
      .then((data) => {
        setBranches(
          data.map((item: any) => {
            return {
              id: item.id,
              branchName: item.branchName,
            };
          })
        );

        setBranchDataIsLoaded(true);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseURL}location`)
      .then((res) => res.json())
      .then((data) => {
        setLocations(
          data.map((item: any) => {
            return {
              id: item.id,
              locationName: item.locationName,
            };
          })
        );
        setLocationDataIsLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (
      customerDataIsLoaded &&
      vehicleClassDataIsLoaded &&
      vehiclesDataIsLoaded &&
      branchDataIsLoaded &&
      locationDataIsLoaded
    ) {
      fetch(`${baseURL}reservation`)
        .then((res) => res.json())
        .then((data) => {
          setTableData(
            data
              .filter((data: any) => data.customerId === parseInt(customerId))
              .map((item: any) => {
                return {
                  ...item,
                  pickupDate: format(
                    new Date(item.pickupDate),
                    "MM/dd/yyyy KK:mm a"
                  ),
                  returnDate: format(
                    new Date(item.returenDate),
                    "MM/dd/yyyy KK:mm a"
                  ),
                  totalPrice: "$" + item.totalPrice,
                  totalRevenue: "$" + item.totalRevenue,
                  totalPaid: "$" + item.totalPaid,
                  totalRefunded: "$" + item.totalRefunded,
                  outstandingBalance: "$" + item.outstandingBalance,
                  dailyRate: "$" + item.dailyRate,
                  walkInCustomer: item.walkInCustomer ? "Yes" : "No",
                  customerName: customers.find(
                    (customer: any) => customer.id === item.customerId
                  )?.customerName,
                  vehicleClassName: vehicleClasses.find(
                    (vehicleClass: any) => vehicleClass.id === item.vehicleClassId
                  )?.name,
                  vehicleName: vehicles.find(
                    (vehicle: any) => vehicle.id === item.vehicleId
                  )?.name,
                  branchName: branches.find(
                    (branch: any) => branch.id === item.branchId
                  )?.branchName,
                  locationName: locations.find(
                    (location: any) => location.id === item.pickupLoationId
                  )?.locationName,
                };
              })
          );

          setSearchTableData(
            data.filter((data: any) => data.customerId === parseInt(customerId))
              .map((item: any) => {
                return {
                  ...item,
                  pickupDate: format(
                    new Date(item.pickupDate),
                    "MM/dd/yyyy KK:mm a"
                  ),
                  returnDate: format(
                    new Date(item.returenDate),
                    "MM/dd/yyyy KK:mm a"
                  ),
                  totalPrice: "$" + item.totalPrice,
                  totalRevenue: "$" + item.totalRevenue,
                  totalPaid: "$" + item.totalPaid,
                  totalRefunded: "$" + item.totalRefunded,
                  outstandingBalance: "$" + item.outstandingBalance,
                  dailyRate: "$" + item.dailyRate,
                  walkInCustomer: item.walkInCustomer ? "Yes" : "No",
                  customerName: customers.find(
                    (customer: any) => customer.id === item.customerId
                  )?.customerName,
                  vehicleClassName: vehicleClasses.find(
                    (vehicleClass: any) => vehicleClass.id === item.vehicleClassId
                  )?.name,
                  vehicleName: vehicles.find(
                    (vehicle: any) => vehicle.id === item.vehicleId
                  )?.name,
                  branchName: branches.find(
                    (branch: any) => branch.id === item.branchId
                  )?.branchName,
                  locationName: locations.find(
                    (location: any) => location.id === item.pickupLoationId
                  )?.locationName,
                };
              })
          );

          setCustomerDataIsLoaded(false);
          setVehicleClassDataIsLoaded(false);
          setVehiclesDataIsLoaded(false);
          setBranchDataIsLoaded(false);
          setLocationDataIsLoaded(false);
          setIsDataLoaded(true);
        });
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDataIsLoaded, vehicleClassDataIsLoaded,
    vehiclesDataIsLoaded, branchDataIsLoaded, locationDataIsLoaded]);

  useEffect(() => {
    switch (customerFilter) {
      case 0:
        setSearchTableData(() =>
          tableData.filter((item: any) =>
            item.customerName.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
        break;
      case 1:
        setSearchTableData(() =>
          tableData.filter((item: any) =>
            item.bookingNumber.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
        break;
    }

    setValueFilter("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (customers) {
      setFilterOption(() =>
        customers.map((item: any) => {
          return { value: item.id, label: item.customerName };
        })
      );
    }
  }, [customers]);

  useEffect(() => {
    switch (customerFilterValue) {
      case 0:
        setValueFilterPlaceholder("Select a customer");
        setFilterOption(() =>
          customers.map((item: any) => {
            return { value: item.id, label: item.customerName };
          })
        );
        break;
      case 1:
        setValueFilterPlaceholder("Select a booking");
        setFilterOption(() =>
          tableData.map((item: any) => {
            return { value: item.id, label: item.bookingNumber };
          })
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFilterValue]);

  const applyFilter = () => {
    setCustomerFilter(() => customerFilterValue);

    if (valueFilter) {
      switch (customerFilterValue) {
        case 0:
          setSearchTableData(() =>
            tableData.filter(
              (item: any) => item.customerId === parseInt(valueFilter)
            )
          );
          break;
        case 1:
          setSearchTableData(() =>
            tableData.filter((item: any) => item.id === parseInt(valueFilter))
          );
          break;
      }
    }

    setDisplayFilter(false);
  };

  return (
    <>
      <Box sx={{ padding: "24px" }}>
        <Stack
          direction="row"
          spacing={3}
          sx={{ border: "1px solid black", alignItems: "center" }}
        >
          <div style={{ position: "relative" }}>
            <Button
              variant="contained"
              endIcon={<ArrowDropDownIcon sx={{ color: "#bdbdbd" }} />}
              sx={{
                color: "black",
                backgroundColor: "#e0dee0",
                textTransform: "unset",
                fontSize: "12px",
                border: "none",
                borderRadius: "0px",

                "&: hover": {
                  backgroundColor: "white",
                },
              }}
              onClick={() => setDisplayFilter(() => !displayFilter)}
            >
              Filter
            </Button>

            <div
              style={{
                display: displayFilter ? "block" : "none",
                width: "120px",
                position: "absolute",
                zIndex: "100",
                top: "-100%",
                left: "100%",
                background: "white",
                border: "1px solid black",
                padding: "8px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="fields"
                    style={{ fontSize: "10px", fontWeight: "bold" }}
                  >
                    Fields
                    <br />
                  </label>

                  <CloseIcon
                    sx={{
                      width: "18px",
                      fontSize: "10px",
                      cursor: "pointer",
                      background: "#e0e0e0",
                    }}
                    onClick={() => setDisplayFilter(false)}
                  />
                </div>

                <select
                  name="fields"
                  style={{
                    width: "100%",
                    fontSize: "10px",
                    paddingBlock: "3px",
                  }}
                  value={customerFilterValue}
                  onChange={(e) =>
                    setCustomerFilterValue(parseInt(e.target.value))
                  }
                >
                  <option value="0">Customer</option>
                  <option value="1">Booking Number</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="operator"
                  style={{ fontSize: "10px", fontWeight: "bold" }}
                >
                  Operator
                  <br />
                  <select
                    name="operator"
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      paddingBlock: "3px",
                    }}
                  value={operatorFilter}
                  onChange={(e) => setOperatorFilter(parseInt(e.target.value))}
                  >
                    <option value="0">In a list</option>
                  </select>
                </label>
              </div>

              <div>
                <label
                  htmlFor="value"
                  style={{ fontSize: "10px", fontWeight: "bold" }}
                >
                  Value
                  <br />
                  <select
                    name="value"
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      paddingBlock: "3px",
                    }}
                    value={valueFilter}
                    onChange={(e) => setValueFilter(e.target.value)}
                  >
                    <option value={""}>{valueFilterPlaceholder}</option>
                    {filterOption.map((item: any) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>

              <button
                style={{ fontSize: "10px" }}
                onClick={() => applyFilter()}
              >
                Apply Filter
              </button>
            </div>
          </div>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Search>
          <Button
            variant="text"
            sx={{
              width: "12%",
              color: "black",
              backgroundColor: "#e0dee0",
              textTransform: "unset",
              fontSize: "12px",
              border: "none",
              borderRadius: "0px",
            }}
          >
            Save this search
          </Button>
        </Stack>

        <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
          {isDataLoaded ? (
            <DataGrid
              rows={searchTableData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
              disableRowSelectionOnClick
              disableColumnMenu
              checkboxSelection
              showCellVerticalBorder={false}
              sx={{
                border: "none",
                fontSize: "12px",
                wordWrap: "break-word",
                overflow: "visible",
              }}
            />
          ) : (
            <div className="loading">
              <p>Please wait</p>
              <span>
                <i></i>
                <i></i>
              </span>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
}
