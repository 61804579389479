import React from 'react';
import colorConfigs from '../configs/colorConfigs';

const RentalJumper = () => {
  return (
    <div style={{
        backgroundColor: colorConfigs.jumper.bg,
        padding: "20px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    }}>
        <span style={{
            fontSize: "3rem"
        }}>
            RD Rental
        </span>
    </div>
  );
};

export default RentalJumper;
