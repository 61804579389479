import React, { useEffect, useState } from "react";
import axios from "axios";
import baseURL from "../../../configs/api";

type Props = {
  goToNextPage: (page: number) => void;
  getDataFromCars: (vehicleId: string, selectedVehicleClass: any) => void;
  branchId: string;
  pickupDate: string;
  pickupTime: string;
  returnDate: string;
  returnTime: string;
  status: string;
  vehicleClassId: string;
  dateAndTimeChange: boolean;
  setDateAndTimeChange: () => void;
  totalOfSelectedVehicleClass: number;
};

export default function Cars({
  goToNextPage,
  getDataFromCars,
  branchId,
  pickupDate,
  pickupTime,
  returnDate,
  returnTime,
  status,
  vehicleClassId,
  dateAndTimeChange,
  setDateAndTimeChange,
  totalOfSelectedVehicleClass
}: Props) {
  const [availableVehicle, setAvailableVehicle] = useState<any[]>([]);
  const [selectedVehicleClass, setSelectedVehicleClass] = useState<any>();

  let latestPickup = new Date();
  let latestReturn = new Date();
  if (pickupDate) latestPickup = new Date(pickupTime ? `${pickupDate}T${pickupTime}` : pickupDate);
  if (returnDate) latestReturn = new Date(returnTime ? `${returnDate}T${returnTime}` : returnDate);

  useEffect(() => {
    if (branchId && pickupDate && pickupTime && returnDate && returnTime) {
      axios
        .get(
          `${baseURL}availableCars?pickupDate=${latestPickup.toISOString()}&returnDate=${latestReturn.toISOString()}&branchId=${branchId}`
        )
        .then((response) => {
          setAvailableVehicle(() => response.data);

          if (dateAndTimeChange) {
            setSelectedVehicleClass(() =>
              response.data.find(
                (item: any) => item.vehicleClassId === parseInt(vehicleClassId)
              )
            );

            getDataFromCars(
              response.data.find(
                (item: any) => item.vehicleClassId === parseInt(vehicleClassId)
              )?.vehicleClassId,
              response.data.find(
                (item: any) => item.vehicleClassId === parseInt(vehicleClassId)
              )
            );
            setDateAndTimeChange();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [branchId, pickupDate, pickupTime, returnDate, returnTime]);

  useEffect(() => {
    if (availableVehicle && vehicleClassId) {
      setSelectedVehicleClass(availableVehicle.find((item: any) => item.vehicleClassId === parseInt(vehicleClassId)));
      getDataFromCars(vehicleClassId, availableVehicle.find((item: any) => item.vehicleClassId === parseInt(vehicleClassId)));
    }
  }, [vehicleClassId, availableVehicle]);


  return (
    <div
      style={{
        width: "100%",
        padding: "24px",
      }}
    >
      {selectedVehicleClass && (
        <>
          <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
            Selected Vehicle Class
          </h5>
          <hr />

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              padding: "0px 16px"
            }}
          >

            <div style={{
              width: "65%",
              display: "flex",
              flexDirection: "row",
              gap: "16px"
            }}>

              <div style={{ width: "50%" }}>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={
                    selectedVehicleClass.imagePath.startsWith(
                      "data:image/png;base64,"
                    )
                      ? selectedVehicleClass.imagePath
                      : `${baseURL}vehicleClassFile/Get?name=` +
                      selectedVehicleClass.imagePath
                  }
                  alt="vehicle"
                />
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h5 style={{ paddingBlock: "0px", marginBlock: "0px" }}>
                  {selectedVehicleClass.vehicleName}
                </h5>
                <p style={{ marginBlock: "0px", fontSize: "12px" }}>
                  {parseInt(selectedVehicleClass.distanceLimitperDay)} miles per
                  day
                </p>
                <p style={{ marginBlock: "0px", fontSize: "12px" }}>
                  Radio/CD Player
                </p>
                <p style={{ marginBlock: "0px", fontSize: "12px" }}>
                  Extra Miles - $
                  {parseInt(
                    selectedVehicleClass.additionalChargeforExceededDistance
                  ).toFixed(2)}{" "}
                  per mile
                </p>
              </div>

            </div>

            <div
              style={{
                width: "35%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {selectedVehicleClass.recommended && (
                <h5
                  style={{
                    backgroundColor: "#81D4FA",
                    color: "white",
                    textAlign: "center",
                    marginTop: "0px",
                    marginBottom: "8px",
                    paddingBlock: "3px",
                  }}
                >
                  Recommended
                </h5>
              )}

              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  border: "1px solid black",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "end",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                  <strong>
                    ${parseInt(selectedVehicleClass.dailyRate).toFixed(2)}
                  </strong>
                  /Day
                </p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                  $
                  {Number(totalOfSelectedVehicleClass)?.toFixed(2)}{" "}
                  total for {selectedVehicleClass.days} Day{" "}
                  {selectedVehicleClass.hours !== 0 &&
                    " and " + selectedVehicleClass.hours + " hours"}
                </p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                  Excl. taxes & insurance(s){" "}
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    marginBlock: "0px",
                    color: "#81D4FA",
                  }}
                >
                  Vehicles Available: {selectedVehicleClass.availableVehicles} (
                  {(
                    (parseFloat(selectedVehicleClass.availableVehicles) * 100) /
                    parseFloat(selectedVehicleClass.vehicleCount)
                  ).toFixed(2)}
                  %)
                </p>
              </div>
            </div>
          </div>

          <hr style={{ marginTop: "16px", marginBottom: "40px" }} />
        </>
      )}

      <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
        {selectedVehicleClass
          ? "Select Other Vehicle Class"
          : "Available Vehicle Class"}
      </h5>
      <hr />

      {availableVehicle.map((item: any, index: number) => {
        return (
          <React.Fragment key={index}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                padding: "0px 16px"
              }}
            >

              <div style={{
                width: "65%",
                display: "flex",
                flexDirection: "row",
                gap: "16px"
              }}>

                <div style={{ width: "50%" }}>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={
                      item.imagePath.startsWith("data:image/png;base64,")
                        ? item.imagePath
                        : `${baseURL}vehicleClassFile/Get?name=` +
                        item.imagePath
                    }
                    alt="vehicle"
                  />
                </div>

                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h5 style={{ paddingBlock: "0px", marginBlock: "0px" }}>
                    {item.vehicleName}
                  </h5>
                  <p style={{ marginBlock: "0px", fontSize: "12px" }}>
                    {parseInt(item.distanceLimitperDay)} miles per day
                  </p>
                  <p style={{ marginBlock: "0px", fontSize: "12px" }}>
                    Radio/CD Player
                  </p>
                  <p style={{ marginBlock: "0px", fontSize: "12px" }}>
                    Extra Miles - $
                    {parseInt(item.additionalChargeforExceededDistance).toFixed(
                      2
                    )}{" "}
                    per mile
                  </p>
                </div>

              </div>

              <div
                style={{
                  width: "35%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {item.recommended && (
                  <h5
                    style={{
                      backgroundColor: "#81D4FA",
                      color: "white",
                      textAlign: "center",
                      marginTop: "0px",
                      marginBottom: "8px",
                      paddingBlock: "3px",
                    }}
                  >
                    Recommended
                  </h5>
                )}

                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "1px solid black",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    alignItems: "end",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                    <strong>${parseInt(item.dailyRate).toFixed(2)}</strong>
                    /Day
                  </p>
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    $
                    {parseFloat(item.days) >= 30
                      ? (
                        parseInt((parseInt(item.days) / 30).toFixed(0)) *
                        Number(item.monthilyRate) +
                        parseInt(
                          parseInt((parseInt(item.days) % 30) + "") / 7 + ""
                        ) *
                        Number(item.weeklyRate) +
                        parseInt(
                          ((parseInt(item.days) % 30) % 7).toFixed(0)
                        ) *
                        Number(item.dailyRate) +
                        parseFloat(item.hours) * Number(item.hourlyRate)
                      ).toFixed(2)
                      : parseFloat(item.days) >= 7
                        ? (
                          parseInt((parseInt(item.days) / 7).toFixed(0)) *
                          Number(item.weeklyRate) +
                          parseInt((parseInt(item.days) % 7).toFixed(0)) *
                          Number(item.dailyRate) +
                          parseFloat(item.hours) * Number(item.hourlyRate)
                        ).toFixed(2)
                        : (
                          parseFloat(item.days) * Number(item.dailyRate) +
                          parseFloat(item.hours) * Number(item.hourlyRate)
                        ).toFixed(2)}{" "}
                    total for {item.days} Day{" "}
                    {item.hours !== 0 && " and " + item.hours + " hours"}
                  </p>
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    Excl. taxes & insurance(s){" "}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      marginBlock: "0px",
                      color: "#81D4FA",
                    }}
                  >
                    Vehicles Available: {item.availableVehicles} (
                    {(
                      (parseFloat(item.availableVehicles) * 100) /
                      parseFloat(item.vehicleCount)
                    ).toFixed(2)}
                    %)
                  </p>
                  {status !== "Rental" &&
                    <button
                      style={{
                        marginBlock: "5px",
                        paddingBlock: "3px",
                        backgroundColor:
                          item.availableVehicles <= 0 ? "red" : "#66BB6A",
                        color: "white",
                        border: "none",
                        cursor:
                          item.availableVehicles <= 0
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={() => {
                        if (item.availableVehicles > 0) {
                          setSelectedVehicleClass(item);
                          getDataFromCars(item?.vehicleClassId, item);
                          goToNextPage(2);
                        }
                      }}
                    >
                      {item.availableVehicles <= 0
                        ? "Not Available"
                        : "Rent this Car"}
                    </button>
                  }
                </div>
              </div>
            </div>
            {index !== availableVehicle.length - 1 && (
              <hr style={{ marginTop: "16px" }} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
