import React from "react";

type Props = {
  params: any[];
};

export default function BranchesAmountView({ params }: Props) {
  return (
    <>
      {params.map((item: any) => {
        return (
          <div
            key={item.id}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p style={{ marginBlock: "0px" }}>{item.branchName}</p>
            <p style={{ marginBlock: "0px" }}>
              {item.chargeType === "Amount"
                ? "$" + item.amountOrPercentage
                : item.amountOrPercentage + "%"}
            </p>
          </div>
        );
      })}
    </>
  );
}
