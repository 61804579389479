import React, { useEffect, useState } from "react";
import axios from "axios";
import baseURL from "../../../configs/api";
import AddPayments from "./AddPayments";
import AddRefund from "./AddRefund";
import AddDiscount from "./AddDiscount";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";
import CloseIcon from "@mui/icons-material/Close";
import { post_ReservationAdditionalCharge } from "../../../services/reservationAdditionalCharge";

type Props = {
  reservation: any;
  reservationUpdate: () => void;
  paymentList: any[];
  paymentUpdate: () => void;
  refundList: any[];
  refundUpdate: () => void;
  selectedVehicleClass: any;
  discountList: any[];
  discountUpdate: () => void;
  securityDepositAmountToPaid: string;
  extraValue: any[]
};

export default function Payments({
  reservation,
  reservationUpdate,
  paymentList,
  paymentUpdate,
  refundList,
  refundUpdate,
  selectedVehicleClass,
  discountList,
  discountUpdate,
  securityDepositAmountToPaid,
  extraValue
}: Props) {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isAddAdjustment, setIsAddAdjustment] = useState<boolean>(false);
  const [adjustment, setAdjustment] = useState<string>("");
  const [price, setPrice] = useState<string>("");

  useEffect(() => {
    if (extraValue.length > 0)
      setAdjustment(extraValue[0]?.id);
  }, [extraValue])

  const changeStatusToQuote = () => {
    axios
      .put(
        `${baseURL}reservation`,
        {
          ...reservation,
          status: "Quote",
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        reservationUpdate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const changeStatusToPending = () => {
    axios
      .put(
        `${baseURL}reservation`,
        {
          ...reservation,
          status: "Pending",
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        reservationUpdate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const changeStatusToOpen = () => {
    axios
      .put(
        `${baseURL}reservation`,
        {
          ...reservation,
          status: "Open",
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        reservationUpdate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const changeStatusToCancel = () => {
    axios
      .put(
        `${baseURL}reservation`,
        {
          ...reservation,
          status: "Cancel",
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        reservationUpdate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const changeStatusToNoShow = () => {
    axios
      .put(
        `${baseURL}reservation`,
        {
          ...reservation,
          status: "No Show",
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        reservationUpdate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleAdjustment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);

    if (!isProcessing) {
      await post_ReservationAdditionalCharge(
        JSON.stringify({
          reservatinId: reservation?.id,
          additionalChargeByBranchId: adjustment,
          quantity: 1,
          basePrice: price,
        })
      )
        .then(data => updateReservation(parseFloat(price)))
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  const updateReservation = async (amount: number) => {
    await axios
      .put(
        `${baseURL}reservation`,
        {
          ...reservation,
          totalPrice: parseFloat(reservation?.totalPrice) + amount,
          outstandingBalance: parseFloat(reservation?.outstandingBalance) + amount,
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        setIsProcessing(false);
        setIsAddAdjustment(false);
        reservationUpdate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "24px",
        }}
      >
        <AddPayments
          reservation={reservation}
          reservationUpdate={() => reservationUpdate()}
          paymentList={paymentList}
          paymentUpdate={() => paymentUpdate()}
          securityDepositAmountToPaid={securityDepositAmountToPaid}
        />

        <AddRefund
          reservation={reservation}
          reservationUpdate={() => reservationUpdate()}
          refundList={refundList}
          refundUpdate={() => refundUpdate()}
        />

        <AddDiscount
          reservation={reservation}
          reservationUpdate={() => reservationUpdate()}
          selectedVehicleClass={selectedVehicleClass}
          discountList={discountList}
          discountUpdate={() => discountUpdate()}
        />

        <div
          style={{
            marginTop: "10px",
            display: reservation?.status !== "Rental" ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
            gap: "3px",
          }}
        >
          <button
            style={{
              marginBlock: "5px",
              paddingBlock: "3px",
              backgroundColor: "white",
              color: "black",
              border: "1px solid gray",
              padding: "8px",
              cursor: "pointer",
            }}
            onClick={() => changeStatusToQuote()}
          >
            Change to Quote
          </button>

          {reservation?.status === "Open" ? (
            <button
              style={{
                marginBlock: "5px",
                paddingBlock: "3px",
                backgroundColor: "white",
                color: "black",
                border: "1px solid gray",
                padding: "8px",
                cursor: "pointer",
              }}
              onClick={() => changeStatusToPending()}
            >
              Pending Reservation
            </button>
          ) : (
            <button
              style={{
                marginBlock: "5px",
                paddingBlock: "3px",
                backgroundColor: "white",
                color: "black",
                border: "1px solid gray",
                padding: "8px",
                cursor: "pointer",
              }}
              onClick={() => changeStatusToOpen()}
            >
              Change to Open
            </button>
          )}
          <button
            style={{
              marginBlock: "5px",
              paddingBlock: "3px",
              backgroundColor: "white",
              color: "black",
              border: "1px solid gray",
              padding: "8px",
              cursor: "pointer",
            }}
            onClick={() => changeStatusToCancel()}
          >
            Cancel Reservation
          </button>

          <button
            style={{
              marginBlock: "5px",
              paddingBlock: "3px",
              backgroundColor: "white",
              color: "black",
              border: "1px solid gray",
              padding: "8px",
              cursor: "pointer",
            }}
            onClick={() => changeStatusToNoShow()}
          >
            No Show
          </button>

          <button
            style={{
              marginBlock: "5px",
              paddingBlock: "3px",
              backgroundColor: "white",
              color: "black",
              border: "1px solid gray",
              padding: "8px",
              cursor: "pointer",
            }}
            onClick={() => setIsAddAdjustment(true)}
          >
            Add Charge or Adjustment
          </button>
        </div>
      </div>

      <Dialog
        open={isAddAdjustment}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "30%",
            display: "flex",
            position: "absolute",
            top: "30px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>Add new adjustment</p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => setIsAddAdjustment(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <form onSubmit={(e) => handleAdjustment(e)}>

            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              padding: " 8px 16px 16px 16px"
            }}>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="adjustment"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Charge or Adjustment
                  <br />
                  <select

                    name="adjustment"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={adjustment}
                    onChange={(e) => setAdjustment(e.target.value)}
                    required
                  >
                    {
                      extraValue.map((item: any) => {
                        return <option key={item.id} value={item.id}>{item.name}</option>
                      })
                    }

                  </select>
                </label>
              </div>

              <div style={{ width: "50%" }}>
                <label
                  htmlFor="price"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Price
                  <br />
                  <input
                    type="text"
                    name="price"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  />
                </label>
              </div>

            </div>

            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBlock: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                backgroundColor: "#eeeeee",
              }}
            >
              <div style={{ width: "50%" }} />

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: "#e0dee0",
                      color: colorConfigs.sidebar.color,
                    },
                  }}
                  onClick={() => setIsAddAdjustment(false)}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                    },
                  }}
                  type="submit"
                >
                  {isProcessing ? "Processing..." : "Save"}
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

    </>
  );
}
