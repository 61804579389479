import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton } from "@mui/material";
import baseURL from "../../../../configs/api";
import axois from "../../../../configs/axois";
import Select, { components } from "react-select";
import "./AddLabel.css";

type Props = {
  data: {
    id: number;
    label: string;
    link: string;
    isActive: boolean;
    isOnTopBar: boolean;
    isOpenInNewTab: boolean;
    topBarIcon: string;
    userId: string;
  };
  handleDelete: (id: number) => void;
  handleTheChange: (
    id: number,
    label: string,
    link: string,
    isActive: boolean,
    isOnTopBar: boolean,
    isOpenInNewTab: boolean,
    topBarIcon: string,
    userId: string
  ) => void;
};

const topBarValues = [
  {
    value: "alternatePlane1",
    label: "Alternate Plane",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-plane-167-474991.png",
  },
  {
    value: "alternatePlane2",
    label: "Alternate Plane",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-plane-1440142-1214643.png",
  },
  {
    value: "mailBulk",
    label: "Mail Bulk",
    icon: "https://cdn.iconscout.com/icon/premium/png-512-thumb/bulk-mail-8106087-6606997.png",
  },
  {
    value: "alternateCar",
    label: "Alternate Car",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-car-1780538-1517628.png",
  },
  {
    value: "location",
    label: "Location",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-gps-fixed-1779970-1518174.png",
  },
  {
    value: "addressCard",
    label: "Address Card",
    icon: "https://cdn.iconscout.com/icon/premium/png-512-thumb/address-card-2240304-1867652.png",
  },
];

const Option = (props: any) => (
  <components.Option {...props} className="top-bar-icon-option">
    <img src={props.data.icon} alt="logo" className="top-bar-icon-logo" />
    {props.data.label}
  </components.Option>
);

export const AddLabel = (props: Props) => {
  const [selectedTopBarIcon, setSelectedTopBarIcon] = useState(
    topBarValues.find((item: any) => item.value === props.data.topBarIcon)
  );

  const handleChange = (value: any) => {
    setSelectedTopBarIcon(value);
    setTopBarIcon(value.value);
  };

  const SingleValue = ({ children, ...props }: any) => (
    <components.SingleValue {...props}>
      <img
        src={selectedTopBarIcon?.icon}
        alt="s-logo"
        className="selected-logo"
      />
      {children}
    </components.SingleValue>
  );

  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(props.data.userId);
  const [label, setLabel] = useState(props.data.label);
  const [link, setLink] = useState(props.data.link);
  const [isActive, setIsActive] = useState(props.data.isActive);
  const [isOnTopBar, setIsOnTopBar] = useState(props.data.isOnTopBar);
  const [isOpenInNewTab, setIsOpenInNewTab] = useState(
    props.data.isOpenInNewTab
  );
  const [topBarIcon, setTopBarIcon] = useState(props.data.topBarIcon);

  useEffect(() => {
    axois
      .get(`${baseURL}users`)
      .then((response) => {
        setUsers(
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    props.handleTheChange(props.data.id, label, link, isActive,isOnTopBar,isOpenInNewTab, topBarIcon,userId,);
  }, [label, link, isActive, isOnTopBar, isOpenInNewTab, topBarIcon, userId]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
    >
      <div style={{ width: "25%", marginLeft: "10px", marginRight: "10px" }}>
        <input
          type="text"
          name="label"
          style={{
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
          }}
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          required
        />
      </div>

      <div style={{ width: "25%", marginLeft: "10px", marginRight: "10px" }}>
        <input
          type="text"
          name="link"
          style={{
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
          }}
          value={link}
          onChange={(e) => setLink(e.target.value)}
          required
        />
      </div>

      <div style={{ width: "10%", marginLeft: "10px", marginRight: "10px" }}>
        <select
          name="active"
          style={{
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
          }}
          value={isActive ? "Yes" : "No"}
          onChange={(e) => setIsActive(e.target.value === "Yes" ? true : false)}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>

      <div style={{ width: "10%", marginLeft: "10px", marginRight: "10px" }}>
        <select
          name="onTopBar"
          style={{
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
          }}
          value={isOnTopBar ? "Yes" : "No"}
          onChange={(e) =>
            setIsOnTopBar(e.target.value === "Yes" ? true : false)
          }
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>

      <div style={{ width: "10%", marginLeft: "10px", marginRight: "10px" }}>
        <select
          name="openInNewTab"
          style={{
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
          }}
          value={isOpenInNewTab ? "Yes" : "No"}
          onChange={(e) =>
            setIsOpenInNewTab(e.target.value === "Yes" ? true : false)
          }
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>

      <div style={{ width: "15%", marginLeft: "10px", marginRight: "10px" }}>
        <Select
          value={selectedTopBarIcon}
          options={topBarValues}
          onChange={handleChange}
          styles={{
            singleValue: (base) => ({
              ...base,
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              paddingBlock: "0",
            }),
          }}
          components={{
            Option,
            SingleValue,
          }}
        />
      </div>

      <div style={{ width: "15%", marginLeft: "10px", marginRight: "10px" }}>
        <select
          name="user"
          style={{
            width: "100%",
            fontSize: "14px",
            paddingBlock: "5px",
          }}
          value={userId}
          onChange={(e) => {
            setUserId(e.target.value);
            console.log(
              users.find((item: any) => item.value === e.target.value)
            );
          }}
        >
          {users.map((user: any) => {
            return (
              <option key={user.value} value={user.value}>
                {user.label}
              </option>
            );
          })}
        </select>
      </div>

      <div style={{ width: "5%" }}>
        <IconButton onClick={() => props.handleDelete(props.data.id)}>
          <DeleteForeverIcon sx={{ color: "red" }} />
        </IconButton>
      </div>
    </div>
  );
};
