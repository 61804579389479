import React, { useEffect, useState } from 'react'
import AmountPresentation from './AmountPresentation'
import { getDashboardBooking } from '../../services/dashboardBooking';
import Chart from '../../components/Chart'
import { Box } from '@mui/material';

type Props = {
  bookingDate: any
}

export default function Bookings({ bookingDate }: Props) {
  const [bookingData, setBookingData] = useState<any>(null);
  const [bookedForDay, setBookedForDay] = useState<any>(null);
  const [bookedForStatus, setBookedForStatus] = useState<any>(null);
  const [bookedForCountry, setBookedForCountry] = useState<any>(null);
  const [bookedForState, setBookedForState] = useState<any>(null);
  const [bookedForPerPickupLocation, setBookedForPerPickupLocation] = useState<any>(null);

  useEffect(() => {
    getDashboardBooking(bookingDate.startDate?.toDate(), bookingDate.endDate?.toDate())
      .then(data => setBookingData(data))
      .catch(error => console.error(error));
  }, [bookingDate])

  useEffect(() => {
    if (bookingData !== null) {
      const options = {
        chart: {
          type: "pie"
        },
        title: {
          text: 'Bookings Per Day'
        },
        credits: {
          enabled: false
        },
        accessibility: {
          enabled: true
        },
        series: [{
          type: "pie",
          name: "Booking",
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}({point.percentage:.1f}%)'
          },
          data: [
            { name: `Sunday`, y: bookingData?.bookingsPerDay?.sunday },
            { name: `Monday`, y: bookingData?.bookingsPerDay?.monday },
            { name: `Tuesday`, y: bookingData?.bookingsPerDay?.tuesday },
            { name: `Wednesday`, y: bookingData?.bookingsPerDay?.wednesday },
            { name: `Thursday`, y: bookingData?.bookingsPerDay?.thursday },
            { name: `Friday`, y: bookingData?.bookingsPerDay?.friday },
            { name: `Saturday`, y: bookingData?.bookingsPerDay?.saturday }
          ]
        }]
      }

      setBookedForDay(options);
    }
  }, [bookingData])

  useEffect(() => {
    if (bookingData !== null) {
      const options = {
        chart: {
          type: "pie"
        },
        title: {
          text: 'Bookings Per Status'
        },
        credits: {
          enabled: false
        },
        accessibility: {
          enabled: true
        },
        series: [{
          type: "pie",
          name: "Booking",
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}({point.percentage:.1f}%)'
          },
          data: [
            { name: `No Show`, y: bookingData?.bookingsPerStatus?.noShow },
            { name: `Completed`, y: bookingData?.bookingsPerStatus?.completed },
            { name: `Rental`, y: bookingData?.bookingsPerStatus?.rental },
            { name: `Open`, y: bookingData?.bookingsPerStatus?.open },
            { name: `Cancelled Paid`, y: bookingData?.bookingsPerStatus?.cancelledPaid },
            { name: `Cancelled`, y: bookingData?.bookingsPerStatus?.cancelled },
          ]
        }]
      }

      setBookedForStatus(options);
    }
  }, [bookingData])

  useEffect(() => {
    if (bookingData !== null) {
      const options = {
        chart: {
          type: "pie"
        },
        title: {
          text: 'Bookings Per Country'
        },
        credits: {
          enabled: false
        },
        accessibility: {
          enabled: true
        },
        series: [{
          type: "pie",
          name: "Booking",
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}({point.percentage:.1f}%)'
          },
          data: [
            { name: `United State`, y: bookingData?.bookingsPerCountry?.unitedState },
            { name: `Canada`, y: bookingData?.bookingsPerCountry?.canada },
            { name: `Australia`, y: bookingData?.bookingsPerCountry?.australia },
            { name: `United Kingdom`, y: bookingData?.bookingsPerCountry?.unitedKingdom },
            { name: `Germany`, y: bookingData?.bookingsPerCountry?.germany },
            { name: `France`, y: bookingData?.bookingsPerCountry?.france },
            { name: `China`, y: bookingData?.bookingsPerCountry?.china },
            { name: `India`, y: bookingData?.bookingsPerCountry?.india },
            { name: `Argentina`, y: bookingData?.bookingsPerCountry?.argentina },
          ]
        }]
      }
      setBookedForCountry(options);
    }
  }, [bookingData])

  useEffect(() => {
    if (bookingData !== null) {
      const options = {
        chart: {
          type: "pie"
        },
        title: {
          text: 'Bookings Per State'
        },
        credits: {
          enabled: false
        },
        accessibility: {
          enabled: true
        },
        series: [{
          type: "pie",
          name: "Booking",
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}({point.percentage:.1f}%)'
          },
          data: [
            { name: `Alabama`, y: bookingData?.bookingsPerState?.alabama },
            { name: `Alaska`, y: bookingData?.bookingsPerState?.alaska },
            { name: `Arizona`, y: bookingData?.bookingsPerState?.arizona },
            { name: `Arkansas`, y: bookingData?.bookingsPerState?.arkansas },
            { name: `California`, y: bookingData?.bookingsPerState?.california },
            { name: `Colorado`, y: bookingData?.bookingsPerState?.colorado },
            { name: `Connecticut`, y: bookingData?.bookingsPerState?.connecticut },
            { name: `Delaware`, y: bookingData?.bookingsPerState?.delaware },
            { name: `Florida`, y: bookingData?.bookingsPerState?.florida },
            { name: `Georgia`, y: bookingData?.bookingsPerState?.georgia },
            { name: `Hawaii`, y: bookingData?.bookingsPerState?.hawaii },
            { name: `Idaho`, y: bookingData?.bookingsPerState?.idaho },
            { name: `Illinois`, y: bookingData?.bookingsPerState?.illinois },
            { name: `Indiana`, y: bookingData?.bookingsPerState?.indiana },
            { name: `Iowa`, y: bookingData?.bookingsPerState?.iowa },
            { name: `Kansas`, y: bookingData?.bookingsPerState?.kansas },
            { name: `kentucky`, y: bookingData?.bookingsPerState?.kentucky },
            { name: `Louisiana`, y: bookingData?.bookingsPerState?.louisiana },
            { name: `Maine`, y: bookingData?.bookingsPerState?.maine },
            { name: `Maryland`, y: bookingData?.bookingsPerState?.maryland },
            { name: `Massachusetts`, y: bookingData?.bookingsPerState?.massachusetts },
            { name: `Michigan`, y: bookingData?.bookingsPerState?.michigan },
            { name: `Mississippi`, y: bookingData?.bookingsPerState?.mississippi },
            { name: `Missouri`, y: bookingData?.bookingsPerState?.missouri },
            { name: `Montana`, y: bookingData?.bookingsPerState?.montana },
            { name: `Nebraska`, y: bookingData?.bookingsPerState?.nebraska },
            { name: `Nevada`, y: bookingData?.bookingsPerState?.nevada },
            { name: `New Hampshire`, y: bookingData?.bookingsPerState?.newHampshire },
            { name: `New Jersey`, y: bookingData?.bookingsPerState?.newJersey },
            { name: `New Mexico`, y: bookingData?.bookingsPerState?.newMexico },
            { name: `New York`, y: bookingData?.bookingsPerState?.newYork },
            { name: `North Carolina`, y: bookingData?.bookingsPerState?.northCarolina },
            { name: `North Dakota`, y: bookingData?.bookingsPerState?.northDakota },
            { name: `Ohio`, y: bookingData?.bookingsPerState?.ohio },
            { name: `Oklahoma`, y: bookingData?.bookingsPerState?.oklahoma },
            { name: `Oregon`, y: bookingData?.bookingsPerState?.oregon },
            { name: `Pennsylvania`, y: bookingData?.bookingsPerState?.pennsylvania },
            { name: `Rhode Island`, y: bookingData?.bookingsPerState?.rhodeIsland },
            { name: `South Carolina`, y: bookingData?.bookingsPerState?.southCarolina },
            { name: `South Dakota`, y: bookingData?.bookingsPerState?.southDakota },
            { name: `Tennessee`, y: bookingData?.bookingsPerState?.tennessee },
            { name: `Texas`, y: bookingData?.bookingsPerState?.texas },
            { name: `Utah`, y: bookingData?.bookingsPerState?.utah },
            { name: `Vermont`, y: bookingData?.bookingsPerState?.vermont },
            { name: `Virginia`, y: bookingData?.bookingsPerState?.virginia },
            { name: `Washington`, y: bookingData?.bookingsPerState?.washington },
            { name: `WestVirginia`, y: bookingData?.bookingsPerState?.westVirginia },
            { name: `Wisconsin`, y: bookingData?.bookingsPerState?.wisconsin },
            { name: `Wyoming`, y: bookingData?.bookingsPerState?.wyoming },
          ]
        }]
      }
      setBookedForState(options);
    }
  }, [bookingData])

  useEffect(() => {
    if (bookingData !== null) {
      const options = {
        chart: {
          type: "pie"
        },
        title: {
          text: 'Bookings Per Pickup Location'
        },
        credits: {
          enabled: false
        },
        accessibility: {
          enabled: true
        },
        series: [{
          type: "pie",
          name: "Booking",
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}({point.percentage:.1f}%)'
          },
          data: [
            { name: `IAH Airport`, y: bookingData?.bookingsPerPickupLocation?.iahAirport },
            { name: `Sugar Land`, y: bookingData?.bookingsPerPickupLocation?.sugarLand },
            { name: `South West Houston`, y: bookingData?.bookingsPerPickupLocation?.soutWestHouston },
            { name: `Hobby Airport`, y: bookingData?.bookingsPerPickupLocation?.hobbyAirport },
          ]
        }]
      }
      setBookedForPerPickupLocation(options);
    }
  }, [bookingData])


  return (
    <>
      <AmountPresentation bookingData={bookingData} />

      <Box sx={{
        display: "flex",
        width: "100%",
        flexDirection: { sm: "column", lg: "row" },
        flex: 1,
        flexWrap: "wrap",
        gap: "16px",
        justifyContent: "center",
        padding: "24px"
      }}>
        {bookedForDay && <Chart options={bookedForDay} />}
        {bookedForStatus && <Chart options={bookedForStatus} />}
      </Box>

      <Box sx={{
        display: "flex",
        width: "100%",
        flexDirection: { sm: "column", lg: "row" },
        flex: 1,
        flexWrap: "wrap",
        gap: "16px",
        justifyContent: "center",
        padding: "12px 24px 12px 24px"
      }}>
        {bookedForCountry && <Chart options={bookedForCountry} />}
        {bookedForState && <Chart options={bookedForState} />}
      </Box>
      <Box sx={{
        display: "flex",
        width: "100%",
        flexDirection: { sm: "column", lg: "row" },
        flex: 1,
        flexWrap: "wrap",
        gap: "16px",
        alignItems: "center",
        justifyContent: "start",
        padding: "12px 24px 12px 24px"
      }}>
        {bookedForPerPickupLocation && <Chart options={bookedForPerPickupLocation} />}
        <div />
      </Box>
    </>
  )
}
