import React, { useEffect, useState } from 'react';
import {
  Box,
  Toolbar,
  Typography,
  Stack,
  Button,
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import colorConfigs from '../../../../configs/colorConfigs';
import { PaidOutlined, ArrowDropDown, ArrowDropUp,  ArrowRightOutlined } from '@mui/icons-material';
import MenuIcon from "@mui/icons-material/Menu";
import AllGateways from './AllGateways';
import './Gateway.css';
import baseURL from '../../../../configs/api';
import { useNavigate } from 'react-router';

const pages = ['All'];

const Gateways = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [firstTime, setFirstTime] = useState<string>(pages[0]);
  const [isMenuShown, setIsMenuShow] = useState<boolean>(false);
  const [menuData, setMenuData] = useState<any[]>([]);

  useEffect(() => {
    fetch(`${baseURL}paymentGatewayMenu`)
      .then((res) => res.json())
      .then((data) => {
        const grouped = data.reduce(
          (result: any[], item: any) => {
            if (!result[item.region]) {
              result[item.region] = [];
            }
            result[item.region].push(item);
            return result;
          },
          {}
        );

        setMenuData(() =>
          Object.keys(grouped).map((key) => {
            return {
              region: key,
              provider: grouped[key],
            };
          })
        );
      });
  }, [])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  return (
    <Box>
      <Toolbar sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <PaidOutlined
            sx={{ fontSize: '24px', marginRight: '5px' }}
          />
          <Typography variant="body1" sx={{ fontSize: '20px' }}>
            Payment Gateways
          </Typography>
        </Box>

        <Stack spacing={1} direction="row">
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#66bb6a',
              color: 'white',
              borderRadius: '0',
              fontSize: 13,
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#66bb6a',
                color: 'white',
              },
            }}
          >
            Signup with Stripe
          </Button>

          <ul className="nested-dropdowns" >
            <li style={{ background: "transparent", padding: "0" }}>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  borderRadius: "0",
                  fontSize: 13,
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                  }
                }}
                onClick={() => setIsMenuShow(!isMenuShown)}
                endIcon={isMenuShown ? <ArrowDropUp /> : <ArrowDropDown />}
              >
                Add Gateway
              </Button>

              <ul style={{ display: isMenuShown ? "block" : "none" }}>
                {menuData.map((item: any, index: number) => {
                  return (
                    <li key={index}>
                      <div className="nested-dropdowns__item">
                        <span style={{ fontSize: "14px" }}>{item.region}</span>
                        <ArrowRightOutlined sx={{ fontSize: "18px" }} />
                      </div>
                      <ul>
                        {item.provider.map((value: any, index: number,) => {
                          return <li key={index} onClick={() => navigate("/admin/paymentgateways/paymentgatewaysform", {state: {id: value.id, provider: value.provider}})}><span style={{ fontSize: "14px" }}>{value.provider}</span></li>
                        })}
                      </ul>
                    </li>
                  )
                })}
              </ul>
            </li>
          </ul>

          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#e0dee0",
              color: "black",
              borderRadius: "0",
              border: "none",
            }}
          >
            <MenuIcon />
          </Button>
        </Stack>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: 'none',
          paddingLeft: '18px',
          paddingRight: '18px',
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: 'black', paddingBlock: '4px' }}
            >
              <span>&#9776;</span>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              paddingTop: '8px',
              paddingLeft: '0px',
              marginLeft: '-20px',
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: 'black',
                      display: 'block',
                      paddingBlock: '0px',
                      marginBlock: '0px',
                      borderRadius: '0px',
                      fontSize: '12px',
                      fontWeight: 'normal',
                      textTransform: 'none',
                      textAlign: 'center',

                      backgroundColor: 'white',
                      borderWidth: '1px',
                      borderColor: 'black',
                      borderStyle: 'solid solid none solid',
                      '&:hover': {
                        backgroundColor: 'white',
                        borderWidth: '1px',
                        borderColor: 'black',
                        borderStyle: 'solid solid none solid',
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: 'black',
                      display: 'block',
                      paddingBlock: '0px',
                      marginBlock: '0px',
                      borderRadius: '0px',
                      fontSize: '12px',
                      fontWeight: 'normal',
                      textTransform: 'none',
                      textAlign: 'center',
                      border: 'none',
                      '&:focus': {
                        backgroundColor: 'white',
                        borderWidth: '1px',
                        borderColor: 'black',
                        borderStyle: 'solid solid none solid',
                      },
                      '&:active': {
                        backgroundColor: 'white',
                        borderWidth: '1px',
                        borderColor: 'black',
                        borderStyle: 'solid solid none solid',
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>

      <div style={{ display: firstTime === pages[0] ? 'block' : 'none' }}>
        <AllGateways />
      </div>
    </Box>
  );
};

export default Gateways;
