import axios from "axios";
import baseURL from "../configs/api";

export const put_AccessControl = (id: number, allowDenyStatusId: number, token: string) => {
    return axios({
        url: `${baseURL}accessControl/AccessControl/${id},${allowDenyStatusId}`,
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        withCredentials: true
    }).then(response => response.data);
}

export const put_AccessControlByGroupName = (id: number, groupName: string, allowDenyStatusId: number, token: string) => {
    return axios({
        url: `${baseURL}accessControl/AccessControlByGroupName/${id},${groupName},${allowDenyStatusId}`,
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        withCredentials: true,
    }).then(response => response.data);
}

export const getAccessControl = (url: string, params = {}, token: string) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        params: params
    }).then(response => response.data);
}