import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import baseURL from "../../../configs/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PDFDocument } from "pdf-lib";
import SignatureCanvas from "react-signature-canvas";
import { Dialog, DialogContent } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";

type Props = {
  goToNextPage: (page: number) => void;
  reservation: any;
  signatureURL: any;
  setSignatureURL: (value: any) => void;
  initialsURL: any;
  setInitialsURL: (Value: any) => void;
  setSignatureChange: (value: boolean) => void;
};

export default function Agreement({
  goToNextPage,
  reservation,
  signatureURL,
  setSignatureURL,
  initialsURL,
  setInitialsURL,
  setSignatureChange,
}: Props) {
  const [isContract, setIsContract] = useState<boolean>(true);
  const [fileUrl, setFileUrl] = useState<string>("");

  const [isSignatures, setIsSignatures] = useState<boolean>(true);

  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

  let signaturePadRef = React.useRef<SignatureCanvas>(null);
  let initialsPadRef = React.useRef<SignatureCanvas>(null);

  const signatureFileRef = useRef<HTMLInputElement>(null);
  const onSignatureFileChangeCapture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPleaseWaitPopup(true);
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}reservationFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((response) => {
          const newFilePath: any = {
            id: 0,
            fileName: response.data,
          };
          signaturePadRef.current?.clear();
          setSignatureURL(newFilePath);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setPleaseWaitPopup(false);
    }
  };

  const initialsFileRef = useRef<HTMLInputElement>(null);
  const onInitialsFileChangeCapture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPleaseWaitPopup(true);
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}reservationFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((response) => {
          const newFilePath: any = {
            id: 0,
            fileName: response.data,
          };

          initialsPadRef.current?.clear();
          setInitialsURL(newFilePath);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setPleaseWaitPopup(false);
    }
  };

  useEffect(() => {
    async function fetchAndConvertPdf() {
      try {
        const response = await axios.get(
          `${baseURL}rentalAgreementPdf?reservationId=${reservation?.id}`,
          {
            responseType: "arraybuffer", // Fetch the PDF as binary data
          }
        );

        const pdfData = new Uint8Array(response.data);
        const encodedArray = Array.from(pdfData);
        const base64PdfData = btoa(
          String.fromCharCode.apply(null, encodedArray)
        );

        const bytes = Uint8Array.from(atob(base64PdfData), (c) =>
          c.charCodeAt(0)
        );

        // Create a PDFDocument from the bytes
        const pdfDoc = await PDFDocument.load(bytes);

        // Modify the PDF content
        const page = pdfDoc.getPages()[0];

        // Set custom metadata (including the file name)
        pdfDoc.setTitle(`RentalAgreement${reservation?.id}.pdf`);
        pdfDoc.setAuthor(reservation?.createdBy);
        pdfDoc.setSubject("Rental Agreement");
        pdfDoc.setProducer("JJ Rental");
        pdfDoc.setCreator("JJ Rental");

        // Serialize the modified PDF to a new Uint8Array
        const modifiedBytes = await pdfDoc.save();

        const modifiedArray = Array.from(modifiedBytes);

        // Convert the modifiedBytes to a base64-encoded string
        const modifiedBase64 = btoa(String.fromCharCode(...modifiedArray));

        setFileUrl(modifiedBase64);
      } catch (error) {
        console.error("Error fetching or converting PDF:", error);
      }
    }

    if (reservation) fetchAndConvertPdf();
  }, [reservation]);

  const uploadSignature = () => {
    if (signatureURL && signatureURL?.id === 0) {
      axios
        .post(
          `${baseURL}fileUploadData`,
          JSON.stringify({
            attributeName: "Reservation",
            attributeId: reservation?.id,
            fileName: signatureURL.fileName,
            tabName: "Signature",
            userId: 1,
            createdDate: new Date(),
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          uploadInitial();
          signaturePadRef.current?.clear();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const url = signaturePadRef.current
        ?.getTrimmedCanvas()
        .toDataURL("image/png");
      if (
        url !==
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC"
      ) {
        axios
          .post(
            `${baseURL}fileUploadData`,
            JSON.stringify({
              attributeName: "Reservation",
              attributeId: reservation?.id,
              fileName: url,
              tabName: "Signature",
              userId: 1,
              createdDate: new Date(),
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then((response) => {
            uploadInitial();
            signaturePadRef.current?.clear();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        uploadInitial();
      }
    }
  };

  const uploadInitial = () => {
    if (initialsURL && initialsURL?.id === 0) {
      axios
        .post(
          `${baseURL}fileUploadData`,
          JSON.stringify({
            attributeName: "Reservation",
            attributeId: reservation?.id,
            fileName: initialsURL.fileName,
            tabName: "Initials",
            userId: 1,
            createdDate: new Date(),
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          goToNextPage(8);
          setSignatureChange(true);
          initialsPadRef.current?.clear();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const url = initialsPadRef.current
        ?.getTrimmedCanvas()
        .toDataURL("image/png");
      if (
        url !==
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC"
      ) {
        axios
          .post(
            `${baseURL}fileUploadData`,
            JSON.stringify({
              attributeName: "Reservation",
              attributeId: reservation?.id,
              fileName: url,
              tabName: "Initials",
              userId: 1,
              createdDate: new Date(),
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then((response) => {
            goToNextPage(8);
            setSignatureChange(true);
            initialsPadRef.current?.clear();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        goToNextPage(8);
        setSignatureChange(true);
      }
    }
  };

  const deleteTheSignatureURL = async () => {
    if (
      window.confirm("Are you sure you want to delete this Signature?") === true
    ) {
      if (signatureURL?.id === 0) {
        setSignatureURL(null);
      } else {
        await axios
          .delete(`${baseURL}fileUploadData?id=${signatureURL?.id}`)
          .then((response) => {
            setSignatureURL(null);
            setSignatureChange(true);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  const deleteTheInitialsURL = async () => {
    if (
      window.confirm("Are you sure you want to delete this Initials?") === true
    ) {
      if (initialsURL.id === 0) {
        setInitialsURL(null);
      } else {
        await axios
          .delete(`${baseURL}fileUploadData?id=${initialsURL?.id}`)
          .then((response) => {
            setInitialsURL(null);
            setSignatureChange(true);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "24px",
        }}
      >
        <h5
          style={{
            paddingBlock: "0px",
            marginBlock: "0px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            cursor: "pointer",
            WebkitUserSelect: "none" /* Safari */,
            msUserSelect: "none" /* IE 10 and IE 11 */,
            userSelect: "none" /* Standard syntax */,
          }}
          onClick={() => setIsContract(!isContract)}
        >
          {isContract ? (
            <ExpandMoreIcon sx={{ fontSize: "20px" }} />
          ) : (
            <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
          )}{" "}
          Contract
        </h5>
        <hr />

        {isContract && (
          <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
            {fileUrl && (
              <embed
                src={`data:application/pdf;base64,${fileUrl}`}
                type="application/pdf"
                width="100%"
                height="600"
              />
            )}
          </div>
        )}

        <h5
          style={{
            paddingBottom: "0px",
            marginBottom: "0px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            cursor: "pointer",
            WebkitUserSelect: "none" /* Safari */,
            msUserSelect: "none" /* IE 10 and IE 11 */,
            userSelect: "none" /* Standard syntax */,
          }}
          onClick={() => setIsSignatures(!isSignatures)}
        >
          {isSignatures ? (
            <ExpandMoreIcon sx={{ fontSize: "20px" }} />
          ) : (
            <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
          )}{" "}
          Signatures
        </h5>
        <hr />

        {isSignatures && (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <div
                style={{
                  width: "50%",
                }}
              >
                <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Signature
                </p>
                <div
                  style={{
                    border: "1px dashed gray",
                    backgroundImage: `url(${
                      signatureURL?.fileName.startsWith("data:image/png;base64")
                        ? signatureURL?.fileName
                        : `${baseURL}reservationFile/Get?name=` +
                          signatureURL?.fileName
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: signatureURL?.fileName.startsWith(
                      "data:image/png;base64"
                    )
                      ? ""
                      : "cover",
                  }}
                >
                  <SignatureCanvas
                    ref={signaturePadRef}
                    penColor={colorConfigs.topbar.bg}
                    canvasProps={{
                      width: 500,
                      height: 150,
                      className: "sigCanvas",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <i
                    style={{
                      fontSize: "18px",
                      backgroundColor: "orange",
                      color: "white",
                      padding: "3px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    className="bx bxs-eraser"
                    onClick={() => {
                      signaturePadRef.current?.clear();
                      if (signatureURL) {
                        deleteTheSignatureURL();
                      }
                    }}
                  ></i>
                  <input
                    type="file"
                    accept="image/*"
                    ref={signatureFileRef}
                    onChangeCapture={onSignatureFileChangeCapture}
                    style={{ display: "none" }}
                  />
                  <i
                    style={{
                      fontSize: "18px",
                      backgroundColor: "skyblue",
                      color: "white",
                      padding: "3px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    className="bx bx-upload"
                    onClick={() => {
                      if (!signatureURL) {
                        if (signatureFileRef.current !== null) {
                          signatureFileRef.current.click();
                        }
                      } else {
                        if (signatureURL?.id === 0) {
                          if (signatureFileRef.current !== null) {
                            signatureFileRef.current.click();
                          }
                        } else {
                          window.alert("Please remove this Signature first.");
                        }
                      }
                    }}
                  ></i>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <p style={{ fontSize: "12px", fontWeight: "bold" }}>Initials</p>
                <div
                  style={{
                    border: "1px dashed gray",
                    backgroundImage: `url(${
                      initialsURL?.fileName.startsWith("data:image/png;base64")
                        ? initialsURL?.fileName
                        : `${baseURL}reservationFile/Get?name=` +
                          initialsURL?.fileName
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: initialsURL?.fileName.startsWith(
                      "data:image/png;base64"
                    )
                      ? ""
                      : "cover",
                  }}
                >
                  <SignatureCanvas
                    ref={initialsPadRef}
                    penColor={colorConfigs.topbar.bg}
                    canvasProps={{
                      width: 500,
                      height: 150,
                      className: "sigCanvas",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <i
                    style={{
                      fontSize: "18px",
                      backgroundColor: "orange",
                      color: "white",
                      padding: "3px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    className="bx bxs-eraser"
                    onClick={() => {
                      initialsPadRef.current?.clear();
                      if (initialsURL) {
                        deleteTheInitialsURL();
                      }
                    }}
                  ></i>
                  <input
                    type="file"
                    accept="image/*"
                    ref={initialsFileRef}
                    onChangeCapture={onInitialsFileChangeCapture}
                    style={{ display: "none" }}
                  />
                  <i
                    style={{
                      fontSize: "18px",
                      backgroundColor: "skyblue",
                      color: "white",
                      padding: "3px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    className="bx bx-upload"
                    onClick={() => {
                      if (!initialsURL) {
                        if (initialsFileRef.current !== null) {
                          initialsFileRef.current.click();
                        }
                      } else {
                        if (initialsURL?.id === 0) {
                          if (initialsFileRef.current !== null) {
                            initialsFileRef.current.click();
                          }
                        } else {
                          window.alert("Please remove this Inititals first.");
                        }
                      }
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <button
                style={{
                  marginBlock: "5px",
                  paddingBlock: "5px",
                  backgroundColor: "#66BB6A",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
                type="submit"
                onClick={() => uploadSignature()}
              >
                Confirm the Agreement
              </button>
            </div>
          </>
        )}
      </div>

      <Dialog
        open={pleaseWaitPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "32%",
          },
        }}
      >
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
              backgroundColor: "white",
              color: "black",
              textAlign: "center",
            }}
          >
            Please Wait...
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
