import axios from "axios"
import baseURL from "../configs/api"

export const getCreditCards = () => {
    return getFetch("creditCard")
}

export const getCreditCard = (primaryKey: string) => {
    return getFetch(`creditCard/${primaryKey}`)
}

export const getCreditCardOfCustomer = (customerId: string) => {
    return getFetch(`creditCard`, {customerId: customerId})
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}